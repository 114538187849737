.statement {
  height: 737.5px;

  &:nth-of-type(odd) {
    position: relative;

    &::after {
      position: absolute;
      display: block;
      content: '';
      height: 1px;
      left: -50px;
      right: -50px;
      bottom: 0;
      border-bottom: 1px dashed #828282;
    }
  }

  .statement_table_cover {
    padding: 30px 50px;
    box-sizing: border-box;
  }

  .statement_table {
    width: 100%;
    max-height: 100%;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid #828282;
      padding: 6px 10px;
      font-size: 14px;
      line-height: 16px;
    }

    .text_center {
      text-align: center;
    }

    .text_right {
      text-align: right;
    }

    .width_60 {
      width: 60%;
    }

    .width_70 {
      width: 70%;
    }
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  size: A4;
  margin: 0;

  .statement_body {
    width: 210mm;
  }
}