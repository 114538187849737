﻿.training_route_building_cover{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.slider_training{
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  .list_slide{
    width: 100%;
    height: 100%;
  }
  .slide{
    width: 100%;
    height: 100%;
    background-color: #cccccc;
    background-size: cover !important;
    background-position: top right !important;
    display: none;
    &.active{
      display: block;
    }
  }
  .text{
    position: absolute;
    left: 60px;
    max-width: 80%;
    >div{
      display: flex;
      margin-bottom: 30px;
      >div{
        flex-grow: 1;
        color: #fff;
        font-size: 24px;
        line-height: 28.13px;
        font-weight: 400;
      }
    }
    span{
      font-size: 32px;
      color: #fff;
      font-weight: 400;
      text-align: center;
      background: #EBB563;
      width: 48px;
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      margin-right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
  
  .nav_slider{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px;
    padding-top: 10px;
    box-sizing: border-box;
    .right{
      display: flex;
      position: absolute;
      right: 30px;
      bottom: 37px;
      .btn{
        background: #EBB563;
        border-radius: 4px;
        height: 60px;
        text-align: center;
        width: 200px;
        margin-left: 10px;
        color: #fff;
        font-size: 14px;
        padding: 20px 0;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .nav{
      display: flex;
      align-items: center;
      .prev, .next{
        background: url("../../img/nav_slider.svg") center no-repeat;
        width: 68px;
        height: 68px;
        cursor: pointer;
        &.disabled{
          cursor: default;
        }
      }
      .next{
        transform: rotate(180deg);
      }
      .position{
        font-weight: bold;
        padding: 13px 0;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #EBB563;
        background: #FFFFFF;
        border: 1px solid #EBB563;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 100px;
        height: 60px;
        box-sizing: border-box;
      }
    }
  }
  .templates_slider {
    justify-content: flex-start;

    .right {
      position: static;
    }
  }
}

.training_route_templates_cover{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

@media (max-height: 750px) and (max-width: 1600px){
  .slider_training {
    .text {
      >div {
        >div {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }
}