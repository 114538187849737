.arcticmodal-overlay,
.arcticmodal-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.arcticmodal-container {
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse;
}

*:first-child + html .arcticmodal-container {
  height: 100%
}

.arcticmodal-container_i {
  height: 100%;
  margin: 0 auto;
}

.arcticmodal-container_i2 {
  padding: 24px;
  margin: 0;
  border: 0;
  vertical-align: middle;
}

.arcticmodal-error {
  padding: 20px;
  border-radius: 10px;
  background: #000;
  color: #fff;
}

.arcticmodal-loading {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  background: url('/Content/images/loading.svg') no-repeat 50% 50%, rgba(255, 255, 255, 0.85);
}

.box-modal {
  background-color: #fff;
  padding: 35px;
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
  position: relative;
  /*transform: scale(0.1);*/
  transition: all 300ms;
  width: 500px;
  font-size: 16px;
  border-radius: 8px;
  &.file_loaded_modal {
    width: 600px;
    p {
      font-weight: bold;
      margin-bottom: 0;
      &.subtitle {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
      }
      span {
        font-weight: normal;
        font-size: 18px;
      }
    }
    .cols_cover {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .col{
        min-width: 49%;
        max-width: 49%;
        padding: 15px 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
      }
      >div:nth-of-type(2){
        padding-left: 2%;
      }
    }
    .badRowsCover {
      margin-bottom: 10px;
      margin-top: 18px;
      p{
        margin-bottom: 0;
      }
      i {
        width: 36px;
        height: 36px;
        display: inline-block;
        text-align: center;
        line-height: 36px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        background: #dc424f;
        font-style: normal;
        color: #fff;
        font-weight: bold;
        margin-right: 5px;
        margin-bottom: 10px;
        font-size: 13px;
        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
  &.appoint_modal {
    width: 80vw;
    .modal-content{
      .table-cover{
        margin-bottom: 20px;
      }
    }
    .modal-content>div{
      margin-bottom: 10px;
    }
    .table-cover{
      overflow: auto;
      margin-bottom: 0px;
      margin-top: 20px;
      table{
        width: 100%;
        overflow: hidden;
      }
      .mdl-textfield{
        width: 110px;
        font-size: 14px;
        padding: 10px 0;
        &.phone{
          width: 130px;
        }
        .mdl-textfield__error{
          left: -10px;
          top: 100%;
          width: 100%;
          white-space: normal;
          line-height: 14px;
          background: #fff;
          padding: 8px 10px;
          z-index: 2;
          border-radius: 3px;
          margin-top: -10px;
          box-shadow: 0 1px 8px rgba(0,0,0,0.3);
          font-size: 10px;
        }
        input{
          font-size: 14px;
        }
        .mdl-textfield__label{
          font-size: 14px;
          top: 16px;
          &:after{
            bottom: 10px;
          }
        }
      }
    }
  }
  &.add_gas_station{
    .map_cover{
      height: 20vh;
      min-height: 490px;
      background: #ccc;
    }
    .gas_station_info{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 30px;
    }
    .mdl-textfield{
      width: 100%;
      margin-top: -17px;
      .select2-container{
        width: 100% !important;
      }
    }
    .mdl-textfield.half{
      width: 49%;
    }
  }
  &.select_template{
    width: 620px;
    .table_scroll_cover{
      overflow: auto;
      margin: 0 -35px;
    }
    .template_head{
      display: flex;
      .mdl-textfield{
        margin-right: 10px;
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
    .table-cover{
      .mdl-data-table td, .mdl-data-table th {
        padding: 2px 10px;
        &:first-of-type{
          padding-left: 20px;
        }
      }
      .mdl-button{
        min-width: 36px;
        padding: 0 6px;
      }
    }
  }
  &.save_template{
    width: 390px;
    .mdl-textfield{
      width: 100%;
    }
    .content_half{
      width: 49%;
      display: inline-block;
    }
  }
  &.economy_modal{
    width: 800px;
    .economy_table_cover{
      margin: 0 -35px;
      margin-top: -26px;
      width: auto;
    }
    .table_levels{
      width: 100%;
      th:first-of-type {
        padding-left: 10px;
      }
      tr[data-level]{
        .nowrap{
          white-space: nowrap;
        }
        td:first-of-type{
          font-size: 16px;
          font-weight: 600;
          padding: 5px 0;
          padding-left: 35px;
          span{
            cursor: pointer;
            position: relative;
            border-bottom: 1px dashed #b5b5b5;
            &:before{
              content: '';
              display: inline-block;
              height: 0;
              width: 0;
              border-top: 5px solid #000;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              margin-right: 5px;
              position: relative;
              top: -2px;
            }
            i{
              position: absolute;
              left: -8px;
              bottom: -1px;
              height: 47px;
              width: 10px;
              border-left: 1px solid #b5b5b5;
              border-bottom: 1px solid #b5b5b5;
              pointer-events: none;
            }
          }
        }
        td:last-of-type{
          padding-right: 35px;
        }
        .checkbox_cover label{
          .icon{
            margin-right: 0;
          }
        }
        td{
          height: 48px;
          .price{
            font-size: 12px;
            color: #ccc;
            position: relative;
            top: -2px;
          }
          .mdl-textfield{
            padding: 0;
          }
        }
      }
      tr[data-level="0"]{
        td:first-of-type{
          font-weight: 600;
          .checkbox_cover{
            margin-right: 6px;
          }
          span{
            i{
              content: '';
              position: absolute;
              left: -4px;
              bottom: -1px;
              height: 47px;
              width: 7px;
              border-left: none;
              border-bottom: 1px dashed #757575;
            }
          }
        }
      }
      tr[data-level="1"]{
        td:first-of-type{
          font-weight: 500;
          .checkbox_cover{
            margin-right: 17px;
          }
        }
      }
      tr[data-level="2"]{
        td:first-of-type{
          font-size: 14px;
          font-weight: 400;
          .checkbox_cover{
            margin-right: 30px;
          }
        }
      }
      tr[data-level="3"]{
        td:first-of-type{
          font-size: 12px;
          color: #3F51B5;
          font-weight: 700;
          /*max-width: 300px;*/
          span{
            padding-left: 0px;
            &:before{
              display: none;
            }
          }
          .checkbox_cover{
            margin-right: 42px;
          }
        }
      }
    }
    .table_levels_small{
      border: none;
      /*border-top: 1px solid #E4E4E4;*/
      border-bottom: 1px solid #E4E4E4;
      box-shadow: none;
      white-space: normal;
      th, td{
        &:first-of-type{
          padding-left: 35px;
        }
        &:last-of-type{
          padding-right: 35px;
        }
      }
      span{
        border-bottom: none;
        i{
          display: none;
        }
      }
    }
    .table_levels_small_summary{
      padding-top: 24px;
      font-size: 16px;
      .summary_title{
        font-weight: 500;
        margin-bottom: 0;
      }
      >div{
        display: flex;
        justify-content: space-between;
        &:first-of-type{
          margin-bottom: 15px;
        }
        &:last-of-type{
          justify-content: flex-end;
        }
      }
    }
    .economy_footer_row{
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 5px;
      p{
        font-weight: bold;
        margin-bottom: 0;
      }
      span{
        position: relative;
        bottom: -3px;
      }
      .green{
        color: #3AB027;
      }
    }
    .modal_footer{
      .mdl-button{
        margin-top: 15px;
      }
    }
    .mdl-data-table th{
      padding: 6px 18px;
      line-height: 14px;
    }
  }
  &.driver_message{
    .label_title{
      font-weight: bold;
      color: #000000;
    }
    .radio_cover{
      margin-bottom: 15px;
    }
    .textarea{
      min-height: 40px;
      border-bottom: 1px solid #ccc;
      outline: transparent;
    }
  }
  .modal_header {

  }
  .modal_footer{
    text-align: right;
    margin-top: 20px;
  }
  .modal_title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 25px;
    .material-icons {
      vertical-align: bottom;
    }
  }
  .mdl-layout-spacer {
    flex-grow: 1;
  }
  .gavel_modal_cover {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    > div {
      width: 165px;
      line-height: 21px;
      font-size: 14px;
      text-align: center;
      padding: 8px 12px;
      padding-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      .mdl-button {
        width: 100%;
      }
      span {
        display: block;
        margin-bottom: 7px;
      }
    }
    b {
      display: inline-block;
      font-size: 16px;
    }
    .mdl-textfield {
      width: 150px;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 11px;
      .mdl-textfield__label {
        top: 6px;
        &:after {
          bottom: 0px !important;
        }
      }
    }
    .mdl-button {
      margin-top: 10px;
    }
  }
  .bids_cover {
    position: absolute;
    left: 100%;
    width: 240px;
    margin-left: 15px;
    top: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 8px;
    .title{
      padding: 12px 20px;
      background: #fff;
      border-radius: 8px 8px 0 0;
    }
    .list_cover{
      border-radius: 0 0 8px 8px;
      background: #fff;
      padding: 12px 20px;
      padding-top: 0px;
      overflow: auto;
      max-height: ~"calc(100% - 56px)";
    }
    ul {
      margin-top: 0;
      padding-left: 16px;
      margin-bottom: 0;
    }
  }
  &.req_modal {
    width: 600px;
    .req_modal_top {
      display: flex;
      align-items: center;
      .material-icons {
        margin-right: 5px;
      }
      .mdl-textfield {
        width: 150px;
        padding: 0;
        margin: 0 10px;
        margin-left: 15px;
        .mdl-textfield__label {
          top: 6px;
          &:after {
            bottom: 0px !important;
          }
        }
      }
    }
    .modal-content {
      > div {
        margin-bottom: 20px;
      }
    }
  }
  &.simple_submit_modal{
    width: 480px;
    .modal_footer{
      text-align: right;
      button + button{
        margin-left: 10px;
      }
    }
  }
  &.generic_modal{
    .mdl-textfield{
      width: 100%;
      /*.mdl-textfield__label{
        display: none;
      }*/
      input.text-box, textarea.text-box{
        border: none;
        border-bottom: 1px solid rgba(0,0,0,.12);
        display: block;
        font-size: 16px;
        font-family: "Helvetica","Arial",sans-serif;
        margin: 0;
        padding: 4px 0;
        width: 100%;
        background: 0 0;
        text-align: left;
        color: inherit;
        border-radius: 0;
        line-height: initial;
        &:focus{
          outline: 0;
          border-bottom: 1px solid #3f51b5;
        }
      }
      select{
        width: 100%;
      }
      input{
        box-sizing: border-box;
      }
    }
  }
}

.edit_truck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;

  .content_half {
    width: 48%;
  }

  .mdl-textfield-select {
    margin-top: -14px;
  }

  .select2-results {
    max-height: 300px
  }
}

.choice_modal {
  width: 800px;

  .dispatcher_form_cover {
    width: 100%;
    padding-bottom: 40px;
    display: flex;
    align-items: flex-end;

    .dispatcher_input_cover {
      width: 220px;

      &:first-child {
        margin-right: 30px;
      }
    }

    .dispatcher_select_cover {
      width: 160px;
      margin-right: 30px;

      span.select2 {
        width: 160px !important;
      }
    }
  }

  .choice_modal_table_cover {
    margin: 0 -35px;

    .mdl-data-table {
      border-left: none;
      border-right: none;
      white-space: normal;

      tr {
        height: 35px;

        &:hover {
          background-color: transparent;
        }
        &:first-of-type {
          td {
            padding-top: 10px;
          }
        }
        &:last-of-type {
          td {
            padding-bottom: 10px;
          }
        }
      }

      th {
        padding: 0 18px 12px 18px;
        border-bottom: 1px solid rgba(0, 0, 0, .12);
      }

      td {
        border: none;
        height: 35px;

        &:first-of-type {
          padding-left: 15px;
          text-align: center;
        }
      }
    }
  }
}

.action_modal {

  .suspend_problem {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    .mdl-radio {
      white-space: nowrap;
    }

    .dispatcher_select_cover {
      width: 35%;
      margin-left: auto;
      padding: 0;
      opacity: 0;
      pointer-events: none;

      .mdl-textfield__label:after {
        bottom: 0;
      }
    }
    .mdl-radio.is-checked + .dispatcher_select_cover {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

.edit_map_modal.add_route_point {
  .dispatcher_input_cover {
    vertical-align: initial;
  }
}

.route_point_modal {

  .map_container {
    height: 350px;
  }

  .inputs_cover {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .content_half {
    width: 49%;
    display: inline-block;
  }
  .mdl-textfield__input {
    padding: 15px 0 7px 0;
  }
}

.open_modal .box-modal {
  transform: scale(1);
  transition: all 300ms;
}

.arcticmodal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 2;
}