.driver_card{
  .problem_tab{
    min-height: auto;
    max-height: none;
  }
  .problem_specs{
    padding-top: 0;
  }
  #txt_1{
    .dispatcher_select_cover{
      margin-right: 15px;
    }
    .all_sum{
      padding: 15px 25px;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      text-align: right;
      background-color: #B4C0FF;
      font-size: 20px;
    }
  }
  .accruals_tab {
    height: ~"calc(100% - 48px)";

    .accruals_content {
      overflow-y: auto;
      padding-bottom: 50px;
      position: relative;
      height: ~"calc(100% - 141px)";
    }

    .monitoring_table {
      overflow-y: auto;

      .table-cover {
        overflow-y: auto;
      }
    }
  }
  #txt_2{
    .grid_cover_content{
      .col-30{
        &:nth-child(1){
          max-width: 255px;
          .select2{
            width: 100% !important;
          }
        }
        &:nth-child(2), &:nth-child(3){
          max-width: 165px;
        }
      }
    }
    .level2{
      display: none;
      &.opened{
        display: table-row;
      }
      .comment_col {
        width: 60%;
      }
    }
  }
}