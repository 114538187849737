﻿.k-grid-toolbar{
  padding: 20px 30px !important;
  background: #fff !important;
}

.k-grid-header .k-header{
  background: rgba(255, 208, 75, 0.3) !important;
  padding-left: 30px !important;
  a{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #272A29;
  }
}

.k-grid-toolbar{
  .k-widget_container{
    position: relative;
    margin-right: auto;
    width: 380px;
    min-height: 32px;
    >p{
      position: absolute;
      color: #9DA3B0;
      font-size: 14px;
      line-height: 16px;
      top: -18px;
      left: 40px;
    }
    .k-grid-delete{
      z-index: 2;
      position: absolute;
      left: 100%;
      top: 0;
      font-weight: 400;
      font-size: 20px !important;
      margin-left: 0 !important;
      border-radius: 0 4px 4px 0 !important;
      padding-top: 2px !important;
      height: 33px;
    }
    .k-grid-download{
      z-index: 2;
      position: absolute;
      right: 0;
      top: 0;
      height: 33px;
    }
  }
  .k-upload{
    width: 380px;
    position: absolute;
    z-index: 2;
    border: 1px solid #DCDFE6;
    background: #FFFFFF;
    border-radius: 4px 0px 0px 4px !important;
    border-bottom: none;
    .k-dropzone{
      padding: 0;
      background: #fff;
      border: none;
      border-bottom: 1px solid #dde2e7;
      min-height: 32px;
      .k-upload-button{
        background: #C2E5C7 url('/img/backup.svg') center no-repeat !important;
        border: 1px solid #33A946 !important;
        margin-top: -1px;
        min-width: 32px;
        border-radius: 4px 0px 0px 4px !important;
        position: relative;
        height: 33px;
      }
    }
    .k-upload-files{
      border-bottom: none;
       border-width: 0px 0 0;
      .k-file-group-wrapper, .k-file-invalid-group-wrapper{
        display: none;
      }
      .k-file-size{
        display: none;
      }
      .k-file-name-size-wrapper{
        margin-left: 0;
        min-height: 20px;
      }
      .k-upload-action{
        background: rgba(245, 108, 108, 0.3) !important;
        border: 1px solid #F56C6C !important;
        color: #F56C6C !important;
      }
    }
    .k-action-buttons{
      border-top: none;
      padding: 12px;
      .k-button{
        padding: 0.375rem 0.75rem !important;
      }
      .k-clear-selected{
        background: rgba(245, 108, 108, 0.3) !important;
        border: 1px solid #F56C6C !important;
        color: #F56C6C !important;
        margin-right: 14px;
      }
    }
    .k-upload-files.k-reset{
      .k-file-success{
        position: absolute;
        background: #fff;
        padding: 0;
        max-width: 100%;
        border: none;
        top: 5px;
        left: 47px;
        .k-file-name{
          color: #9DA3B0;
        }
      }
    }
  }
  .k-grid-delete{
    padding: .375rem .75rem !important;
  }
  #startparsers{
    background: rgba(51, 169, 70, 0.3) !important;
    border: 1px solid #33A946 !important;
    color: #33A946 !important;
  }
}

.k-grid tr{
  td{
    border: none !important;
    border-top: 1px solid #DCDFE6 !important;
    border-bottom: 1px solid #DCDFE6 !important;
    padding: 9px 30px !important;
    &.k-command-cell{
      text-align: right;
    }
  }
}
.k-grid .k-alt{
  background-color: #fff !important;
}

.page-content{
  height: 100%;
}

.k-grid{
  height: ~"calc(100vh - 64px)";
}
.k-filter-menu{
  max-width: 330px;
}
.k-filter-menu .k-filter-menu-container{
  select, input{
    margin-right: 10px;
    min-width: 60px;
    &:last-of-type{
      margin-right: 0;
    }
  }
  label{
    width: 100%;
    display: inline-block;
  }
  input{
    border-color: rgba(33,37,41,.15);
    color: #212529;
    background-color: #fff;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    border-radius: .25rem;
    padding: .375rem .75rem;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    font: inherit;
    font-size: 1rem;
    line-height: 1.5;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    height: 32px;
    &[type="checkbox"]{
      width: 20px;
      height: 20px;
      padding: 0;
      min-width: 0;
      position: relative;
      top: 7px;
      margin-right: 15px;
      &.checked{
        &:after{
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 18px;
          height: 18px;
          background: #FFD04B url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
          display: inline-block;
        }
      }
    }
    &.checked{
      border: 1px solid #FFD04B;
    }
    &[type="radio"]{
      width: 20px;
      height: 20px;
      padding: 0;
      min-width: 0;
      position: relative;
      top: 7px;
      margin-right: 15px;
      border-radius: 50%;
      &.checked{
        &:after{
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 14px;
          height: 14px;
          background: #FFD04B;
          border-radius: 50%;
          display: inline-block;
        }
      }
    }
  }
  select{
    line-height: 34px;
    margin-bottom: 15px;
    width: 100%;
    height: 32px;
    border: 1px solid rgba(33, 37, 41, 0.15);
  }
  .k-filter-help-text{
    margin-bottom: 5px;
  }
  .k-datepicker{
    margin: 0;
    margin-bottom: 15px;
    input{
      margin-bottom: 0 !important;
    }
  }
  .k-button{
    background: rgba(245, 108, 108, 0.3) !important;
    border: 1px solid #F56C6C !important;
    color: #F56C6C !important;
    display: inline-block;
    &.k-primary{
      background: rgba(64, 158, 255, 0.3) !important;
      border: 1px solid #409EFF !important;
      color: #409EFF !important;
    }
  }
  .k-action-buttons{
    display: flex;
    justify-content: space-between;
    .k-button{
      border-color: rgba(0,0,0,.08) !important;
      background: #fff !important;
      border-radius: 0 !important;
    }
  }
}

.k-button{
  background: rgba(64, 158, 255, 0.3) !important;
  border: 1px solid #409EFF !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #409EFF !important;
  &.k-grid-delete{
    background: rgba(245, 108, 108, 0.3) !important;
    border: 1px solid #F56C6C !important;
    color:#F56C6C !important;
  }
  &.k-grid-cancel{
    background: rgba(245, 108, 108, 0.3) !important;
    border: 1px solid #F56C6C !important;
    color:#F56C6C !important;
  }
}
#selectRoutePointModal{
  .k-grid .k-grid-search{
    width: 25.7em;
  }
}
.admin_panel_page{
  padding: 20px 30px;
  .k-dropdown{
    margin-bottom: 10px;
    width: 250px !important;
  }
}
.companiesSelect_style{
  .select2-container{
    border-radius: .25rem;
    width: 250px !important;
    border-color: #e4e7eb;
    color: #212529;
    background-color: #e4e7eb;
    transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    white-space: nowrap;
    border-width: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    .selection{
      width: 100%;
    }
    .select2-selection--single{
      padding-top: 7px;
      border: none;
      width: 100%;
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow{
    background: transparent;
  }
}
.item.isPublishTemplate{
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  display: inline-block;
  &.published{
    background: url("/img/status_ok.svg");
  }
  &.unpublished{
    background: url("/img/status_none.svg");
  }
}

.isStatusTemplate{
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 3;
  &.status_tried{
    background: url("/img/status_tried.svg");
  }
  &.status_middle{
    background: url("/img/status_middle.svg");
  }
  &.status_untested{
    background: url("/img/status_untested.svg");
  }
}

input.text-box, textarea.text-box{
  box-sizing: border-box;
}
.page-content .table_levels thead tr > th:first-of-type, .page-content .table_levels tr[data-level] > td:first-of-type{
  width: 660px;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_bottom, .page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_bottom, .page-content .table_levels tr[data-level="0"] td:first-of-type span i:not(.material-icons), .page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_top, .page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_top{
  display: none;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template), .page-content .table_levels tr[data-level] td:first-of-type span:not(load){
  border-bottom: none;
}
.k-grid-edit{
  padding: 0 !important;
  .k-i-edit{
    width: 30px !important;
    margin: 0 !important;
    top: 3px;
    height: 30px !important;
    &:before{
      content: '' !important;
      background: url("/img/edit.svg");
      width: 24px;
      height: 24px;
      display: inline-block;
    }
  }
}

.k-button.k-grid-see-route{
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  position: relative;
  &:before{
    content: '';
    background: url("/img/show.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    left: 3px;
    top: 1px;
    opacity: 1;
  }
}

.k-button.k-grid-change-route{
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  position: relative;
  margin-left: 15px !important;
  &:before{
    content: '';
    background: url("/img/edit.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    left: 3px;
    top: 1px;
    opacity: 1;
  }
}

#fake_save{
  background: #C2E5C7;
  border: 1px solid #33A946;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #33A946;
  z-index: 555;
  box-shadow: none;
  padding: 5px 16px;
}

#saveTableChanges{
  opacity: 0!important;
  height: 0!important;
  width: 0 !important;
}

.mdl-textfield{
  &.modified{
    position: relative;
    overflow: hidden;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 6px solid transparent; border-left: 6px solid #F56C6C; border-top: 6px solid #F56C6C;
    }
  }
}

#app{
  .table_levels.disabled_table{
    input{
      pointer-events: none !important;
      cursor: default !important;
    }
    .mdl-textfield{
      background: #f7f7f7;
    }
  }
}

.k-grid-delete{
  padding: 0 !important;
  margin-left: 15px !important;
  .k-i-close{
    width: 30px !important;
    margin: 0 !important;
    top: 3px;
    height: 30px !important;
    &:before{
      content: '' !important;
      background: url("/img/delete.svg");
      width: 24px;
      height: 24px;
      display: inline-block;
    }
  }
}

.k-grid-download{
  padding: 0 !important;
  margin-left: 15px !important;
  .k-i-download{
    width: 30px !important;
    margin: 0 !important;
    top: 3px;
    height: 30px !important;
    &:before{
      width: 24px;
      height: 24px;
      display: inline-block;
      font-size: 18px;
      margin-top: 2px;
    }
  }
}

.k-grid-detailsLocator{
  padding: 0 !important;
  margin-left: 15px !important;
  .k-i-globe{
    width: 30px !important;
    margin: 0 !important;
    top: 3px;
    height: 30px !important;
    &:before{
      width: 24px;
      height: 24px;
      display: inline-block;
      font-size: 18px;
      content: '' !important;
      background: url("/img/detailsLocator.svg") center no-repeat;
    }
  }
}


.triangular_btn{
  background: #FFD04B;
  padding: 8px 15px;
  margin-right: 21px;
  position: relative;
  color: #fff;
  text-decoration: none;
  border-radius: 5px 0 0 5px;
  &:before{
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    border: 18px solid transparent;
    border-radius: 0 5px 0 0;
    border-left: 20px solid #FFD04B;
  }
}

.k-grid-details{
  padding: 0 !important;
  margin-left: 15px !important;
  .k-i-grid-layout{
    width: 30px !important;
    margin: 0 !important;
    top: 3px;
    height: 30px !important;
    &:before{
      width: 24px;
      height: 24px;
      display: inline-block;
      font-size: 18px;
      margin-top: 2px;
    }
  }
}


.k-pager-numbers .k-state-selected, .k-pager-numbers .k-state-selected:hover{
  color: #409EFF !important;
  background: rgba(64, 158, 255, 0.3) !important;
  border: 1px solid #409EFF !important;
}

.k-i-plus{
  &:before{
    content: '' !important;
    background: url("/img/add.svg");
    background-repeat: no-repeat !important;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
}

.k-window, .box-modal{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  .k-grid-cancel{
    background: rgba(245, 108, 108, 0.3) !important;
    border: 1px solid #F56C6C !important;
    color: #F56C6C !important;
  }
  .k-edit-field{
    box-sizing: border-box;
    .select2{
      width: 100% !important;
      .select2-selection.select2-selection--single{
        outline: none !important;
        height: 34px;
      }
    }
  }
}

.overflow_hidden{
  overflow: hidden;
}

.k-prompt-container, .k-window-content, .k-edit-form-container, form{
  box-sizing: border-box;
}

.k-grid-toolbar{
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  &:after{
    display: none !important;
  }
  .k-grid-search{
    margin-right: auto !important;
  }
}

.k-grid .k-grid-search{
  float: none !important;
}

.k-filebrowser {
  .k-filemanager-listview {
    padding: 10px;
    justify-content: flex-end;
  }

  .k-file-name {
    display: block;
    font-size: 12px;
    line-height: 16px;
  }

  .k-file-size {
    font-size: 12px;
    line-height: 16px;
  }

  .k-listview-content {
    display: flex;
  }

  .k-listview-item {
    margin: 0 10px
  }
}