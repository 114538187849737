.number_filter_cover {
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.number_label {
  margin-right: auto;
  font-size: 14px;
  color: @color_dark_blue;
}

.number_filter_input {
  background-color: transparent;
  border: none;
  padding: 0 6px;
  width: 10px;
}

.number_filter_control {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  border: none;
  padding: 0;
  text-align: center;
  cursor: pointer;

  .material-icons {
    font-size: 18px;
    vertical-align: top;
  }
}

.number_filter_subtract {
  background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
  color: @color_red;
}

.number_filter_add {
  background-color: @color_light_blue;
  color: #E0EDFF;
}

.number_filter_disabled {
  background: linear-gradient(180deg, #FFFFFF 0%, #E9E9E9 100%);
  color: #E0E0E0;
  pointer-events: none;
}