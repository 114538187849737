.object_modal {
  width: 600px;

  .modal-title {
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    padding-bottom: 20px;
  }

  .object_close {
    display: block;
    margin: 0 auto;
    width: 130px;
  }

  .object_details {
    strong {
      color: #000000;
    }
  }

  .object_services {
    padding-bottom: 25px;

    tr,
    td,
    th{
      height: 30px;
    }
  }

  .mdl-data-table {
    .inner_data {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .inner_data {
    text-align: left;

    label {
      padding-left: 24px;
    }
  }

  .inner_services {
    width: 100%;
    margin-top: 6px;

    tr {
      height: 24px;
    }

    td {
      height: 24px;
      border: none;
    }

    .service_item_inner {
      text-align: left;
      width: 218px;

      .dot {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: #000000;
        border-radius: 50%;
        margin: 5px 5px 3px 0;
      }
    }
    .services_price {
      width: 118px;
      padding-right: 0;
    }
  }
  .input_coords {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: rgb(52, 73, 94);
    padding: 0;
  }
  .copy_coords {
    width: 25px;
    height: 30px;
    color: rgb(52, 73, 94);
    background-color: transparent;
    border: none;
    cursor: pointer;
    vertical-align: middle;
    padding: 0;
  }
}