.boarding_summary {
  padding: 35px 30px 15px 30px;
  display: flex;
  border-bottom: 1px solid @color_light_blue;

  .boarding_summary_info,
  .boarding_summary_controls {
    width: 49%;
  }

  .boarding_summary_row {
    margin-bottom: 10px;
  }

  .trip_title {
    font-size: 18px;
    line-height: 20px;
    color: #142d52;
    margin: 0;
  }

  .boarding_summary_item {
    margin-right: 20px;
    display: inline;
  }

  .trip_title_text {
    font-weight: 700;
    text-transform: uppercase;
    color: #000000;
  }

  .icons_item {
    display: inline-flex;
    align-items: center;

    .material-icons {
      margin: 0 10px;
    }
  }

  .icons_item_destinations {
    font-size: 18px;
    line-height: 21px;
  }

  .boarding_summary_controls {
    text-align: right;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
  }

  .boarding_controls_button {
    width: 200px;
    text-decoration: none;
    box-sizing: border-box;
    line-height: 22px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .onboard_controls_button {
    width: 300px;
    text-decoration: none;
    box-sizing: border-box;
    line-height: 22px;
  }
}

.boarding_filters_inner {
  display: flex;

  .select2-container {
    width: 100% !important;
  }
}

.tab_selector {
  display: flex;
  justify-content: space-between;
  height: 40px;
  box-sizing: border-box;
  margin: auto 20px 15px auto;
  border: 1px solid @color_light_blue;
  border-radius: 20px;
  padding: 4px;
}

.tab_selector_radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:checked {
    &+.tab_selector_item {
      background-color: @color_light_blue;
      border-color: @color_light_blue;
      color: #fff;
    }
  }
}

.tab_selector_item {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 13px;
  color: @color_dark_blue;
}

.boarding_tab {
  display: none;
}

.tab_shown {
  display: block !important;
}

.boarding_page {
  .arrival_button {
    width: 100px;

    &.arrival_button_red {
      background-color: @color_light_red;
      color: @color_red;
      border-color: @color_red;
    }
  }
}

.arrrived-data{
  margin-left: 10px;
}

.arrival_status {
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 36px;

  &.arrival_status_danger {
    color: @color_red;
  }
}

.nested_table_group {
  .icon_arrow_up {
    display: none;
  }

  &.opened {
    .icon_arrow_up {
      display: initial;
    }

    .icon_arrow_down {
      display: none;
    }
  }
}

.onboard_page {
  .onboard_table_button {
    width: 100px;
  }

  .onboard_table_button_large {
    width: 204px;
  }
}