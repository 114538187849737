#company_data_ajax{
  width: 1200px;

  h6 {
    margin-bottom: 0;

    &:first-child {
      margin-top: 40px;
    }
  }
  .mdl-textfield{
    width: 100%;
  }
  .field_hint{
    width: 30%;
    position: relative;
    padding-right: 40px;
    margin-right: 40px;
    display: inline-block;
  }
}

.save_settings {
  margin-top: 50px;
}

.hint_hover{
  position: absolute;
  top: 25px;
  right: 8px;
  cursor: pointer;
  .material-icons{
    color: #3F51B5;
  }
  .hint_drop{
    position: absolute;
    left: 15px;
    top: 15px;
    width: 190px;
    background: #E2E6FF;
    border-radius: 2px;
    padding: 17px 20px;
    color: #2E2E2E;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    display: none;
    z-index: 3;
  }
  &:hover{
    .hint_drop{
      display: block;
    }
  }
}
.page-content {
  .carrier_cover {
    .password_field {
      width: 18%;
      margin-right: 20px;
    }
    .password_save {
      margin-left: 0;
    }
    .phone_changing_field {
      width: 18%;
    }
  }
  .phone_changing,
  .password_changing {
    .mdl-textfield {
      display: block;
    }
  }
  
  .password_changing {
    width: 1200px;
    padding-bottom: 50px;

    h6 {
      margin-bottom: 0;
      margin-top: 40px;

      b {
        margin-bottom: 0;
      }
    }
  }
  .regions {
    width: 67%;

    .mdl-textfield.longWidth {
      margin-top: 0;
    }
  }
}


