﻿.calculation_result_map_button{
  position: absolute;
  right: 620px;
  top: 10px;
  width: 275px;
  .item{
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .title{
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: #9DA3B0;
      flex-grow: 1;
      margin-right: 15px;
    }
    .switch_cover{
      min-width: 64px;
      margin: 0;
      top: 0;
      max-width: 64px;
    }
  }
}
.calculation_result{
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0;
  width: 600px;
  box-sizing: border-box;
  max-height: ~"calc(100vh - 64px - 20px)";
  .table-cover{
    max-height: ~"calc(100vh - 166px - 64px - 20px - 84px)";
    overflow-x: hidden;
    overflow-y: auto;
  }
  .calculation_result_top{
    padding: 30px 20px;
    .basic_info{
      display: flex;
      margin-bottom: 30px;
      .basic_info_item{
        margin-right: 40px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        color: #272A29;
        .material-icons{
          color: #EBB563;
          margin-right: 10px;
        }
      }
    }
    .point_list{
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      .point{
        margin-right: 5px;
        position: relative;
        width: 125px;
        min-width: 125px;
        i{
          font-style: normal;
          width: 20px;
          height: 20px;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #EBB563;
          border-radius: 50%;
          color: #606266;
        }
        span{
          text-align: center;
          transform: translateX(-50px);
          display: inline-block;
          padding: 5px;
          padding-bottom: 0;
          font-size: 10px;
          line-height: 12px;
        }
        &:after{
          content: '';
          position: absolute;
          top: 10px;
          left: 25px;
          height: 1px;
          width: 100px;
          background: #606266;
        }
        &:last-of-type{
          &:after{
            display: none;
          }
        }
      }
    }
  }
  
  .table-cover{
    width: 100%;
    table{
      margin: 0;
      min-width: 100%;
      border-collapse: collapse;
      thead{
        background: rgba(235, 181, 99, 0.3);
        border: 1px solid #DCDFE6;
      }
      tbody{
        tr{
          background: #FFFFFF;
          box-shadow: none;
        }
      }
      th, td{
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        color: #272A29;
        padding: 4px 13px;
        vertical-align: middle;
        &:first-of-type{
          text-align: left;
          padding-left: 20px;
        }
        &:last-of-type{
          padding-right: 20px;
        }
      }
      th{
        font-style: normal;
      }
      td{
        &:last-of-type{
          p{
            padding: 0;
            margin: 0;
            line-height: 14px;
            font-size: 12px;
          }
          span{
            font-size: 10px;
            line-height: 12px;
            color: #9DA3B0;
          }
        }
      }
      .result_tr{
        background: rgba(235, 181, 99, 0.3);
        border: 1px solid #DCDFE6;
        td{
          font-weight: bold;
        }
      }
    }
  }
  .calculation_result_footer{
    padding: 20px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    button{
      padding: 8px 15px;
      font-size: 14px;
      line-height: 16px;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      >img, >i{
        margin-right: 9px;
      }
      &.edit{
        color: #409EFF;
        background: #C6E2FF;
        border: 1px solid #409EFF;
      }
      &.copy{
        color: #33A946;
        background: rgba(51, 169, 70, 0.3);
        border: 1px solid #33A946;
        i{
          font-size: 16px;
          color: #33A946;
        }
      }
      &.close_and_save{
        background: #EBB563;
        color: #FFFFFF;
      }
    }
  }
}

#selectRoutePointModal{
  .box-modal{
    width: 700px;
  }
  .k-grid-content.k-auto-scrollable{
    max-height: ~"calc(100vh - 75px - 42px - 45px - 56px - 48px - 35px - 24px - 4px)" !important;
  }
  .k-grid{
    max-height: ~"calc(100vh - 48px - 35px - 35px - 35px - 24px - 4px)" !important;
  }
}