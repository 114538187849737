.trip {
  padding: 20px;

  .trip_header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .trip_header_text {
    font-size: 36px;
    line-height: 49px;
    font-weight: bold;
    margin: 0 0 0 140px;
    color: @color_dark_blue;
    font-family: 'Nunito', sans-serif;
  }

  .trip_section {
    background-color: @color_light_grey;
    border-radius: 20px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
  .title_section {
    padding: 0 30px 30px 30px;

    .trip_section_title {
      padding-left: 0;
    }
  }

  .trip_title {
    font-size: 18px;
    line-height: 20px;
    color: #142D52;
  }

  .trip_title_text {
    vertical-align: middle;
    font-weight: bold;
    color: @color_dark_blue;
    font-size: 24px;
    line-height: 33px;
  }

  .trip_back {
    background-color: @color_light_blue;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
    padding: 0 20px 0 16px;
    height: 40px;

    span {
      vertical-align: middle;
    }

    .material-icons {
      vertical-align: middle;
      color: #ffffff;
    }
  }

  .title_data {
    display: flex;
    flex-wrap: wrap;
  }

  .title_data_item {
    width: 45%;
    margin-right: 20px;

    .material-icons {
      vertical-align: middle;
    }
  }

  .trip_section_title {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: @color_dark_blue;
    padding: 30px;
    margin: 0;
  }

  .passengers_item {
    display: flex;
    align-items: center;
    padding: 0 30px;
  }

  .passengers_block {
    padding-bottom: 30px;
  }

  .passengers_map {
    display: flex;
    margin-left: 50px;
  }

  .passengers_map_legend {
    align-self: center;
  }

  .legend_horizontal {
    display: none;
  }

  .passengers_data_row {
    height: 40px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .passengers_data_item {
      height: 100%;
      display: flex;
    }

    .passengers_data_title {
      width: 155px;
      background-color: @color_light_blue;
      border: 1px solid @color_light_blue;
      border-radius: 20px 0 0 20px;
      text-align: right;
      padding: 10px 16px;
      color: #ffffff;
      font-size: 14px;
      line-height: 16px;
    }

    .passenger_document_data_title {
      padding: 8px 16px;
      line-height: 12px;
    }

    .passenger_data_input {
      width: 210px;
      padding: 10px 16px;
      color: @color_dark_blue;
      border: 1px solid @color_light_blue;
      border-radius: 0 20px 20px 0;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .trip_table {
    .trip_head_row {
      background: @color_light_blue;

      th {
        padding: 12px 20px;
        color: #ffffff;
      }
    }

    tr {
      background-color: #F8F8F8;
    }

    td {
      padding: 10px 20px;
    }
  }

  .transport_plate_field {
    width: 240px;
    color: @color_dark_blue;
  }

  .trip_table_cost {
    text-align: right;
  }

  td.trip_table_cost {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  .passenger_data_field {
    width: 160px;
  }

  .seat_selector_opener {
    background-color: transparent;
    border: none;
    color: @color_dark_blue;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    cursor: pointer;
    white-space: nowrap;
    padding: 0;
    min-width: 118px;
    text-align: left;

    .seat_selector_text {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    .material-icons {
      vertical-align: middle;
    }
  }

  .seat_selector_cell {
    padding: 0;
  }

  .seat_selector_map {
    padding: 10px 20px;
    border-radius: 20px;

    svg {
      width: 518px;
      height: 411px;
    }
  }

  .seat_selector_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
    }

    .radio_cover {
      margin-right: 10px;
      width: 50px;

      label {
        span {
          top: 0;
        }
      }
    }
  }

  .total_section {
    overflow: hidden;
    padding-bottom: 0;
  }

  .total_section_inner {
    display: flex;
  }

  .total_section_right {
    padding: 30px;
  }

  .finalize_row {
    flex-direction: column;
  }

  .payment_type_block {
    padding: 60px 30px;
    background-color: @color_light_blue;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .payment_type_text {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
    display: block;
    margin-bottom: 25px;
  }

  .payment_type_row {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    label {
      display: flex;
    }

    .payment_type_icon {
      height: 43px;
      width: 50px;
      border: 1px solid @color_dark_blue;
      border-radius: 20px 0 0 20px;
      background-color: #ffffff;
      box-sizing: border-box;

      &:after {
        transition: none;
        margin: 0;
      }
    }

    .payment_type_title {
      width: 185px;
      padding: 2px 16px;
      color: @color_dark_blue;
      border: 1px solid @color_dark_blue;
      border-radius: 0 20px 20px 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      background-color: #ffffff;
      height: 43px;
      display: flex;
      align-items: center;
      text-align: left;
      top: 0;
    }

    input[type=radio] {
      &:checked {
        &+.icon {
          background-color: @color_dark_blue;
          &::after {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
            background-color: @color_dark_blue;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-size: 50%;
            background-position: 50%;
            transition: none;
          }
        }
      }
    }
  }

  .total_row {
    display: flex;
  }

  .total_checkbox {
    margin-bottom: 20px;
  }

  .total_field_cover {
    width: 280px;
    margin-right: 20px;
    padding-bottom: 15px;
    position: relative;

    .filter_field {
      padding-left: 15px;
    }
  }

  .special_field_reminder {
    color: @color_dark_blue;
    display: block;
    font-size: 12px;
    line-height: 14px;
    padding-left: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .total_price {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: @color_dark_blue;
  }

  .total_send {
    width: 280px;
    margin: 20px 20px 0 0;
  }

  .no_data {
    padding: 50px 20px;

    .submit {
      width: 240px;
    }
  }

  .no_data_text {
    margin-right: 20px;
  }
}

@media (max-width: 1000px) {
  .trip {
    .trip_header_text {
      font-size: 20px;
      line-height: 27px;
      padding: 20px 0;
      margin: 0 0 0 20px;
    }

    .trip_back {
      padding: 0;
      width: 40px;
    }

    .trip_back_text {
      display: none;
    }

    .trip_section {
      padding-bottom: 20px;
    }

    .title_section {
      padding: 0 20px 20px 20px;
    }

    .trip_section_title {
      font-size: 20px;
      line-height: 24px;
      padding: 20px;
    }

    .title_data {
      display: block;
    }

    .title_data_item {
      margin: 0;
      width: 100%;

      strong {
        margin-right: 10px;
      }
    }

    .transport_secondary_data {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid @color_light_blue;
    }

    .transport_data_title {
      display: inline;
      width: 40%;
      min-height: 40px;
      background-color: @color_light_blue;
      border: 1px solid @color_light_blue;
      border-radius: 20px 0 0 20px;
      text-align: right;
      padding: 10px;
      color: #ffffff;
      font-size: 12px;
      line-height: 16px;
      box-sizing: border-box;
    }

    .transport_data_field {
      width: 60%;
      min-height: 40px;
      padding: 10px;
      color: @color_dark_blue;
      border: 1px solid @color_light_blue;
      border-radius: 0 20px 20px 0;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      font-weight: normal;
      box-sizing: border-box;

      .cost_small {
        display: none;
      }

      &::placeholder {
        color: @color_dark_grey;
      }
    }

    .passengers_item {
      flex-direction: column;
      padding: 0 20px;
    }

    .passengers_data_row {
      height: auto;
      min-height: 40px;

      .passengers_data_title {
        width: 40%;
        padding: 10px;
        font-size: 12px;
        line-height: 16px;
      }

      .passenger_data_input {
        width: 60%;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .passengers_map {
      flex-direction: column;

      .seat_selector_map {
        padding: 10px 0;
        svg {
          width: 100% !important;
        }
      }
    }

    .legend_horizontal {
      display: block;
    }

    .legend_vertical {
      display: none;
    }

    .total_section_inner {
      flex-direction: column;
    }

    .payment_type_block {
      padding: 40px 20px;
    }

    .total_section_right {
      padding: 20px;
    }

    .total_field_cover {
      .filter_label {
        white-space: nowrap;
      }
    }

    .total_email_cover {
      margin-right: 0;
    }

    .label_extended {
      display: none;
    }

    .total_checkbox {
      display: flex;
      align-items: center;
    }

    .total_agreement {
      line-height: 16px;
      margin-left: 10px;
    }

    .total_send {
      margin-right: 0;
      width: 100%;
    }
  }
}