.desktop_slider {
  display: block;
}

.mobile_slider {
  display: none;
}

.title_carousel {
  margin: 0 auto;
  text-align: center;
  color: @color_deep_blue;
  position: relative;

  h1 {
    font-family: "Nunito", Arial, sans-serif;
    font-size: 42px;
    line-height: 57px;
    margin: 0;
  }

  .subtitle {
    font-size: 24px;
    line-height: 33px;
  }

  &.multiple_carousel {
    margin-bottom: -75px;

    h1,
    .subtitle {
      color: #ffffff;
    }
  }

  .title_slide {
    padding: 50px 0;
  }

  .multiple_slide {
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: @color_dark_blue;
    padding: 140px 10px 250px 10px;
    box-sizing: border-box;
  }

  .owl-nav {
    button {
      &.owl-prev,
      &.owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        border: 1px solid @color_red;
        color: @color_red;
        background-color: #ffffff;
        border-radius: 50%;

        .material-icons {
          vertical-align: middle;
          display: block;
          font-size: 34px;
        }
      }

      &.owl-prev {
        left: 50px;
      }

      &.owl-next {
        right: 50px;
      }
    }
  }
}

.section_title {
  font-family: 'Nunito', Arial, sans-serif;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: @color_deep_blue;
  margin: 0;
  padding-bottom: 35px;
  font-weight: bold;
}

.main_filter_block {
  background-color: @color_dark_blue;
  border-radius: 20px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  .filters {
    padding: 0;
  }

  .filter_label {
    display: none;
  }

  .filter_cover {
    margin: 15px;
  }

  .filter_mid_cover {
    width: 240px;
  }

  .filter_field {
    border-color: @color_dark_blue;
  }

  .select2-selection {
    border-color: @color_dark_blue;
  }

  .select2-container--default {
    width: 100% !important;

    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: @color_dark_blue transparent transparent;
        }
      }
    }
  }

  .filter_pseudo_cover {
    width: 375px;
  }

  .filter_pseudo_dropdown {
    display: none;
    width: 375px;
    top: 40px;
    left: 0;

    .filter_cover {
      margin: 15px 0;
    }

    .filter_label {
      display: block;
    }
  }

  .transport_additional {
    display: none;
  }

  .delete_transport {
    margin-left: 10px;
  }
}

.why_us {
  margin-bottom: 80px;
}

.why_us_inner {
  display: flex;
  margin-top: 55px;
}

.why_us_item {
  width: 25%;
  padding: 0 10px;
}

.why_us_image {
  max-width: 100%;
  margin: 0 auto 30px auto;
  text-align: center;

  img {
    max-width: 100%;
  }
}

.why_us_text {
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}

.map_container {
  position: relative;
}

.map_inner {
  margin-top: 35px;
  min-height: 550px;
}

.map_side {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 260px;
  height: 430px;
  z-index: 1;
  box-shadow: -1px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  background-color: #ffffff;
}

.map_side_icon {
  background-color: @color_light_blue;
  padding: 10px;
  text-align: center;

  .material-icons {
    color: #ffffff;
  }
}

.map_side_quays,
.map_side_ferries {
  padding: 25px 0;
  margin: 0 30px;
  text-align: center;
}

.map_side_quays {
  border-bottom: 1px solid @color_grey;
}

.map_side_type,
.map_side_button {
  display: block;
}

.map_side_type {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
}

.map_side_button {
  font-size: 18px;
  line-height: 25px;/**/
  color: @color_deep_blue;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  margin: 0 auto 8px auto;

  &:hover {
    color: @color_light_blue;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 1000px) {
  .desktop_slider {
    display: none;
  }

  .mobile_slider {
    display: block;
    padding: 50px 0;

    h1 {
      font-size: 26px;
      line-height: 36px;
      color: #ffffff;
    }

    .subtitle {
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
    }

    .solo_carousel,
    .mobile_solo_slide {
      box-sizing: border-box;
    }
  }

  .mobile_solo_slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("/img/title_carousel/slide_2.jpg");
  }

  .main_filter_block {
    border-radius: 0;
    min-height: ~'calc(100vh - 64px)';

    .filter_label {
      display: block;
      color: #ffffff;
    }

    .filter_label_empty {
      height: 14px;
    }

    .filters {
      margin: 0 20px;
      justify-content: space-between;
      padding: 10px;
      background-color: #1855a3;
      border-radius: 20px;
    }

    .adding_transport {
      .select2-container {
        width: 81% !important;
      }
    }

    .filter_pseudo_dropdown {
      width: 100%;
      top: 52px;

      .filter_label {
        color: @color_dark_blue;
      }
    }

    .filter_cover {
      margin: 0 0 10px 0;
    }

    .filter_submit_cover {
      margin-top: 10px;
    }

    .filter_mid_cover {
      width: 48%;
    }

    .filter_pseudo_cover {
      width: 100%;
    }

    .filter_submit_cover {
      width: 100%;
    }
  }

  .section_title {
    font-size: 20px;
    line-height: 27px;
  }

  .why_us {
    margin-bottom: 20px;
  }

  .why_us_inner {
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .why_us_item {
    width: 49%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .why_us_text {
    font-size: 14px;
    line-height: 19px;
  }

  .map_inner {
    min-height: 450px;
  }

  .map_side {
    position: relative;
    right: auto;
    top: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    transform: none;
    box-sizing: border-box;
  }

  .map_side_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    padding: 0;
    box-sizing: border-box;
    display: flex;

    .material-icons {
      vertical-align: middle;
      color: @color_red;
      margin: auto;
    }
  }

  .map_side_quays,
  .map_side_ferries {
    width: 50%;
    margin: 20px 0;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .map_side_quays {
    border-bottom: none;
    border-right: 1px solid @color_grey;
  }

  .map_side_type,
  .map_side_button {
    font-size: 18px;
    line-height: 25px;
    color: @color_deep_blue;
  }
}

.color_red {
  color: @color_red !important;
}
