.client {
  .mdl-layout__content {
    margin-left: 0 !important;
    display: flex;
    flex-direction: column;
  }

  .page-content {
    flex-grow: 1;
    height: auto;
  }

  .mdl-layout__header {
    padding: 15px;
  }

  .wrapper {
    max-width: 1080px;
    margin: 0 auto;
  }

  .header_outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .header_inner_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header_profile_row {
    .header_link {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header_phone {
    font-size: 24px;
    line-height: 33px;
  }

  .header_nav_list {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .header_nav_item {
    list-style: none;
    padding: 10px 10px 0 10px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .header_link,
  .footer_link {
    &:hover {
      color: @color_light_blue;
    }
  }

  .header_link {
    color: #ffffff;
    text-decoration: none;
    line-height: 24px;

    span {
      vertical-align: middle;
    }

    .material-icons {
      color: #ffffff;
    }
  }

  .user_name {
    padding: 10px 10px 10px 20px;
    border-radius: 20px 0 0 20px;
    background-color: @color_light_blue;
  }

  .user_unauthorized {
    border-radius: 20px;
    padding: 8px 20px;
    cursor: pointer;
    color: #ffffff;

    span {
      vertical-align: middle;
    }
  }

  .user_logout {
    padding: 10px;
    border-radius: 0 20px 20px 0;
    background-color: @color_light_blue;
  }

  .button_burger {
    display: none;
    border: none;
    background-color: @color_light_blue;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;

    .material-icons {
      color: #ffffff;
      vertical-align: middle;
    }
  }

  .page_footer {
    background-color: @color_dark_blue;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25);
    padding: 15px;
  }

  .footer_outer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer_left {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }

  .footer_list {
    padding: 0;
    margin: 0;
  }

  .footer_list_item {
    list-style: none;
    padding: 4px 0;
  }

  .footer_link {
    color: #ffffff;
    text-decoration: none;
  }

  .footer_right {
    text-align: right;
  }

  .footer_info {
    display: block;
    color: #ffffff;
    text-decoration: none;
    padding-bottom: 15px;
  }

  .page_title {
    display: block;
    font-family: 'Nunito', Arial, sans-serif;
    color: @color_dark_blue;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    line-height: 49px;
    margin: 0;
    padding: 50px 0;
  }
}

@media (max-width: 1000px) {
  .client {
    header {
      .header_inner,
      .header_user {
        display: none;
      }

      &.menu_open {
        height: 100vh;
        overflow-y: auto;

        .header_outer {
          flex-wrap: wrap;
        }

        .header_logo {
          order: 1;
        }

        .button_burger {
          order: 2;

          .icon_show {
            display: none;
          }

          .icon_hide {
            display: inline;
          }
        }

        .header_inner {
          order: 3;
          width: 100%;
        }

        .header_user {
          order: 4;
          width: 100%;
        }

        .header_inner {
          display: flex;
          flex-direction: column;
        }

        .header_nav_row {
          order: 1;
          border-bottom: 1px solid #ffffff;
        }

        .header_profile_row {
          order: 2;
          flex-direction: column;
          align-items: flex-start;
          padding-top: 20px;

          .header_link {
            font-size: 20px;
            line-height: 27px;
            padding: 4px 24px;

            .material-icons {
              display: none;
            }

            &.active {
              background-color: #ffffff;
              color: @color_deep_blue;
              border-radius: 20px;
            }
          }
        }

        .header_user {
          display: initial;
        }

        .user_name {
          display: inline-block;
          padding: 4px 4px 4px 24px;
        }

        .user_unauthorized {
          .material-icons {
            display: none;
          }
        }

        .user_name,
        .user_logout {
          background-color: transparent;
          font-size: 20px;
          line-height: 27px;
        }

        .header_phone {
          display: none;
        }

        .header_nav {
          padding: 40px 0 26px 0;
        }

        .header_nav_list {
          flex-direction: column;
        }

        .header_nav_item {
          padding-left: 0;

          a {
            font-size: 20px;
            line-height: 27px;
            padding: 4px 24px;

            &.active {
              background-color: #ffffff;
              color: @color_deep_blue;
              border-radius: 20px;
            }
          }
        }
      }
    }

    .header_logo {
      img {
        height: 34px;
        width: auto;
      }
    }

    .button_burger {
      display: inline-block;

      .icon_hide {
        display: none;
      }
    }

    .page_title {
      font-size: 20px;
      line-height: 27px;
      padding: 20px 0;
    }

    .switch_table_cover {
      table,
      thead,
      tbody,
      tr,
      th,
      td{
        display: block;
      }

      thead {
        display: none;
      }

      .table_row_openable {
        display: none;
      }
    }

    .nested_table_group {
      &.opened {
        .table_row_openable {
          display: block;
          box-shadow: none;
          border-top: 1px solid @color_light_blue;
        }
      }
    }

    .page_footer {
      padding: 20px;

      .footer_left,
      .footer_logo {
        display: none;
      }

      .footer_right {
        text-align: left;
      }

      .footer_info {
        padding-left: 0;
      }
    }
  }
}
