.tasks {
  padding: 40px;

  .tasks_table {
    border-collapse: collapse;
    margin-bottom: 60px;
    max-width: 500px;

    td {
      padding: 10px 0;
      vertical-align: top;
    }
  }

  .tasks_row {

  }

  .tasks_title {
    font-weight: bold;
    min-width: 200px;
  }

  .message_author {
    display: flex;
    flex-direction: row;

    #edit_message {
      margin-left: auto;
    }
  }

  .tasks_comments {
    max-width: 500px;
  }

  .tasks_message {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(63, 81, 181, 0.5);
  }

  .message_area {
    margin-bottom: 20px;
  }
}