.monitoring_request {
  .column_screen {
    height: calc(~"100vh - 64px");
  }
  .monitoring_tracking {
    min-width: 500px;
    max-width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }

  .monitoring_map {
    flex-grow: 1;
    width: 100%;
    display: block;
  }

  .problem_tab {
    background-color: #ffffff;
    padding-top: 20px;
    position: relative;
    z-index: 5;
    min-height: 300px;
    max-height: 430px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    .list_problem {
      max-height: 110px;
      overflow-y: auto;
    }

    .route_on_map {
      position: absolute;
      color: #3F51B5;
      background: #fff;
      border-radius: 35px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      white-space: nowrap;
      bottom: -60px;
      right: 10px;
      z-index: 1;

      label{
        display: block;
        padding: 13px 16px;
        padding-top: 15px;
      }
      .icon{
        position: relative;
        top: -1px;
      }
      span{
        margin-right: 23px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .problem_specs {
    padding: 20px 0;
    position: relative;
    z-index: 2;
  }

  .problem_specs_row {
    padding: 8px 30px;

    &:first-child {
      padding-bottom: 0;
    }

    .checkbox_cover {
      margin-left: 10px;
    }

    .to_edit {
      float: right;
      margin-right: -16px;
    }
  }

  .instruction {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &:hover {
      .instruction_popup {
        display: block;
      }
    }
  }

  .instruction_popup {
    display: none;
    position: absolute;
    right: -305px;
    top: -5px;
    min-width: 250px;
    max-width: 250px;
    background-color: #E2E6FF;
    padding: 20px;
    font-size: 14px;
    z-index: 100;
    font-weight: 400;
    border-radius: 4px;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);

    &::before {
      position: absolute;
      display: block;
      content: "";
      left: -14px;
      top: 10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 14px 8px 0;
      border-color: transparent #e2e6ff transparent transparent;
    }

    span {
      display: block;
      padding-bottom: 5px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .monitoring_statistics {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: calc(~"100% - 480px");
    position: relative;
    z-index: 2;
  }

  .monitoring_table {
    max-width: 100%;
    height: 100%;

    .icon_container {
      padding: 0 10px 0 0;
      line-height: 10px;
    }

    .address_width {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .customer_width {
      max-width: 100px;

      .customer_with_overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }
    }

    .comment_block {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      right: 7px;
    }

    .comment_additional {
      display: none;
      position: absolute;
      right: -135px;
      top: 35px;
      min-width: 250px;
      max-width: 250px;
      background-color: #E2E6FF;
      padding: 20px;
      font-size: 14px;
      z-index: 100;
      font-weight: 400;
      border-radius: 4px;
      box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
      word-wrap: break-word;
      white-space: normal;

      &::before {
        position: absolute;
        display: block;
        content: "";
        left: 135px;
        top: -14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 14px 8px;
        border-color: transparent transparent #e2e6ff transparent;
      }
    }

    .comment_info {
      color: #A5A5A5;
      font-size: 22px;
      display: block;
      width: 22px;
      height: 22px;
    }

    .comment_info:hover + .comment_additional {
      display: block;
    }
  }

  .history {
    .count {
      padding-left: 15px;
      width: 20px;
    }
    .wrap_cell {
      max-width: 400px;
      white-space: normal;
      word-wrap: break-word;
    }
  }

  .history_controls {
    text-align: right;
    padding-bottom: 20px;
  }

  .monitoring_table, // Для всплывающих подсказок
  .table-cover {
    overflow: visible;

    .mCustomScrollBox {
      overflow: visible;
    }
    .mCSB_container {
      overflow: visible;
    }
  }
  .monitoring_statistics {
    overflow-y: auto;
    overflow-x: hidden;
  }

  //tab styles
  .tabs {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
  }

  .tabs > input {
    display: none;
  }

  .tabs > div {
    display: none;
  }

  .tabs > label {
    display: inline-block;
    margin: 16px 18px 0 18px;
    padding-bottom: 9px;
    text-align: center;
    cursor: pointer;
    &:first-of-type {
      margin-left: 28px;
    }
  }

  .tabs > input:checked + label {
    border-bottom: 2px solid rgb(63, 81, 181);
  }

  #tab_1:checked ~ #txt_1,
  #tab_2:checked ~ #txt_2,
  #tab_3:checked ~ #txt_3,
  #tab_4:checked ~ #txt_4,
  #tab_5:checked ~ #txt_5,
  #tab_6:checked ~ #txt_6 {
    display: block;
  }

  .content_tab {
    border-top: 1px solid #C0C0C0;
  }
  .tab_inner {
    .monitoring_table {
      tbody {
        tr {
          cursor: default;
        }
      }
      .gas_station_table {
        tr:not(.gas_station_total) {
          cursor: pointer;
        }
      }
    }
  }
  .button_cover {
    text-align: right;
  }
  .tab_add_comment {
    margin: 10px;
    width: 155px;

    &.loading {
      font-size: 0;
      pointer-events: none;

      &::before {
        content: 'cached';
        position: absolute;
        left: 50%;
        top: 50%;
        color: #ffffff;
        width: 24px;
        height: 24px;
        margin-top: -12px;
        margin-left: -12px;
        font-family: 'Material Icons';
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        word-wrap: normal;
        -moz-font-feature-settings: 'liga';
        font-feature-settings: 'liga';
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
      }
    }
  }
  .tab_no_content {
    display: block;
    padding: 10px;
    text-align: center;
  }

  .gas_station {
    max-width: 400px;
    min-width: 400px;
    white-space: normal;
  }
  .gas_station_total {
    background-color: #e2e6ff;

    &:hover {
      background-color: #e2e6ff;
    }

    .price {
      color: #5f5f5f
    }
  }
  .wrap_cell {
    white-space: normal;
  }
}

.confirm_reminder {
  color: rgba(63, 81, 181, 1);
  font-size: 14px;
}

.monitoring_route_header{
  display: flex;
  margin-left: 30px;
  .item{
    display: flex;
    align-items: center;
    margin-right: 30px;
    .text{
      margin-left: 15px;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }
  }
}


@media (max-width: 1024px) {
  .monitoring_request {
    .column_screen {
      height: calc(~"100vh - 57px");
    }
  }
}


