.receipt {
  padding: 30px;
  width: 800px;
}

.receipt_table {
  width: 100%;
  border: 1px solid #000000;
  border-collapse: collapse;
  vertical-align: top;

  td,
  th {
    padding: 6px;
    border: 1px solid #000000;
    border-collapse: collapse;
    vertical-align: top;
  }
}

.receipt_date {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0 10px 0;
  margin: 0;
}

.receipt_mid {
  border: none;
  border-collapse: separate;
  border-spacing: 0 8px;

  td {
    &:first-of-type {
      border-right: 0;
    }

    &:last-of-type {
      font-weight: bold;
    }
  }
}

.receipt_mid {
  margin-bottom: 40px;
}

.receipt_bot {
  border: none;
}

.receipt_bot_details {
  border: 1px solid #000000;
}

.receipt_bot_summary {
  border: none;

  th,
  td {
    text-align: right;
    border: none;
  }
}

.number_td {
  white-space: nowrap;
  text-align: right;
}

.receipt_reminders {
  border-bottom: 2px solid #000000;
  margin-bottom: 40px;

  p,
  strong {
    margin-bottom: 4px;
  }
}

.receipt_signature {
  position: relative;

  .receipt_stamp {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-36%);
    width: 350px;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("/img/signature.png");
  }
}

.receipt_title {
  float: left;
  font-weight: bold;
}

.receipt_surname {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #000000;
}

.receipt_clear {
  clear: both;
}