@page {
  size: A4;
}

@media print {
  div { float: none !important; }
  .profiler-results,
  .mdl-layout__header,
  .station_on_map,
  .material-icons,
  .total {
    display: none;
  }

  .mdl-layout__container {
    position: initial;
    width: initial;
    height: initial;
  }

  .mdl-layout--fixed-drawer>.mdl-layout__content {
    margin: 0 auto;
  }

  .mdl-layout {
    width: initial;
    height: initial;
    display: initial;
    overflow: initial;
    position: initial;
  }

  .mdl-layout__content {
    margin: 0 auto;
  }

  .mdl-data-table {
    th {
      height: auto;
    }
  }

  .page-content {
    padding-bottom: 0;

    .page_big_map {
      height: 100%;
      width: 100%;
      display: initial;

      .info_col_cover {
        break-before: always;
        page-break-inside: avoid;
        page-break-before: always;
        display: block;
        position: relative;

        .info_col {
          width: initial;
        }
        .info_col.big {
          width: initial;
          padding: 80px 0 0 0;

          .table_levels_small {
            width: 100%;
            margin-left: 0;
            
            &.fixed_head{
              display: none;
            }
            
          }

          .title {
            .content_cols {
              position: initial;
            }
          }

          .path_points > div {
            width: initial;
            margin-right: 20px;
          }

          .table_levels_small_summary {
            margin: 0;

            & > div {
              width: 600px;
              margin-left: 30px;

              &:last-of-type {
                display: none;
              }
            }
          }

          .table-cover {
            width: initial;
            height: initial;
            margin-left: 0;
            margin-top: 25px;

            tbody {
              tr {
                border-bottom: 1px solid #E4E4E4;
              }
            }
          }

          .table_big {
            overflow: initial;
            height: initial;
            margin-left: 0;
            margin-top: 0;
            width: initial;
          }
        }
      }

      .big_map_cover {
        height: 100% !important;
        width: 100%;
        display: block;
        position: relative;
        page-break-after: always;

      }

      .big_map_cover.small {
        width: 100%;
        max-width: 100%;

        #myMap {
          width: 100% !important;
          height: 270mm !important;
          overflow: hidden;
          /*>ymaps{
            width: 100% !important;
            height: 270mm !important;
            >ymaps{
              width: 100% !important;
              height: 270mm !important;
              >ymaps{
                width: 100% !important;
                height: 270mm !important;
                >ymaps:first-of-type{
                  width: 100% !important;
                  height: 270mm !important;
                }
              }
            }
          }*/
        }

        .ymaps-2-1-73-map {
          //width: 100% !important;
          transform: none;
          position: initial;
          pointer-events: none;
        }
        .ymaps-2-1-73-copyright__layout {
          display: none;
        }
      }
    }
   
    .big_map_cover {
      .info_col_cover {
        .info_col {
          width: initial;
        }
        .info_col.big {
          width: initial;

          .table-cover {
            overflow: initial;
            height: initial;
            margin-left: initial;
            margin-top: initial;
            width: initial;
          }
        }
      }
    }
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small_summary > div:last-of-type {
    display: none;
  }

  //.page-content .page_big_map .big_map_cover.small .ymaps-2-1-73-map {
  //  width: 1300px !important;
  //}

  thead {
    display: table-row-group;
  }

  tr,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  table {
    page-break-after: auto;
  }
}