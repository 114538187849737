.page-content{
  .new_route_page{
    /*.mdl-textfield__input{
      padding: 11px 0;
    }*/

    position: relative;

    .show_hint {
      position: absolute;
      left: 45px;
      top: 25px;
      z-index: 1;
    }

    .date_and_time_cover {
      display: block;
    }

    .mdl-textfield-select{
      margin-top: -13px;
      .mdl-textfield__label{
        top: 17px !important;
      }
    }
    .page_big_map {
      .big_map_cover{
        width: ~"calc(100% - 755px)";
      }
      .info_col_cover{
        .content_cols {
          align-items: flex-end;
          &.top_content {
            align-items: flex-start;
          }
          .car {
            flex-wrap: wrap;
            align-items: flex-start;
          }
          .route_block {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .route_permit {
            width: 130px !important;
          }
        }
        .info_col{
          width: 755px;
          display: flex;
          flex-direction: column;
          max-height: 100%;
        }
        .info_block{
          padding-left: 30px;

          .route_number {
            margin-top: 14px;
            width: 100%;
          }
          .car {
            .car_number {
              width: 155px;
              .select2 {
                width: 100% !important;
              }
            }
            .car_number,
            .temperature {
              width: 130px;
              margin: 0 10px;
            }
          }
          .trailer {
            width: 48%;
            .select2-container {
              width: 100% !important;
            }
          }
          .temperature_block {
            justify-content: space-between;
          }
          .temperature {
            width: 48%;
          }
        }
        .section_top{
          justify-content: flex-start;
          align-items: center;
          .title{
            margin-right: 15px;
          }
          .mdl-button {
            margin: 0 5px;
          }
          .content_half{
            display: flex;
            align-items: center;
          }
          .bg_icon{
            background: #C5CEFF;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            i{
              color: #3F51B5;
            }
          }
          .point_divide{
            position: relative;
            margin: 0 25px;
            margin-left: 15px;
            .point_buttons{
              position: absolute;
              right: -9px;
              bottom: 20px;
              .mdl-button{
                min-width: auto;
                padding: 0 4px;
                height: 34px;
                .material-icons{
                  margin: 0;
                  color: #3F51B5;
                }
              }
            }
          }
        }
        .section_bottom{
          padding-left: 35px;
          align-items: center;
          .content_half{
            &.customer{
              width: 31%;
              margin-right: 10px;

              .select2 {
                width: 100% !important;
              }
            }
            &.start_way{
              width: 23%;
            }
            &.algorithm{
              width: 44%;
              justify-content: flex-end;
              .material-icons {
                margin-top: 0;
              }
            }
          }
        }
        .gray_cols{
          margin-left: 35px;
          padding: 20px 20px;
          background: #E4E4E4;
          .checkbox_cover{
            margin-top: 10px;
          }
        }
      }
    }
    select{
      border: none;
    }
    .content_cols{
      &.content_cols_3{
        //flex-wrap: nowrap;
        .content_half{
          width: 33%;
          margin-right: 25px;
          &:last-of-type{
            margin-right: 0;
          }
          &.point{
            flex-grow: 1;
            position: relative;
            .route_details{
              position: absolute;
              left: -20px;
              height: auto;
              line-height: normal;
              bottom: 27px;
              padding: 0;
              min-width: auto;
              .material-icons{
                margin: 0;
                font-size: 14px;
                color: #A5A5A5;
              }
            }
            .ab_points{
              width: 100%;

              input {
                padding-right: 55px;
              }
              
              .field-validation-error{
                bottom: 9px;
                left: 33px;
              }
            }
            .point_not_exactly{
              position: absolute;
              bottom: -8px;
              left: 35px;
            }
            .point_buttons{
              position: absolute;
              right: -9px;
              bottom: 20px;
              .mdl-button{
                min-width: auto;
                padding: 0 4px;
                height: 34px;
                .material-icons{
                  margin: 0;
                  color: #3F51B5;
                }
              }
            }
          }
          &.purpose{
            width: 140px;
            .select2 {
              width: 100% !important;
            }
          }
          &.date{
            width: 140px;

            input[type=date] {
              margin: 0;
            }

            input[type=time] {
              border: none;
              padding: 5px;
              font-size: 16px;
            }
            &.date_short {
              width: 60px;
              .field-validation-error{
                line-height: 9px;
                bottom: -3px;
              }
            }
          }
        }
      }
    }
    .route_list {
      max-height: ~"calc(100vh - 64px - 115px)";
      overflow-y: auto;
      .select2-container--default .select2-results > .select2-results__options {
        max-height: 160px;
      }
    }
  }
}
