.page-content {
  .settings_dispatcher {
    padding: 0 24px;

    .form_section {
      > b {
        margin: 20px 0;
        display: inline-block;
        margin-right: 24px;
      }
    }

    .dispatcher_form_cover {
      overflow: visible;
    }

    .dispatcher_input_cover {
      position: relative;
      width: 400px;
      margin-right: 50px;

      .settings_info_show {
        border: none;
        background-color: #3F51B5;
        border-radius: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        top: 20px;
        right: 0;

        &::after {
          content: '?';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 15px;
          color: #ffffff;
          font-weight: bold;
        }
      }

      .settings_info {
        position: absolute;
        right: -305px;
        top: 12px;
        min-width: 250px;
        max-width: 250px;
        background-color: #E2E6FF;
        padding: 20px;
        font-size: 14px;
        z-index: 100;
        font-weight: 400;
        border-radius: 4px;
        box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);

        &::before {
          position: absolute;
          display: block;
          content: "";
          left: -14px;
          top: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 14px 8px 0;
          border-color: transparent #e2e6ff transparent transparent;
        }
      }

      .invisible {
        display: none;
      }
    }

    .button_cover {
      margin: 40px 0;
    }
  }
}