.monitoring_page{
  overflow: hidden;
  height: ~"calc(100vh - 64px)";

  .client_width {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .request_width{
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .grid_cover_content {
    align-items: flex-end;
    height: 81px;

    .reset_filters {
      border: none;
      background-color: transparent;
      height: 100%;
      cursor: pointer;

      i {
        vertical-align: middle;
        color: #3F51B5;
      }

      &:active {
        i {
          color: #CCCCCC;
        }
      }
    }
  }

  .drop_table_details {
    padding: 0;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .mdl-data-table {
    border: none;
  }
}

.multiselect{
  position: relative;
  &.multiselect--active{
    .multiselect__select{
      transform: none;
      &:before{
        //transform: rotate(180deg);
      }
    }
  }
  .multiselect__select{
    z-index: 55;
    margin-right: -1px;
    top: 0;
    background: #fff;
    &:after{
      content: '';
      position: absolute;
      right: 38px;
      bottom: 1px;
      width: 36px;
      height: 40px;
      z-index: 1;
      background: rgba(0,0,0,0);
      background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(255,255,255,1)));
      background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      background: -ms-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff', GradientType=1 );
    }
    &:before{
      top: 25px;
      z-index: 2;
    }
  }
  .multiselect__tags{
    display: flex;
    overflow: hidden;
   /* position: relative;
    &:before{
      background: linear-gradient(to left, red, #000);
      position: absolute;
      right: 0;
      top: 0;
      height: 40px;
      width: 40px;
    }*/
  }
  .multiselect__tags-wrap{
    white-space: nowrap;
    display: flex;
    height: 40px;
    align-items: flex-end;
    .multiselect__tag{
      //margin-bottom: 0;
    }
  }
  .multiselect__tags{
    min-height: 34px;
    height: 40px;
    padding: 0 40px 0 0px;
    border-radius: 0;
    border: none;

    .multiselect__tag {
      background-color: #CCCCCC;
    }

    .multiselect--active{
      
    }
    .multiselect__input{
      margin-bottom: 0;
      padding-top: 17px;
      padding-left: 0;
      font-size: 16px;
      &::-webkit-input-placeholder { color: #fff; }
      &:-ms-input-placeholder { color: #fff; }
      &::-ms-input-placeholder { color: #fff; }
      &::placeholder { color: #fff; }
    }
    .multiselect__placeholder{
      margin-bottom: 0;
      padding-top: 17px;
      color: rgba(0, 0, 0, .26);
      font-size: 16px;
    }
  }
  .multiselect__content-wrapper{
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

    .multiselect__option {
      overflow: hidden;
      white-space: normal;

      &::after {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        background-color: transparent;
        border: 1px solid #000000;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 80%;
      }

      .empty_list {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        pointer-events: none;
        cursor: initial;
        z-index: 1;
        right: 0;
        padding: 10px;
        top: 0;
        margin: 0;
      }
    }

    .multiselect__option--highlight {
      background-color: #CCCCCC;
    }

    .multiselect__option--selected {
      &::after {
        background-image: url("/Content/images/check-bold.svg");
      }
    }

    .multiselect__option--disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &::after {
        display: none;
      }
    }
  }
}

.monitoring_search{
  background: #fff;
  border-bottom: 1px solid #E4E4E4;
  height: 62px;
}
.column_screen{
  display: flex;
  justify-content: space-between;
  height: calc(~"100vh - 64px - 62px - 1px");
  position: relative;
}
.monitoring_table{
  flex-grow: 1;
  background: #fff;
  overflow-x: auto;
  height: ~"calc(100% - 30px)";
  position: relative;
  z-index: 3;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &.monitoring_table_left {
    width: 50%;
  }

  .address_width {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action {
    height: 30px;
    line-height: 30px;
  }

  tbody {
    tr {
      cursor: pointer;
    }

    .inner_info {
      cursor: default;
    }
  }

  .mdl-data-table {
    .icon_container {
      padding: 0;
      min-width: 32px;
    }
  }

  .sort_order {
    cursor: pointer;
    position: relative;

    &::before {
      position: absolute;
      left: -3px;
      bottom: 9px;
    }

    &:first-child {
      padding: 3px 4px 4px 15px;
      text-align: center;

      i {
        font-size: 22px;
      }

      &::before {
        left: 5px;
      }
    }

    span {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.54);
    }
  }


  .status_problem{
    background: blue;
    width: 11px;
    height: 11px;
    border-radius: 50%;
  }

  .status {
    margin-bottom: 2px;
    vertical-align: middle;
    font-size: 14px;
    &.online {
      color: rgb(23, 212, 53);
    }
    &.offline {
      color: #35495e;
    }
    &.inactive {
      color: rgb(235, 53, 53);
    }
  }

  .no_results {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 1;
    text-align: center;

    span {
      font-size: 22px;
      font-weight: bold;
      display: block;
      padding-top: 100px;
    }
  }

  .dropdown_route_details {
    background-color: #3F51B5;
    height: 37px;
    width: 100%;
    padding: 0;
    border: none;

    tbody {
      tr {
        cursor: default;
      }
    }
  }

  .history{
    .event_cell {
      max-width: 250px;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }

    .empty_segment_events {
      text-align: center;
    }

    .comment_cell {
      max-width: 250px;
      white-space: normal;
    }

    .mdl-data-table {
      background: transparent;
      border: none;
      margin: 0 0 20px 0;

      thead {
        padding-bottom: 0;
      }

      th {
        padding-bottom: 2px;
        vertical-align: middle;
      }

      td {
        border: none;
        height: auto;
      }

      tbody {
        tr {
          height: 35px;
          cursor: default;
        }
        td {
          padding: 0 15px;
        }
      }

      thead,
      tbody {
        background: transparent;
        border: 1px solid #ffffff;

        tr {
          &:hover {
            background: transparent;
          }
        }
      }
    }
    .check {
      min-width: 23px;
      max-width: 23px;
      padding-left: 10px;

      i {
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}
.mdl-data-table {
  tbody {
    .teal_background {
      background-color: #B4C0FF;

      td {
        border-color: #B4C0FF;
      }

      &:hover {
        background-color: #B4C0FF;
      }
    }
  }
}

.drag_handle {
  background-color: transparent;
}

.dragable_lcol{
  display: block;
  height: 100%;
  width: 6px;
  z-index: 5;
  left: 50%;
  margin-left: -5px;
  top: 0;
  position: absolute;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dragable_top {
  display: block;
  width: calc(~"100% - 415px");
  height: 17px;
  right: 0;
  left: auto !important;
  top: 50%;
  position: absolute;
  margin-top: 0;
  z-index: 5;
  cursor: row-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown_route_stats {
  padding: 0 15px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.route_stats_block {
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: #ffffff;
}

.route_stats_text {
  margin-left: 15px;
}

.mdl-data-table {
  .drop_table_details {
    border-top: none;
    background: #B4C0FF;
  }

  .drop_table_inner {
    thead {
      tr {
        &:hover {
          background-color: #B4C0FF;
        }
      }
    }

    tr {
      background: #B4C0FF;

      &:not(.level3_opener) {
        cursor: default;
      }
    }

    td {
      border: none;
    }
  }

  .inner_table_item {
    border-top: 1px solid #ffffff;
  }

  tbody {
    .level3 {
      display: none;
      cursor: default;

      td {
        padding-bottom: 20px;
        border-top: 2px solid #B4C0FF;
      }

      &:hover {
        background-color: #B4C0FF;
      }
    }

    .level3_opener {
      .icon_cell {
        div {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-image: url("/Content/images/down_arrow.svg");
        }
      }

      &.open {
        .icon_cell {
          div {
            background-image: url("/Content/images/up_arrow.svg");
          }
        }
        &~.level3 {
          display: table-row;
        }
      }

      &:hover {
        &+.problem_list_row {
          background-color: #eeeeee;

          td {
            border-top: 2px solid #eeeeee;
            transition-duration: .28s;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1);
            transition-property: border-top;
          }
        }
      }
    }

    .problem_list_row {
      cursor: default;

      td {
        border-top: 2px solid #B4C0FF;
        padding-bottom: 20px;
        transition-duration: .28s;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-property: border-top;
      }

      &:hover {
        background-color: #B4C0FF;
      }
    }
  }
}

.list_problem{
  .item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 10px;
    margin-bottom: 2px;
    box-sizing: border-box;
    &.attention{
      background: #FFCECE;
    }
    &.pending{
      background: #E2E6FF;
    }
    .desc{
      display: flex;
      align-items: center;
      .icon{
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
      .name{
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}

.monitoring_tracking{
  flex-grow: 1;
  width: 50%;
  position: relative;
  z-index: 2;

  &.monitoring_tracking_general {
    overflow: hidden;
  }
}
.monitoring_map{
  background: #ccc;
  height: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 4;
  overflow: hidden;
}
.monitoring_problems{
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 50%;
  position: relative;
  z-index: 2;

  tbody {
    tr {
      cursor: default;
    }
  }

  .attention{
    background: #FFCECE;
    }

  .pending{
    background: #E2E6FF;
  }

  .monitoring_table {
    max-width: 100%;

    .icon_container {
      padding: 0 0 0 10px;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    //margin-right: 20px;
  }
}

.multiselect_cover {
  position: relative;

  &::after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .12);
    bottom: 20px;
    left: 0;
  }

  &.is-focused {
    .mdl-textfield__label {
      top: 15px;
      z-index: 50;
    }
  }

}