.persons {
  .persons_tabs {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;

    .tab_selector {
      border: none;
      margin: 0;
      padding: 0;
      border-radius: 0;
      justify-content: flex-start;
    }

    .tab_selector_item {
      font-size: 16px;
      line-height: 19px;
      border-radius: 0;
      border: none;
      border-bottom: 3px solid transparent;
      padding: 12px;
      margin: 0 10px;
    }

    .tab_selector_radio {
      &:checked {
        &+.tab_selector_item {
          border-bottom: 3px solid @color_dark_blue;
          background-color: transparent;
          color: @color_dark_blue;
        }
      }
    }
  }

  .persons_inner_tab {
    display: none;

    &.tab_shown {
      display: block;
    }

    .persons_filter_cover {
      padding: 20px 0 0 0;

      .k-clear-value {
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
      }

      .k-widget {
        height: auto;
      }
    }

    .filter_field {
      &.k-input {
        height: 40px;
        padding: 9px 10px 8px 10px;
        font-size: 14px;
        line-height: 16px;
        border: 1px solid @color_light_blue;
      }
    }
  }

  .persons_table_row {
    height: 66px;
    border-bottom: 1px solid @color_grey;
  }
}

.persons_filter_row {
  display: flex;
  justify-content: space-between;
}

.persons_submit_col {
  padding: 22px 14px 0 0;
}