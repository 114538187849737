.inner_page {
  color: @color_dark_blue;
  padding-bottom: 20px;

  .page_title {
    display: none;
  }

  .tickets_page_title {
    display: none;
  }

  .filters {
    padding: 15px 30px;
    display: flex;
    flex-wrap: wrap;
  }

  .filter_cover {
    width: 150px;
    margin: 0 20px 15px 0;

    .v-select {
      border: 1px solid @color_light_blue;
      box-sizing: border-box;
      border-radius: 20px;
      font-size: 14px;
      line-height: 16px;
      padding: 4px 10px;
      color: @color_dark_blue;
      cursor: pointer;
      width: 100%;
      height: 40px;
    }

    .vs__dropdown-toggle {
      border: none;
      max-height: 100%;
    }

    .vs__selected-options {
      flex-wrap: nowrap;
    }

    .vs__open-indicator {
      fill: @color_dark_blue;
      transform: scale(0.7);
    }

    .vs__clear {
      svg {
        fill: @color_dark_blue;
        transform: scale(0.7);
      }
    }

    .vs__selected {
      color: @color_dark_blue;
    }
  }

  .filter_xl_cover {
    width: 444px;

    &.with_subcovers {
      width: 560px;
    }
  }

  .filter_xl_half_cover {
    width: 270px;
  }

  .filter_large_cover {
    width: 324px;

    .filter_half {
      width: 150px;

    }
  }

  .with_subcovers {
    display: flex;
    justify-content: space-between;

    .dates_divider {
      margin-bottom: -12px;
    }
  }

  .dates_divider {
    font-weight: bold;
    height: 18px;
    width: 14px;
    display: inline-block;
    align-self: center;
    text-align: center;
  }

  .filter_mid_cover {
    width: 160px;
  }

  .submit_cover {
    display: flex;
    position: relative;

    .submit {
      margin-top: auto;
    }

    .filters_validation {
      position: absolute;
      z-index: 1;
      top: 60px;
      left: 0;
      padding: 5px;
      background-color: #ffffff;
      border: 1px solid @color_red;
      border-radius: 20px;
      box-shadow: 0 -2px 4px rgba(0,0,0,.25), 0 2px 4px rgba(0,0,0,.25);

      &::before {
        content: '';
        position: absolute;
        left: 20px;
        top: -20px;
        border: 10px solid transparent;
        border-bottom: 10px solid @color_red;
      }

      ul {
        line-height: 24px;
        padding: 0 0 0 20px;
        margin: 0;
      }

      .filters_validation_warning {
        color: @color_red;
        margin-bottom: 4px;
        display: flex;

        .material-icons {
          margin-right: 5px;
        }
      }

      .filters_validation_text {
        font-size: 12px;
        line-height: 12px;
        padding: 2px 0;
      }
    }
  }

  .filter_wide_cover {
    width: 322px;
  }

  .filter_pseudo_cover {
    position: relative;
  }

  .filter_field {
    border: 1px solid @color_light_blue;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 10px 8px 10px;
    color: @color_dark_blue;
    cursor: pointer;
    width: 100%;
    height: 40px;

    &::placeholder {
      color: @color_dark_blue;
    }
  }

  .select_field {
    padding-top: 7px;
  }

  .form_error {
    .filter_label,
    .seat_selector_text,
    .payment_type_title,
    label {
      color: @color_red !important;
    }

    .filter_field,
    .passenger_data_input,
    .payment_type_title,
    .payment_type_icon {
      border-color: @color_red !important;
    }
  }

  .filter_text_field {
    padding-left: 14px;
  }

  .filter_half {
    width: 150px;
    padding-left: 12px;
  }

  .filter_label {
    display: block;
    font-size: 12px;
    line-height: 14px;
    padding-left: 15px;
    cursor: pointer;

    .client_only_label {
      display: none;
    }
  }

  .filter_pseudo_field {
    background-color: #ffffff;
    text-align: left;
    line-height: 18px;
    padding-left: 14px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;

    .absolute_icon {
      position: absolute;
      right: 0;
      top: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .filter_pseudo_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
    padding-right: 5px;
  }

  .filter_pseudo_dropdown {
    position: absolute;
    z-index: 1;
    top: 60px;
    left: -10px;
    padding: 15px;
    width: 320px;
    background: @color_light_grey;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    box-sizing: border-box;

    .filter_cover {
      width: 100%;
    }
  }

  .passengers_from_autocomplete {
    margin-bottom: 10px;
  }

  .passengers_from_autocomplete_title {
    color: @color_dark_blue;
  }

  .additional_from_autocomplete_title {
    display: block;
    padding-bottom: 5px;
  }

  .passengers_from_autocomplete_checkbox {
    padding: 5px 0;
    display: block;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .adding_transport {
    .filter_field,
    .select2-container {
      width: 84% !important;
    }
  }

  .add_transport,
  .add_passengers {
    background-color: @color_light_blue;
    border: 1px solid @color_light_blue;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    width: 210px;
    display: block;
    margin: 0 auto;
    color: #ffffff;
  }

  .add_passengers {
    margin-bottom: 10px;
  }

  .delete_transport {
    background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
    border: 1px solid @color_red;
    color: @color_red;
    border-radius: 50%;
    cursor: pointer;
    width: 40px;
    height: 40px;
    vertical-align: bottom;
    padding: 0;

    .material-icons {
      vertical-align: middle;
    }
  }

  .no_suitable_model {
    display: block;
    width: 180px;
    margin: 20px auto;
    text-decoration: none;
    color: @color_dark_blue;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }

  .submit{
    background-color: @color_light_blue;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    padding: 8px;
    cursor: pointer;
    height: 40px;
    width: 100%;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }

    &:active {
      box-shadow: none;
    }
  }

  .button_silver {
    background: linear-gradient(180deg, #FFFFFF 0%, #E0EDFF 100%);
    border: 1px solid @color_dark_blue;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    transition: background 0.3s;
    color: @color_dark_blue;
    line-height: 15px;

    &:hover {
      background: linear-gradient(360deg, #FFFFFF 0%, #E0EDFF 100%);
      transition: background 0.3s;
    }
  }

  .select2-selection {
    .filter_field();
    outline: none;
  }

  .select2-container--default {
    &.select2-container--focus {
      .select2-selection--multiple {
        border-color: @color_light_blue;
      }
    }
    .select2-selection--single {
      .select2-selection__rendered {
        color: @color_dark_blue;
        line-height: 20px;
        padding-left: 5px !important;
      }

      .select2-selection__arrow {
        height: 100%;
        b {
          border-color: @color_light_blue transparent transparent;
        }
      }
    }

    .select2-dropdown {
      border: none;

      .select2-search--dropdown {
        .select2-search__field {
          padding: 0;
        }
      }
    }

    .select2-selection--multiple {
      &:before {
        content: "";
        position: absolute;
        right: 7px;
        top: 44%;
        border-top: 5px solid @color_light_blue;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      .select2-selection__rendered {
        padding: 0 !important;
      }

      .select2-search {
        display: none;
      }

      .select2-selection__choice {
        background: #E0EDFF;
        border: 1px solid @color_dark_blue;
        box-sizing: border-box;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        font-size: 12px;
        line-height: 18px;
        color: @color_dark_blue;
        margin-top: 0;
        height: 20px;
      }

      .select2-selection__choice__remove {
        color: @color_dark_blue;
      }
    }
  }

  .actions {
    padding: 0 30px 15px 30px
  }

  .actions_text {
    font-size: 16px;
    line-height: 18px;
    margin-right: 20px;
  }

  .actions_submit {
    width: 200px;
  }
}

.mapSeatItem {
  cursor: pointer;
  fill: @color_dark_blue
}

.map_seat_checked {
  fill: @color_light_blue !important;
}

.not_available {
  fill: #E0E0E0 !important;
  cursor: not-allowed;
  pointer-events: none;
}

.pagination_cover {
  text-align: right;
  padding: 15px;
}

.pagination {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.pagination_number {
  background-color: @color_light_blue;
  border: 1px solid @color_light_blue;
  display: inline-block;
  cursor: pointer;
  height: 38px;
  width: 34px;
  box-sizing: border-box;
  margin-right: 1px;

  a,
  span {
    text-decoration: none;
    display: block;
    height: 100%;
    width: 100%;
    padding: 6px 8px 5px 8px;
    box-sizing: border-box;
    text-align: center;
    vertical-align: middle;
    line-height: 26px;
    font-weight: bold;
    color: #ffffff;
  }

  &:first-child {
    border-radius: 20px 0 0 20px;
    padding-left: 4px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
    padding-right: 4px;
  }

  &:hover {
    background-color: #ffffff;

    a {
      color: @color_light_blue;
    }
  }

  &.active {
    background-color: @color_dark_blue;
    border-color: @color_dark_blue;
    pointer-events: none;
  }

  &.pagination_dots {
    &:hover {
      background-color: @color_light_blue;
    }
  }
}

@media (max-width: 1000px) {
  .inner_page {
    .adding_transport {
      .filter_field,
      .select2-container {
        width: 82% !important;
      }
    }

    .filters {
      padding: 20px;
    }
  }
}