/* Стили для панели сверху */
.catalog-page {
  .admin_head_cover {
    position: fixed;
    width: 100%;
    z-index: 1010;
  }
}

.admin_head_cover {
  text-align: center;
  background: #e6e6e6;
  z-index: 20;
  position: relative;
}

.admin_head {
  display: inline-block;
  text-align: center;
  padding: 22px 0px 23px;
  font-size: 14px;
  a {
    color: #2461b5;
    text-decoration: underline;
    margin-left: 20px;
    &:hover {
      color: #48c9b0;
      transition: all .2s;
    }
  }
  #tech_support{
    color: #2461b5;
    cursor: pointer;
    border-bottom: 1px dashed #2461b5;
  }
  .btn {
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: #000;
    position: relative;
    height: 36px;
    margin: 0;
    min-width: 64px;
    padding: 0 16px;
    display: inline-block;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    background: rgb(63,81,181);
    color: rgb(255,255,255);
  }
}

/* Авторизация */
.main-wrap-authorization {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
  display: table;
  font-family: 'Nunito', sans-serif;
  input,textarea{
    font-family: 'Nunito', sans-serif;
  }
  .logo_login{
    margin-bottom: 45px;
  }
  .authorization {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .authorization_In_form {
    padding: 40px 50px;
    width: 420px;
    box-sizing: border-box;
    text-align: center;
    background: #F6FAFF;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    input{
      outline: none !important;
      &.input-validation-error{
        border-color: red;
      }
    }
    .bottom-part{
      margin-top: 40px;
      display: flex;
      justify-content: center;
      .btn{
        margin: 0 10px;
      }
      a{
        font-size: 13px;
        line-height: 20px;
        color: #272a29;
        padding: 10px;
        box-sizing: border-box;
        text-decoration: none;
      }
    }
    fieldset{
      border: none;
    }
    h2{
      margin: 10px 0;
      padding: 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      color: #272A29;
    }
    form{
      >p{
        font-size: 14px;
        line-height: 16px;
      }
    }
    .btn{
      background-color: #00A0E8;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      border: none;
      padding: 8px;
      cursor: pointer;
      height: 40px;
      width: 134px;
      transition: box-shadow 0.3s;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
      }

      &:active {
        box-shadow: none;
      }
    }
  }
}

.login_seo{
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #272A29;
  a{
    font-weight: bold;
    color: #272A29;
    text-decoration: none;
  }
}

.authorization-container {
  display: table;
  position: relative;
  margin: 0 auto;
  text-align: left;
  .logo{
    text-align: center;
    padding-bottom: 30px;
    position: absolute;
    top: -58px;
    height: 30px;
    width: 100%;
    svg{
      width: 223px;
    }
  }
  .authorization_bottom{
    text-align: center;
    padding-top: 20px;
    line-height: 30px;
    color: #fff;
    position: absolute;
    bottom: -80px;
    width: 100%;
    a{
      color: #fff;
      margin-left: 5px;
      text-decoration: none;
    }
  }
  h3 {
    padding-bottom: 0.3em;
    padding-top: 0;
  }
  .remember-me {
    padding-bottom: 15px;
    padding-top:  20px;
    label {
      cursor: pointer;
    }
    color: #606466;
    .jq-checkbox{
      background: #ffffff;
      border: 1px solid #cccccc;
    }
    span{
      position: relative;
      top: -1px;
      left: 5px;
      color: #606466;
    }
  }
  table {
    text-align: left;
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 15px;
    width: 100%;
    td {
      padding-bottom: 3px;
    }
    td:first-child {
      padding-right: 5px;
    }
  }

  .btn {
    background: 0 0;
    border: none;
    border-radius: 2px;
    color: #000;
    position: relative;
    height: 36px;
    margin: 0;
    min-width: 64px;
    padding: 0 16px;
    display: inline-block;
    font-family: "Nunito","Helvetica","Arial",sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    overflow: hidden;
    will-change: box-shadow;
    transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    vertical-align: middle;
    background: rgb(63,81,181);
    color: rgb(255,255,255);
  }
}

.authorization-container {
  .sad_man_authorization {
    font-size: 14px;
    color: rgb(63,81,181);
    position: relative;
    width: auto;
    line-height: 20px;
    a {
      color: rgb(63,81,181);
      text-decoration: none;
    }
  }
}

.drzn_logo_authorization {
  position: absolute;
  left: 50%;
  margin-left: -68px;
  top: -80px;
}

/* Контент зона */
.contentZoneBorder {
  border: 1px dashed #212121;
}

/* Карандаш */
.icon_pencil {
  background: url("/Content/img/admins_iconAnd_bg/icon_pencil.png") no-repeat;
  display: inline-block;
  height: 14px;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 14px;
}

.pencil-url {
  border: 1px dotted black !important;
  height: 20px;
  width: 20px;
  margin: 3px 10px !important;
  position: relative;
  display: inline-block;
}

/* 404 ошибка */
.fourZeroFour {
  text-align: center;
}

.authorized_form_modal {
  h3 {
    text-align: center;
    padding-top: 0px;
  }
  input {
    margin-bottom: 15px;
    width: 100%;
  }
  border: none;
  border-radius: 0 !important;
  padding: 10px;
  .btn {
    width: 100%;
    margin-bottom: 20px;
  }
  .modal-container{
    padding: 0 20px;
    padding-bottom: 20px;
    width: 360px;
  }
  .authorize-block_footer{
    a:nth-of-type(2){
      float: right;
    }
  }
  .prompt{
    padding-bottom: 10px;
    color: #e31e24;
  }
}

.admin-edit-tools-block {
  margin-bottom: 5px;
  .admin-edit-tools-item-container {
    &:before {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }
    &:after {
      content: '';
      height: 0;
      display: block;
      clear: both;
    }
  }
  .admin-edit-tools-item {
    line-height: 20px;
    font-size: 14px;
    cursor: pointer;
    border: 1px dashed #2e2e2e;
    float: left;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    color: #2e2e2e;
    text-align: center;
    transition: all .2s;
    &:hover {
      background-color: #2e2e2e;
      color: #fff;
    }
    &:first-child {
      margin-left: 0px;
    }
    &.is-loading {
      cursor: default;
      background-color: #2e2e2e;
      color: #fff;
    }
    &.admin-edit-tools-item-publish {
      &.to-publish {
        .fa-eye {
          display: none;
        }
        .fa-eye-slash {
          display: inline-block;
        }
      }
      &.not-to-publish {
        .fa-eye {
          display: inline-block;
        }
        .fa-eye-slash {
          display: none;
        }
      }
    }
  }
}

.inputer_cover {
  position: relative;
  border: none;
  padding: 0;
  margin: 5px 0;
  input {
    border: 1px solid #00a0e8;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 10px 8px 14px;
    color: #1855a3 !important;
    cursor: pointer;
    width: 100%;
    height: 40px;
  }
  .field-validation-error {
    white-space: nowrap;
    position: absolute;
    padding: 8px 10px;
    border-radius: 3px;
    visibility: visible;
    opacity: 1;
    transition: all 300ms;
    background: #fff;
    top: 15px;
    left: 100%;
    filter: drop-shadow(0 1px 10px rgba(0, 0, 0, .2));
    -webkit-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, .2));
    &:before {
      content: "";
      position: absolute;
      left: -6px;
      top: 50%;
      margin-top: -10px;
      border-right: 10px solid #fff;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
  .field-validation-valid {
    position: absolute;
    height: 12px;
    width: 12px;
    bottom: 14px;
    right: 6px;
    border-radius: 50%;
    box-sizing: border-box;
    transition: all 300ms;
    > span {
      visibility: visible;
      opacity: 0;
    }
  }
  label{
    display: block;
    font-size: 12px;
    line-height: 14px;
    padding-left: 15px;
    cursor: pointer;
    color: #1855A3;
    text-align: left;
  }
}
.error_compare_pass{
  .field-validation-error{
    margin: 0;
    padding-left: 18px;
  }
}

.edit_field_cover {
  overflow: hidden;
  padding-top: 15px;
  margin: 0 -1%;
  > div {
    vertical-align: top;
    margin: 0 1%;
    margin-bottom: 2%;
    label {
      width: 10%;
      font-weight: normal !important;
      color: #747b7e;
    }
    input.single-line {
      width: 89.7%;
    }
    .field-validation-valid {
      display: block;
    }
  }
}

.photo_downloading_block {
  margin-top: 40px;
  margin-bottom: 45px;
  .photo_downloading_block_desc {
    display: inline-block;
    color: #a7a7a7;
    vertical-align: middle;
  }
}

.fileupload-progress {
  margin-top: 20px;
}

input.text-box, textarea.text-box {
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.462;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  resize: none;
  width: 100%;
}

.btn_save_button {
  display: inline-block;
  background: transparent;
  margin-left: 20px;
  //.icon-download_grey;
  &:hover {
    background: transparent;
    //.icon-download_green;
  }
}

.btn_watch_on_site {
  display: inline-block;
  background: transparent;
  margin-left: 27px;
  //.icon-visible;
  &:hover {
    background: transparent;
    //.icon-visible;
  }
}

.btn_come_back {
  display: inline-block;
  float: left;
  margin-top: 18px;
  margin-right: 13px;
  //.icon-back;
}

.central_page {
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
}
#tech_support_modal{
  padding: 30px 40px;
  width: 570px;
  label {
    display: inline-block;
    margin-top: 15px;
    color: #939a9d;
    font-weight: normal;
    margin-bottom: 5px;
  }
  h2, .h2 {
    font-size: 30px;
    padding-top: 0;
    padding-bottom: 10px;
  }
  input, textarea{
    width: 100%;
    padding: 6px 10px;
    font-size: 18px;
    line-height: 1.462;
    border-radius: 5px;
    border: 1px solid #bdc3c7;
    resize: none;
  }
  .btn{
    display: inline-block;
    background: #426ea0;
    margin-right: 8px;
    font-size: 18px;
    padding: 12px 20px;
    padding-bottom: 10px;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
  }
  .modal-footer {
    padding-top: 20px;
  }
}