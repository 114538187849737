.side_menu {
  .menu_lvl1_item {
    padding: 0;

    a {
      padding: 10px 12px;
      min-height: auto;
    }

    > span {
      padding: 10px 7px;
    }

    .mdl-list__item-icon {
      margin-right: 20px;
    }
  }

  .menu_lvl1_title {
    width: 100%;
  }

  .menu_lvl2 {
    display: none;
  }

  .menu_lvl2 {
    list-style: none;
    padding: 0;
    margin-top: 5px;

    .menu_lvl2_item {
      padding: 0 9px 0 45px;

      .mdl-list__item {
        .mdl-list__item-primary-content {
          white-space: normal;
        }
      }

      .mdl-list__item.active{
        .mdl-list__item-primary-content {
          color: @color_light_blue;
        }

        &:before{
          display: none;
        }
      }
      a {
        padding: 17px 0 17px 60px;
        font-size: 14px;
        min-height: 0;
      }
    }
  }
}
.more_info_footer_button {
  .support {
    display: block;
    height: 14px;
    line-height: 30px;
  }
}