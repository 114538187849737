.offices_tab_selector {
  border: none;
  border-bottom: 2px solid @color_grey;
  border-radius: 0;
  justify-content: flex-start;
  height: auto;
  padding: 0;
  margin: 0;

  .tab_selector_item {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: @color_deep_blue;
    border-radius: 0;
    border: none;
    padding: 5px 30px;
  }

  .tab_selector_radio {
    &:checked {
      &+.tab_selector_item {
        color: @color_light_blue;
        background-color: transparent;
        position: relative;

        &::after {
          position: absolute;
          bottom: -2px;
          left: 0;
          display: block;
          content: '';
          height: 4px;
          width: 100%;
          background-color: @color_light_blue;
        }
      }
    }
  }
}

.sales_offices_inner {
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.offices_tab {
  display: none;
}

.offices_item {
  margin: 0 10px 10px 0;
  padding: 20px 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 260px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &.active {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  }
}

.office_info {
  margin-bottom: 4px;
}

.office_name {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
  color: @color_deep_blue;
}

.office_info_type,
.office_info_text {
  display: block;
  margin-bottom: 8px;
  color: @color_deep_blue;
}

.office_on_map {
  background-color: transparent;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 20px;
  border: 1px solid @color_light_blue;
  color: @color_light_blue;
  text-align: center;
  cursor: pointer;
  margin: auto auto 0 auto;

  &:hover {
    background-color: @color_light_blue;
    color: #ffffff;
  }
}

@media (max-width: 1000px) {
  .sales_offices_tabs {
    display: none;
  }

  .sales_offices_inner {
    flex-direction: column;
  }

  .offices_item {
    width: auto;
    margin: 10px;
  }

  .office_on_map {
    margin: auto auto 0 0;
  }
}
