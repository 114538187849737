.ticket {
  width: 800px;
  padding: 80px 50px;

  .ticket_general {
    padding: 20px;
  }

  .ticket_head {
    background-color: #1855A3;
    color: #ffffff !important;
    font-size: 24px;
    line-height: 28px;

    &:first-letter {
      color: #ffffff !important;
    }
  }

  .ticket_time {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .ticket_title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    text-transform: uppercase;
  }

  .ticket_inner_item {
    display: flex;
    padding: 8px 20px;
  }

  .ticket_inner_field {
    font-size: 16px;
    line-height: 19px;
  }

  .ticket_inner_position {
    margin-right: 20px;
  }

  .ticket_inner_transport,
  .ticket_inner_passenger {
    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  .ticket_footer {
    padding: 0 20px;

    p {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .qr_code {
    height: 200px;
    width: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}