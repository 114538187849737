.instructor_block {
  padding: 25px;

  .instructor_field {
    display: block;
  }
}

.word_break {
  word-wrap: break-word;
}