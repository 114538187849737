.profile {
  .table_item_subtitle {
    display: none;
  }

  .profile_item {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-bottom: 40px;
    position: relative;

    .filter_auto_cover {
      width: auto;
      flex: 1 1 0;
    }

    .filter_mid_cover {
      width: 220px;
    }
  }

  .is_static {
    .static {
      &.flex {
        display: flex;
      }

      &.block {
        display: block
      }

      &.inline {
        display: inline;
      }
    }

    .editing {
      display: none;
    }
  }

  .is_editing {
    .static {
      display: none;
    }

    .editing {
      &.flex {
        display: flex;
      }

      &.block {
        display: block;
      }

      &.inline {
        display: inline;
      }

      .profile_data_row {
        justify-content: flex-start;
      }
    }
  }

  .profile_item_title {
    font-family: 'Nunito', Arial, sans-serif;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: @color_deep_blue;
    margin: 0;
    padding-bottom: 20px;
  }

  .profile_item_data {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .profile_data_half {
    width: 40%;
  }

  .profile_data_full {
    width: 100%;
  }

  .profile_data_row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
  }

  .profile_submit {
    width: 220px;
  }

  .inner_table {
    tr {
      border-bottom: 1px solid @color_grey;
    }
  }

  .row_controls {
    display: flex;
  }

  .delete_row {
    vertical-align: middle;
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    .material-icons {
      vertical-align: middle;
      color: @color_red;
      font-size: 30px;
    }
  }

  .profile_add_transport {
    background-color: transparent;
    border: 1px solid @color_deep_blue;
    color: @color_deep_blue;

    &:hover {
      color: @color_light_blue;
      border-color: @color_light_blue;
    }
  }

  .profile_no_model {
    background-color: transparent;
    color: @color_deep_blue;
    margin-left: 28px;
    cursor: pointer;

    &:hover {
      color: @color_light_blue;
    }
  }

  .profile_table_data {
    margin: 0 -30px;

    .inner_table {
      th,
      td {
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
    }
  }
}

.order_history {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  .table_row_openable {
    box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.25),
                inset 0 -11px 8px -10px rgba(0, 0, 0, 0.25);
  }

  .scalable_submit {
    .scalable_submit_icon {
      display: none;
    }
  }

  .table_item_subtitle {
    display: none;
  }

  .switchable_table_button {
    display: none;
  }

  .awaiting_payment {
    background-color: @color_light_blue;
    color: @color_deep_blue;
    border-radius: 5px;
    padding: 3px 6px;
    font-size: 13px;
    text-align: center;
    width: fit-content;
  }
}

@media (max-width: 1000px) {
  .client {
    .order_history {
      .switchable_filters {
        flex-wrap: nowrap;

        .filter_xl_cover {
          width: ~'calc(100% - 48px)';
          margin-right: 6px;

          .select2-selection {
            border-radius: 20px 0 0 20px;
          }
        }

        .filter_mid_cover {
          width: 42px;
          margin-right: 0;
        }

        .scalable_submit {
          border-radius: 0 20px 20px 0;
          width: 100%;
          align-self: flex-end;

          .scalable_submit_icon {
            display: inline;
          }

          .scalable_submit_text {
            display: none;
          }
        }
      }

      .nested_table_group {
        border-radius: 20px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        margin: 10px;
      }

      .table_row_opener {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: none;

        td {
          width: 49%;
          box-sizing: border-box;
        }

        .button_column {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .table_item_subtitle {
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
      }

      .price_column {
        text-align: left;
      }

      .switchable_table_button {
        display: initial;
        padding: 8px 20px;
        width: fit-content;
      }

      .switchable_table_button {
        border: 1px solid @color_light_blue;

        &.btn_opened {
          color: @color_light_blue;
          border-color: @color_light_blue;
          background-color: #ffffff;
        }
      }

      .nested_table_body_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: none;

        td {
          width: 49%;
          box-sizing: border-box;
        }

        .button_column {
          width: 100%;
        }
      }

      .nested_table_cell {
        padding: 30px 0 20px 0;
      }

      .nested_table_title {
        padding: 0 15px;
      }
    }

    .profile {
      padding: 0 10px;

      .profile_item {
        box-sizing: border-box;
        padding: 15px 0;
      }

      .profile_item_title {
        font-size: 18px;
        line-height: 25px;
        padding: 0 15px 10px 15px;
      }

      .table_item_subtitle {
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: @color_dark_grey;
      }

      .profile_item_data {
        tr {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          th,
          td {
            width: 49%;
            box-sizing: border-box;
          }
        }
      }

      .profile_table_data {
        margin: 0;

        .inner_table {
          th,
          td {
            &:first-child,
            &:last-child {
              padding: 15px;
            }
          }
        }
      }

      .profile_item_controls {
        padding: 0 15px;
      }

      .is_editing {
        .editing {
          .profile_data_row {
            flex-wrap: wrap;
            padding: 0 15px;
            justify-content: space-between;
            box-sizing: border-box;
          }
        }

        .editing_transport,
        .editing_passengers,
        .editing_drivers {
          .profile_data_row {
            border-bottom: 1px solid @color_grey;
            padding: 15px;

            .filter_mid_cover {
              width: 48%;
              margin-right: 0;
            }

            .filter_doc_cover {
              margin-right: 0;
              width: 100%;
            }

            .row_controls {
              width: 100%;

              .delete_row {
                margin-left: auto;
              }
            }
          }
        }

        .editing_juridical {
          .profile_data_row {
            .filter_auto_cover {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }

      .filter_auto_cover {
        width: 44%;
        flex: auto;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
