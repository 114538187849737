.cards_cover{
  padding: 40px 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .mdl-card{
    width: 49%;
    margin-bottom: 25px;
    .green{
      color: #30e988;
    }
    .mdl-card__title{
      padding-right: 126px;
    }
    .mdl-card__actions{
      text-align: right;
    }
    p{
      margin-bottom: 0;
    }
  }
}