.ferry_modal {
  padding: 30px;
  border-radius: 20px;
  max-width: 1080px;
  width: auto;
  overflow: hidden;

  .form_error {
    font-size: 14px;
  }

  .modal-content {
    padding-bottom: 6px;
  }

  .box-modal_close {
    .material-icons {
      color: @color_red;
    }
  }

  .modal_title_text {
    color: @color_dark_blue;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin: 0;
  }

  .passengers_item {
    display: flex;
    align-items: center;
  }

  .passengers_block {
    padding-bottom: 30px;
  }

  .passengers_map {
    display: flex;

    .passengers_map_image {
      svg {
        width: 518px;
        height: auto;
      }
    }
  }

  .passengers_map_legend {
    align-self: center;
  }

  .passengers_data_row {
    height: 40px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .passengers_data_item {
      height: 100%;
      display: flex;
    }

    .passengers_data_title {
      width: 155px;
      background-color: @color_light_blue;
      border: 1px solid @color_light_blue;
      border-radius: 20px 0 0 20px;
      text-align: right;
      padding: 10px 16px;
      color: #ffffff;
      font-size: 14px;
      line-height: 16px;
    }

    .passenger_data_input {
      width: 210px;
      padding: 10px 16px;
      color: @color_dark_blue;
      border: 1px solid @color_light_blue;
      border-radius: 0 20px 20px 0;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .passengers_data_seat_item {
    position: relative;

    .clear_seat_button {
      position: absolute;
      right: 6px;
      padding: 6px 8px;
      font-size: 12px;
      top: 50%;
      transform: translateY(-50%);
      background-color: @color_light_blue;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      border: 1px solid @color_light_blue;
      transition: background 0.3s, box-shadow 0.3s;
      color: #ffffff;
      line-height: 16px;
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .passengers_controls {
    margin-top: 30px;
  }

  .ferry_modal_button {
    background-color: @color_light_blue;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border: 1px solid @color_light_blue;
    transition: background 0.3s, box-shadow 0.3s;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    border-radius: 20px;
    padding: 11px 30px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    }
  }

  .modal_footer {
    border-top: 1px solid @color_light_blue;
    margin: 0 -30px -30px -30px;
    display: flex;
  }

  .modal_footer_left {
    width: 300px;
  }

  .payment_type_block {
    padding: 60px 30px;
    background-color: @color_light_blue;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .payment_type_text {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
    display: block;
    margin-bottom: 25px;
  }

  .payment_type_row {
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    label {
      display: flex;
    }

    .payment_type_icon {
      height: 43px;
      width: 50px;
      border: 1px solid @color_dark_blue;
      border-radius: 20px 0 0 20px;
      background-color: #ffffff;
      box-sizing: border-box;

      &:after {
        transition: none;
        margin: 0;
      }
    }

    .payment_type_title {
      width: 185px;
      padding: 2px 16px;
      color: @color_dark_blue;
      border: 1px solid @color_dark_blue;
      border-radius: 0 20px 20px 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      background-color: #ffffff;
      height: 43px;
      display: flex;
      align-items: center;
      text-align: left;
      top: 0;
    }

    input[type=radio] {
      &:checked {
        &+.icon {
          background-color: @color_dark_blue;
          &::after {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
            background-color: @color_dark_blue;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            background-size: 50%;
            background-position: 50%;
            transition: none;
          }
        }
      }
    }
  }

  .modal_footer_right {
    padding: 30px;
    text-align: left;
  }

  .modal_consent_checkbox {
    display: block;
    margin: 5px 0;
  }

  .total_field_cover {
    width: 280px;
    margin-right: 20px;
    padding-bottom: 15px;
    position: relative;
  }

  .modal_consent_block {
    .invalid_field {
      color: #ff0000;
    }
  }

  .filter_label {
    display: block;
    font-size: 12px;
    line-height: 14px;
    padding-left: 15px;
    cursor: pointer;
    color: @color_dark_blue;
  }

  .filter_field {
    border: 1px solid @color_light_blue;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    padding: 9px 10px 8px 14px;
    color: @color_dark_blue;
    cursor: pointer;
    width: 100%;
    height: 40px;
  }

  .total_row {
    margin: 20px 0;
  }

  .total_price {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: right;
    color: @color_dark_blue;
  }

  .select2-selection {
    width: 210px;
    padding: 10px 16px;
    color: @color_dark_blue;
    border: 1px solid @color_light_blue;
    border-radius: 0 20px 20px 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
    height: 100%;
    outline: none;
  }

  .select2-container--default {
    &.select2-container--focus {
      .select2-selection--multiple {
        border-color: @color_light_blue;
      }
    }
    .select2-selection--single {
      .select2-selection__rendered {
        color: @color_dark_blue;
        line-height: 18px;
        padding: 0 !important;
      }

      .select2-selection__arrow {
        height: 100%;
        b {
          border-color: @color_light_blue transparent transparent;
        }
      }
    }

    .select2-dropdown {
      border: none;
    }

    .select2-selection--multiple {
      .select2-selection__rendered {
        padding: 0 !important;
      }

      .select2-search {
        display: none;
      }

      .select2-selection__choice {
        background: #E0EDFF;
        border: 1px solid @color_dark_blue;
        box-sizing: border-box;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        font-size: 12px;
        line-height: 18px;
        color: @color_dark_blue;
        margin-top: 0;
        height: 20px;
      }

      .select2-selection__choice__remove {
        color: @color_dark_blue;
      }
    }
  }

  .transport_modal_form {
    display: flex;
    margin-bottom: 30px;

    .filter_field,
    .select2-selection {
      border-radius: 20px;
      height: 40px;
      width: 240px;
      border-color: @color_light_blue;
    }

    .filter_cover {
      width: 240px;
      margin: 0 20px 20px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.ferry_notification_modal {
    .notification_modal_form {
      display: flex;
      flex-wrap: wrap;

      .filter_cover {
        width: 49%;
      }

      .textarea_cover {
        width: 100%;
        margin: 5px 0 20px 0;

        textarea {
          width: 100%;
          resize: none;
          height: auto;
        }
      }
    }

    .notification_checkbox_cover {
      display: flex;
      align-items: center;
      margin: 0 0 0 10px;

      label {
        border: 1px solid #E0EDFF;
        padding: 9px 8px 8px 20px;
        width: 100%;
        align-self: flex-end;
        border-radius: 20px;
      }

      &.notification_checkbox_wide {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }

    .notification_modal_controls {
      padding: 26px 30px 20px 30px;
      text-align: left;
    }

    .modal_footer {
      flex-direction: column;
      text-align: left;
      padding-bottom: 20px;
    }

    .notification_table_title {
      padding: 0 30px;
    }

    .notification_modal_table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        text-align: left;
        padding: 10px 20px;
        font-size: 14px;
        line-height: 16px;

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }

      .button_column {
        text-align: center;
      }

      .table_head_row {
        background-color: @color_light_blue;
        color: #ffffff;
      }

      td {
        color: @color_dark_blue;
      }
    }

    .order_additional {
      top: auto;
      bottom: 90%;
      border-radius: 20px 20px 0 20px;
    }

    .notification_full_email {
      display: none;
      width: 300px;
    }

    .notification_show_email {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        & + .notification_full_email {
          display: block;
        }
      }
    }
  }
  input {
    &.invalid_field {
      border-color: #ff0000 !important;
    }
  }

  .filter_file_cover {
    width: 100%;
    margin-right: 0;
  }

  .filter_file_inner {
    display: flex;
    justify-content: space-between;
  }

  .register_file_input {
    opacity: 0;
    position: absolute;
    top: -10000px;
    left: 0;
    z-index: -1;
    clip: rect(0 0 0 0);
  }

  .register_file_name {
    padding: 10px 16px;
    border: 1px solid @color_light_blue;
    color: @color_dark_blue;
    border-radius: 20px;
    flex: 1 1 0;
    font-size: 14px;
  }

  .file_button_pseudo {
    padding: 10px;
    border: 1px solid @color_deep_blue;
    border-radius: 20px;
    cursor: pointer;
    width: 200px;
    margin-left: 20px;
    text-align: center;
    color: @color_deep_blue;

    &:hover {
      background-color: @color_deep_blue;
      color: #ffffff;
    }
  }

  .ferry_modal_controls {
    padding: 26px 30px 20px 30px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
  }

  &.small_ferry_modal {
    .select2-selection {
      width: auto;
      border-radius: 20px;
    }
  }

  &.reports_modal {
    .reports_modal_form {
      padding-bottom: 20px;
    }

    .reports_form_row {
      display: flex;
      width: 100%;
    }

    .filter_cover {
      width: 340px;
      margin: 0 20px 20px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  &.end_order_modal {
    .ips_cover {
      width: 340px;
      margin: 0 20px 20px 0;
    }

    .order_bill_number {
      margin-right: 20px;
    }
  }

  .ferry_modal_button_outline {
    background-color: transparent;
    color: @color_deep_blue;
    border-color: @color_deep_blue;
  }

  .forgot_password {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    margin-top: 20px;
    color: @color_dark_blue;
    cursor: pointer;

    &:hover {
      color: @color_light_blue;
    }
  }

  &.authorization_modal {
    .ferry_modal_button_outline {
      margin-left: 20px;
    }

    .filter_cover {
      margin-right: 0;
    }

    .double_row {
      .filter_cover {
        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .filter_select {
      width: 100%;
    }

    .select_cover {
      .select2-container {
        width: 100% !important;
      }
    }

    .filter_file_cover {
      width: 100%;
    }

    .modal_consent_checkbox {
      margin-bottom: 20px;

      .consent_text {
        font-weight: bold;
        color: @color_dark_blue;
      }
    }
  }

  &.ferry_refund_modal {
    max-width: 740px;

    .modal_content_wide {
      margin: 0 -30px
    }

    .modal_content_table {
      padding-bottom: 0;
    }

    .refund_file_fields {
      padding-bottom: 16px;
    }

    tbody {
      tr {
        border-bottom: 1px solid @color_grey;
      }
    }

    .filter_file_cover {
      margin-bottom: 10px;
    }

    .refund_disclaimer {
      font-size: 14px;
      line-height: 18px;
      color: @color_deep_blue;
    }
  }

  &.issue_ticket_modal {
    .issue_ticket_subtitle {
      font-size: 20px;
      line-height: 24px;
      color: @color_dark_blue;
      margin: 10px 0;
    }

    .issue_ticket_block {
      margin-bottom: 20px;
    }

    .filter_cover {
      margin-bottom: 10px;
      width: 340px;
    }
  }

  .table_button {
    span {
      vertical-align: middle;
    }
  }

  .red_button {
    color: @color_red;
    border-color: @color_red;
    background-color: @color_light_red;
  }

  .legend_horizontal {
    display: none;
  }

  &.change_trip_modal {
    overflow: visible;

    .select2-selection {
      width: 100%;
      border-radius: 20px;
    }

    .change_trip_content {
      .change_trip_line {
        color: @color_dark_blue;
      }
    }
  }

  &.ferry_no_car_modal {
    .no_car_textarea {
      height: 80px;
      resize: none;
    }

    .filter_cover {
      margin-bottom: 15px;
    }

    .validation_text {
      display: none;
      color: @color_red;
      padding-left: 15px;
      font-size: 12px;
    }

    .filter_field {
      &.input-validation-error {
        border-color: @color_red;
      }
    }

    .input-validation-error {
      & + .validation_text {
        display: block;
      }
    }
  }

  &.comment_modal {
    .comment_modal_order {
      font-size: 16px;
      font-weight: bold;
      color: @color_dark_blue;
    }

    .textarea_cover {
      width: 300px;

      .filter_field {
        height: auto;
        resize: none;
        cursor: auto;
      }
    }
  }

  &.change_price_modal {
    .modal-content {
      padding-bottom: 20px;
    }

    .row {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    .notification_modal_controls {
      padding: 26px 30px 20px;
      text-align: left;
    }
  }

  &.issue_ticket_modal {
    .additional_transport_filed {
      width: 87%;
    }

    .delete_transport {
      background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
      border: 1px solid @color_red;
      color: @color_red;
      border-radius: 50%;
      cursor: pointer;
      width: 40px;
      height: 40px;
      vertical-align: bottom;
      padding: 0;

      .material-icons {
        vertical-align: middle;
      }
    }

    .number_filter_input {
      width: 24px;
      text-align: center;
    }
  }
}

@media (max-width: 1000px) {
  .ferry_modal {
    padding: 20px;

    .transport_modal_form {
      flex-direction: column;

      .filter_cover {
        width: 100%;
        margin-right: 0;

        .filter_field {
          width: 100%;
        }
      }
    }

    .modal_footer {
      flex-direction: column;
    }

    .modal_footer_left {
      width: 100%;
    }

    .total_field_cover {
      width: 100%;
    }

    .passengers_item {
      flex-direction: column;
    }

    .passengers_data_row {
      height: auto;
      min-height: 40px;

      .passengers_data_title {
        width: 40%;
        padding: 10px;
        font-size: 12px;
        line-height: 16px;
      }

      .passenger_data_input {
        width: 60%;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .passengers_map {
      flex-direction: column;

      .passengers_map_image {
        padding: 10px 0;

        svg {
          width: 100% !important;
        }
      }
    }

    .seat_selector_map {
      padding: 10px 0;
    }

    .legend_vertical {
      display: none;
    }

    .legend_horizontal {
      display: block;
    }
  }
}

@media (max-width: 320px) {
  .arcticmodal-container_i2 {
    padding: 24px 0;
  }

  .ferry_modal {
    &.ferry_transport_modal,
    &.ferry_passenger_modal {
      max-width: 96vw;
      box-sizing: border-box;
    }
  }
}