.table_cover {
  width: 100%;
}
.sort_column {
  cursor: pointer;
}

.sorting_header {
  display: flex;
  align-items: center;
}

.sorting {
  .sort_element {
    width: 8px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin-left: 10px;

    &::after {
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }

    &[data-sort-direction="desc"] {
      &::after {
        content: '\2193';
      }
    }

    &[data-sort-direction="asc"] {
      &::after {
        content: '\2191';
      }
    }
  }
}

.inner_table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 15px;
    text-align: left;
  }

  .nested_table_cell {
    padding: 30px 15px;
  }

  .offer_column {
    text-align: center;
  }

  .price_column,
  .button_column {
    text-align: right;
  }

  .price_column {
    .sorting_header {
      justify-content: flex-end;
    }
  }

  .table_head_row {
    background: @color_light_blue;

    th {
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }
  }

  .reserved {
    color: #828282;
  }

  .material-icons {
    vertical-align: middle;
  }

  .offer_icon {
    color: @color_red;
  }

  .cost_small {
    color: #828282;
    text-decoration: line-through;
    font-size: 12px;
    line-height: 14px;
  }

  .empty_table_warning {
    text-align: center;
    padding: 60px 15px;

    span {
      font-size: 24px;
      line-height: 20px;
      font-weight: bold;
    }
  }

  .table_filter_cover {
    margin: 0;
  }

  .table_item_subtitle {
    display: none;
  }

  .transport_data_title {
    display: none;
  }
}

.button_column_controls {
  position: relative;
  width: 36px;
  height: 36px;
}

.button_column_cover {
  display: inline-flex;
  align-items: center;
}

.order_additional {
  display: none;
  position: absolute;
  background-color: @color_light_grey;
  padding: 20px;
  border-radius: 20px 0 20px 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  right: 90%;
  top: 90%;
  z-index: 1;
  width: 240px;
  box-sizing: border-box;
  text-align: left;
}

.order_additional_button {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: @color_dark_blue;
  padding: 0 0 15px 0;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    color: @color_light_blue;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.table_row_opener {
  cursor: pointer;
  border-bottom: 1px solid @color_grey;
  height: 66px;

  &:hover {
    background-color: #eae5e5;
  }
}

.table_row_openable {
  box-shadow: inset 0 2px 12px rgba(0, 0, 0, 0.25);
  display: none;
}

.nested_table_group {
  &.opened {
    .table_row_opener {
      .table_button {
        color: @color_deep_blue;
        border-color: @color_deep_blue;
      }
      td {
        color: @color_deep_blue;
      }
    }

    .table_row_openable {
      display: table-row;
    }
  }
}

.nested_table_cover {
  &:nth-child(n+2) {
    .nested_table_title {
      margin-top: 15px;
    }
  }
}

.nested_table_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: block;
  text-align: left;
  margin-bottom: 14px;
  color: @color_dark_blue;

  .nested_title_button {
    margin-left: 20px;
    border-color: @color_dark_blue;
    color: @color_dark_blue;
  }
}

.nested_table_title_text {
  vertical-align: middle;
  font-size: 18px;
  line-height: 25px;
}

.nested_table {
  background-color: #ffffff;

  .nested_table_head_row {
    background-color: @color_light_blue;
    border: 1px solid @color_light_blue;
  }

  th {
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    padding: 12px;
  }

  .nested_table_body_row {
    border: 1px solid @color_light_blue;

    td {
      font-size: 14px;
      line-height: 16px;
      padding: 12px;
    }
  }
}

.table_button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  color: @color_light_blue;
  background-color: #ffffff;
  border: 1px solid @color_light_blue;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  &:not(:first-child) {
    margin-left: 10px;
  }

  .material-icons {
    font-size: 21px;
  }

  &:active {
    box-shadow: none;
  }
}

.table_button_link {
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;

  .material-icons {
    margin-top: 6px;
  }
}

@media (max-width: 1000px) {
  .table_row_opener {
    height: auto;

    &:hover {
      background-color: transparent;
    }
  }
}