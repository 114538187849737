.page-content {
  .k-widget {
    border-width: 0;
  }

  .options_button {
    background-color: @color_light_blue !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    border-radius: 20px !important;
    font-size: 14px;
    line-height: 22px !important;
    text-align: center;
    color: #fff !important;
    border: none;
    padding: 8px !important;
    cursor: pointer;
    height: 40px;
    width: auto;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }

    &:active {
      box-shadow: none;
    }

    &.info_button {
      width: 24px;
      height: 24px;
      line-height: 24px !important;
      padding: 0 !important;
      border: 1px solid @color_light_blue !important;
      background-color: #ffffff !important;
      color: @color_light_blue !important;
      font-weight: bold;
      text-align: center;
    }
  }

  .add_button {
    .k-i-plus {
      &::before {
        content: "\e11e" !important;
        background: none;
      }
    }
  }

  .k-grid {
    tr,
    .k-alt {
      color: @color_dark_blue;
    }

    tr {
      td {
        border-top: none !important;
        border-bottom-color: @color_grey;
      }
    }
  }

  .k-grid-header {
    border-bottom: none;

    .k-header {
      background: @color_light_blue !important;
      border: none !important;
      color: #ffffff;

      a {
        color: #ffffff;
      }
    }
  }

  .tariffs_table {
    .k-grid-header {
      padding-right: 0 !important;
    }
    .k-grid-content {
      height: auto !important;
      overflow-y: auto;
    }
  }

  .k-grid-edit,
  .k-grid-delete,
  .k-grid-edit-custom,
  .k-grid-show-custom,
  .k-grid-info-custom {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    border-radius: 20px !important;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #fff !important;
    border: none;
    padding: 8px !important;
    cursor: pointer;
    height: 36px;
    width: 36px;
  }

  .k-grid-edit,
  .k-grid-edit-custom,
  .k-grid-show-custom,
  .k-grid-info-custom {
    background: #ffffff !important;
    border: 1px solid @color_light_blue;

    .k-icon {
      &::before {
        background: none;
        font-size: 20px;
        line-height: 22px;
        color: @color_light_blue;
      }
    }
  }

  .k-grid-edit {
    .k-icon {
      &::before {
        content: '\e10b' !important;
      }
    }
  }

  .k-event {
    background-color: @color_light_blue;
  }

  .k-scheduler-toolbar {
    .k-nav-today,
    .k-nav-prev,
    .k-nav-next,
    .k-nav-current,
    .k-state-selected {
      background-color: @color_light_blue !important;
      color: #ffffff !important;
      height: 34px !important;
      box-sizing: border-box;

      &:hover,
      &:active {
        z-index: initial;
      }
    }

    .k-nav-today {
      border-radius: 20px 0 0 20px !important;
    }

    .k-nav-prev {
      border-radius: 0 !important;
      border-left: 1px solid #ffffff !important
    }

    .k-nav-next {
      border-radius: 0 20px 20px 0 !important;
      border-left: 1px solid #ffffff !important
    }

    .k-nav-current {
      padding: 7px 10px;
      border-radius: 20px;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    .k-state-selected {
      padding: 5px 10px !important;
      border-radius: 20px !important;
    }
  }
}

.k-grid-update,
.k-grid-cancel {
  background: @color_light_blue !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
  border-radius: 20px !important;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff !important;
  border: none;
  padding: 8px !important;
  cursor: pointer;
  height: 36px;
  width: auto;
}

.offices_edit_form {
  display: flex;
  flex-direction: column;
}

.edit_form_inputs {
  min-height: 350px;
}

.k-pager-numbers-wrap {
  .k-dropdown {
    width: auto;
    margin-left: 20px;
    cursor: pointer;
  }
}

.log_info_controls {
  padding: 30px 0;
}

.log_info_button {
  background-color: @color_light_blue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border: 1px solid @color_light_blue;
  transition: background 0.3s, box-shadow 0.3s;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  border-radius: 20px;
  padding: 11px 30px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }
}

//Custom Icon Buttons
.k-grid-edit-custom,
.k-grid-show-custom,
.k-grid-info-custom {
  .k-icon {
    width: 30px!important;
    margin: 0!important;
    top: 3px;
    height: 30px!important;

    &::before {
      background: 0 0;
      width: 24px;
      height: 24px;
      display: inline-block;
      font-size: 20px;
      line-height: 22px;
      color: #00a0e8;
    }
  }
}

.k-grid-edit-custom {
  .k-icon {
    &::before {
      content: '\e10b';
    }
  }
}

.k-grid-show-custom {
  .k-icon {
    &::before {
      content: '';
      background: url('/img/show.svg') !important;
    }
  }
}

.k-grid-info-custom {
  .k-icon {
    &::before {
      content: '';
      background: url('/img/info.svg') !important;
    }
  }
}

.options_filters {
  padding: 15px 30px;
  display: flex;

  .tab_selector {
    margin: 0 auto 0 0;
  }
}