.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  /*padding-left: 8px;*/
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
  vertical-align: middle;
  top: -1px;
  margin-right: 7px;
  cursor: pointer;
  color: #3f51b5;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}
.select2-results {
  display: block;
  max-height: 300px;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
/*.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}*/
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 190px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 190px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}
.cards_cover {
  padding: 40px 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cards_cover .mdl-card {
  width: 49%;
  margin-bottom: 25px;
}
.cards_cover .mdl-card .green {
  color: #30e988;
}
.cards_cover .mdl-card .mdl-card__title {
  padding-right: 126px;
}
.cards_cover .mdl-card .mdl-card__actions {
  text-align: right;
}
.cards_cover .mdl-card p {
  margin-bottom: 0;
}
#app_gas_station_map {
  height: 100vh !important;
}
#app_gas_station_map .info_col_cover {
  height: 100vh;
}
#app_gas_station_map .info_col {
  padding-bottom: 0;
}
#app_gas_station_map .info_col .info_block {
  padding: 20px 16px;
  padding-left: 32px;
}
#app_gas_station_map .info_col .info_block .title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  display: block;
}
#app_gas_station_map .info_col .info_block .radio_cover,
#app_gas_station_map .info_col .info_block .checkbox_cover {
  margin-bottom: 10px;
  display: block;
}
#app_gas_station_map .mobile_filter_icon,
#app_gas_station_map .mobile_filter_icon_close,
#app_gas_station_map .mobile_ok_filter {
  display: none;
}
#app_gas_station_map .color_indificator {
  margin-left: 5px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}
#gasStationModal .gasStationInfo > div {
  margin-bottom: 10px;
}
#gasStationModal .gasStationInfo > div b {
  margin-right: 5px;
}
#gasStationModal .modal_footer input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: inline-block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  background: 0 0;
  text-align: left;
  color: inherit;
}
#gasStationModal .modal_footer input:focus {
  outline: none;
}
#gasStationModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect {
  display: none;
}
#gasStationModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p {
  display: none;
}
@media screen and (max-width: 1000px) {
  #gasStationModal,
  #noGasStationsModal,
  #locationDisabledModal {
    width: 85%;
    padding: 25px;
    padding-top: 35px;
    margin: 0 auto;
  }
  #gasStationModal .modal_footer > div,
  #noGasStationsModal .modal_footer > div,
  #locationDisabledModal .modal_footer > div {
    display: flex;
  }
  #gasStationModal .modal_footer #coordinateInput,
  #noGasStationsModal .modal_footer #coordinateInput,
  #locationDisabledModal .modal_footer #coordinateInput {
    width: 65%;
  }
  #gasStationModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect,
  #noGasStationsModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect,
  #locationDisabledModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect {
    display: block;
  }
  #gasStationModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p,
  #noGasStationsModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p,
  #locationDisabledModal .modal_footer .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p {
    display: block;
  }
  #app_gas_station_map .mobile_filter {
    transition: right 400ms;
  }
  #app_gas_station_map .info_col_cover {
    position: absolute;
    width: 100%;
    right: 0;
  }
  #app_gas_station_map .info_col_cover .info_col {
    right: 0;
    width: 100%;
    padding-bottom: 50px;
  }
  #app_gas_station_map .mobile_filter_icon,
  #app_gas_station_map .mobile_filter_icon_close {
    display: block;
    cursor: pointer;
  }
  #app_gas_station_map .mobile_filter_icon_close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    background: transparent;
  }
  #app_gas_station_map .mobile_filter_icon {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 9px;
    background: #3f51b5;
    border-radius: 5px;
    box-shadow: 0 0 15px -1px #cccccc;
    line-height: 0;
  }
  #app_gas_station_map .mobile_filter_icon i {
    color: #fff;
  }
  #app_gas_station_map .content_cols {
    justify-content: flex-start !important;
  }
  #app_gas_station_map .mobile_ok_filter {
    display: inline-block;
    margin-left: 20px;
  }
}
.arcticmodal-overlay,
.arcticmodal-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.arcticmodal-container {
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse;
}
*:first-child + html .arcticmodal-container {
  height: 100%;
}
.arcticmodal-container_i {
  height: 100%;
  margin: 0 auto;
}
.arcticmodal-container_i2 {
  padding: 24px;
  margin: 0;
  border: 0;
  vertical-align: middle;
}
.arcticmodal-error {
  padding: 20px;
  border-radius: 10px;
  background: #000;
  color: #fff;
}
.arcticmodal-loading {
  width: 200px;
  height: 200px;
  border-radius: 5px;
  background: url('/Content/images/loading.svg') no-repeat 50% 50%, rgba(255, 255, 255, 0.85);
}
.box-modal {
  background-color: #fff;
  padding: 35px;
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
  position: relative;
  /*transform: scale(0.1);*/
  transition: all 300ms;
  width: 500px;
  font-size: 16px;
  border-radius: 8px;
}
.box-modal.file_loaded_modal {
  width: 600px;
}
.box-modal.file_loaded_modal p {
  font-weight: bold;
  margin-bottom: 0;
}
.box-modal.file_loaded_modal p.subtitle {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.box-modal.file_loaded_modal p span {
  font-weight: normal;
  font-size: 18px;
}
.box-modal.file_loaded_modal .cols_cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.box-modal.file_loaded_modal .cols_cover .col {
  min-width: 49%;
  max-width: 49%;
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.box-modal.file_loaded_modal .cols_cover > div:nth-of-type(2) {
  padding-left: 2%;
}
.box-modal.file_loaded_modal .badRowsCover {
  margin-bottom: 10px;
  margin-top: 18px;
}
.box-modal.file_loaded_modal .badRowsCover p {
  margin-bottom: 0;
}
.box-modal.file_loaded_modal .badRowsCover i {
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #dc424f;
  font-style: normal;
  color: #fff;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 10px;
  font-size: 13px;
}
.box-modal.file_loaded_modal .badRowsCover i:last-of-type {
  margin-left: 0;
}
.box-modal.appoint_modal {
  width: 80vw;
}
.box-modal.appoint_modal .modal-content .table-cover {
  margin-bottom: 20px;
}
.box-modal.appoint_modal .modal-content > div {
  margin-bottom: 10px;
}
.box-modal.appoint_modal .table-cover {
  overflow: auto;
  margin-bottom: 0px;
  margin-top: 20px;
}
.box-modal.appoint_modal .table-cover table {
  width: 100%;
  overflow: hidden;
}
.box-modal.appoint_modal .table-cover .mdl-textfield {
  width: 110px;
  font-size: 14px;
  padding: 10px 0;
}
.box-modal.appoint_modal .table-cover .mdl-textfield.phone {
  width: 130px;
}
.box-modal.appoint_modal .table-cover .mdl-textfield .mdl-textfield__error {
  left: -10px;
  top: 100%;
  width: 100%;
  white-space: normal;
  line-height: 14px;
  background: #fff;
  padding: 8px 10px;
  z-index: 2;
  border-radius: 3px;
  margin-top: -10px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  font-size: 10px;
}
.box-modal.appoint_modal .table-cover .mdl-textfield input {
  font-size: 14px;
}
.box-modal.appoint_modal .table-cover .mdl-textfield .mdl-textfield__label {
  font-size: 14px;
  top: 16px;
}
.box-modal.appoint_modal .table-cover .mdl-textfield .mdl-textfield__label:after {
  bottom: 10px;
}
.box-modal.add_gas_station .map_cover {
  height: 20vh;
  min-height: 490px;
  background: #ccc;
}
.box-modal.add_gas_station .gas_station_info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 30px;
}
.box-modal.add_gas_station .mdl-textfield {
  width: 100%;
  margin-top: -17px;
}
.box-modal.add_gas_station .mdl-textfield .select2-container {
  width: 100% !important;
}
.box-modal.add_gas_station .mdl-textfield.half {
  width: 49%;
}
.box-modal.select_template {
  width: 620px;
}
.box-modal.select_template .table_scroll_cover {
  overflow: auto;
  margin: 0 -35px;
}
.box-modal.select_template .template_head {
  display: flex;
}
.box-modal.select_template .template_head .mdl-textfield {
  margin-right: 10px;
}
.box-modal.select_template .template_head .mdl-textfield:last-of-type {
  margin-right: 0;
}
.box-modal.select_template .table-cover .mdl-data-table td,
.box-modal.select_template .table-cover .mdl-data-table th {
  padding: 2px 10px;
}
.box-modal.select_template .table-cover .mdl-data-table td:first-of-type,
.box-modal.select_template .table-cover .mdl-data-table th:first-of-type {
  padding-left: 20px;
}
.box-modal.select_template .table-cover .mdl-button {
  min-width: 36px;
  padding: 0 6px;
}
.box-modal.save_template {
  width: 390px;
}
.box-modal.save_template .mdl-textfield {
  width: 100%;
}
.box-modal.save_template .content_half {
  width: 49%;
  display: inline-block;
}
.box-modal.economy_modal {
  width: 800px;
}
.box-modal.economy_modal .economy_table_cover {
  margin: 0 -35px;
  margin-top: -26px;
  width: auto;
}
.box-modal.economy_modal .table_levels {
  width: 100%;
}
.box-modal.economy_modal .table_levels th:first-of-type {
  padding-left: 10px;
}
.box-modal.economy_modal .table_levels tr[data-level] .nowrap {
  white-space: nowrap;
}
.box-modal.economy_modal .table_levels tr[data-level] td:first-of-type {
  font-size: 16px;
  font-weight: 600;
  padding: 5px 0;
  padding-left: 35px;
}
.box-modal.economy_modal .table_levels tr[data-level] td:first-of-type span {
  cursor: pointer;
  position: relative;
  border-bottom: 1px dashed #b5b5b5;
}
.box-modal.economy_modal .table_levels tr[data-level] td:first-of-type span:before {
  content: '';
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 5px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.box-modal.economy_modal .table_levels tr[data-level] td:first-of-type span i {
  position: absolute;
  left: -8px;
  bottom: -1px;
  height: 47px;
  width: 10px;
  border-left: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  pointer-events: none;
}
.box-modal.economy_modal .table_levels tr[data-level] td:last-of-type {
  padding-right: 35px;
}
.box-modal.economy_modal .table_levels tr[data-level] .checkbox_cover label .icon {
  margin-right: 0;
}
.box-modal.economy_modal .table_levels tr[data-level] td {
  height: 48px;
}
.box-modal.economy_modal .table_levels tr[data-level] td .price {
  font-size: 12px;
  color: #ccc;
  position: relative;
  top: -2px;
}
.box-modal.economy_modal .table_levels tr[data-level] td .mdl-textfield {
  padding: 0;
}
.box-modal.economy_modal .table_levels tr[data-level="0"] td:first-of-type {
  font-weight: 600;
}
.box-modal.economy_modal .table_levels tr[data-level="0"] td:first-of-type .checkbox_cover {
  margin-right: 6px;
}
.box-modal.economy_modal .table_levels tr[data-level="0"] td:first-of-type span i {
  content: '';
  position: absolute;
  left: -4px;
  bottom: -1px;
  height: 47px;
  width: 7px;
  border-left: none;
  border-bottom: 1px dashed #757575;
}
.box-modal.economy_modal .table_levels tr[data-level="1"] td:first-of-type {
  font-weight: 500;
}
.box-modal.economy_modal .table_levels tr[data-level="1"] td:first-of-type .checkbox_cover {
  margin-right: 17px;
}
.box-modal.economy_modal .table_levels tr[data-level="2"] td:first-of-type {
  font-size: 14px;
  font-weight: 400;
}
.box-modal.economy_modal .table_levels tr[data-level="2"] td:first-of-type .checkbox_cover {
  margin-right: 30px;
}
.box-modal.economy_modal .table_levels tr[data-level="3"] td:first-of-type {
  font-size: 12px;
  color: #3F51B5;
  font-weight: 700;
  /*max-width: 300px;*/
}
.box-modal.economy_modal .table_levels tr[data-level="3"] td:first-of-type span {
  padding-left: 0px;
}
.box-modal.economy_modal .table_levels tr[data-level="3"] td:first-of-type span:before {
  display: none;
}
.box-modal.economy_modal .table_levels tr[data-level="3"] td:first-of-type .checkbox_cover {
  margin-right: 42px;
}
.box-modal.economy_modal .table_levels_small {
  border: none;
  /*border-top: 1px solid #E4E4E4;*/
  border-bottom: 1px solid #E4E4E4;
  box-shadow: none;
  white-space: normal;
}
.box-modal.economy_modal .table_levels_small th:first-of-type,
.box-modal.economy_modal .table_levels_small td:first-of-type {
  padding-left: 35px;
}
.box-modal.economy_modal .table_levels_small th:last-of-type,
.box-modal.economy_modal .table_levels_small td:last-of-type {
  padding-right: 35px;
}
.box-modal.economy_modal .table_levels_small span {
  border-bottom: none;
}
.box-modal.economy_modal .table_levels_small span i {
  display: none;
}
.box-modal.economy_modal .table_levels_small_summary {
  padding-top: 24px;
  font-size: 16px;
}
.box-modal.economy_modal .table_levels_small_summary .summary_title {
  font-weight: 500;
  margin-bottom: 0;
}
.box-modal.economy_modal .table_levels_small_summary > div {
  display: flex;
  justify-content: space-between;
}
.box-modal.economy_modal .table_levels_small_summary > div:first-of-type {
  margin-bottom: 15px;
}
.box-modal.economy_modal .table_levels_small_summary > div:last-of-type {
  justify-content: flex-end;
}
.box-modal.economy_modal .economy_footer_row {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 5px;
}
.box-modal.economy_modal .economy_footer_row p {
  font-weight: bold;
  margin-bottom: 0;
}
.box-modal.economy_modal .economy_footer_row span {
  position: relative;
  bottom: -3px;
}
.box-modal.economy_modal .economy_footer_row .green {
  color: #3AB027;
}
.box-modal.economy_modal .modal_footer .mdl-button {
  margin-top: 15px;
}
.box-modal.economy_modal .mdl-data-table th {
  padding: 6px 18px;
  line-height: 14px;
}
.box-modal.driver_message .label_title {
  font-weight: bold;
  color: #000000;
}
.box-modal.driver_message .radio_cover {
  margin-bottom: 15px;
}
.box-modal.driver_message .textarea {
  min-height: 40px;
  border-bottom: 1px solid #ccc;
  outline: transparent;
}
.box-modal .modal_footer {
  text-align: right;
  margin-top: 20px;
}
.box-modal .modal_title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 25px;
}
.box-modal .modal_title .material-icons {
  vertical-align: bottom;
}
.box-modal .mdl-layout-spacer {
  flex-grow: 1;
}
.box-modal .gavel_modal_cover {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.box-modal .gavel_modal_cover > div {
  width: 165px;
  line-height: 21px;
  font-size: 14px;
  text-align: center;
  padding: 8px 12px;
  padding-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.box-modal .gavel_modal_cover > div .mdl-button {
  width: 100%;
}
.box-modal .gavel_modal_cover > div span {
  display: block;
  margin-bottom: 7px;
}
.box-modal .gavel_modal_cover b {
  display: inline-block;
  font-size: 16px;
}
.box-modal .gavel_modal_cover .mdl-textfield {
  width: 150px;
  padding: 0;
  margin-top: 5px;
  margin-bottom: 11px;
}
.box-modal .gavel_modal_cover .mdl-textfield .mdl-textfield__label {
  top: 6px;
}
.box-modal .gavel_modal_cover .mdl-textfield .mdl-textfield__label:after {
  bottom: 0px !important;
}
.box-modal .gavel_modal_cover .mdl-button {
  margin-top: 10px;
}
.box-modal .bids_cover {
  position: absolute;
  left: 100%;
  width: 240px;
  margin-left: 15px;
  top: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
}
.box-modal .bids_cover .title {
  padding: 12px 20px;
  background: #fff;
  border-radius: 8px 8px 0 0;
}
.box-modal .bids_cover .list_cover {
  border-radius: 0 0 8px 8px;
  background: #fff;
  padding: 12px 20px;
  padding-top: 0px;
  overflow: auto;
  max-height: calc(100% - 56px);
}
.box-modal .bids_cover ul {
  margin-top: 0;
  padding-left: 16px;
  margin-bottom: 0;
}
.box-modal.req_modal {
  width: 600px;
}
.box-modal.req_modal .req_modal_top {
  display: flex;
  align-items: center;
}
.box-modal.req_modal .req_modal_top .material-icons {
  margin-right: 5px;
}
.box-modal.req_modal .req_modal_top .mdl-textfield {
  width: 150px;
  padding: 0;
  margin: 0 10px;
  margin-left: 15px;
}
.box-modal.req_modal .req_modal_top .mdl-textfield .mdl-textfield__label {
  top: 6px;
}
.box-modal.req_modal .req_modal_top .mdl-textfield .mdl-textfield__label:after {
  bottom: 0px !important;
}
.box-modal.req_modal .modal-content > div {
  margin-bottom: 20px;
}
.box-modal.simple_submit_modal {
  width: 480px;
}
.box-modal.simple_submit_modal .modal_footer {
  text-align: right;
}
.box-modal.simple_submit_modal .modal_footer button + button {
  margin-left: 10px;
}
.box-modal.generic_modal .mdl-textfield {
  width: 100%;
  /*.mdl-textfield__label{
        display: none;
      }*/
}
.box-modal.generic_modal .mdl-textfield input.text-box,
.box-modal.generic_modal .mdl-textfield textarea.text-box {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: 0 0;
  text-align: left;
  color: inherit;
  border-radius: 0;
  line-height: initial;
}
.box-modal.generic_modal .mdl-textfield input.text-box:focus,
.box-modal.generic_modal .mdl-textfield textarea.text-box:focus {
  outline: 0;
  border-bottom: 1px solid #3f51b5;
}
.box-modal.generic_modal .mdl-textfield select {
  width: 100%;
}
.box-modal.generic_modal .mdl-textfield input {
  box-sizing: border-box;
}
.edit_truck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.edit_truck .content_half {
  width: 48%;
}
.edit_truck .mdl-textfield-select {
  margin-top: -14px;
}
.edit_truck .select2-results {
  max-height: 300px;
}
.choice_modal {
  width: 800px;
}
.choice_modal .dispatcher_form_cover {
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  align-items: flex-end;
}
.choice_modal .dispatcher_form_cover .dispatcher_input_cover {
  width: 220px;
}
.choice_modal .dispatcher_form_cover .dispatcher_input_cover:first-child {
  margin-right: 30px;
}
.choice_modal .dispatcher_form_cover .dispatcher_select_cover {
  width: 160px;
  margin-right: 30px;
}
.choice_modal .dispatcher_form_cover .dispatcher_select_cover span.select2 {
  width: 160px !important;
}
.choice_modal .choice_modal_table_cover {
  margin: 0 -35px;
}
.choice_modal .choice_modal_table_cover .mdl-data-table {
  border-left: none;
  border-right: none;
  white-space: normal;
}
.choice_modal .choice_modal_table_cover .mdl-data-table tr {
  height: 35px;
}
.choice_modal .choice_modal_table_cover .mdl-data-table tr:hover {
  background-color: transparent;
}
.choice_modal .choice_modal_table_cover .mdl-data-table tr:first-of-type td {
  padding-top: 10px;
}
.choice_modal .choice_modal_table_cover .mdl-data-table tr:last-of-type td {
  padding-bottom: 10px;
}
.choice_modal .choice_modal_table_cover .mdl-data-table th {
  padding: 0 18px 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.choice_modal .choice_modal_table_cover .mdl-data-table td {
  border: none;
  height: 35px;
}
.choice_modal .choice_modal_table_cover .mdl-data-table td:first-of-type {
  padding-left: 15px;
  text-align: center;
}
.action_modal .suspend_problem {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.action_modal .suspend_problem .mdl-radio {
  white-space: nowrap;
}
.action_modal .suspend_problem .dispatcher_select_cover {
  width: 35%;
  margin-left: auto;
  padding: 0;
  opacity: 0;
  pointer-events: none;
}
.action_modal .suspend_problem .dispatcher_select_cover .mdl-textfield__label:after {
  bottom: 0;
}
.action_modal .suspend_problem .mdl-radio.is-checked + .dispatcher_select_cover {
  opacity: 1;
  pointer-events: initial;
}
.edit_map_modal.add_route_point .dispatcher_input_cover {
  vertical-align: initial;
}
.route_point_modal .map_container {
  height: 350px;
}
.route_point_modal .inputs_cover {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.route_point_modal .content_half {
  width: 49%;
  display: inline-block;
}
.route_point_modal .mdl-textfield__input {
  padding: 15px 0 7px 0;
}
.open_modal .box-modal {
  transform: scale(1);
  transition: all 300ms;
}
.arcticmodal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 2;
}
/* Стили для панели сверху */
.catalog-page .admin_head_cover {
  position: fixed;
  width: 100%;
  z-index: 1010;
}
.admin_head_cover {
  text-align: center;
  background: #e6e6e6;
  z-index: 20;
  position: relative;
}
.admin_head {
  display: inline-block;
  text-align: center;
  padding: 22px 0px 23px;
  font-size: 14px;
}
.admin_head a {
  color: #2461b5;
  text-decoration: underline;
  margin-left: 20px;
}
.admin_head a:hover {
  color: #48c9b0;
  transition: all 0.2s;
}
.admin_head #tech_support {
  color: #2461b5;
  cursor: pointer;
  border-bottom: 1px dashed #2461b5;
}
.admin_head .btn {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  background: #3f51b5;
  color: #ffffff;
}
/* Авторизация */
.main-wrap-authorization {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
  display: table;
  font-family: 'Nunito', sans-serif;
}
.main-wrap-authorization input,
.main-wrap-authorization textarea {
  font-family: 'Nunito', sans-serif;
}
.main-wrap-authorization .logo_login {
  margin-bottom: 45px;
}
.main-wrap-authorization .authorization {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.main-wrap-authorization .authorization_In_form {
  padding: 40px 50px;
  width: 420px;
  box-sizing: border-box;
  text-align: center;
  background: #F6FAFF;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.main-wrap-authorization .authorization_In_form input {
  outline: none !important;
}
.main-wrap-authorization .authorization_In_form input.input-validation-error {
  border-color: red;
}
.main-wrap-authorization .authorization_In_form .bottom-part {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.main-wrap-authorization .authorization_In_form .bottom-part .btn {
  margin: 0 10px;
}
.main-wrap-authorization .authorization_In_form .bottom-part a {
  font-size: 13px;
  line-height: 20px;
  color: #272a29;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
}
.main-wrap-authorization .authorization_In_form fieldset {
  border: none;
}
.main-wrap-authorization .authorization_In_form h2 {
  margin: 10px 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #272A29;
}
.main-wrap-authorization .authorization_In_form form > p {
  font-size: 14px;
  line-height: 16px;
}
.main-wrap-authorization .authorization_In_form .btn {
  background-color: #00A0E8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  padding: 8px;
  cursor: pointer;
  height: 40px;
  width: 134px;
  transition: box-shadow 0.3s;
}
.main-wrap-authorization .authorization_In_form .btn:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}
.main-wrap-authorization .authorization_In_form .btn:active {
  box-shadow: none;
}
.login_seo {
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #272A29;
}
.login_seo a {
  font-weight: bold;
  color: #272A29;
  text-decoration: none;
}
.authorization-container {
  display: table;
  position: relative;
  margin: 0 auto;
  text-align: left;
}
.authorization-container .logo {
  text-align: center;
  padding-bottom: 30px;
  position: absolute;
  top: -58px;
  height: 30px;
  width: 100%;
}
.authorization-container .logo svg {
  width: 223px;
}
.authorization-container .authorization_bottom {
  text-align: center;
  padding-top: 20px;
  line-height: 30px;
  color: #fff;
  position: absolute;
  bottom: -80px;
  width: 100%;
}
.authorization-container .authorization_bottom a {
  color: #fff;
  margin-left: 5px;
  text-decoration: none;
}
.authorization-container h3 {
  padding-bottom: 0.3em;
  padding-top: 0;
}
.authorization-container .remember-me {
  padding-bottom: 15px;
  padding-top: 20px;
  color: #606466;
}
.authorization-container .remember-me label {
  cursor: pointer;
}
.authorization-container .remember-me .jq-checkbox {
  background: #ffffff;
  border: 1px solid #cccccc;
}
.authorization-container .remember-me span {
  position: relative;
  top: -1px;
  left: 5px;
  color: #606466;
}
.authorization-container table {
  text-align: left;
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 15px;
  width: 100%;
}
.authorization-container table td {
  padding-bottom: 3px;
}
.authorization-container table td:first-child {
  padding-right: 5px;
}
.authorization-container .btn {
  background: 0 0;
  border: none;
  border-radius: 2px;
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Nunito", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
  background: #3f51b5;
  color: #ffffff;
}
.authorization-container .sad_man_authorization {
  font-size: 14px;
  color: #3f51b5;
  position: relative;
  width: auto;
  line-height: 20px;
}
.authorization-container .sad_man_authorization a {
  color: #3f51b5;
  text-decoration: none;
}
.drzn_logo_authorization {
  position: absolute;
  left: 50%;
  margin-left: -68px;
  top: -80px;
}
/* Контент зона */
.contentZoneBorder {
  border: 1px dashed #212121;
}
/* Карандаш */
.icon_pencil {
  background: url("/Content/img/admins_iconAnd_bg/icon_pencil.png") no-repeat;
  display: inline-block;
  height: 14px;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 14px;
}
.pencil-url {
  border: 1px dotted black !important;
  height: 20px;
  width: 20px;
  margin: 3px 10px !important;
  position: relative;
  display: inline-block;
}
/* 404 ошибка */
.fourZeroFour {
  text-align: center;
}
.authorized_form_modal {
  border: none;
  border-radius: 0 !important;
  padding: 10px;
}
.authorized_form_modal h3 {
  text-align: center;
  padding-top: 0px;
}
.authorized_form_modal input {
  margin-bottom: 15px;
  width: 100%;
}
.authorized_form_modal .btn {
  width: 100%;
  margin-bottom: 20px;
}
.authorized_form_modal .modal-container {
  padding: 0 20px;
  padding-bottom: 20px;
  width: 360px;
}
.authorized_form_modal .authorize-block_footer a:nth-of-type(2) {
  float: right;
}
.authorized_form_modal .prompt {
  padding-bottom: 10px;
  color: #e31e24;
}
.admin-edit-tools-block {
  margin-bottom: 5px;
}
.admin-edit-tools-block .admin-edit-tools-item-container:before {
  content: '';
  height: 0;
  display: block;
  clear: both;
}
.admin-edit-tools-block .admin-edit-tools-item-container:after {
  content: '';
  height: 0;
  display: block;
  clear: both;
}
.admin-edit-tools-block .admin-edit-tools-item {
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
  border: 1px dashed #2e2e2e;
  float: left;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: #2e2e2e;
  text-align: center;
  transition: all 0.2s;
}
.admin-edit-tools-block .admin-edit-tools-item:hover {
  background-color: #2e2e2e;
  color: #fff;
}
.admin-edit-tools-block .admin-edit-tools-item:first-child {
  margin-left: 0px;
}
.admin-edit-tools-block .admin-edit-tools-item.is-loading {
  cursor: default;
  background-color: #2e2e2e;
  color: #fff;
}
.admin-edit-tools-block .admin-edit-tools-item.admin-edit-tools-item-publish.to-publish .fa-eye {
  display: none;
}
.admin-edit-tools-block .admin-edit-tools-item.admin-edit-tools-item-publish.to-publish .fa-eye-slash {
  display: inline-block;
}
.admin-edit-tools-block .admin-edit-tools-item.admin-edit-tools-item-publish.not-to-publish .fa-eye {
  display: inline-block;
}
.admin-edit-tools-block .admin-edit-tools-item.admin-edit-tools-item-publish.not-to-publish .fa-eye-slash {
  display: none;
}
.inputer_cover {
  position: relative;
  border: none;
  padding: 0;
  margin: 5px 0;
}
.inputer_cover input {
  border: 1px solid #00a0e8;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 10px 8px 14px;
  color: #1855a3 !important;
  cursor: pointer;
  width: 100%;
  height: 40px;
}
.inputer_cover .field-validation-error {
  white-space: nowrap;
  position: absolute;
  padding: 8px 10px;
  border-radius: 3px;
  visibility: visible;
  opacity: 1;
  transition: all 300ms;
  background: #fff;
  top: 15px;
  left: 100%;
  filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2));
  -webkit-filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.2));
}
.inputer_cover .field-validation-error:before {
  content: "";
  position: absolute;
  left: -6px;
  top: 50%;
  margin-top: -10px;
  border-right: 10px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.inputer_cover .field-validation-valid {
  position: absolute;
  height: 12px;
  width: 12px;
  bottom: 14px;
  right: 6px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 300ms;
}
.inputer_cover .field-validation-valid > span {
  visibility: visible;
  opacity: 0;
}
.inputer_cover label {
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  cursor: pointer;
  color: #1855A3;
  text-align: left;
}
.error_compare_pass .field-validation-error {
  margin: 0;
  padding-left: 18px;
}
.edit_field_cover {
  overflow: hidden;
  padding-top: 15px;
  margin: 0 -1%;
}
.edit_field_cover > div {
  vertical-align: top;
  margin: 0 1%;
  margin-bottom: 2%;
}
.edit_field_cover > div label {
  width: 10%;
  font-weight: normal !important;
  color: #747b7e;
}
.edit_field_cover > div input.single-line {
  width: 89.7%;
}
.edit_field_cover > div .field-validation-valid {
  display: block;
}
.photo_downloading_block {
  margin-top: 40px;
  margin-bottom: 45px;
}
.photo_downloading_block .photo_downloading_block_desc {
  display: inline-block;
  color: #a7a7a7;
  vertical-align: middle;
}
.fileupload-progress {
  margin-top: 20px;
}
input.text-box,
textarea.text-box {
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.462;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  resize: none;
  width: 100%;
}
.btn_save_button {
  display: inline-block;
  background: transparent;
  margin-left: 20px;
}
.btn_save_button:hover {
  background: transparent;
}
.btn_watch_on_site {
  display: inline-block;
  background: transparent;
  margin-left: 27px;
}
.btn_watch_on_site:hover {
  background: transparent;
}
.btn_come_back {
  display: inline-block;
  float: left;
  margin-top: 18px;
  margin-right: 13px;
}
.central_page {
  position: relative;
  margin: 0 auto;
  padding: 0 50px;
}
#tech_support_modal {
  padding: 30px 40px;
  width: 570px;
}
#tech_support_modal label {
  display: inline-block;
  margin-top: 15px;
  color: #939a9d;
  font-weight: normal;
  margin-bottom: 5px;
}
#tech_support_modal h2,
#tech_support_modal .h2 {
  font-size: 30px;
  padding-top: 0;
  padding-bottom: 10px;
}
#tech_support_modal input,
#tech_support_modal textarea {
  width: 100%;
  padding: 6px 10px;
  font-size: 18px;
  line-height: 1.462;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  resize: none;
}
#tech_support_modal .btn {
  display: inline-block;
  background: #426ea0;
  margin-right: 8px;
  font-size: 18px;
  padding: 12px 20px;
  padding-bottom: 10px;
  color: #FFFFFF;
  cursor: pointer;
  position: relative;
}
#tech_support_modal .modal-footer {
  padding-top: 20px;
}
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS:
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited).
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar.
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars.
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars.
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: none;
  touch-action: none;
  /* MSPointer events - direct all pointer events to js */
}
.mCustomScrollbar.mCS_no_scrollbar {
  -ms-touch-action: auto;
  touch-action: auto;
}
.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}
.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto;
}
/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}
.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
}
/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}
/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
}
.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}
/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0;
}
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}
/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}
.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}
.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center;
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */
}
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0;
}
/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px;
}
.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%;
}
.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}
/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
}
.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px;
}
/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto;
}
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0;
}
/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}
.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
}
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px;
}
.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px;
}
/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0;
}
/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0;
}
/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px;
}
/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0;
}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px;
}
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0;
}
/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0;
}
/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s, margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s, opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/
/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)";
}
.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}
.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)";
}
.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)";
}
.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /*
  sprites locations
  light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
  dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
  */
}
.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /*
  sprites locations
  light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
  dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
  */
}
.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /*
  sprites locations
  light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
  dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
  */
}
.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /*
  sprites locations
  light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
  dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
  */
}
.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)";
}
.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)";
}
/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}
.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}
/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto;
}
.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px;
}
.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px;
}
/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px;
}
.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px;
}
/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0;
}
.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px;
}
.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px;
}
/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px;
}
.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px;
}
/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px;
}
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
}
.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto;
}
/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}
.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}
/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px;
}
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px;
}
.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0;
}
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0;
}
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */
}
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px;
}
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px;
}
.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px;
}
/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px;
}
.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px;
}
/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px;
}
.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px;
}
/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=");
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px;
}
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px;
}
/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
}
/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px;
}
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px;
}
.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}
.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
}
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
}
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
}
.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
}
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)";
}
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px;
}
.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}
.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555;
}
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0;
}
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px;
}
/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto;
}
.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px;
}
.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px;
}
.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}
.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}
/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)";
}
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)";
}
/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
}
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0;
}
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px;
}
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
}
.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0;
}
.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px;
}
.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px;
}
.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px;
}
.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px;
}
/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px;
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px;
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px;
}
.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px;
}
/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2);
}
/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85);
}
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
}
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
}
/* ---------------------------------------- */
/*Colors*/
body,
input,
textarea,
select,
button {
  font-family: 'Nunito', sans-serif;
}
body {
  display: flex;
  flex-direction: column;
}
body .page-content,
body .k-widget {
  font-family: 'Nunito', sans-serif;
}
.mdl-layout {
  flex: 1 0 auto;
}
a {
  color: #5469d4;
}
#map {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.dataPageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.dataPageModal .modal-dialog {
  display: table;
  width: 100%;
  height: 100%;
}
.dataPageModal .modal-content-cover {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.dataPageModal .modal-header {
  position: relative;
}
.dataPageModal .modal-header .close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.dataPageModal .modal-content {
  display: inline-block;
  width: 400px;
  background: #fff;
  padding: 15px;
  text-align: left;
}
.dataPageModal .modal-content input,
.dataPageModal .modal-content textarea {
  width: 100%;
  box-sizing: border-box;
}
.dataPageModal .modal-footer {
  text-align: center;
  margin-top: 10px;
}
.modal {
  display: none;
}
.box-modal .mdl-textfield {
  position: relative;
}
.box-modal .mdl-textfield .field-validation-error {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  color: #d50000;
}
.box-modal .mdl-textfield .textfield__valid {
  color: #48b04b;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  display: block;
}
.box-modal .mdl-textfield .textfield__valid.msgBlockerror {
  color: #d50000;
  font-size: 12px;
  margin-top: 0;
  display: block;
}
.box-modal .mdl-textfield.bid_cover {
  width: 100px;
  margin-right: 7px;
}
.box-modal .mdl-textfield .point_icon {
  color: #3F51B5;
  min-height: 24px;
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
  background: #C5CEFF;
  border-radius: 25px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  margin-right: 10px;
}
.box-modal .mdl-textfield input.placeholdered {
  opacity: 0;
}
.box-modal .mdl-textfield input.placeholdered:focus {
  opacity: 1;
}
.box-modal .mdl-textfield input.placeholdered:focus + .mdl-textfield-placeholder {
  opacity: 0;
}
.box-modal .mdl-textfield .mdl-textfield-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: right;
  box-shadow: inset 0 -1px 0 #ccc;
  font-family: "Nunito", "Arial", sans-serif;
  pointer-events: none;
  font-size: 14px;
  padding: 4px 0;
  line-height: 17px;
  box-sizing: border-box;
  opacity: 1;
}
.box-modal .mdl-textfield input[type=date] {
  border: none;
  width: 100%;
  min-width: 120px;
  padding-bottom: 3px;
  padding-top: 2px;
  margin: 3px 0;
  margin-top: 10px;
  font-size: 16px;
}
.logo_company {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.logo_company > div:nth-of-type(1) {
  color: #fff;
}
.logo_company > div:nth-of-type(2) {
  color: #fdc927;
  font-size: 30px;
  max-height: 24px;
  margin-top: -43px;
  padding: 0 1px;
}
.logo_company > div:nth-of-type(3) {
  color: #7986cb;
}
.more_info_footer_button {
  display: none;
}
.page_controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;
}
.page_controls .logo_company {
  width: 195px;
  text-decoration: none;
  border-right: 1px solid #ffffff;
  height: 100%;
}
.page_controls .logo_company div:nth-child(2) {
  margin-top: -5px;
}
.page_controls .page_controls_back {
  background-color: #ffffff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: 0 20px 0 0;
}
.page_controls .page_controls_back i {
  display: block;
  color: #3F51B5;
  font-weight: bold;
}
.page_controls .confirm_close {
  background-color: #ffd740;
  color: #3f51b5;
  border-radius: 3px;
  padding: 3px 7px 2px 7px;
  margin-left: 20px;
  cursor: pointer;
}
.page_controls .close_confirmed {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 3px;
  padding: 3px 7px 2px 7px;
  margin-left: 20px;
}
.basket_header {
  position: absolute;
  top: 0;
  height: 48px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
  left: 9px;
}
.basket_header span {
  display: none;
}
.burger_header {
  margin: 8px 0px;
  display: block;
  height: 48px;
  width: 48px;
  border: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: 'Nunito', Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 98px;
  z-index: 4;
  outline: none !important;
  position: absolute;
  color: #ffffff;
  background-color: inherit;
  transform: rotate(90deg);
  transition: all 300ms;
  z-index: 2;
}
.burger_header i {
  color: #ffffff;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item {
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  padding: 8px 6px;
  position: relative;
  flex-wrap: wrap;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item span span {
  display: none;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item i {
  color: #FFFFFF;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item.active {
  background-color: #1855A3;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item.active > a,
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item.active > span {
  color: #ffffff;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item.active:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #ffffff;
}
.mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item.active i {
  color: #ffffff;
}
.mdl-list__item-primary-content {
  position: relative;
}
.mdl-list__item-primary-content .material-icons {
  right: 0 !important;
  margin-right: 13px !important;
}
.mdl-list__item-primary-content .arrow.material-icons {
  display: none;
}
.mdl-layout__header {
  background-color: #1855A3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.mdl-layout__header-row {
  padding: 0 40px 0 165px;
  transition: padding 0.3s;
}
.open_menu .basket_header,
.remember_open_menu .basket_header {
  width: auto;
  left: 13px;
  text-decoration: none;
}
.open_menu .basket_header > img,
.remember_open_menu .basket_header > img {
  margin-bottom: -5px;
}
.open_menu .basket_header span,
.remember_open_menu .basket_header span {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 18px;
}
.open_menu .burger_header,
.remember_open_menu .burger_header {
  left: 245px;
  transform: rotate(0deg);
}
.open_menu .mdl-layout__header-row,
.remember_open_menu .mdl-layout__header-row {
  padding: 0 40px 0 321px;
  transition: padding 0.3s;
}
.open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item,
.remember_open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item {
  min-height: auto;
}
.open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item span span,
.remember_open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item span span {
  display: block;
}
.open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item .mdl-list__item-icon,
.remember_open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item .mdl-list__item-icon {
  margin-right: 20px !important;
}
.open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item .arrow,
.remember_open_menu .mdl-layout:not(.is-small-screen) .nav_menu .mdl-list__item .arrow {
  position: absolute;
  right: 0;
  top: 12px;
}
.open_menu .mdl-list__item-primary-content .material-icons,
.remember_open_menu .mdl-list__item-primary-content .material-icons {
  position: relative;
  right: initial;
}
.open_menu .mdl-list__item-primary-content .arrow.material-icons,
.remember_open_menu .mdl-list__item-primary-content .arrow.material-icons {
  display: block;
}
.open_menu .mdl-layout__header,
.remember_open_menu .mdl-layout__header {
  z-index: 5;
}
.open_menu .mdl-layout__content,
.remember_open_menu .mdl-layout__content {
  margin-left: 300px !important;
}
.mdl-layout-title {
  background-color: #1855A3;
  padding-left: 0;
}
.mdl-textfield__input:focus {
  outline: none;
}
.mdl-data-table {
  width: 100%;
}
.mdl-data-table td {
  padding: 2px 15px;
}
.mdl-data-table th {
  padding: 2px 15px;
  font-size: 14px;
  color: #272A29;
}
.mdl-data-table thead {
  background: rgba(255, 208, 75, 0.3);
  z-index: 2;
  position: relative;
}
.mdl-data-table thead tr:hover {
  background: transparent !important;
}
.mdl-data-table.disabled_table .mdl-textfield-placeholder {
  opacity: 0.5 !important;
}
.table_inner_cover {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
.mdl-list__item .mdl-list__item-primary-content {
  white-space: nowrap;
}
.nav_menu {
  border-right: none;
  transition: width 400ms;
  z-index: 2;
}
.nav_menu .mdl-layout-title {
  padding-left: 16px;
}
.nav_menu .mdl-list__item {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  min-height: 56px;
  padding-right: 0 !important;
  display: flex;
  align-items: center;
  width: 100%;
}
.account_cover {
  position: relative;
  background: #1855A3;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.account_cover_button {
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: 36px;
  height: 36px;
  color: #00A0E8;
  border: 1px solid #00A0E8;
}
.account_cover_button .material-icons {
  font-size: 22px;
  vertical-align: middle;
}
.account_cover_button.has_notifications {
  position: relative;
}
.account_cover_button.has_notifications::after {
  position: absolute;
  right: -3px;
  top: -3px;
  display: block;
  width: 14px;
  height: 14px;
  font-size: 10px;
  background-color: #C12C2C;
  content: attr(data-counter);
  color: #ffffff;
  border-radius: 50%;
}
.account_cover_button:hover {
  color: #000000;
}
.account_cover_button:hover::after {
  color: #ffffff;
}
.account_cover_content {
  display: none;
  position: absolute;
  background-color: #f6faff;
  padding: 20px;
  border-radius: 20px 0 20px 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  right: 90%;
  top: 90%;
  z-index: 1;
  width: auto;
  box-sizing: border-box;
  text-align: left;
}
.account_cover_content.account_cover_content_wide {
  width: 240px;
}
.account_cover_content_button {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #1855a3;
  padding: 0 0 15px;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.account_cover_content_button:hover {
  color: #00A0E8;
}
.account_cover_content_button:last-child {
  padding-bottom: 0;
}
.header_user_info {
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}
.menu_bottom_controls {
  position: relative;
}
.footer_info {
  padding-left: 16px;
  padding-bottom: 20px;
  transition: opacity 400ms;
}
.footer_info .typography_caption {
  font-size: 12px;
  display: block;
}
.footer_info .tel {
  margin-bottom: 15px;
}
.footer_info .copywrite {
  margin-top: 15px;
}
.select2-dropdown {
  background: transparent !important;
}
.select2-dropdown .select2-search__field {
  padding: 4px 15px;
}
.mdl-textfield-select select {
  width: 100%;
}
.mdl-textfield-select .select2-container {
  z-index: 1;
}
.mdl-textfield-select .select2-container .select2-selection--single {
  height: 41px;
  padding-top: 10px;
}
.mdl-textfield-select .select2-container .select2-selection--single:focus {
  outline: none !important;
}
.mdl-textfield-select .mdl-textfield__label:after {
  display: none !important;
}
.mdl-textfield-select .select2-container--search_theme .select2-selection--single {
  cursor: text;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
}
.mdl-textfield-select .select2-container--default.select2-container--focus .select2-selection--multiple,
.mdl-textfield-select .select2-container--single_no_input.select2-container--focus .select2-selection--multiple,
.mdl-textfield-select .select2-container--single_no_input_top.select2-container--focus .select2-selection--multiple {
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple,
.mdl-textfield-select .select2-container--default .select2-selection--single,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single {
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  border-radius: 0;
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .select2-selection__arrow,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .select2-selection__arrow,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .select2-selection__arrow,
.mdl-textfield-select .select2-container--default .select2-selection--single .select2-selection__arrow,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .select2-selection__arrow,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 15px;
  right: 0px;
  height: 20px;
  width: 20px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 50%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 50%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, #ffffff 50%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .select2-selection__arrow b,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .select2-selection__arrow b,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .select2-selection__arrow b,
.mdl-textfield-select .select2-container--default .select2-selection--single .select2-selection__arrow b,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .select2-selection__arrow b,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  left: 6px;
  top: 8px;
  border-top: 5px solid #828282;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .select2-selection__rendered,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .select2-selection__rendered,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .select2-selection__rendered,
.mdl-textfield-select .select2-container--default .select2-selection--single .select2-selection__rendered,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .select2-selection__rendered,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .select2-selection__rendered {
  padding: 0;
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .select2-selection__choice,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .select2-selection__choice,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .select2-selection__choice,
.mdl-textfield-select .select2-container--default .select2-selection--single .select2-selection__choice,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .select2-selection__choice,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .select2-selection__choice {
  cursor: default;
  border-radius: 16px;
  border: 0;
  background-color: #dedede;
  padding: 0 12px;
  margin-bottom: 0;
  margin-top: 2px;
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .mdl-chip--deletable,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .mdl-chip--deletable,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .mdl-chip--deletable,
.mdl-textfield-select .select2-container--default .select2-selection--single .mdl-chip--deletable,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .mdl-chip--deletable,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .mdl-chip--deletable {
  padding-right: 4px;
}
.mdl-textfield-select .select2-container--default .select2-selection--multiple .select2-search--inline,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--multiple .select2-search--inline,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--multiple .select2-search--inline,
.mdl-textfield-select .select2-container--default .select2-selection--single .select2-search--inline,
.mdl-textfield-select .select2-container--single_no_input .select2-selection--single .select2-search--inline,
.mdl-textfield-select .select2-container--single_no_input_top .select2-selection--single .select2-search--inline {
  margin-top: 8px;
}
.mdl-textfield-select .mdl-textfield__label {
  top: 31px;
  left: 15px;
}
.mdl-button--raised.mdl-button--colored {
  font-size: 14px;
  line-height: 16px;
  color: #409EFF;
  background: rgba(64, 158, 255, 0.3);
  border: 1px solid #409EFF;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 16px;
  text-transform: none;
  font-weight: normal;
  display: flex;
  align-items: center;
}
.mdl-button--raised.mdl-button--colored:hover,
.mdl-button--raised.mdl-button--colored:active,
.mdl-button--raised.mdl-button--colored:focus {
  background: rgba(64, 158, 255, 0.3) !important;
  box-shadow: none !important;
}
.mdl-button--raised.mdl-button--colored > img,
.mdl-button--raised.mdl-button--colored i.material-icons {
  margin-right: 13px;
}
.mdl-textfield.is-focused .mdl-textfield__label {
  display: none !important;
}
.select2-selection__rendered {
  position: relative;
  padding: 0 12px !important;
  padding-right: 29px !important;
}
.select2-selection__rendered .select2-selection__clear {
  position: absolute !important;
  right: 0 !important;
  font-size: 20px !important;
}
.grid_cover_content {
  box-shadow: none;
}
.grid_cover_content .big_select .select2-container {
  width: 330px !important;
}
.grid_cover_content .big_select .mdl-textfield__label {
  width: 103%;
}
.material-icons.icon-in-cell {
  margin-top: 5px;
}
.material-icons.red {
  color: #ec0d3f;
}
.material-icons.blue {
  color: #1c70f5;
}
.checkbox_cover {
  display: inline-block;
}
.checkbox_cover label {
  cursor: pointer;
}
.checkbox_cover label .icon {
  width: 16px;
  height: 16px;
  border: 2px solid #3f51b5;
  border-radius: 2px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.checkbox_cover label .icon.indefinite {
  background: #ffd740;
  border: 2px solid #ffd740;
}
.checkbox_cover label .icon.indefinite:before {
  content: '';
  height: 2px;
  width: 10px;
  position: absolute;
  left: 3px;
  top: 7px;
  background: #ffffff;
}
.checkbox_cover label .icon:after {
  content: '';
  height: 16px;
  width: 16px;
  background: transparent;
  display: block;
}
.checkbox_cover label input[type=checkbox] {
  display: none;
}
.checkbox_cover label input[type=checkbox]:checked + .icon.indefinite {
  background: #ffd740;
  border: 2px solid #ffd740;
}
.checkbox_cover label input[type=checkbox]:checked + .icon.indefinite:after {
  display: none;
}
.checkbox_cover label input[type=checkbox]:checked + .icon:after {
  background: #3f51b5 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}
.checkbox_cover a {
  color: #1855A3;
}
.radio_cover label {
  cursor: pointer;
}
.radio_cover label span {
  position: relative;
  top: 2px;
}
.radio_cover label .icon {
  width: 16px;
  height: 16px;
  border: 2px solid #1855A3;
  border-radius: 10px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.radio_cover label .icon:after {
  content: '';
  height: 12px;
  width: 12px;
  margin-left: 2px;
  margin-top: 2px;
  background: transparent;
  display: block;
  border-radius: 10px;
  transform: scale(0);
  transition: 400ms;
}
.radio_cover label input[type=radio] {
  display: none;
}
.radio_cover label input[type=radio]:checked + .icon:after {
  background: #1855A3;
  transform: scale(1);
}
.disabled {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  pointer-events: none !important;
  opacity: 0.7 !important;
  filter: grayscale(100%) !important;
}
#app,
#discountTemplates_app {
  position: relative;
}
#saveTableChanges {
  position: absolute;
  top: -36px;
  left: 50%;
  margin-left: -68px;
  z-index: 3;
  width: auto;
  min-width: 136px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
}
#saveTableChanges .material-icons {
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0px, -2px);
  transform: translate(0px, -2px);
  vertical-align: middle;
  display: inline-block;
}
#saveTableChanges span {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translate(0px, -1px);
  transform: translate(0px, -1px);
}
#saveTableChanges.disabled .material-icons {
  transform: none;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
[v-cloak] {
  display: none;
}
.page-content .table_scroll_cover {
  overflow: auto;
  position: relative;
}
.page-content .table_footer {
  text-align: right;
}
.page-content .table-cover {
  width: 100%;
}
.page-content .table-cover .mCSB_dragger,
.page-content .table-cover .mCSB_dragger_bar {
  background-color: #3f51b5;
}
.page-content .table-cover .mCSB_dragger {
  border-radius: 20px;
}
.page-content .table-cover tr.disabled-row {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  opacity: 0.7;
  background: #cecece;
  filter: grayscale(100%);
}
.page-content .table_levels {
  table-layout: fixed;
  white-space: normal !important;
  border: none;
}
.page-content .table_levels.fixed_head {
  margin-bottom: -48px;
  position: relative;
  z-index: 2;
  box-shadow: none;
  border-top: none;
  margin-top: 15px;
}
.page-content .table_levels table {
  table-layout: fixed;
}
.page-content .table_levels .scroll_guy {
  max-height: 350px;
  overflow: auto;
}
.page-content .table_levels .scroll_guy table {
  background: #f9f9f9;
}
.page-content .table_levels .scroll_guy tr {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
}
.page-content .table_levels .scroll_guy tr td {
  flex-grow: 1;
  display: flex;
  align-items: center;
  border-top: none;
  background: #efefef;
}
.page-content .table_levels .scroll_guy tr td.dieselPrice {
  justify-content: flex-end;
  min-width: 11%;
  max-width: 11%;
  padding-right: 2.2vw;
}
.page-content .table_levels .scroll_guy tr td.premiumDieselPrice {
  min-width: 11%;
  max-width: 11%;
  justify-content: flex-end;
  padding-right: 24px;
}
.page-content .table_levels .scroll_guy tr td.discount_temlate_drop {
  padding-right: 3vw;
  min-width: 22vw;
  max-width: 25%;
  justify-content: flex-end;
}
.page-content .table_levels .scroll_guy tr td.discount_temlate_drop.icon_td {
  max-width: 0;
  width: 0;
  padding: 0;
  min-width: 0;
}
.page-content .table_levels .table_levels {
  border: none;
}
.page-content .table_levels .section_title {
  margin-bottom: 7px;
  margin-left: 9px;
  font-size: 17px;
  margin-top: 18px;
  font-weight: 500;
  text-align: left;
  color: #606060;
}
.page-content .table_levels th:first-of-type {
  padding-left: 10px;
}
.page-content .table_levels tr.unchecked {
  opacity: 0.7 !important;
  filter: grayscale(100%) !important;
}
.page-content .table_levels tr.ghost {
  opacity: 0.5 !important;
  filter: grayscale(100%) !important;
  background: #a0a0a0;
  pointer-events: none;
  display: none;
}
.page-content .table_levels thead tr > th:first-of-type,
.page-content .table_levels tr[data-level] > td:first-of-type {
  width: 360px;
}
.page-content .table_levels tr {
  height: auto;
}
.page-content .table_levels tr:hover {
  background: #fff;
}
.page-content .table_levels td[colspan="4"],
.page-content .table_levels td[colspan="3"] {
  padding: 0;
  border: none;
  height: auto;
}
.page-content .table_levels td[colspan="4"] > table,
.page-content .table_levels td[colspan="3"] > table {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  width: 100%;
}
.page-content .table_levels td[colspan="3"] > table td:first-of-type {
  padding-left: 37px;
}
.page-content .table_levels td[colspan="3"] > table td:first-of-type span:not(.edit_template) {
  cursor: default;
}
.page-content .table_levels td[colspan="3"] > table td:first-of-type span:not(.edit_template):before {
  display: none;
}
.page-content .table_levels td[colspan="3"] > table td:first-of-type i.line_to_top {
  position: absolute;
  display: block;
  left: 23px;
  bottom: 13px;
  height: 47px;
  width: 10px;
  border-left: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  pointer-events: none;
}
.page-content .table_levels td[colspan="3"] .scroll_guy {
  border-top: 1px solid #ccc;
}
.page-content .table_levels td[colspan="3"] .scroll_guy > div {
  margin-top: -1px;
}
.page-content .table_levels td[colspan="3"] .scroll_guy table,
.page-content .table_levels td[colspan="3"] .scroll_guy tr td {
  background: #fff;
}
.page-content .table_levels td.icon_td,
.page-content .table_levels th.icon_td {
  width: 60px;
  max-width: 60px;
}
.page-content .table_levels td.icon_td .material-icons,
.page-content .table_levels th.icon_td .material-icons {
  color: #3F51B5;
  margin-top: 7px;
  cursor: pointer;
}
.page-content .table_levels tr[data-level] td:first-of-type {
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template),
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) {
  cursor: pointer;
  position: relative;
  border-bottom: 1px dashed #b5b5b5;
  user-select: none;
  display: inline-block;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template):focus,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load):focus {
  outline: transparent;
  box-shadow: 0 0 0 2px #9db8e4;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template):before,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load):before {
  content: '';
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 5px solid #000;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_top,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_top {
  position: absolute;
  display: block;
  left: -8px;
  bottom: -1px;
  height: 47px;
  width: 10px;
  border-left: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  pointer-events: none;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_bottom,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_bottom {
  position: absolute;
  left: 13px;
  bottom: -15px;
  height: 15px;
  width: 1px;
  background: #b5b5b5;
}
.page-content .table_levels tr[data-level] td:first-of-type .load {
  opacity: 0;
  display: block;
  position: absolute;
  right: -45px;
  top: -1px;
  border-bottom: none;
  transform: translateX(0px);
  transition: right 8s, opacity 500ms;
  pointer-events: none;
}
.page-content .table_levels tr[data-level] td:first-of-type .load:after {
  content: '';
  position: absolute;
  right: 28px;
  top: 8px;
  height: 1px;
  width: 12px;
  background: #ccc;
}
.page-content .table_levels tr[data-level] td:first-of-type .load:before {
  content: '';
  position: absolute;
  right: 30px;
  top: 12px;
  height: 1px;
  width: 10px;
  background: #ccc;
  border: none;
}
.page-content .table_levels tr[data-level] td:first-of-type .load.active {
  -webkit-animation: vibrate-1 1s linear infinite both;
  animation: vibrate-1 1s linear infinite both;
  opacity: 1;
  right: -76px;
}
.page-content .table_levels tr[data-level] td:first-of-type .load.active:after {
  -webkit-animation: shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}
.page-content .table_levels tr[data-level] td:first-of-type .load.active:before {
  -webkit-animation: shake-horizontal 1s 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-horizontal 1s 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}
.page-content .table_levels tr[data-level] td:first-of-type .load img {
  height: 25px;
}
.page-content .table_levels tr[data-level] td:first-of-type .counter {
  font-size: 12px;
  border-bottom: none;
  pointer-events: none;
}
.page-content .table_levels tr[data-level] td:first-of-type .counter:before {
  display: none;
}
.page-content .table_levels tr[data-level] .checkbox_cover label .icon {
  margin-right: 0;
}
.page-content .table_levels tr[data-level] td {
  height: 48px;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.12);
}
.page-content .table_levels tr[data-level] td .price {
  font-size: 12px;
  color: #ccc;
  position: relative;
  top: -2px;
}
.page-content .table_levels tr[data-level] td .mdl-textfield {
  padding: 0;
}
.page-content .table_levels tr[data-level="0"] td:first-of-type {
  font-weight: 500;
}
.page-content .table_levels tr[data-level="0"] td:first-of-type .checkbox_cover {
  margin-right: 6px;
}
.page-content .table_levels tr[data-level="0"] td:first-of-type span i:not(.material-icons) {
  content: '';
  position: absolute;
  left: -4px;
  bottom: -1px;
  height: 47px;
  width: 7px;
  border-left: none;
  border-bottom: 1px dashed #757575;
}
.page-content .table_levels tr[data-level="0"] td:first-of-type span.edit_template i {
  position: relative;
  left: 0;
  bottom: -5px;
  color: #3F51B5;
  cursor: pointer;
}
.page-content .table_levels tr[data-level="1"] td:first-of-type {
  font-weight: normal;
}
.page-content .table_levels tr[data-level="1"] td:first-of-type .checkbox_cover {
  margin-right: 17px;
}
.page-content .table_levels tr[data-level="2"] td:first-of-type {
  font-size: 15px;
  font-weight: lighter;
}
.page-content .table_levels tr[data-level="2"] td:first-of-type .checkbox_cover {
  margin-right: 30px;
}
.page-content .table_levels tr[data-level="3"].bigMargin td:first-of-type .checkbox_cover {
  margin-right: 55px;
}
.page-content .table_levels tr[data-level="3"] td:first-of-type {
  font-size: 12px;
  color: #606266;
  font-weight: 700;
  max-width: none;
}
.page-content .table_levels tr[data-level="3"] td:first-of-type span {
  border-bottom: 1px solid #b5b5b5;
  padding-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.page-content .table_levels tr[data-level="3"] td:first-of-type span:before {
  display: none;
}
.page-content .table_levels tr[data-level="3"] td:first-of-type span:not(.edit_template):before {
  display: none;
}
.page-content .table_levels tr[data-level="3"] td:first-of-type .checkbox_cover {
  margin-right: 42px;
}
.page-content .mdl-data-table {
  width: 100%;
}
.page-content .mdl-data-table .checkbox_cover {
  position: relative;
  top: -2px;
}
.page-content .mdl-data-table .icon_cell {
  width: 60px;
}
.page-content .mdl-data-table th {
  line-height: 14px;
  padding-bottom: 17px;
  border: 1px solid #DCDFE6;
}
.page-content .mdl-data-table th.sortable {
  cursor: pointer;
}
.page-content .mdl-data-table th.sortable span {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.54);
}
.page-content .mdl-data-table th.cell_checkbox_cover,
.page-content .mdl-data-table td.cell_checkbox_cover {
  padding-right: 0;
}
.page-content .mdl-data-table td {
  /*height: 61px;*/
}
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield {
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  width: 88px;
  padding: 2px 0 !important;
  height: 32px;
}
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield .mdl-textfield-placeholder {
  box-shadow: none;
  border: none;
  padding: 8px;
}
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield .mdl-textfield__input {
  text-align: right;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  padding: 6px 8px;
}
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield .mdl-textfield__input:focus,
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield .mdl-textfield__input:active {
  border: none;
}
.page-content .mdl-data-table td:not(.mdl-data-table__cell--non-numeric) .mdl-textfield .mdl-textfield__label {
  text-align: right;
}
.page-content .mdl-button.red {
  color: #ec0d3f;
}
.page-content .mdl-button.blue {
  color: #1c70f5;
}
.page-content .mdl-button.make_bid {
  background: rgba(158, 158, 158, 0.2);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  margin: 2px 0;
  vertical-align: middle;
}
.page-content .mdl-button.make_bid:active {
  box-shadow: none;
}
.page-content .bet_counter.red {
  color: #dc424f;
}
.page-content .bet_counter.red .counter_cover .counter {
  background: #dc424f;
  color: #fff;
}
.page-content .bet_counter.green {
  color: #48b04b;
}
.page-content .bet_counter.green .counter_cover .counter {
  background: #48b04b;
  color: #fff;
}
.page-content .bet_counter .counter_cover {
  display: flex;
  margin-bottom: 4px;
}
.page-content .bet_counter .counter_cover .counter {
  background: #ccc;
  padding: 3px;
  border-radius: 15px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.page-content .bet_counter .counter_cover i {
  margin-left: 8px;
}
.page-content .bet_counter .message {
  font-weight: bold;
}
.page-content .mdl-textfield {
  position: relative;
}
.page-content .mdl-textfield .field-validation-error {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 10px;
  color: #d50000;
}
.page-content .mdl-textfield .textfield__valid {
  color: #48b04b;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  display: block;
}
.page-content .mdl-textfield .textfield__valid.msgBlockerror {
  color: #d50000;
  font-size: 12px;
  margin-top: 0;
  display: block;
}
.page-content .mdl-textfield.bid_cover {
  width: 100px;
  margin-right: 7px;
}
.page-content .mdl-textfield .point_icon {
  color: #3F51B5;
  min-height: 24px;
  min-width: 24px;
  max-width: 24px;
  max-height: 24px;
  background: #C5CEFF;
  border-radius: 25px;
  text-align: center;
  line-height: 24px;
  display: inline-block;
  margin-right: 10px;
}
.page-content .mdl-textfield input.placeholdered {
  opacity: 0;
}
.page-content .mdl-textfield input.placeholdered:focus {
  opacity: 1;
}
.page-content .mdl-textfield input.placeholdered:focus + .mdl-textfield-placeholder {
  opacity: 0;
}
.page-content .mdl-textfield .mdl-textfield-placeholder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: right;
  box-shadow: inset 0 -1px 0 #ccc;
  font-family: "Nunito", "Arial", sans-serif;
  pointer-events: none;
  font-size: 14px;
  padding: 4px 0;
  line-height: 17px;
  box-sizing: border-box;
  opacity: 1;
}
.page-content .mdl-textfield input[type=date] {
  border: none;
  width: 100%;
  min-width: 120px;
  padding-bottom: 3px;
  padding-top: 2px;
  margin: 3px 0;
  margin-top: 10px;
  font-size: 16px;
}
.page-content .gavel_cell_cover {
  position: relative;
  padding-right: 80px;
  /*.gavel_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }*/
}
.page-content .gavel_cell_cover .mdl-button {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -2px;
  transform: translateY(-50%);
}
.page-content .gavel_cell_cover .go_ride {
  font-weight: bold;
  color: #48b04b;
  cursor: pointer;
  margin-top: 8px;
  display: inline-block;
}
.page-content .gavel_cell_cover .start {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 8px;
  display: inline-block;
}
.page-content .gavel_cell_cover .mdl-button.green {
  width: 100%;
  margin-top: 10px;
}
.page-content .gavel_cell_cover .current {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}
.page-content .grid_cover_content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  align-self: stretch;
  padding: 0 13px;
  padding-bottom: 10px;
  z-index: 6;
  position: relative;
}
.page-content .grid_cover_content .mdl-layout-spacer {
  max-width: 100%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.page-content .grid_cover_content .col-10 {
  max-width: 9%;
  margin: 0 0.5%;
}
.page-content .grid_cover_content .col-15 {
  max-width: 14%;
  margin: 0 0.5%;
}
.page-content .grid_cover_content .col-20 {
  max-width: 19%;
  margin: 0 0.5%;
}
.page-content .grid_cover_content .col-25 {
  max-width: 24%;
  margin: 0 0.5%;
}
.page-content .grid_cover_content .col-30 {
  max-width: 29%;
  margin: 0 0.5%;
}
.page-content .map_toggler .map_toggler_button {
  padding: 0;
  width: 64px;
  font-size: 0;
}
.page-content .map_toggler i {
  display: inline-block;
  height: 100%;
  width: 50%;
  vertical-align: middle;
  line-height: 36px;
  background-color: #ffffff;
  color: #3f51b5;
}
.page-content .map_toggler .toggled {
  background-color: #3f51b5;
  color: #ffffff;
}
.page-content .marker_map {
  height: calc(100vh - 155px) !important;
}
.page-content .visually-hidden {
  opacity: 0;
  position: absolute;
  top: -10000px;
  left: 0;
  z-index: -1;
  clip: rect(0 0 0 0);
}
.page-content .group_title {
  font-size: 16px;
  font-weight: bold;
  padding: 0 24px;
  padding-top: 30px;
  padding-bottom: 15px;
}
.page-content .carrier_cover {
  padding: 0 24px;
}
.page-content .carrier_cover > div {
  overflow: visible;
}
.page-content .carrier_cover .mdl-textfield {
  width: 34%;
}
.page-content .carrier_cover .mdl-textfield.longWidth {
  width: 800px;
  max-width: 100%;
  margin-top: 20px;
}
.page-content .carrier_cover .checkbox_cover {
  margin: 20px 0;
}
.page-content .carrier_cover .mdl-button--nomargin {
  margin-left: 0;
}
.page-content .carrier_cover b {
  margin: 20px 0;
  display: inline-block;
  margin-right: 24px;
}
.page-content .carrier_cover .mdl-chip {
  margin-right: 15px;
  position: relative;
  top: -3px;
}
.page-content .mt24 {
  margin-top: 24px;
}
.page-content .mt30 {
  margin-top: 30px;
}
.page-content .ml15 {
  margin-left: 15px;
}
.page-content .vabt {
  vertical-align: bottom;
}
.page-content .mdl-chip[type=button] {
  cursor: pointer;
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.page-content .mdl-chip[type=button]:active {
  box-shadow: none;
}
.page-content .mdl-chip.green {
  background: #48b04b;
  color: #fff;
}
.page-content .mdl-button.green {
  background: #48b04b;
  color: #fff;
}
.checkbox_cover label .icon {
  border: 2px solid #1855A3;
}
.checkbox_cover label input[type=checkbox]:checked + .icon:after {
  background: #1855A3 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
}
.fuel_amount_radio_form {
  position: relative;
  flex-grow: 1;
}
.fuel_amount_radio_form .label {
  position: absolute;
  left: 0;
  color: #3f51b5;
  font-size: 12px;
  top: -10px;
}
.fuel_amount_label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  height: 48px;
  width: 48px;
  top: 15px;
}
.fuel_amount_label + .fuel_amount_label {
  margin-left: 3px;
}
.fuel_amount_label input[type="radio"] {
  display: none;
}
.fuel_amount_label input[type="radio"]:checked + .icon {
  border: 2px solid #3f51b5;
}
.fuel_amount_label .icon {
  height: 44px;
  width: 44px;
  position: relative;
  border-radius: 8px;
  border: 2px solid #ccc;
  text-align: center;
  transition: all 300ms;
  left: 0;
  top: 0;
}
.fuel_amount_label .icon:hover {
  transform: translateY(-2px);
}
.fuel_amount_label .icon img {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  position: absolute;
  display: inline-block;
}
.comparison_discount {
  font-size: 12px;
  color: #9e9e9e;
  line-height: 0;
  position: relative;
  top: -1px;
}
.select2-container--default .select2-dropdown {
  top: -30px;
  border: none;
}
.select2-container--default .select2-dropdown .select2-search--dropdown {
  padding: 0;
}
.select2-container--default .select2-dropdown .select2-search--dropdown .select2-search__field {
  background: transparent;
  border: none;
}
.select2-container--default .select2-dropdown .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--default .select2-dropdown .select2-results__option {
  padding: 0 16px;
  margin: 3px 0;
  min-height: 27px;
  height: auto;
  line-height: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;
}
.select2-container--default .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background: #f2f2f2;
  color: #a5a5a5;
}
.select2-container--default .select2-dropdown .select2-results {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  top: 37px;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.select2-container--search_theme .select2-dropdown {
  top: -33px;
  border: none;
}
.select2-container--search_theme .select2-dropdown .select2-search--dropdown {
  padding: 0;
}
.select2-container--search_theme .select2-dropdown .select2-search--dropdown .select2-search__field {
  background: transparent;
  border: none;
}
.select2-container--search_theme .select2-dropdown .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--search_theme .select2-dropdown .select2-results__option {
  padding: 7px 16px;
  min-height: 35px;
  display: flex;
  align-items: center;
  line-height: 18px;
}
.select2-container--search_theme .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background: #f2f2f2;
  color: #a5a5a5;
}
.select2-container--search_theme .select2-dropdown .select2-results {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  top: 37px;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.select2-container--single_no_input .select2-dropdown {
  top: -30px;
  border: none;
}
.select2-container--single_no_input .select2-dropdown .select2-search--dropdown {
  padding: 0;
}
.select2-container--single_no_input .select2-dropdown .select2-search--dropdown .select2-search__field {
  background: transparent;
  border: none;
}
.select2-container--single_no_input .select2-dropdown .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--single_no_input .select2-dropdown .select2-results__option {
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  /*&:first-of-type{
        height: 0;
      }*/
}
.select2-container--single_no_input .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background: #f2f2f2;
  color: #a5a5a5;
}
.select2-container--single_no_input .select2-dropdown .select2-results {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  top: 37px;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.select2-container--single_no_input .select2-dropdown .select2-results ul {
  max-height: 50vh;
}
.select2-container--single_no_input_top .select2-dropdown {
  top: -30px;
  border: none;
}
.select2-container--single_no_input_top .select2-dropdown .select2-search--dropdown {
  padding: 0;
}
.select2-container--single_no_input_top .select2-dropdown .select2-search--dropdown .select2-search__field {
  background: transparent;
  border: none;
}
.select2-container--single_no_input_top .select2-dropdown .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--single_no_input_top .select2-dropdown .select2-results__option {
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  /*&:first-of-type{
        height: 0;
      }*/
}
.select2-container--single_no_input_top .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background: #f2f2f2;
  color: #a5a5a5;
}
.select2-container--single_no_input_top .select2-dropdown .select2-results {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  top: auto;
  bottom: 11px;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.select2-container--single_no_input_top .select2-dropdown .select2-results ul {
  max-height: 50vh;
}
.select2-container--single_no_input_mini {
  outline: 0;
}
.select2-container--single_no_input_mini span {
  outline: 0;
}
.select2-container--single_no_input_mini .select2-dropdown {
  top: -30px;
  border: none;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-search--dropdown {
  padding: 0;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-search--dropdown .select2-search__field {
  background: transparent;
  border: none;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-results__option {
  padding: 0 16px;
  height: 42px;
  font-size: 12px;
  display: flex;
  align-items: center;
  /*&:first-of-type{
        height: 0;
      }*/
}
.select2-container--single_no_input_mini .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background: #f2f2f2;
  color: #a5a5a5;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-results {
  -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  top: 37px;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.select2-container--single_no_input_mini .select2-dropdown .select2-results ul {
  max-height: 300px;
}
.select2-container--single_no_input_mini.select2-container .select2-selection--single {
  height: 28px;
}
.select2-container--single_no_input_mini.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 4px 0;
  padding-bottom: 1px;
  border-bottom: 1px solid #ccc;
}
.page-content .table_levels tr[data-level] td .mdl-textfield {
  position: relative;
  display: block;
  float: right;
}
.row_pagination {
  display: flex;
  justify-content: flex-end;
  color: #848484;
  padding: 0 20px;
  padding-top: 7px;
  font-size: 14px;
}
.row_pagination .counter {
  margin-right: 24px;
  position: relative;
  top: 3px;
}
.row_pagination .counter .mdl-textfield {
  width: 30px;
  text-align: center;
  padding: 0;
}
.row_pagination .counter .mdl-textfield .mdl-textfield__input {
  text-align: center;
}
.row_pagination .pagination_arrows {
  height: 26px;
}
.row_pagination .pagination_arrows a {
  color: #ccc;
}
.row_pagination .pagination_arrows .left,
.row_pagination .pagination_arrows .right {
  cursor: pointer;
  font-size: 30px;
  margin-left: 8px;
}
.answer_snackbars {
  position: absolute;
  text-align: center;
  left: 50%;
  min-width: 210px;
  margin-left: -105px;
  top: 18px;
  height: 48px;
  line-height: 52px;
  padding: 0 16px;
  box-shadow: 0 4px 14px rgba(76, 76, 76, 0.3);
  border-radius: 4px;
  z-index: 9;
  color: #ffffffde;
  font-size: 14px;
  cursor: default;
  box-sizing: border-box;
}
.answer_snackbars.success_answer {
  background: #45B539;
}
.answer_snackbars.error_answer {
  background: #ef4040;
}
.slideFade-enter-active {
  transition: all 300ms ease;
}
.slideFade-leave-active {
  transition: all 800ms cubic-bezier(1, 0.5, 0.8, 1);
}
.slideFade-enter,
.slideFade-leave-to {
  transform: translateX(15px);
  opacity: 0 !important;
}
.slideFadeTopDelay-enter-active {
  transition: all 300ms ease;
}
.slideFadeTopDelay-leave-active {
  transition: all 800ms 2000ms cubic-bezier(1, 0.5, 0.8, 1);
}
.slideFadeTopDelay-enter,
.slideFadeTopDelay-leave-to {
  transform: translateY(-5px);
  opacity: 0 !important;
}
.slideFadeTop-enter-active {
  transition: all 300ms ease;
}
.slideFadeTop-leave-active {
  transition: all 800ms cubic-bezier(1, 0.5, 0.8, 1);
}
.slideFadeTop-enter,
.slideFadeTop-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-3-1 11:8:32
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  20% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  40% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  60% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  80% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  90% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  20% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  40% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  60% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  80% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  90% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2019-3-1 11:17:48
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  80% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  90% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  80% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  90% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.nav_menu {
  position: fixed;
  left: 0;
  width: 47px;
  background: #00A0E8;
  top: 0;
  transition: width 300ms;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 64px;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.25);
}
.nav_menu .menu_lvl2.active {
  height: 0;
}
.nav_menu_inner {
  overflow-y: auto;
  height: 100%;
}
.basket_header {
  margin: 8px 2px;
}
.remember_open_menu .nav_menu,
.open_menu .nav_menu {
  width: 300px;
}
.remember_open_menu .nav_menu .menu_lvl2.active,
.open_menu .nav_menu .menu_lvl2.active {
  height: auto;
}
.remember_open_menu .basket_header,
.open_menu .basket_header {
  margin: 5px 0px;
}
.nav_menu .mdl-navigation {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-top: 16px;
}
.nav_menu .mdl-navigation .mdl-navigation__link {
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575;
}
.mdl-layout:not(.is-small-screen).is-upgraded > .mdl-layout__header > .mdl-layout__content {
  margin-left: 0;
}
.mdl-layout__content {
  margin-left: 47px !important;
  transition: margin 0.3s;
}
.mdl-layout:not(.is-small-screen) > .route_page.mdl-layout__content {
  margin-left: 0;
}
.mdl-layout:not(.is-small-screen) > .route_page.mdl-layout__content .page-content .page_big_map {
  height: 100vh;
}
.mCSB_scrollTools_horizontal.fixed {
  position: fixed;
  bottom: 0;
  margin: 0 17px 0 0;
  padding: 0 50px;
  max-width: 100%;
  z-index: 77;
  box-sizing: border-box;
}
.version {
  max-width: 1800px;
  margin: 0 auto;
  padding: 60px;
}
.unconfirmed {
  background-color: #FFCECE;
}
@page {
  size: A4;
}
@media print {
  div {
    float: none !important;
  }
  .profiler-results,
  .mdl-layout__header,
  .station_on_map,
  .material-icons,
  .total {
    display: none;
  }
  .mdl-layout__container {
    position: initial;
    width: initial;
    height: initial;
  }
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin: 0 auto;
  }
  .mdl-layout {
    width: initial;
    height: initial;
    display: initial;
    overflow: initial;
    position: initial;
  }
  .mdl-layout__content {
    margin: 0 auto;
  }
  .mdl-data-table th {
    height: auto;
  }
  .page-content {
    padding-bottom: 0;
  }
  .page-content .page_big_map {
    height: 100%;
    width: 100%;
    display: initial;
  }
  .page-content .page_big_map .info_col_cover {
    break-before: always;
    page-break-inside: avoid;
    page-break-before: always;
    display: block;
    position: relative;
  }
  .page-content .page_big_map .info_col_cover .info_col {
    width: initial;
  }
  .page-content .page_big_map .info_col_cover .info_col.big {
    width: initial;
    padding: 80px 0 0 0;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small {
    width: 100%;
    margin-left: 0;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small.fixed_head {
    display: none;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .title .content_cols {
    position: initial;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .path_points > div {
    width: initial;
    margin-right: 20px;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small_summary {
    margin: 0;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small_summary > div {
    width: 600px;
    margin-left: 30px;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small_summary > div:last-of-type {
    display: none;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table-cover {
    width: initial;
    height: initial;
    margin-left: 0;
    margin-top: 25px;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table-cover tbody tr {
    border-bottom: 1px solid #E4E4E4;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_big {
    overflow: initial;
    height: initial;
    margin-left: 0;
    margin-top: 0;
    width: initial;
  }
  .page-content .page_big_map .big_map_cover {
    height: 100% !important;
    width: 100%;
    display: block;
    position: relative;
    page-break-after: always;
  }
  .page-content .page_big_map .big_map_cover.small {
    width: 100%;
    max-width: 100%;
  }
  .page-content .page_big_map .big_map_cover.small #myMap {
    width: 100% !important;
    height: 270mm !important;
    overflow: hidden;
    /*>ymaps{
            width: 100% !important;
            height: 270mm !important;
            >ymaps{
              width: 100% !important;
              height: 270mm !important;
              >ymaps{
                width: 100% !important;
                height: 270mm !important;
                >ymaps:first-of-type{
                  width: 100% !important;
                  height: 270mm !important;
                }
              }
            }
          }*/
  }
  .page-content .page_big_map .big_map_cover.small .ymaps-2-1-73-map {
    transform: none;
    position: initial;
    pointer-events: none;
  }
  .page-content .page_big_map .big_map_cover.small .ymaps-2-1-73-copyright__layout {
    display: none;
  }
  .page-content .big_map_cover .info_col_cover .info_col {
    width: initial;
  }
  .page-content .big_map_cover .info_col_cover .info_col.big {
    width: initial;
  }
  .page-content .big_map_cover .info_col_cover .info_col.big .table-cover {
    overflow: initial;
    height: initial;
    margin-left: initial;
    margin-top: initial;
    width: initial;
  }
  .page-content .page_big_map .info_col_cover .info_col.big .table_levels_small_summary > div:last-of-type {
    display: none;
  }
  thead {
    display: table-row-group;
  }
  tr,
  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table {
    page-break-after: auto;
  }
}
.highlight_red {
  background-color: #FFCECE;
}
.highlight_yellow {
  background-color: #FFF6D4;
}
.x_scroll {
  overflow-x: auto;
}
.x_scroll th {
  white-space: normal;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover {
  overflow: initial;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover .info_col.big {
  margin-left: auto;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover .info_col.big .table-cover {
  height: calc(100vh - 261px);
  width: auto;
  margin: 0 -25px;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover .info_col.big .table_levels_small .dispatcher_narrow_col {
  width: 50px;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover .info_col.big .table_levels_small .dispatcher_wide_col {
  width: 230px;
}
.page-content .page_big_map.new_path_dispatcher .info_col_cover .info_col.big .table_levels_small_summary {
  padding: 25px 25px 10px 25px;
}
.page-content .page_big_map.new_path_dispatcher .dispatcher_total b {
  text-transform: uppercase;
  margin-right: 10px;
}
.page-content .settings_dispatcher {
  padding: 0 24px;
}
.page-content .settings_dispatcher .form_section > b {
  margin: 20px 0;
  display: inline-block;
  margin-right: 24px;
}
.page-content .settings_dispatcher .dispatcher_form_cover {
  overflow: visible;
}
.page-content .settings_dispatcher .dispatcher_input_cover {
  position: relative;
  width: 400px;
  margin-right: 50px;
}
.page-content .settings_dispatcher .dispatcher_input_cover .settings_info_show {
  border: none;
  background-color: #3F51B5;
  border-radius: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 20px;
  right: 0;
}
.page-content .settings_dispatcher .dispatcher_input_cover .settings_info_show::after {
  content: '?';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
}
.page-content .settings_dispatcher .dispatcher_input_cover .settings_info {
  position: absolute;
  right: -305px;
  top: 12px;
  min-width: 250px;
  max-width: 250px;
  background-color: #E2E6FF;
  padding: 20px;
  font-size: 14px;
  z-index: 100;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
}
.page-content .settings_dispatcher .dispatcher_input_cover .settings_info::before {
  position: absolute;
  display: block;
  content: "";
  left: -14px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 14px 8px 0;
  border-color: transparent #e2e6ff transparent transparent;
}
.page-content .settings_dispatcher .dispatcher_input_cover .invisible {
  display: none;
}
.page-content .settings_dispatcher .button_cover {
  margin: 40px 0;
}
.reports {
  padding: 0 24px;
}
.reports > b {
  margin: 20px 0;
  display: inline-block;
  margin-right: 24px;
}
.reports .dispatcher_form_cover {
  width: 300px;
}
.reports .dispatcher_select_cover {
  width: 100%;
}
.reports .dispatcher_select_cover:not(:first-child) {
  width: 47%;
}
.reports .dispatcher_select_cover:not(:nth-child(2n + 1)) {
  margin-right: 10px;
}
.reports .button_cover {
  margin-top: 20px;
}
.dispatcher_modal .modal-title {
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 25px;
}
.dispatcher_modal .button_cover {
  text-align: right;
}
.dispatcher_modal .button_delete {
  color: #FF6A7C;
}
.edit_small_modal {
  width: 360px;
}
.edit_small_modal .dispatcher_input_cover {
  width: 100%;
}
.edit_small_modal .dispatcher_select_cover {
  width: 100%;
}
.edit_small_modal .dispatcher_select_cover span.select2 {
  width: 100% !important;
}
.edit_map_modal {
  width: 70vw;
}
.edit_map_modal .dispatcher_input_cover {
  margin-right: 30px;
  width: 300px;
  vertical-align: middle;
}
.edit_map_modal .dispatcher_input_cover .mdl-textfield__input {
  padding-bottom: 11px;
}
.edit_map_modal .dispatcher_input_cover .mdl-textfield__label {
  top: 31px;
}
.edit_map_modal .dispatcher_input_cover.is-focused .mdl-textfield__label,
.edit_map_modal .dispatcher_input_cover.is-dirty .mdl-textfield__label,
.edit_map_modal .dispatcher_input_cover.has-placeholder .mdl-textfield__label {
  top: 4px;
}
.edit_map_modal .dispatcher_select_cover {
  width: 300px;
  margin-right: 30px;
}
.edit_map_modal .dispatcher_select_cover span.select2 {
  width: 300px !important;
}
.edit_map_modal .button_cover {
  text-align: right;
}
.dispatcher_modal_map {
  height: 300px;
}
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir=rtl] .multiselect {
  text-align: right;
}
[dir=rtl] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir=rtl] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir=rtl] .multiselect__content {
  text-align: right;
}
[dir=rtl] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir=rtl] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir=rtl] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
.documentation_page .doc_list {
  white-space: nowrap;
}
.documentation_page .doc_list > div {
  display: inline-block;
}
.documentation_page .doc_list button {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  padding: 0;
  border: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  text-align: center;
  transition: box-shadow 400ms;
}
.documentation_page .doc_list button i.material-icons {
  line-height: 30px;
  transition: all 400ms;
}
.documentation_page .doc_list button.doc_status {
  border-radius: 50%;
  background: #C4C4C4;
}
.documentation_page .doc_list button.doc_status i.material-icons {
  color: #fff;
}
.documentation_page .doc_list button.doc_status.accepted {
  background: #17D435;
}
.documentation_page .doc_list button.doc_status.not_accepted {
  background: #EB3535;
}
.documentation_page .doc_list button.doc_status:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.documentation_page .doc_list button.add_doc:hover i.material-icons {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
}
.documentation_page .doc_button_send,
.documentation_page .doc_completion {
  width: 230px;
  border-radius: 2px;
  text-transform: uppercase;
  border: 1px solid #3F51B5;
  line-height: 27px;
  box-shadow: none;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
}
.documentation_page .doc_button_send {
  color: #fff;
  cursor: pointer;
  background: #3F51B5;
}
.documentation_page .doc_completion {
  background: #fff;
  color: #3F51B5;
}
.documentation_page .mdl-button.red.doc_list_delete {
  color: #ffffff;
}
.documentation_page .mdl-button.red.doc_list_delete:hover {
  background-color: #3f51b5;
}
.documentation_page .doc_list_accept {
  border-radius: 2px;
  text-transform: uppercase;
  color: #fff;
  line-height: 27px;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  background: #17D435;
  cursor: pointer;
  border: none;
  padding: 1px 15px;
}
.mdl-button--colored.red {
  background: #EB3535;
}
.mdl-button--colored.red:hover {
  background: #EB3535;
}
.mdl-button--colored.green {
  background: #17D435;
}
.mdl-button--colored.green:hover {
  background: #17D435;
}
#add_document_modal,
#document_rework_modal {
  width: 360px;
}
#add_document_modal .mdl-textfield,
#document_rework_modal .mdl-textfield {
  width: 100%;
}
#document_more_modal .doc_images a,
#document_more_modal .doc_images img {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.doc_download {
  color: #ffffff;
  text-decoration: none;
}
.monitoring_page {
  overflow: hidden;
  height: calc(100vh - 64px);
}
.monitoring_page .client_width {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitoring_page .request_width {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitoring_page .grid_cover_content {
  align-items: flex-end;
  height: 81px;
}
.monitoring_page .grid_cover_content .reset_filters {
  border: none;
  background-color: transparent;
  height: 100%;
  cursor: pointer;
}
.monitoring_page .grid_cover_content .reset_filters i {
  vertical-align: middle;
  color: #3F51B5;
}
.monitoring_page .grid_cover_content .reset_filters:active i {
  color: #CCCCCC;
}
.monitoring_page .drop_table_details {
  padding: 0;
}
.monitoring_page .drop_table_details:first-of-type {
  padding-left: 0;
}
.monitoring_page .drop_table_details:last-of-type {
  padding-right: 0;
}
.monitoring_page .mdl-data-table {
  border: none;
}
.multiselect {
  position: relative;
}
.multiselect.multiselect--active .multiselect__select {
  transform: none;
}
.multiselect .multiselect__select {
  z-index: 55;
  margin-right: -1px;
  top: 0;
  background: #fff;
}
.multiselect .multiselect__select:after {
  content: '';
  position: absolute;
  right: 38px;
  bottom: 1px;
  width: 36px;
  height: 40px;
  z-index: 1;
  background: rgba(0, 0, 0, 0);
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  background: -ms-linear-gradient(left, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#ffffff', GradientType=1);
}
.multiselect .multiselect__select:before {
  top: 25px;
  z-index: 2;
}
.multiselect .multiselect__tags {
  display: flex;
  overflow: hidden;
  /* position: relative;
    &:before{
      background: linear-gradient(to left, red, #000);
      position: absolute;
      right: 0;
      top: 0;
      height: 40px;
      width: 40px;
    }*/
}
.multiselect .multiselect__tags-wrap {
  white-space: nowrap;
  display: flex;
  height: 40px;
  align-items: flex-end;
}
.multiselect .multiselect__tags {
  min-height: 34px;
  height: 40px;
  padding: 0 40px 0 0px;
  border-radius: 0;
  border: none;
}
.multiselect .multiselect__tags .multiselect__tag {
  background-color: #CCCCCC;
}
.multiselect .multiselect__tags .multiselect__input {
  margin-bottom: 0;
  padding-top: 17px;
  padding-left: 0;
  font-size: 16px;
}
.multiselect .multiselect__tags .multiselect__input::-webkit-input-placeholder {
  color: #fff;
}
.multiselect .multiselect__tags .multiselect__input:-ms-input-placeholder {
  color: #fff;
}
.multiselect .multiselect__tags .multiselect__input::-ms-input-placeholder {
  color: #fff;
}
.multiselect .multiselect__tags .multiselect__input::placeholder {
  color: #fff;
}
.multiselect .multiselect__tags .multiselect__placeholder {
  margin-bottom: 0;
  padding-top: 17px;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
}
.multiselect .multiselect__content-wrapper {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
}
.multiselect .multiselect__content-wrapper .multiselect__option {
  overflow: hidden;
  white-space: normal;
}
.multiselect .multiselect__content-wrapper .multiselect__option::after {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-color: transparent;
  border: 1px solid #000000;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 80%;
}
.multiselect .multiselect__content-wrapper .multiselect__option .empty_list {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  pointer-events: none;
  cursor: initial;
  z-index: 1;
  right: 0;
  padding: 10px;
  top: 0;
  margin: 0;
}
.multiselect .multiselect__content-wrapper .multiselect__option--highlight {
  background-color: #CCCCCC;
}
.multiselect .multiselect__content-wrapper .multiselect__option--selected::after {
  background-image: url("/Content/images/check-bold.svg");
}
.multiselect .multiselect__content-wrapper .multiselect__option--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.multiselect .multiselect__content-wrapper .multiselect__option--disabled::after {
  display: none;
}
.monitoring_search {
  background: #fff;
  border-bottom: 1px solid #E4E4E4;
  height: 62px;
}
.column_screen {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 64px - 62px - 1px);
  position: relative;
}
.monitoring_table {
  flex-grow: 1;
  background: #fff;
  overflow-x: auto;
  height: calc(100% - 30px);
  position: relative;
  z-index: 3;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.monitoring_table.monitoring_table_left {
  width: 50%;
}
.monitoring_table .address_width {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitoring_table .action {
  height: 30px;
  line-height: 30px;
}
.monitoring_table tbody tr {
  cursor: pointer;
}
.monitoring_table tbody .inner_info {
  cursor: default;
}
.monitoring_table .mdl-data-table .icon_container {
  padding: 0;
  min-width: 32px;
}
.monitoring_table .sort_order {
  cursor: pointer;
  position: relative;
}
.monitoring_table .sort_order::before {
  position: absolute;
  left: -3px;
  bottom: 9px;
}
.monitoring_table .sort_order:first-child {
  padding: 3px 4px 4px 15px;
  text-align: center;
}
.monitoring_table .sort_order:first-child i {
  font-size: 22px;
}
.monitoring_table .sort_order:first-child::before {
  left: 5px;
}
.monitoring_table .sort_order span {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.54);
}
.monitoring_table .status_problem {
  background: blue;
  width: 11px;
  height: 11px;
  border-radius: 50%;
}
.monitoring_table .status {
  margin-bottom: 2px;
  vertical-align: middle;
  font-size: 14px;
}
.monitoring_table .status.online {
  color: #17d435;
}
.monitoring_table .status.offline {
  color: #35495e;
}
.monitoring_table .status.inactive {
  color: #eb3535;
}
.monitoring_table .no_results {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 1;
  text-align: center;
}
.monitoring_table .no_results span {
  font-size: 22px;
  font-weight: bold;
  display: block;
  padding-top: 100px;
}
.monitoring_table .dropdown_route_details {
  background-color: #3F51B5;
  height: 37px;
  width: 100%;
  padding: 0;
  border: none;
}
.monitoring_table .dropdown_route_details tbody tr {
  cursor: default;
}
.monitoring_table .history .event_cell {
  max-width: 250px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.monitoring_table .history .empty_segment_events {
  text-align: center;
}
.monitoring_table .history .comment_cell {
  max-width: 250px;
  white-space: normal;
}
.monitoring_table .history .mdl-data-table {
  background: transparent;
  border: none;
  margin: 0 0 20px 0;
}
.monitoring_table .history .mdl-data-table thead {
  padding-bottom: 0;
}
.monitoring_table .history .mdl-data-table th {
  padding-bottom: 2px;
  vertical-align: middle;
}
.monitoring_table .history .mdl-data-table td {
  border: none;
  height: auto;
}
.monitoring_table .history .mdl-data-table tbody tr {
  height: 35px;
  cursor: default;
}
.monitoring_table .history .mdl-data-table tbody td {
  padding: 0 15px;
}
.monitoring_table .history .mdl-data-table thead,
.monitoring_table .history .mdl-data-table tbody {
  background: transparent;
  border: 1px solid #ffffff;
}
.monitoring_table .history .mdl-data-table thead tr:hover,
.monitoring_table .history .mdl-data-table tbody tr:hover {
  background: transparent;
}
.monitoring_table .history .check {
  min-width: 23px;
  max-width: 23px;
  padding-left: 10px;
}
.monitoring_table .history .check i {
  font-size: 20px;
  vertical-align: middle;
}
.mdl-data-table tbody .teal_background {
  background-color: #B4C0FF;
}
.mdl-data-table tbody .teal_background td {
  border-color: #B4C0FF;
}
.mdl-data-table tbody .teal_background:hover {
  background-color: #B4C0FF;
}
.drag_handle {
  background-color: transparent;
}
.dragable_lcol {
  display: block;
  height: 100%;
  width: 6px;
  z-index: 5;
  left: 50%;
  margin-left: -5px;
  top: 0;
  position: absolute;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dragable_top {
  display: block;
  width: calc(100% - 415px);
  height: 17px;
  right: 0;
  left: auto !important;
  top: 50%;
  position: absolute;
  margin-top: 0;
  z-index: 5;
  cursor: row-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dropdown_route_stats {
  padding: 0 15px;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.route_stats_block {
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: #ffffff;
}
.route_stats_text {
  margin-left: 15px;
}
.mdl-data-table .drop_table_details {
  border-top: none;
  background: #B4C0FF;
}
.mdl-data-table .drop_table_inner thead tr:hover {
  background-color: #B4C0FF;
}
.mdl-data-table .drop_table_inner tr {
  background: #B4C0FF;
}
.mdl-data-table .drop_table_inner tr:not(.level3_opener) {
  cursor: default;
}
.mdl-data-table .drop_table_inner td {
  border: none;
}
.mdl-data-table .inner_table_item {
  border-top: 1px solid #ffffff;
}
.mdl-data-table tbody .level3 {
  display: none;
  cursor: default;
}
.mdl-data-table tbody .level3 td {
  padding-bottom: 20px;
  border-top: 2px solid #B4C0FF;
}
.mdl-data-table tbody .level3:hover {
  background-color: #B4C0FF;
}
.mdl-data-table tbody .level3_opener .icon_cell div {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("/Content/images/down_arrow.svg");
}
.mdl-data-table tbody .level3_opener.open .icon_cell div {
  background-image: url("/Content/images/up_arrow.svg");
}
.mdl-data-table tbody .level3_opener.open ~ .level3 {
  display: table-row;
}
.mdl-data-table tbody .level3_opener:hover + .problem_list_row {
  background-color: #eeeeee;
}
.mdl-data-table tbody .level3_opener:hover + .problem_list_row td {
  border-top: 2px solid #eeeeee;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: border-top;
}
.mdl-data-table tbody .problem_list_row {
  cursor: default;
}
.mdl-data-table tbody .problem_list_row td {
  border-top: 2px solid #B4C0FF;
  padding-bottom: 20px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: border-top;
}
.mdl-data-table tbody .problem_list_row:hover {
  background-color: #B4C0FF;
}
.list_problem .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 10px;
  margin-bottom: 2px;
  box-sizing: border-box;
}
.list_problem .item.attention {
  background: #FFCECE;
}
.list_problem .item.pending {
  background: #E2E6FF;
}
.list_problem .item .desc {
  display: flex;
  align-items: center;
}
.list_problem .item .desc .icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
.list_problem .item .desc .name {
  font-size: 13px;
  line-height: 15px;
}
.monitoring_tracking {
  flex-grow: 1;
  width: 50%;
  position: relative;
  z-index: 2;
}
.monitoring_tracking.monitoring_tracking_general {
  overflow: hidden;
}
.monitoring_map {
  background: #ccc;
  height: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 4;
  overflow: hidden;
}
.monitoring_problems {
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 50%;
  position: relative;
  z-index: 2;
}
.monitoring_problems tbody tr {
  cursor: default;
}
.monitoring_problems .attention {
  background: #FFCECE;
}
.monitoring_problems .pending {
  background: #E2E6FF;
}
.monitoring_problems .monitoring_table {
  max-width: 100%;
}
.monitoring_problems .monitoring_table .icon_container {
  padding: 0 0 0 10px;
}
.monitoring_problems .icon {
  width: 32px;
  height: 32px;
}
.multiselect_cover {
  position: relative;
}
.multiselect_cover::after {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  bottom: 20px;
  left: 0;
}
.multiselect_cover.is-focused .mdl-textfield__label {
  top: 15px;
  z-index: 50;
}
.monitoring_request .column_screen {
  height: calc(100vh - 64px);
}
.monitoring_request .monitoring_tracking {
  min-width: 500px;
  max-width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3;
}
.monitoring_request .monitoring_map {
  flex-grow: 1;
  width: 100%;
  display: block;
}
.monitoring_request .problem_tab {
  background-color: #ffffff;
  padding-top: 20px;
  position: relative;
  z-index: 5;
  min-height: 300px;
  max-height: 430px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.monitoring_request .problem_tab .list_problem {
  max-height: 110px;
  overflow-y: auto;
}
.monitoring_request .problem_tab .route_on_map {
  position: absolute;
  color: #3F51B5;
  background: #fff;
  border-radius: 35px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  bottom: -60px;
  right: 10px;
  z-index: 1;
}
.monitoring_request .problem_tab .route_on_map label {
  display: block;
  padding: 13px 16px;
  padding-top: 15px;
}
.monitoring_request .problem_tab .route_on_map .icon {
  position: relative;
  top: -1px;
}
.monitoring_request .problem_tab .route_on_map span {
  margin-right: 23px;
  display: inline-block;
  vertical-align: middle;
}
.monitoring_request .problem_specs {
  padding: 20px 0;
  position: relative;
  z-index: 2;
}
.monitoring_request .problem_specs_row {
  padding: 8px 30px;
}
.monitoring_request .problem_specs_row:first-child {
  padding-bottom: 0;
}
.monitoring_request .problem_specs_row .checkbox_cover {
  margin-left: 10px;
}
.monitoring_request .problem_specs_row .to_edit {
  float: right;
  margin-right: -16px;
}
.monitoring_request .instruction {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.monitoring_request .instruction:hover .instruction_popup {
  display: block;
}
.monitoring_request .instruction_popup {
  display: none;
  position: absolute;
  right: -305px;
  top: -5px;
  min-width: 250px;
  max-width: 250px;
  background-color: #E2E6FF;
  padding: 20px;
  font-size: 14px;
  z-index: 100;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
}
.monitoring_request .instruction_popup::before {
  position: absolute;
  display: block;
  content: "";
  left: -14px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 14px 8px 0;
  border-color: transparent #e2e6ff transparent transparent;
}
.monitoring_request .instruction_popup span {
  display: block;
  padding-bottom: 5px;
}
.monitoring_request .instruction_popup span:last-child {
  padding-bottom: 0;
}
.monitoring_request .monitoring_statistics {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: calc(100% - 480px);
  position: relative;
  z-index: 2;
}
.monitoring_request .monitoring_table {
  max-width: 100%;
  height: 100%;
}
.monitoring_request .monitoring_table .icon_container {
  padding: 0 10px 0 0;
  line-height: 10px;
}
.monitoring_request .monitoring_table .address_width {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.monitoring_request .monitoring_table .customer_width {
  max-width: 100px;
}
.monitoring_request .monitoring_table .customer_width .customer_with_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.monitoring_request .monitoring_table .comment_block {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  right: 7px;
}
.monitoring_request .monitoring_table .comment_additional {
  display: none;
  position: absolute;
  right: -135px;
  top: 35px;
  min-width: 250px;
  max-width: 250px;
  background-color: #E2E6FF;
  padding: 20px;
  font-size: 14px;
  z-index: 100;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  white-space: normal;
}
.monitoring_request .monitoring_table .comment_additional::before {
  position: absolute;
  display: block;
  content: "";
  left: 135px;
  top: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 14px 8px;
  border-color: transparent transparent #e2e6ff transparent;
}
.monitoring_request .monitoring_table .comment_info {
  color: #A5A5A5;
  font-size: 22px;
  display: block;
  width: 22px;
  height: 22px;
}
.monitoring_request .monitoring_table .comment_info:hover + .comment_additional {
  display: block;
}
.monitoring_request .history .count {
  padding-left: 15px;
  width: 20px;
}
.monitoring_request .history .wrap_cell {
  max-width: 400px;
  white-space: normal;
  word-wrap: break-word;
}
.monitoring_request .history_controls {
  text-align: right;
  padding-bottom: 20px;
}
.monitoring_request .monitoring_table,
.monitoring_request .table-cover {
  overflow: visible;
}
.monitoring_request .monitoring_table .mCustomScrollBox,
.monitoring_request .table-cover .mCustomScrollBox {
  overflow: visible;
}
.monitoring_request .monitoring_table .mCSB_container,
.monitoring_request .table-cover .mCSB_container {
  overflow: visible;
}
.monitoring_request .monitoring_statistics {
  overflow-y: auto;
  overflow-x: hidden;
}
.monitoring_request .tabs {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}
.monitoring_request .tabs > input {
  display: none;
}
.monitoring_request .tabs > div {
  display: none;
}
.monitoring_request .tabs > label {
  display: inline-block;
  margin: 16px 18px 0 18px;
  padding-bottom: 9px;
  text-align: center;
  cursor: pointer;
}
.monitoring_request .tabs > label:first-of-type {
  margin-left: 28px;
}
.monitoring_request .tabs > input:checked + label {
  border-bottom: 2px solid #3f51b5;
}
.monitoring_request #tab_1:checked ~ #txt_1,
.monitoring_request #tab_2:checked ~ #txt_2,
.monitoring_request #tab_3:checked ~ #txt_3,
.monitoring_request #tab_4:checked ~ #txt_4,
.monitoring_request #tab_5:checked ~ #txt_5,
.monitoring_request #tab_6:checked ~ #txt_6 {
  display: block;
}
.monitoring_request .content_tab {
  border-top: 1px solid #C0C0C0;
}
.monitoring_request .tab_inner .monitoring_table tbody tr {
  cursor: default;
}
.monitoring_request .tab_inner .monitoring_table .gas_station_table tr:not(.gas_station_total) {
  cursor: pointer;
}
.monitoring_request .button_cover {
  text-align: right;
}
.monitoring_request .tab_add_comment {
  margin: 10px;
  width: 155px;
}
.monitoring_request .tab_add_comment.loading {
  font-size: 0;
  pointer-events: none;
}
.monitoring_request .tab_add_comment.loading::before {
  content: 'cached';
  position: absolute;
  left: 50%;
  top: 50%;
  color: #ffffff;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;
  font-family: 'Material Icons';
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.monitoring_request .tab_no_content {
  display: block;
  padding: 10px;
  text-align: center;
}
.monitoring_request .gas_station {
  max-width: 400px;
  min-width: 400px;
  white-space: normal;
}
.monitoring_request .gas_station_total {
  background-color: #e2e6ff;
}
.monitoring_request .gas_station_total:hover {
  background-color: #e2e6ff;
}
.monitoring_request .gas_station_total .price {
  color: #5f5f5f;
}
.monitoring_request .wrap_cell {
  white-space: normal;
}
.confirm_reminder {
  color: #3f51b5;
  font-size: 14px;
}
.monitoring_route_header {
  display: flex;
  margin-left: 30px;
}
.monitoring_route_header .item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.monitoring_route_header .item .text {
  margin-left: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
@media (max-width: 1024px) {
  .monitoring_request .column_screen {
    height: calc(100vh - 57px);
  }
}
.page-content .new_route_page {
  /*.mdl-textfield__input{
      padding: 11px 0;
    }*/
  position: relative;
}
.page-content .new_route_page .show_hint {
  position: absolute;
  left: 45px;
  top: 25px;
  z-index: 1;
}
.page-content .new_route_page .date_and_time_cover {
  display: block;
}
.page-content .new_route_page .mdl-textfield-select {
  margin-top: -13px;
}
.page-content .new_route_page .mdl-textfield-select .mdl-textfield__label {
  top: 17px !important;
}
.page-content .new_route_page .page_big_map .big_map_cover {
  width: calc(100% - 755px);
}
.page-content .new_route_page .page_big_map .info_col_cover .content_cols {
  align-items: flex-end;
}
.page-content .new_route_page .page_big_map .info_col_cover .content_cols.top_content {
  align-items: flex-start;
}
.page-content .new_route_page .page_big_map .info_col_cover .content_cols .car {
  flex-wrap: wrap;
  align-items: flex-start;
}
.page-content .new_route_page .page_big_map .info_col_cover .content_cols .route_block {
  flex-wrap: wrap;
  justify-content: space-between;
}
.page-content .new_route_page .page_big_map .info_col_cover .content_cols .route_permit {
  width: 130px !important;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_col {
  width: 755px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block {
  padding-left: 30px;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .route_number {
  margin-top: 14px;
  width: 100%;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .car .car_number {
  width: 155px;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .car .car_number .select2 {
  width: 100% !important;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .car .car_number,
.page-content .new_route_page .page_big_map .info_col_cover .info_block .car .temperature {
  width: 130px;
  margin: 0 10px;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .trailer {
  width: 48%;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .trailer .select2-container {
  width: 100% !important;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .temperature_block {
  justify-content: space-between;
}
.page-content .new_route_page .page_big_map .info_col_cover .info_block .temperature {
  width: 48%;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top {
  justify-content: flex-start;
  align-items: center;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .title {
  margin-right: 15px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .mdl-button {
  margin: 0 5px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .content_half {
  display: flex;
  align-items: center;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .bg_icon {
  background: #C5CEFF;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .bg_icon i {
  color: #3F51B5;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .point_divide {
  position: relative;
  margin: 0 25px;
  margin-left: 15px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .point_divide .point_buttons {
  position: absolute;
  right: -9px;
  bottom: 20px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .point_divide .point_buttons .mdl-button {
  min-width: auto;
  padding: 0 4px;
  height: 34px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_top .point_divide .point_buttons .mdl-button .material-icons {
  margin: 0;
  color: #3F51B5;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom {
  padding-left: 35px;
  align-items: center;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom .content_half.customer {
  width: 31%;
  margin-right: 10px;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom .content_half.customer .select2 {
  width: 100% !important;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom .content_half.start_way {
  width: 23%;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom .content_half.algorithm {
  width: 44%;
  justify-content: flex-end;
}
.page-content .new_route_page .page_big_map .info_col_cover .section_bottom .content_half.algorithm .material-icons {
  margin-top: 0;
}
.page-content .new_route_page .page_big_map .info_col_cover .gray_cols {
  margin-left: 35px;
  padding: 20px 20px;
  background: #E4E4E4;
}
.page-content .new_route_page .page_big_map .info_col_cover .gray_cols .checkbox_cover {
  margin-top: 10px;
}
.page-content .new_route_page select {
  border: none;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half {
  width: 33%;
  margin-right: 25px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half:last-of-type {
  margin-right: 0;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point {
  flex-grow: 1;
  position: relative;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .route_details {
  position: absolute;
  left: -20px;
  height: auto;
  line-height: normal;
  bottom: 27px;
  padding: 0;
  min-width: auto;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .route_details .material-icons {
  margin: 0;
  font-size: 14px;
  color: #A5A5A5;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .ab_points {
  width: 100%;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .ab_points input {
  padding-right: 55px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .ab_points .field-validation-error {
  bottom: 9px;
  left: 33px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .point_not_exactly {
  position: absolute;
  bottom: -8px;
  left: 35px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .point_buttons {
  position: absolute;
  right: -9px;
  bottom: 20px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .point_buttons .mdl-button {
  min-width: auto;
  padding: 0 4px;
  height: 34px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.point .point_buttons .mdl-button .material-icons {
  margin: 0;
  color: #3F51B5;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.purpose {
  width: 140px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.purpose .select2 {
  width: 100% !important;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.date {
  width: 140px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.date input[type=date] {
  margin: 0;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.date input[type=time] {
  border: none;
  padding: 5px;
  font-size: 16px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.date.date_short {
  width: 60px;
}
.page-content .new_route_page .content_cols.content_cols_3 .content_half.date.date_short .field-validation-error {
  line-height: 9px;
  bottom: -3px;
}
.page-content .new_route_page .route_list {
  max-height: calc(100vh - 64px - 115px);
  overflow-y: auto;
}
.page-content .new_route_page .route_list .select2-container--default .select2-results > .select2-results__options {
  max-height: 160px;
}
.side_menu .menu_lvl1_item {
  padding: 0;
}
.side_menu .menu_lvl1_item a {
  padding: 10px 12px;
  min-height: auto;
}
.side_menu .menu_lvl1_item > span {
  padding: 10px 7px;
}
.side_menu .menu_lvl1_item .mdl-list__item-icon {
  margin-right: 20px;
}
.side_menu .menu_lvl1_title {
  width: 100%;
}
.side_menu .menu_lvl2 {
  display: none;
}
.side_menu .menu_lvl2 {
  list-style: none;
  padding: 0;
  margin-top: 5px;
}
.side_menu .menu_lvl2 .menu_lvl2_item {
  padding: 0 9px 0 45px;
}
.side_menu .menu_lvl2 .menu_lvl2_item .mdl-list__item .mdl-list__item-primary-content {
  white-space: normal;
}
.side_menu .menu_lvl2 .menu_lvl2_item .mdl-list__item.active .mdl-list__item-primary-content {
  color: #00A0E8;
}
.side_menu .menu_lvl2 .menu_lvl2_item .mdl-list__item.active:before {
  display: none;
}
.side_menu .menu_lvl2 .menu_lvl2_item a {
  padding: 17px 0 17px 60px;
  font-size: 14px;
  min-height: 0;
}
.more_info_footer_button .support {
  display: block;
  height: 14px;
  line-height: 30px;
}
#company_data_ajax {
  width: 1200px;
}
#company_data_ajax h6 {
  margin-bottom: 0;
}
#company_data_ajax h6:first-child {
  margin-top: 40px;
}
#company_data_ajax .mdl-textfield {
  width: 100%;
}
#company_data_ajax .field_hint {
  width: 30%;
  position: relative;
  padding-right: 40px;
  margin-right: 40px;
  display: inline-block;
}
.save_settings {
  margin-top: 50px;
}
.hint_hover {
  position: absolute;
  top: 25px;
  right: 8px;
  cursor: pointer;
}
.hint_hover .material-icons {
  color: #3F51B5;
}
.hint_hover .hint_drop {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 190px;
  background: #E2E6FF;
  border-radius: 2px;
  padding: 17px 20px;
  color: #2E2E2E;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: none;
  z-index: 3;
}
.hint_hover:hover .hint_drop {
  display: block;
}
.page-content .carrier_cover .password_field {
  width: 18%;
  margin-right: 20px;
}
.page-content .carrier_cover .password_save {
  margin-left: 0;
}
.page-content .carrier_cover .phone_changing_field {
  width: 18%;
}
.page-content .phone_changing .mdl-textfield,
.page-content .password_changing .mdl-textfield {
  display: block;
}
.page-content .password_changing {
  width: 1200px;
  padding-bottom: 50px;
}
.page-content .password_changing h6 {
  margin-bottom: 0;
  margin-top: 40px;
}
.page-content .password_changing h6 b {
  margin-bottom: 0;
}
.page-content .regions {
  width: 67%;
}
.page-content .regions .mdl-textfield.longWidth {
  margin-top: 0;
}
.tasks {
  padding: 40px;
}
.tasks .tasks_table {
  border-collapse: collapse;
  margin-bottom: 60px;
  max-width: 500px;
}
.tasks .tasks_table td {
  padding: 10px 0;
  vertical-align: top;
}
.tasks .tasks_title {
  font-weight: bold;
  min-width: 200px;
}
.tasks .message_author {
  display: flex;
  flex-direction: row;
}
.tasks .message_author #edit_message {
  margin-left: auto;
}
.tasks .tasks_comments {
  max-width: 500px;
}
.tasks .tasks_message {
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(63, 81, 181, 0.5);
}
.tasks .message_area {
  margin-bottom: 20px;
}
.object_modal {
  width: 600px;
}
.object_modal .modal-title {
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  padding-bottom: 20px;
}
.object_modal .object_close {
  display: block;
  margin: 0 auto;
  width: 130px;
}
.object_modal .object_details strong {
  color: #000000;
}
.object_modal .object_services {
  padding-bottom: 25px;
}
.object_modal .object_services tr,
.object_modal .object_services td,
.object_modal .object_services th {
  height: 30px;
}
.object_modal .mdl-data-table .inner_data {
  padding-left: 0;
  padding-right: 0;
}
.object_modal .inner_data {
  text-align: left;
}
.object_modal .inner_data label {
  padding-left: 24px;
}
.object_modal .inner_services {
  width: 100%;
  margin-top: 6px;
}
.object_modal .inner_services tr {
  height: 24px;
}
.object_modal .inner_services td {
  height: 24px;
  border: none;
}
.object_modal .inner_services .service_item_inner {
  text-align: left;
  width: 218px;
}
.object_modal .inner_services .service_item_inner .dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #000000;
  border-radius: 50%;
  margin: 5px 5px 3px 0;
}
.object_modal .inner_services .services_price {
  width: 118px;
  padding-right: 0;
}
.object_modal .input_coords {
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: #34495e;
  padding: 0;
}
.object_modal .copy_coords {
  width: 25px;
  height: 30px;
  color: #34495e;
  background-color: transparent;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  padding: 0;
}
.driver_card .problem_tab {
  min-height: auto;
  max-height: none;
}
.driver_card .problem_specs {
  padding-top: 0;
}
.driver_card #txt_1 .dispatcher_select_cover {
  margin-right: 15px;
}
.driver_card #txt_1 .all_sum {
  padding: 15px 25px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
  background-color: #B4C0FF;
  font-size: 20px;
}
.driver_card .accruals_tab {
  height: calc(100% - 48px);
}
.driver_card .accruals_tab .accruals_content {
  overflow-y: auto;
  padding-bottom: 50px;
  position: relative;
  height: calc(100% - 141px);
}
.driver_card .accruals_tab .monitoring_table {
  overflow-y: auto;
}
.driver_card .accruals_tab .monitoring_table .table-cover {
  overflow-y: auto;
}
.driver_card #txt_2 .grid_cover_content .col-30:nth-child(1) {
  max-width: 255px;
}
.driver_card #txt_2 .grid_cover_content .col-30:nth-child(1) .select2 {
  width: 100% !important;
}
.driver_card #txt_2 .grid_cover_content .col-30:nth-child(2),
.driver_card #txt_2 .grid_cover_content .col-30:nth-child(3) {
  max-width: 165px;
}
.driver_card #txt_2 .level2 {
  display: none;
}
.driver_card #txt_2 .level2.opened {
  display: table-row;
}
.driver_card #txt_2 .level2 .comment_col {
  width: 60%;
}
.instructor_block {
  padding: 25px;
}
.instructor_block .instructor_field {
  display: block;
}
.word_break {
  word-wrap: break-word;
}
input,
textarea,
select,
button {
  outline: none !important;
}
input:focus,
textarea:focus,
select:focus,
button:focus,
input:active,
textarea:active,
select:active,
button:active {
  outline: none !important;
}
button {
  border: none;
}
.add_route {
  position: relative;
}
.add_route .training {
  position: absolute;
  right: 720px;
  width: 40px;
  top: 12px;
  height: 40px;
  background: url("../../img/training.svg");
  cursor: pointer;
}
.add_route .training#training_route_templates {
  right: 420px;
  top: 77px;
}
.add_route #myMap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ccc;
}
.add_route .double_input {
  display: flex;
  justify-content: space-between;
}
.add_route .double_input > div {
  flex-grow: 1;
  max-width: calc(50% - 7px);
  min-width: calc(50% - 7px);
}
.add_route .input_cover,
.add_route .select_cover,
.add_route .switch_cover {
  position: relative;
}
.add_route .input_cover label,
.add_route .select_cover label,
.add_route .switch_cover label {
  margin: 10px 5px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 16px;
  color: #9DA3B0;
  display: block;
}
.add_route .input_cover label i,
.add_route .select_cover label i,
.add_route .switch_cover label i {
  font-style: normal;
  color: #F56C6C;
}
.add_route .input_cover input,
.add_route .select_cover input,
.add_route .switch_cover input,
.add_route .input_cover textarea,
.add_route .select_cover textarea,
.add_route .switch_cover textarea {
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #606266;
  padding: 8px 15px;
  outline: none !important;
}
.add_route .input_cover input:focus,
.add_route .select_cover input:focus,
.add_route .switch_cover input:focus,
.add_route .input_cover textarea:focus,
.add_route .select_cover textarea:focus,
.add_route .switch_cover textarea:focus {
  border-color: #409EFF;
}
.add_route .input_cover textarea,
.add_route .select_cover textarea,
.add_route .switch_cover textarea {
  height: 82px;
  resize: none;
}
.add_route .input_cover .address_input,
.add_route .select_cover .address_input,
.add_route .switch_cover .address_input {
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #606266;
  display: flex;
}
.add_route .input_cover .address_input input,
.add_route .select_cover .address_input input,
.add_route .switch_cover .address_input input {
  padding: 8px 15px;
  border: none;
  border-top: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  flex-grow: 1;
  border-radius: 0;
}
.add_route .input_cover .address_input .point,
.add_route .select_cover .address_input .point,
.add_route .switch_cover .address_input .point {
  width: 32px;
  background: #C6E2FF;
  border: 1px solid #409EFF;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: #409EFF;
}
.add_route .input_cover .address_input .copi_icon,
.add_route .select_cover .address_input .copi_icon,
.add_route .switch_cover .address_input .copi_icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #DCDFE6;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
}
.add_route .input_cover .address_input .copi_icon i,
.add_route .select_cover .address_input .copi_icon i,
.add_route .switch_cover .address_input .copi_icon i {
  color: #409EFF;
  font-size: 20px;
}
.add_route .input_cover .address_input.disabled,
.add_route .select_cover .address_input.disabled,
.add_route .switch_cover .address_input.disabled {
  pointer-events: none;
  cursor: default;
  background: #DCDFE6;
}
.add_route .input_cover .address_input.disabled input,
.add_route .select_cover .address_input.disabled input,
.add_route .switch_cover .address_input.disabled input {
  background: #DCDFE6;
  border-color: #9DA3B0;
}
.add_route .input_cover .address_input.disabled .copi_icon,
.add_route .select_cover .address_input.disabled .copi_icon,
.add_route .switch_cover .address_input.disabled .copi_icon {
  background: #DCDFE6;
  border-color: #9DA3B0;
}
.add_route .input_cover .address_input.disabled .copi_icon i,
.add_route .select_cover .address_input.disabled .copi_icon i,
.add_route .switch_cover .address_input.disabled .copi_icon i {
  opacity: 0;
}
.add_route .input_cover .address_input.disabled .point,
.add_route .select_cover .address_input.disabled .point,
.add_route .switch_cover .address_input.disabled .point {
  background: #DCDFE6;
  color: #9DA3B0;
  border-color: #9DA3B0;
}
.add_route .input_cover.disabled .select2 .select2-selection--single,
.add_route .select_cover.disabled .select2 .select2-selection--single,
.add_route .switch_cover.disabled .select2 .select2-selection--single {
  background: #DCDFE6;
  border-color: #9DA3B0;
}
.add_route .input_cover.error .select2-container--default .select2-selection--single,
.add_route .select_cover.error .select2-container--default .select2-selection--single,
.add_route .switch_cover.error .select2-container--default .select2-selection--single,
.add_route .input_cover.error > input,
.add_route .select_cover.error > input,
.add_route .switch_cover.error > input {
  border-color: red;
}
.add_route .input_cover.error .address_input input,
.add_route .select_cover.error .address_input input,
.add_route .switch_cover.error .address_input input,
.add_route .input_cover.error .address_input .copi_icon,
.add_route .select_cover.error .address_input .copi_icon,
.add_route .switch_cover.error .address_input .copi_icon {
  border-color: red;
}
.add_route .input_cover.error .field-validation-error,
.add_route .select_cover.error .field-validation-error,
.add_route .switch_cover.error .field-validation-error {
  display: none !important;
}
.add_route .input_cover .field-validation-error,
.add_route .select_cover .field-validation-error,
.add_route .switch_cover .field-validation-error {
  position: absolute;
  left: 0;
  bottom: -18px;
  color: red;
}
.add_route .checkbox_cover {
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  padding: 0 10px;
  align-items: center;
}
.add_route .checkbox_cover label {
  margin: 10px 5px;
  font-size: 14px;
  line-height: 16px;
  color: #9DA3B0;
  display: inline-block;
}
.add_route .checkbox_cover label i {
  font-style: normal;
  color: #F56C6C;
}
.add_route .checkbox_cover input {
  margin-right: 15px;
  cursor: pointer;
}
.add_route .column_address {
  display: flex;
}
.add_route .column_address .address_list {
  flex-grow: 1;
}
.add_route .switch_cover {
  margin-left: 22px;
  align-self: flex-end;
  position: relative;
  top: -31px;
}
.add_route .switch_cover .switch {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 32px;
  margin: 0;
}
.add_route .switch_cover .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.add_route .switch_cover .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.add_route .switch_cover .slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 7px;
  bottom: 7px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.add_route .switch_cover input:checked + .slider {
  background-color: #C6E2FF;
}
.add_route .switch_cover input:focus + .slider {
  box-shadow: 0 0 1px #C6E2FF;
}
.add_route .switch_cover input:checked + .slider:before {
  -webkit-transform: translateX(31px);
  -ms-transform: translateX(31px);
  transform: translateX(31px);
  background-color: #409EFF;
}
.add_route .switch_cover .slider.round {
  border-radius: 34px;
}
.add_route .switch_cover .slider.round:before {
  border-radius: 50%;
}
.add_route .address_list .input_cover label {
  margin-left: 39px;
}
.add_route .address_list.disabled_address .column_address .info_block {
  width: 100%;
}
.add_route .address_list.disabled_address .column_address > div:nth-child(2) {
  display: none;
}
.add_route .address_list.disabled_address .column_address:nth-child(1n + 2) .address_input {
  pointer-events: none;
  cursor: default;
  background: #DCDFE6;
}
.add_route .address_list.disabled_address .column_address:nth-child(1n + 2) .address_input input {
  background: #DCDFE6;
  border-color: #9DA3B0;
}
.add_route .address_list.disabled_address .column_address:nth-child(1n + 2) .address_input .copi_icon {
  background: #DCDFE6;
  border-color: #9DA3B0;
}
.add_route .address_list.disabled_address .column_address:nth-child(1n + 2) .address_input .copi_icon i {
  opacity: 0;
}
.add_route .address_list.disabled_address .column_address:nth-child(1n + 2) .address_input .point {
  background: #DCDFE6;
  color: #9DA3B0;
  border-color: #9DA3B0;
}
.add_route .supporting_point_block {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
  position: relative;
}
.add_route .supporting_point_block .remove_step {
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  padding: 3px 16px;
  color: #F56C6C;
  background: rgba(245, 108, 108, 0.3);
  border: 1px solid #F56C6C;
  margin-right: auto;
}
.add_route .supporting_point_block .supporting_point {
  background: #C6E2FF;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #409EFF;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  padding: 3px 16px;
  color: #409EFF;
}
.add_route .supporting_point_block .supporting_point i {
  color: #409EFF;
  margin-right: 14px;
}
.add_route .supporting_point_block .add_step-error {
  color: red;
  position: absolute;
  right: 0;
  bottom: 40px;
}
.add_route .bottom_cover {
  margin-top: 30px;
}
.add_route .bottom_cover .btn {
  width: 100%;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 8px 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.add_route .bottom_cover .btn_orange {
  background: #EBB563;
  color: #FFFFFF;
}
.add_route .bottom_cover.double_btn {
  display: flex;
  align-items: flex-start;
}
.add_route .bottom_cover.double_btn .btn {
  width: auto;
  flex-grow: 1;
  margin-right: 15px;
}
.add_route .bottom_cover.double_btn .k-button {
  width: 80px;
  min-width: 80px;
  height: 32px;
}
.add_route .select2 {
  width: 100% !important;
}
.add_route .select2-container--default .select2-selection--single {
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  height: 34px;
}
.add_route .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
  padding-left: 17px;
  color: #606266;
}
.add_route .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 32px;
  width: 28px;
}
.add_route .select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin: 0;
  display: inline-block;
  top: 5px;
  right: -5px;
  left: initial;
  border: none;
  position: relative;
  width: 12px;
  height: 8px;
  background: url(../../img/down.png);
}
.add_route.cursor_marker {
  cursor: url("../../img/cursor_map.cur"), url("../../img/cursor_map.svg"), crosshair !important;
}
#button_create_my_location {
  background: #C6E2FF;
  border: 1px solid #409EFF;
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 5px;
  padding-top: 6px;
  cursor: pointer;
}
#button_create_my_location .material-icons {
  color: #409EFF;
  font-size: 16px;
}
.select2-container--default .select2-dropdown {
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
}
.select2-container--default .select2-dropdown .select2-search--dropdown {
  background: transparent;
  padding: 0 15px;
}
.select2-container--default .select2-dropdown .select2-results {
  top: 30px;
  border-radius: 0 0 4px 4px;
}
.add_route_abs {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 20px;
  width: 400px;
  box-sizing: border-box;
}
.abs_map_button {
  position: absolute;
  right: 420px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 20px;
}
.abs_map_button .btn_search {
  padding: 3px 17px;
  background: #C6E2FF;
  border: 1px solid #409EFF;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #409EFF;
  cursor: pointer;
}
.abs_map_button .btn_search i {
  color: #409EFF;
  margin-right: 15px;
}
.template_list_conteiner {
  position: absolute;
  right: 420px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px 20px;
  padding-top: 15px;
  width: 500px;
}
.template_list_conteiner .title {
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  color: #272A29;
  margin-bottom: 15px;
}
.template_list_conteiner .template_list {
  padding-bottom: 10px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.template_list_conteiner .template_list .item {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.template_list_conteiner .template_list .item input[type="radio"] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.template_list_conteiner .template_list .item .grow {
  flex-grow: 1;
  padding: 0 15px;
}
.template_list_conteiner .template_list .item .radio_fake {
  width: 14px;
  min-width: 14px;
  height: 14px;
  border: 1px solid #EDEFF2;
  border-radius: 50%;
  margin: 0;
  position: relative;
}
.template_list_conteiner .template_list .item .icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 3;
}
.template_list_conteiner .template_list .item.status_tried .icon {
  background: url("/img/status_tried.svg");
}
.template_list_conteiner .template_list .item.status_middle .icon {
  background: url("/img/status_middle.svg");
}
.template_list_conteiner .template_list .item.status_untested .icon {
  background: url("/img/status_untested.svg");
}
.template_list_conteiner .template_list .item.active {
  border-color: #409EFF;
}
.template_list_conteiner .template_list .item.active .radio_fake {
  border-color: #409EFF;
}
.template_list_conteiner .template_list .item.active .radio_fake:before {
  content: '';
  pointer-events: none;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 11px;
  height: 11px;
  background: #409EFF;
  border-radius: 50%;
}
.template_list_conteiner .template_list .item .address {
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #606266;
}
.template_list_conteiner .template_list .item .deck {
  font-size: 14px;
  line-height: 16px;
  color: #9DA3B0;
}
.template_list_conteiner .bottom-part {
  display: flex;
  justify-content: flex-end;
}
.template_list_conteiner .bottom-part .template_not_use {
  background: rgba(245, 108, 108, 0.3);
  border: 1px solid #F56C6C;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #F56C6C;
  align-items: center;
  display: flex;
  height: 32px;
  cursor: pointer;
}
.template_list_conteiner .bottom-part .template_not_use i {
  color: #F56C6C;
  margin-right: 12px;
  font-size: 18px;
}
.select_route_points_modal {
  cursor: pointer;
}
.select_cover .field-validation-error {
  display: none !important;
}
.page-content.loading .loading_page {
  display: block !important;
}
#route_preservation_modal {
  width: 360px;
}
#route_preservation_modal .modal_title {
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  color: #272A29;
  font-weight: normal;
}
#route_preservation_modal .input_cover,
#route_preservation_modal .select_cover,
#route_preservation_modal .switch_cover {
  position: relative;
}
#route_preservation_modal .input_cover label,
#route_preservation_modal .select_cover label,
#route_preservation_modal .switch_cover label {
  margin: 10px 5px;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 16px;
  color: #9DA3B0;
  display: block;
}
#route_preservation_modal .input_cover label i,
#route_preservation_modal .select_cover label i,
#route_preservation_modal .switch_cover label i {
  font-style: normal;
  color: #F56C6C;
}
#route_preservation_modal .input_cover input,
#route_preservation_modal .select_cover input,
#route_preservation_modal .switch_cover input,
#route_preservation_modal .input_cover textarea,
#route_preservation_modal .select_cover textarea,
#route_preservation_modal .switch_cover textarea {
  background: #FFFFFF;
  width: 100%;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #606266;
  padding: 8px 15px;
  outline: none !important;
  resize: none;
}
#route_preservation_modal .input_cover input:focus,
#route_preservation_modal .select_cover input:focus,
#route_preservation_modal .switch_cover input:focus,
#route_preservation_modal .input_cover textarea:focus,
#route_preservation_modal .select_cover textarea:focus,
#route_preservation_modal .switch_cover textarea:focus {
  border-color: #409EFF;
}
#route_preservation_modal .input_cover.error .select2-container--default .select2-selection--single,
#route_preservation_modal .select_cover.error .select2-container--default .select2-selection--single,
#route_preservation_modal .switch_cover.error .select2-container--default .select2-selection--single,
#route_preservation_modal .input_cover.error > input,
#route_preservation_modal .select_cover.error > input,
#route_preservation_modal .switch_cover.error > input {
  border-color: red;
}
#route_preservation_modal .input_cover.error .address_input input,
#route_preservation_modal .select_cover.error .address_input input,
#route_preservation_modal .switch_cover.error .address_input input,
#route_preservation_modal .input_cover.error .address_input .copi_icon,
#route_preservation_modal .select_cover.error .address_input .copi_icon,
#route_preservation_modal .switch_cover.error .address_input .copi_icon {
  border-color: red;
}
#route_preservation_modal .input_cover.error .field-validation-error,
#route_preservation_modal .select_cover.error .field-validation-error,
#route_preservation_modal .switch_cover.error .field-validation-error {
  display: none !important;
}
#route_preservation_modal .input_cover .field-validation-error,
#route_preservation_modal .select_cover .field-validation-error,
#route_preservation_modal .switch_cover .field-validation-error {
  position: absolute;
  left: 0;
  bottom: -18px;
  color: red;
}
#route_preservation_modal .modal_footer {
  display: flex;
  justify-content: space-between;
}
#route_preservation_modal .modal_footer button {
  height: 32px;
  padding: 0 15px !important;
  margin: 0 !important;
}
.box-modal.select_template#selectSegmentTemplateModal {
  width: 475px;
}
.line_address .column_address > .info_block {
  flex-grow: 1;
}
.calculation_result_map_button {
  position: absolute;
  right: 620px;
  top: 10px;
  width: 275px;
}
.calculation_result_map_button .item {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.calculation_result_map_button .item .title {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #9DA3B0;
  flex-grow: 1;
  margin-right: 15px;
}
.calculation_result_map_button .item .switch_cover {
  min-width: 64px;
  margin: 0;
  top: 0;
  max-width: 64px;
}
.calculation_result {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 0;
  width: 600px;
  box-sizing: border-box;
  max-height: calc(100vh - 64px - 20px);
}
.calculation_result .table-cover {
  max-height: calc(100vh - 166px - 64px - 20px - 84px);
  overflow-x: hidden;
  overflow-y: auto;
}
.calculation_result .calculation_result_top {
  padding: 30px 20px;
}
.calculation_result .calculation_result_top .basic_info {
  display: flex;
  margin-bottom: 30px;
}
.calculation_result .calculation_result_top .basic_info .basic_info_item {
  margin-right: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: #272A29;
}
.calculation_result .calculation_result_top .basic_info .basic_info_item .material-icons {
  color: #EBB563;
  margin-right: 10px;
}
.calculation_result .calculation_result_top .point_list {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.calculation_result .calculation_result_top .point_list .point {
  margin-right: 5px;
  position: relative;
  width: 125px;
  min-width: 125px;
}
.calculation_result .calculation_result_top .point_list .point i {
  font-style: normal;
  width: 20px;
  height: 20px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBB563;
  border-radius: 50%;
  color: #606266;
}
.calculation_result .calculation_result_top .point_list .point span {
  text-align: center;
  transform: translateX(-50px);
  display: inline-block;
  padding: 5px;
  padding-bottom: 0;
  font-size: 10px;
  line-height: 12px;
}
.calculation_result .calculation_result_top .point_list .point:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 25px;
  height: 1px;
  width: 100px;
  background: #606266;
}
.calculation_result .calculation_result_top .point_list .point:last-of-type:after {
  display: none;
}
.calculation_result .table-cover {
  width: 100%;
}
.calculation_result .table-cover table {
  margin: 0;
  min-width: 100%;
  border-collapse: collapse;
}
.calculation_result .table-cover table thead {
  background: rgba(235, 181, 99, 0.3);
  border: 1px solid #DCDFE6;
}
.calculation_result .table-cover table tbody tr {
  background: #FFFFFF;
  box-shadow: none;
}
.calculation_result .table-cover table th,
.calculation_result .table-cover table td {
  text-align: right;
  font-size: 12px;
  line-height: 14px;
  color: #272A29;
  padding: 4px 13px;
  vertical-align: middle;
}
.calculation_result .table-cover table th:first-of-type,
.calculation_result .table-cover table td:first-of-type {
  text-align: left;
  padding-left: 20px;
}
.calculation_result .table-cover table th:last-of-type,
.calculation_result .table-cover table td:last-of-type {
  padding-right: 20px;
}
.calculation_result .table-cover table th {
  font-style: normal;
}
.calculation_result .table-cover table td:last-of-type p {
  padding: 0;
  margin: 0;
  line-height: 14px;
  font-size: 12px;
}
.calculation_result .table-cover table td:last-of-type span {
  font-size: 10px;
  line-height: 12px;
  color: #9DA3B0;
}
.calculation_result .table-cover table .result_tr {
  background: rgba(235, 181, 99, 0.3);
  border: 1px solid #DCDFE6;
}
.calculation_result .table-cover table .result_tr td {
  font-weight: bold;
}
.calculation_result .calculation_result_footer {
  padding: 20px;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.calculation_result .calculation_result_footer button {
  padding: 8px 15px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.calculation_result .calculation_result_footer button > img,
.calculation_result .calculation_result_footer button > i {
  margin-right: 9px;
}
.calculation_result .calculation_result_footer button.edit {
  color: #409EFF;
  background: #C6E2FF;
  border: 1px solid #409EFF;
}
.calculation_result .calculation_result_footer button.copy {
  color: #33A946;
  background: rgba(51, 169, 70, 0.3);
  border: 1px solid #33A946;
}
.calculation_result .calculation_result_footer button.copy i {
  font-size: 16px;
  color: #33A946;
}
.calculation_result .calculation_result_footer button.close_and_save {
  background: #EBB563;
  color: #FFFFFF;
}
#selectRoutePointModal .box-modal {
  width: 700px;
}
#selectRoutePointModal .k-grid-content.k-auto-scrollable {
  max-height: calc(100vh - 75px - 42px - 45px - 56px - 48px - 35px - 24px - 4px) !important;
}
#selectRoutePointModal .k-grid {
  max-height: calc(100vh - 48px - 35px - 35px - 35px - 24px - 4px) !important;
}
.training_route_building_cover {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.slider_training {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.slider_training .list_slide {
  width: 100%;
  height: 100%;
}
.slider_training .slide {
  width: 100%;
  height: 100%;
  background-color: #cccccc;
  background-size: cover !important;
  background-position: top right !important;
  display: none;
}
.slider_training .slide.active {
  display: block;
}
.slider_training .text {
  position: absolute;
  left: 60px;
  max-width: 80%;
}
.slider_training .text > div {
  display: flex;
  margin-bottom: 30px;
}
.slider_training .text > div > div {
  flex-grow: 1;
  color: #fff;
  font-size: 24px;
  line-height: 28.13px;
  font-weight: 400;
}
.slider_training .text span {
  font-size: 32px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  background: #EBB563;
  width: 48px;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.slider_training .nav_slider {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  padding-top: 10px;
  box-sizing: border-box;
}
.slider_training .nav_slider .right {
  display: flex;
  position: absolute;
  right: 30px;
  bottom: 37px;
}
.slider_training .nav_slider .right .btn {
  background: #EBB563;
  border-radius: 4px;
  height: 60px;
  text-align: center;
  width: 200px;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  padding: 20px 0;
  box-sizing: border-box;
  cursor: pointer;
}
.slider_training .nav_slider .nav {
  display: flex;
  align-items: center;
}
.slider_training .nav_slider .nav .prev,
.slider_training .nav_slider .nav .next {
  background: url("../../img/nav_slider.svg") center no-repeat;
  width: 68px;
  height: 68px;
  cursor: pointer;
}
.slider_training .nav_slider .nav .prev.disabled,
.slider_training .nav_slider .nav .next.disabled {
  cursor: default;
}
.slider_training .nav_slider .nav .next {
  transform: rotate(180deg);
}
.slider_training .nav_slider .nav .position {
  font-weight: bold;
  padding: 13px 0;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #EBB563;
  background: #FFFFFF;
  border: 1px solid #EBB563;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100px;
  height: 60px;
  box-sizing: border-box;
}
.slider_training .templates_slider {
  justify-content: flex-start;
}
.slider_training .templates_slider .right {
  position: static;
}
.training_route_templates_cover {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
@media (max-height: 750px) and (max-width: 1600px) {
  .slider_training .text > div > div {
    font-size: 15px;
    line-height: 20px;
  }
}
.loading_page {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.loader {
  position: relative;
  width: 78px;
  height: 78px;
}
.loader .wBall {
  position: absolute;
  width: 74px;
  height: 74px;
  opacity: 0;
  transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  animation: orbit 6.96s infinite;
  -o-animation: orbit 6.96s infinite;
  -ms-animation: orbit 6.96s infinite;
  -webkit-animation: orbit 6.96s infinite;
  -moz-animation: orbit 6.96s infinite;
}
.loader .wBall .wInnerBall {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #000000;
  left: 0px;
  top: 0px;
  border-radius: 10px;
}
.loader #wBall_1 {
  animation-delay: 1.52s;
  -o-animation-delay: 1.52s;
  -ms-animation-delay: 1.52s;
  -webkit-animation-delay: 1.52s;
  -moz-animation-delay: 1.52s;
}
.loader #wBall_2 {
  animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
}
.loader #wBall_3 {
  animation-delay: 0.61s;
  -o-animation-delay: 0.61s;
  -ms-animation-delay: 0.61s;
  -webkit-animation-delay: 0.61s;
  -moz-animation-delay: 0.61s;
}
.loader #wBall_4 {
  animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -moz-animation-delay: 0.91s;
}
.loader #wBall_5 {
  animation-delay: 1.22s;
  -o-animation-delay: 1.22s;
  -ms-animation-delay: 1.22s;
  -webkit-animation-delay: 1.22s;
  -moz-animation-delay: 1.22s;
}
@keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
@-o-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -o-transform: rotate(180deg);
    -o-animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    -o-transform: rotate(300deg);
    -o-animation-timing-function: linear;
    -o-origin: 0%;
  }
  30% {
    opacity: 1;
    -o-transform: rotate(410deg);
    -o-animation-timing-function: ease-in-out;
    -o-origin: 7%;
  }
  39% {
    opacity: 1;
    -o-transform: rotate(645deg);
    -o-animation-timing-function: linear;
    -o-origin: 30%;
  }
  70% {
    opacity: 1;
    -o-transform: rotate(770deg);
    -o-animation-timing-function: ease-out;
    -o-origin: 39%;
  }
  75% {
    opacity: 1;
    -o-transform: rotate(900deg);
    -o-animation-timing-function: ease-out;
    -o-origin: 70%;
  }
  76% {
    opacity: 0;
    -o-transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    -o-transform: rotate(900deg);
  }
}
@-ms-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -ms-transform: rotate(180deg);
    -ms-animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    -ms-transform: rotate(300deg);
    -ms-animation-timing-function: linear;
    -ms-origin: 0%;
  }
  30% {
    opacity: 1;
    -ms-transform: rotate(410deg);
    -ms-animation-timing-function: ease-in-out;
    -ms-origin: 7%;
  }
  39% {
    opacity: 1;
    -ms-transform: rotate(645deg);
    -ms-animation-timing-function: linear;
    -ms-origin: 30%;
  }
  70% {
    opacity: 1;
    -ms-transform: rotate(770deg);
    -ms-animation-timing-function: ease-out;
    -ms-origin: 39%;
  }
  75% {
    opacity: 1;
    -ms-transform: rotate(900deg);
    -ms-animation-timing-function: ease-out;
    -ms-origin: 70%;
  }
  76% {
    opacity: 0;
    -ms-transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    -ms-transform: rotate(900deg);
  }
}
@-webkit-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -webkit-transform: rotate(180deg);
    -webkit-animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    -webkit-transform: rotate(300deg);
    -webkit-animation-timing-function: linear;
    -webkit-origin: 0%;
  }
  30% {
    opacity: 1;
    -webkit-transform: rotate(410deg);
    -webkit-animation-timing-function: ease-in-out;
    -webkit-origin: 7%;
  }
  39% {
    opacity: 1;
    -webkit-transform: rotate(645deg);
    -webkit-animation-timing-function: linear;
    -webkit-origin: 30%;
  }
  70% {
    opacity: 1;
    -webkit-transform: rotate(770deg);
    -webkit-animation-timing-function: ease-out;
    -webkit-origin: 39%;
  }
  75% {
    opacity: 1;
    -webkit-transform: rotate(900deg);
    -webkit-animation-timing-function: ease-out;
    -webkit-origin: 70%;
  }
  76% {
    opacity: 0;
    -webkit-transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(900deg);
  }
}
@-moz-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    -moz-transform: rotate(180deg);
    -moz-animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    -moz-transform: rotate(300deg);
    -moz-animation-timing-function: linear;
    -moz-origin: 0%;
  }
  30% {
    opacity: 1;
    -moz-transform: rotate(410deg);
    -moz-animation-timing-function: ease-in-out;
    -moz-origin: 7%;
  }
  39% {
    opacity: 1;
    -moz-transform: rotate(645deg);
    -moz-animation-timing-function: linear;
    -moz-origin: 30%;
  }
  70% {
    opacity: 1;
    -moz-transform: rotate(770deg);
    -moz-animation-timing-function: ease-out;
    -moz-origin: 39%;
  }
  75% {
    opacity: 1;
    -moz-transform: rotate(900deg);
    -moz-animation-timing-function: ease-out;
    -moz-origin: 70%;
  }
  76% {
    opacity: 0;
    -moz-transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    -moz-transform: rotate(900deg);
  }
}
.k-grid-toolbar {
  padding: 20px 30px !important;
  background: #fff !important;
}
.k-grid-header .k-header {
  background: rgba(255, 208, 75, 0.3) !important;
  padding-left: 30px !important;
}
.k-grid-header .k-header a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #272A29;
}
.k-grid-toolbar .k-widget_container {
  position: relative;
  margin-right: auto;
  width: 380px;
  min-height: 32px;
}
.k-grid-toolbar .k-widget_container > p {
  position: absolute;
  color: #9DA3B0;
  font-size: 14px;
  line-height: 16px;
  top: -18px;
  left: 40px;
}
.k-grid-toolbar .k-widget_container .k-grid-delete {
  z-index: 2;
  position: absolute;
  left: 100%;
  top: 0;
  font-weight: 400;
  font-size: 20px !important;
  margin-left: 0 !important;
  border-radius: 0 4px 4px 0 !important;
  padding-top: 2px !important;
  height: 33px;
}
.k-grid-toolbar .k-widget_container .k-grid-download {
  z-index: 2;
  position: absolute;
  right: 0;
  top: 0;
  height: 33px;
}
.k-grid-toolbar .k-upload {
  width: 380px;
  position: absolute;
  z-index: 2;
  border: 1px solid #DCDFE6;
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px !important;
  border-bottom: none;
}
.k-grid-toolbar .k-upload .k-dropzone {
  padding: 0;
  background: #fff;
  border: none;
  border-bottom: 1px solid #dde2e7;
  min-height: 32px;
}
.k-grid-toolbar .k-upload .k-dropzone .k-upload-button {
  background: #C2E5C7 url('/img/backup.svg') center no-repeat !important;
  border: 1px solid #33A946 !important;
  margin-top: -1px;
  min-width: 32px;
  border-radius: 4px 0px 0px 4px !important;
  position: relative;
  height: 33px;
}
.k-grid-toolbar .k-upload .k-upload-files {
  border-bottom: none;
  border-width: 0px 0 0;
}
.k-grid-toolbar .k-upload .k-upload-files .k-file-group-wrapper,
.k-grid-toolbar .k-upload .k-upload-files .k-file-invalid-group-wrapper {
  display: none;
}
.k-grid-toolbar .k-upload .k-upload-files .k-file-size {
  display: none;
}
.k-grid-toolbar .k-upload .k-upload-files .k-file-name-size-wrapper {
  margin-left: 0;
  min-height: 20px;
}
.k-grid-toolbar .k-upload .k-upload-files .k-upload-action {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
}
.k-grid-toolbar .k-upload .k-action-buttons {
  border-top: none;
  padding: 12px;
}
.k-grid-toolbar .k-upload .k-action-buttons .k-button {
  padding: 0.375rem 0.75rem !important;
}
.k-grid-toolbar .k-upload .k-action-buttons .k-clear-selected {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
  margin-right: 14px;
}
.k-grid-toolbar .k-upload .k-upload-files.k-reset .k-file-success {
  position: absolute;
  background: #fff;
  padding: 0;
  max-width: 100%;
  border: none;
  top: 5px;
  left: 47px;
}
.k-grid-toolbar .k-upload .k-upload-files.k-reset .k-file-success .k-file-name {
  color: #9DA3B0;
}
.k-grid-toolbar .k-grid-delete {
  padding: 0.375rem 0.75rem !important;
}
.k-grid-toolbar #startparsers {
  background: rgba(51, 169, 70, 0.3) !important;
  border: 1px solid #33A946 !important;
  color: #33A946 !important;
}
.k-grid tr td {
  border: none !important;
  border-top: 1px solid #DCDFE6 !important;
  border-bottom: 1px solid #DCDFE6 !important;
  padding: 9px 30px !important;
}
.k-grid tr td.k-command-cell {
  text-align: right;
}
.k-grid .k-alt {
  background-color: #fff !important;
}
.page-content {
  height: 100%;
}
.k-grid {
  height: calc(100vh - 64px);
}
.k-filter-menu {
  max-width: 330px;
}
.k-filter-menu .k-filter-menu-container select,
.k-filter-menu .k-filter-menu-container input {
  margin-right: 10px;
  min-width: 60px;
}
.k-filter-menu .k-filter-menu-container select:last-of-type,
.k-filter-menu .k-filter-menu-container input:last-of-type {
  margin-right: 0;
}
.k-filter-menu .k-filter-menu-container label {
  width: 100%;
  display: inline-block;
}
.k-filter-menu .k-filter-menu-container input {
  border-color: rgba(33, 37, 41, 0.15);
  color: #212529;
  background-color: #fff;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font: inherit;
  font-size: 1rem;
  line-height: 1.5;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  margin-bottom: 15px;
  height: 32px;
}
.k-filter-menu .k-filter-menu-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  padding: 0;
  min-width: 0;
  position: relative;
  top: 7px;
  margin-right: 15px;
}
.k-filter-menu .k-filter-menu-container input[type="checkbox"].checked:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 18px;
  height: 18px;
  background: #FFD04B url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
  display: inline-block;
}
.k-filter-menu .k-filter-menu-container input.checked {
  border: 1px solid #FFD04B;
}
.k-filter-menu .k-filter-menu-container input[type="radio"] {
  width: 20px;
  height: 20px;
  padding: 0;
  min-width: 0;
  position: relative;
  top: 7px;
  margin-right: 15px;
  border-radius: 50%;
}
.k-filter-menu .k-filter-menu-container input[type="radio"].checked:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  background: #FFD04B;
  border-radius: 50%;
  display: inline-block;
}
.k-filter-menu .k-filter-menu-container select {
  line-height: 34px;
  margin-bottom: 15px;
  width: 100%;
  height: 32px;
  border: 1px solid rgba(33, 37, 41, 0.15);
}
.k-filter-menu .k-filter-menu-container .k-filter-help-text {
  margin-bottom: 5px;
}
.k-filter-menu .k-filter-menu-container .k-datepicker {
  margin: 0;
  margin-bottom: 15px;
}
.k-filter-menu .k-filter-menu-container .k-datepicker input {
  margin-bottom: 0 !important;
}
.k-filter-menu .k-filter-menu-container .k-button {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
  display: inline-block;
}
.k-filter-menu .k-filter-menu-container .k-button.k-primary {
  background: rgba(64, 158, 255, 0.3) !important;
  border: 1px solid #409EFF !important;
  color: #409EFF !important;
}
.k-filter-menu .k-filter-menu-container .k-action-buttons {
  display: flex;
  justify-content: space-between;
}
.k-filter-menu .k-filter-menu-container .k-action-buttons .k-button {
  border-color: rgba(0, 0, 0, 0.08) !important;
  background: #fff !important;
  border-radius: 0 !important;
}
.k-button {
  background: rgba(64, 158, 255, 0.3) !important;
  border: 1px solid #409EFF !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #409EFF !important;
}
.k-button.k-grid-delete {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
}
.k-button.k-grid-cancel {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
}
#selectRoutePointModal .k-grid .k-grid-search {
  width: 25.7em;
}
.admin_panel_page {
  padding: 20px 30px;
}
.admin_panel_page .k-dropdown {
  margin-bottom: 10px;
  width: 250px !important;
}
.companiesSelect_style .select2-container {
  border-radius: 0.25rem;
  width: 250px !important;
  border-color: #e4e7eb;
  color: #212529;
  background-color: #e4e7eb;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  white-space: nowrap;
  border-width: 0;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
}
.companiesSelect_style .select2-container .selection {
  width: 100%;
}
.companiesSelect_style .select2-container .select2-selection--single {
  padding-top: 7px;
  border: none;
  width: 100%;
}
.companiesSelect_style .select2-container--default .select2-selection--single .select2-selection__arrow {
  background: transparent;
}
.item.isPublishTemplate {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  display: inline-block;
}
.item.isPublishTemplate.published {
  background: url("/img/status_ok.svg");
}
.item.isPublishTemplate.unpublished {
  background: url("/img/status_none.svg");
}
.isStatusTemplate {
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 3;
}
.isStatusTemplate.status_tried {
  background: url("/img/status_tried.svg");
}
.isStatusTemplate.status_middle {
  background: url("/img/status_middle.svg");
}
.isStatusTemplate.status_untested {
  background: url("/img/status_untested.svg");
}
input.text-box,
textarea.text-box {
  box-sizing: border-box;
}
.page-content .table_levels thead tr > th:first-of-type,
.page-content .table_levels tr[data-level] > td:first-of-type {
  width: 660px;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_bottom,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_bottom,
.page-content .table_levels tr[data-level="0"] td:first-of-type span i:not(.material-icons),
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template) i.line_to_top,
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) i.line_to_top {
  display: none;
}
.page-content .table_levels tr[data-level] td:first-of-type span:not(edit_template),
.page-content .table_levels tr[data-level] td:first-of-type span:not(load) {
  border-bottom: none;
}
.k-grid-edit {
  padding: 0 !important;
}
.k-grid-edit .k-i-edit {
  width: 30px !important;
  margin: 0 !important;
  top: 3px;
  height: 30px !important;
}
.k-grid-edit .k-i-edit:before {
  content: '' !important;
  background: url("/img/edit.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
}
.k-button.k-grid-see-route {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  position: relative;
}
.k-button.k-grid-see-route:before {
  content: '';
  background: url("/img/show.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
  left: 3px;
  top: 1px;
  opacity: 1;
}
.k-button.k-grid-change-route {
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  position: relative;
  margin-left: 15px !important;
}
.k-button.k-grid-change-route:before {
  content: '';
  background: url("/img/edit.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
  left: 3px;
  top: 1px;
  opacity: 1;
}
#fake_save {
  background: #C2E5C7;
  border: 1px solid #33A946;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: #33A946;
  z-index: 555;
  box-shadow: none;
  padding: 5px 16px;
}
#saveTableChanges {
  opacity: 0!important;
  height: 0!important;
  width: 0 !important;
}
.mdl-textfield.modified {
  position: relative;
  overflow: hidden;
}
.mdl-textfield.modified:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border: 6px solid transparent;
  border-left: 6px solid #F56C6C;
  border-top: 6px solid #F56C6C;
}
#app .table_levels.disabled_table input {
  pointer-events: none !important;
  cursor: default !important;
}
#app .table_levels.disabled_table .mdl-textfield {
  background: #f7f7f7;
}
.k-grid-delete {
  padding: 0 !important;
  margin-left: 15px !important;
}
.k-grid-delete .k-i-close {
  width: 30px !important;
  margin: 0 !important;
  top: 3px;
  height: 30px !important;
}
.k-grid-delete .k-i-close:before {
  content: '' !important;
  background: url("/img/delete.svg");
  width: 24px;
  height: 24px;
  display: inline-block;
}
.k-grid-download {
  padding: 0 !important;
  margin-left: 15px !important;
}
.k-grid-download .k-i-download {
  width: 30px !important;
  margin: 0 !important;
  top: 3px;
  height: 30px !important;
}
.k-grid-download .k-i-download:before {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 18px;
  margin-top: 2px;
}
.k-grid-detailsLocator {
  padding: 0 !important;
  margin-left: 15px !important;
}
.k-grid-detailsLocator .k-i-globe {
  width: 30px !important;
  margin: 0 !important;
  top: 3px;
  height: 30px !important;
}
.k-grid-detailsLocator .k-i-globe:before {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 18px;
  content: '' !important;
  background: url("/img/detailsLocator.svg") center no-repeat;
}
.triangular_btn {
  background: #FFD04B;
  padding: 8px 15px;
  margin-right: 21px;
  position: relative;
  color: #fff;
  text-decoration: none;
  border-radius: 5px 0 0 5px;
}
.triangular_btn:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  border: 18px solid transparent;
  border-radius: 0 5px 0 0;
  border-left: 20px solid #FFD04B;
}
.k-grid-details {
  padding: 0 !important;
  margin-left: 15px !important;
}
.k-grid-details .k-i-grid-layout {
  width: 30px !important;
  margin: 0 !important;
  top: 3px;
  height: 30px !important;
}
.k-grid-details .k-i-grid-layout:before {
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 18px;
  margin-top: 2px;
}
.k-pager-numbers .k-state-selected,
.k-pager-numbers .k-state-selected:hover {
  color: #409EFF !important;
  background: rgba(64, 158, 255, 0.3) !important;
  border: 1px solid #409EFF !important;
}
.k-i-plus:before {
  content: '' !important;
  background: url("/img/add.svg");
  background-repeat: no-repeat !important;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.k-window,
.box-modal {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.k-window .k-grid-cancel,
.box-modal .k-grid-cancel {
  background: rgba(245, 108, 108, 0.3) !important;
  border: 1px solid #F56C6C !important;
  color: #F56C6C !important;
}
.k-window .k-edit-field,
.box-modal .k-edit-field {
  box-sizing: border-box;
}
.k-window .k-edit-field .select2,
.box-modal .k-edit-field .select2 {
  width: 100% !important;
}
.k-window .k-edit-field .select2 .select2-selection.select2-selection--single,
.box-modal .k-edit-field .select2 .select2-selection.select2-selection--single {
  outline: none !important;
  height: 34px;
}
.overflow_hidden {
  overflow: hidden;
}
.k-prompt-container,
.k-window-content,
.k-edit-form-container,
form {
  box-sizing: border-box;
}
.k-grid-toolbar {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
}
.k-grid-toolbar:after {
  display: none !important;
}
.k-grid-toolbar .k-grid-search {
  margin-right: auto !important;
}
.k-grid .k-grid-search {
  float: none !important;
}
.k-filebrowser .k-filemanager-listview {
  padding: 10px;
  justify-content: flex-end;
}
.k-filebrowser .k-file-name {
  display: block;
  font-size: 12px;
  line-height: 16px;
}
.k-filebrowser .k-file-size {
  font-size: 12px;
  line-height: 16px;
}
.k-filebrowser .k-listview-content {
  display: flex;
}
.k-filebrowser .k-listview-item {
  margin: 0 10px;
}
.inner_page {
  color: #1855A3;
  padding-bottom: 20px;
}
.inner_page .page_title {
  display: none;
}
.inner_page .tickets_page_title {
  display: none;
}
.inner_page .filters {
  padding: 15px 30px;
  display: flex;
  flex-wrap: wrap;
}
.inner_page .filter_cover {
  width: 150px;
  margin: 0 20px 15px 0;
}
.inner_page .filter_cover .v-select {
  border: 1px solid #00A0E8;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 10px;
  color: #1855A3;
  cursor: pointer;
  width: 100%;
  height: 40px;
}
.inner_page .filter_cover .vs__dropdown-toggle {
  border: none;
  max-height: 100%;
}
.inner_page .filter_cover .vs__selected-options {
  flex-wrap: nowrap;
}
.inner_page .filter_cover .vs__open-indicator {
  fill: #1855A3;
  transform: scale(0.7);
}
.inner_page .filter_cover .vs__clear svg {
  fill: #1855A3;
  transform: scale(0.7);
}
.inner_page .filter_cover .vs__selected {
  color: #1855A3;
}
.inner_page .filter_xl_cover {
  width: 444px;
}
.inner_page .filter_xl_cover.with_subcovers {
  width: 560px;
}
.inner_page .filter_xl_half_cover {
  width: 270px;
}
.inner_page .filter_large_cover {
  width: 324px;
}
.inner_page .filter_large_cover .filter_half {
  width: 150px;
}
.inner_page .with_subcovers {
  display: flex;
  justify-content: space-between;
}
.inner_page .with_subcovers .dates_divider {
  margin-bottom: -12px;
}
.inner_page .dates_divider {
  font-weight: bold;
  height: 18px;
  width: 14px;
  display: inline-block;
  align-self: center;
  text-align: center;
}
.inner_page .filter_mid_cover {
  width: 160px;
}
.inner_page .submit_cover {
  display: flex;
  position: relative;
}
.inner_page .submit_cover .submit {
  margin-top: auto;
}
.inner_page .submit_cover .filters_validation {
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0;
  padding: 5px;
  background-color: #ffffff;
  border: 1px solid #C12C2C;
  border-radius: 20px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.inner_page .submit_cover .filters_validation::before {
  content: '';
  position: absolute;
  left: 20px;
  top: -20px;
  border: 10px solid transparent;
  border-bottom: 10px solid #C12C2C;
}
.inner_page .submit_cover .filters_validation ul {
  line-height: 24px;
  padding: 0 0 0 20px;
  margin: 0;
}
.inner_page .submit_cover .filters_validation .filters_validation_warning {
  color: #C12C2C;
  margin-bottom: 4px;
  display: flex;
}
.inner_page .submit_cover .filters_validation .filters_validation_warning .material-icons {
  margin-right: 5px;
}
.inner_page .submit_cover .filters_validation .filters_validation_text {
  font-size: 12px;
  line-height: 12px;
  padding: 2px 0;
}
.inner_page .filter_wide_cover {
  width: 322px;
}
.inner_page .filter_pseudo_cover {
  position: relative;
}
.inner_page .filter_field {
  border: 1px solid #00A0E8;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 10px 8px 10px;
  color: #1855A3;
  cursor: pointer;
  width: 100%;
  height: 40px;
}
.inner_page .filter_field::placeholder {
  color: #1855A3;
}
.inner_page .select_field {
  padding-top: 7px;
}
.inner_page .form_error .filter_label,
.inner_page .form_error .seat_selector_text,
.inner_page .form_error .payment_type_title,
.inner_page .form_error label {
  color: #C12C2C !important;
}
.inner_page .form_error .filter_field,
.inner_page .form_error .passenger_data_input,
.inner_page .form_error .payment_type_title,
.inner_page .form_error .payment_type_icon {
  border-color: #C12C2C !important;
}
.inner_page .filter_text_field {
  padding-left: 14px;
}
.inner_page .filter_half {
  width: 150px;
  padding-left: 12px;
}
.inner_page .filter_label {
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  cursor: pointer;
}
.inner_page .filter_label .client_only_label {
  display: none;
}
.inner_page .filter_pseudo_field {
  background-color: #ffffff;
  text-align: left;
  line-height: 18px;
  padding-left: 14px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inner_page .filter_pseudo_field .absolute_icon {
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.inner_page .filter_pseudo_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  padding-right: 5px;
}
.inner_page .filter_pseudo_dropdown {
  position: absolute;
  z-index: 1;
  top: 60px;
  left: -10px;
  padding: 15px;
  width: 320px;
  background: #F6FAFF;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  box-sizing: border-box;
}
.inner_page .filter_pseudo_dropdown .filter_cover {
  width: 100%;
}
.inner_page .passengers_from_autocomplete {
  margin-bottom: 10px;
}
.inner_page .passengers_from_autocomplete_title {
  color: #1855A3;
}
.inner_page .additional_from_autocomplete_title {
  display: block;
  padding-bottom: 5px;
}
.inner_page .passengers_from_autocomplete_checkbox {
  padding: 5px 0;
  display: block;
}
.inner_page .passengers_from_autocomplete_checkbox:last-of-type {
  padding-bottom: 0;
}
.inner_page .adding_transport .filter_field,
.inner_page .adding_transport .select2-container {
  width: 84% !important;
}
.inner_page .add_transport,
.inner_page .add_passengers {
  background-color: #00A0E8;
  border: 1px solid #00A0E8;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  height: 40px;
  width: 210px;
  display: block;
  margin: 0 auto;
  color: #ffffff;
}
.inner_page .add_passengers {
  margin-bottom: 10px;
}
.inner_page .delete_transport {
  background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
  border: 1px solid #C12C2C;
  color: #C12C2C;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  vertical-align: bottom;
  padding: 0;
}
.inner_page .delete_transport .material-icons {
  vertical-align: middle;
}
.inner_page .no_suitable_model {
  display: block;
  width: 180px;
  margin: 20px auto;
  text-decoration: none;
  color: #1855A3;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.inner_page .submit {
  background-color: #00A0E8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  padding: 8px;
  cursor: pointer;
  height: 40px;
  width: 100%;
  transition: box-shadow 0.3s;
}
.inner_page .submit:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.inner_page .submit:active {
  box-shadow: none;
}
.inner_page .button_silver {
  background: linear-gradient(180deg, #FFFFFF 0%, #E0EDFF 100%);
  border: 1px solid #1855A3;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: background 0.3s;
  color: #1855A3;
  line-height: 15px;
}
.inner_page .button_silver:hover {
  background: linear-gradient(360deg, #FFFFFF 0%, #E0EDFF 100%);
  transition: background 0.3s;
}
.inner_page .select2-selection {
  border: 1px solid #00A0E8;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 10px 8px 10px;
  color: #1855A3;
  cursor: pointer;
  width: 100%;
  height: 40px;
  outline: none;
}
.inner_page .select2-selection::placeholder {
  color: #1855A3;
}
.inner_page .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #00A0E8;
}
.inner_page .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #1855A3;
  line-height: 20px;
  padding-left: 5px !important;
}
.inner_page .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
}
.inner_page .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #00A0E8 transparent transparent;
}
.inner_page .select2-container--default .select2-dropdown {
  border: none;
}
.inner_page .select2-container--default .select2-dropdown .select2-search--dropdown .select2-search__field {
  padding: 0;
}
.inner_page .select2-container--default .select2-selection--multiple:before {
  content: "";
  position: absolute;
  right: 7px;
  top: 44%;
  border-top: 5px solid #00A0E8;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
.inner_page .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 !important;
}
.inner_page .select2-container--default .select2-selection--multiple .select2-search {
  display: none;
}
.inner_page .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #E0EDFF;
  border: 1px solid #1855A3;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  color: #1855A3;
  margin-top: 0;
  height: 20px;
}
.inner_page .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #1855A3;
}
.inner_page .actions {
  padding: 0 30px 15px 30px;
}
.inner_page .actions_text {
  font-size: 16px;
  line-height: 18px;
  margin-right: 20px;
}
.inner_page .actions_submit {
  width: 200px;
}
.mapSeatItem {
  cursor: pointer;
  fill: #1855A3;
}
.map_seat_checked {
  fill: #00A0E8 !important;
}
.not_available {
  fill: #E0E0E0 !important;
  cursor: not-allowed;
  pointer-events: none;
}
.pagination_cover {
  text-align: right;
  padding: 15px;
}
.pagination {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
.pagination_number {
  background-color: #00A0E8;
  border: 1px solid #00A0E8;
  display: inline-block;
  cursor: pointer;
  height: 38px;
  width: 34px;
  box-sizing: border-box;
  margin-right: 1px;
}
.pagination_number a,
.pagination_number span {
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
  padding: 6px 8px 5px 8px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  line-height: 26px;
  font-weight: bold;
  color: #ffffff;
}
.pagination_number:first-child {
  border-radius: 20px 0 0 20px;
  padding-left: 4px;
}
.pagination_number:last-child {
  border-radius: 0 20px 20px 0;
  padding-right: 4px;
}
.pagination_number:hover {
  background-color: #ffffff;
}
.pagination_number:hover a {
  color: #00A0E8;
}
.pagination_number.active {
  background-color: #1855A3;
  border-color: #1855A3;
  pointer-events: none;
}
.pagination_number.pagination_dots:hover {
  background-color: #00A0E8;
}
@media (max-width: 1000px) {
  .inner_page .adding_transport .filter_field,
  .inner_page .adding_transport .select2-container {
    width: 82% !important;
  }
  .inner_page .filters {
    padding: 20px;
  }
}
.number_filter_cover {
  display: flex;
}
.number_filter_cover:last-of-type {
  margin-bottom: 0;
}
.number_filter_cover input::-webkit-outer-spin-button,
.number_filter_cover input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.number_filter_cover input[type=number] {
  -moz-appearance: textfield;
}
.number_label {
  margin-right: auto;
  font-size: 14px;
  color: #1855A3;
}
.number_filter_input {
  background-color: transparent;
  border: none;
  padding: 0 6px;
  width: 10px;
}
.number_filter_control {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  border: none;
  padding: 0;
  text-align: center;
  cursor: pointer;
}
.number_filter_control .material-icons {
  font-size: 18px;
  vertical-align: top;
}
.number_filter_subtract {
  background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
  color: #C12C2C;
}
.number_filter_add {
  background-color: #00A0E8;
  color: #E0EDFF;
}
.number_filter_disabled {
  background: linear-gradient(180deg, #FFFFFF 0%, #E9E9E9 100%);
  color: #E0E0E0;
  pointer-events: none;
}
.client .mdl-layout__content {
  margin-left: 0 !important;
  display: flex;
  flex-direction: column;
}
.client .page-content {
  flex-grow: 1;
  height: auto;
}
.client .mdl-layout__header {
  padding: 15px;
}
.client .wrapper {
  max-width: 1080px;
  margin: 0 auto;
}
.client .header_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.client .header_inner_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client .header_profile_row .header_link {
  margin-right: 10px;
}
.client .header_profile_row .header_link:last-child {
  margin-right: 0;
}
.client .header_phone {
  font-size: 24px;
  line-height: 33px;
}
.client .header_nav_list {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.client .header_nav_item {
  list-style: none;
  padding: 10px 10px 0 10px;
}
.client .header_nav_item:first-child {
  padding-left: 0;
}
.client .header_nav_item:last-child {
  padding-right: 0;
}
.client .header_link:hover,
.client .footer_link:hover {
  color: #00A0E8;
}
.client .header_link {
  color: #ffffff;
  text-decoration: none;
  line-height: 24px;
}
.client .header_link span {
  vertical-align: middle;
}
.client .header_link .material-icons {
  color: #ffffff;
}
.client .user_name {
  padding: 10px 10px 10px 20px;
  border-radius: 20px 0 0 20px;
  background-color: #00A0E8;
}
.client .user_unauthorized {
  border-radius: 20px;
  padding: 8px 20px;
  cursor: pointer;
  color: #ffffff;
}
.client .user_unauthorized span {
  vertical-align: middle;
}
.client .user_logout {
  padding: 10px;
  border-radius: 0 20px 20px 0;
  background-color: #00A0E8;
}
.client .button_burger {
  display: none;
  border: none;
  background-color: #00A0E8;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
}
.client .button_burger .material-icons {
  color: #ffffff;
  vertical-align: middle;
}
.client .page_footer {
  background-color: #1855A3;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.25);
  padding: 15px;
}
.client .footer_outer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.client .footer_left {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.client .footer_list {
  padding: 0;
  margin: 0;
}
.client .footer_list_item {
  list-style: none;
  padding: 4px 0;
}
.client .footer_link {
  color: #ffffff;
  text-decoration: none;
}
.client .footer_right {
  text-align: right;
}
.client .footer_info {
  display: block;
  color: #ffffff;
  text-decoration: none;
  padding-bottom: 15px;
}
.client .page_title {
  display: block;
  font-family: 'Nunito', Arial, sans-serif;
  color: #1855A3;
  text-align: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 49px;
  margin: 0;
  padding: 50px 0;
}
@media (max-width: 1000px) {
  .client header .header_inner,
  .client header .header_user {
    display: none;
  }
  .client header.menu_open {
    height: 100vh;
    overflow-y: auto;
  }
  .client header.menu_open .header_outer {
    flex-wrap: wrap;
  }
  .client header.menu_open .header_logo {
    order: 1;
  }
  .client header.menu_open .button_burger {
    order: 2;
  }
  .client header.menu_open .button_burger .icon_show {
    display: none;
  }
  .client header.menu_open .button_burger .icon_hide {
    display: inline;
  }
  .client header.menu_open .header_inner {
    order: 3;
    width: 100%;
  }
  .client header.menu_open .header_user {
    order: 4;
    width: 100%;
  }
  .client header.menu_open .header_inner {
    display: flex;
    flex-direction: column;
  }
  .client header.menu_open .header_nav_row {
    order: 1;
    border-bottom: 1px solid #ffffff;
  }
  .client header.menu_open .header_profile_row {
    order: 2;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
  }
  .client header.menu_open .header_profile_row .header_link {
    font-size: 20px;
    line-height: 27px;
    padding: 4px 24px;
  }
  .client header.menu_open .header_profile_row .header_link .material-icons {
    display: none;
  }
  .client header.menu_open .header_profile_row .header_link.active {
    background-color: #ffffff;
    color: #0C356A;
    border-radius: 20px;
  }
  .client header.menu_open .header_user {
    display: initial;
  }
  .client header.menu_open .user_name {
    display: inline-block;
    padding: 4px 4px 4px 24px;
  }
  .client header.menu_open .user_unauthorized .material-icons {
    display: none;
  }
  .client header.menu_open .user_name,
  .client header.menu_open .user_logout {
    background-color: transparent;
    font-size: 20px;
    line-height: 27px;
  }
  .client header.menu_open .header_phone {
    display: none;
  }
  .client header.menu_open .header_nav {
    padding: 40px 0 26px 0;
  }
  .client header.menu_open .header_nav_list {
    flex-direction: column;
  }
  .client header.menu_open .header_nav_item {
    padding-left: 0;
  }
  .client header.menu_open .header_nav_item a {
    font-size: 20px;
    line-height: 27px;
    padding: 4px 24px;
  }
  .client header.menu_open .header_nav_item a.active {
    background-color: #ffffff;
    color: #0C356A;
    border-radius: 20px;
  }
  .client .header_logo img {
    height: 34px;
    width: auto;
  }
  .client .button_burger {
    display: inline-block;
  }
  .client .button_burger .icon_hide {
    display: none;
  }
  .client .page_title {
    font-size: 20px;
    line-height: 27px;
    padding: 20px 0;
  }
  .client .switch_table_cover table,
  .client .switch_table_cover thead,
  .client .switch_table_cover tbody,
  .client .switch_table_cover tr,
  .client .switch_table_cover th,
  .client .switch_table_cover td {
    display: block;
  }
  .client .switch_table_cover thead {
    display: none;
  }
  .client .switch_table_cover .table_row_openable {
    display: none;
  }
  .client .nested_table_group.opened .table_row_openable {
    display: block;
    box-shadow: none;
    border-top: 1px solid #00A0E8;
  }
  .client .page_footer {
    padding: 20px;
  }
  .client .page_footer .footer_left,
  .client .page_footer .footer_logo {
    display: none;
  }
  .client .page_footer .footer_right {
    text-align: left;
  }
  .client .page_footer .footer_info {
    padding-left: 0;
  }
}
.admin .schedule .wrapper {
  padding: 30px;
  max-width: 1080px;
  margin: 0 auto;
}
.desktop_slider {
  display: block;
}
.mobile_slider {
  display: none;
}
.title_carousel {
  margin: 0 auto;
  text-align: center;
  color: #0C356A;
  position: relative;
}
.title_carousel h1 {
  font-family: "Nunito", Arial, sans-serif;
  font-size: 42px;
  line-height: 57px;
  margin: 0;
}
.title_carousel .subtitle {
  font-size: 24px;
  line-height: 33px;
}
.title_carousel.multiple_carousel {
  margin-bottom: -75px;
}
.title_carousel.multiple_carousel h1,
.title_carousel.multiple_carousel .subtitle {
  color: #ffffff;
}
.title_carousel .title_slide {
  padding: 50px 0;
}
.title_carousel .multiple_slide {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #1855A3;
  padding: 140px 10px 250px 10px;
  box-sizing: border-box;
}
.title_carousel .owl-nav button.owl-prev,
.title_carousel .owl-nav button.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: 1px solid #C12C2C;
  color: #C12C2C;
  background-color: #ffffff;
  border-radius: 50%;
}
.title_carousel .owl-nav button.owl-prev .material-icons,
.title_carousel .owl-nav button.owl-next .material-icons {
  vertical-align: middle;
  display: block;
  font-size: 34px;
}
.title_carousel .owl-nav button.owl-prev {
  left: 50px;
}
.title_carousel .owl-nav button.owl-next {
  right: 50px;
}
.section_title {
  font-family: 'Nunito', Arial, sans-serif;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: #0C356A;
  margin: 0;
  padding-bottom: 35px;
  font-weight: bold;
}
.main_filter_block {
  background-color: #1855A3;
  border-radius: 20px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
}
.main_filter_block .filters {
  padding: 0;
}
.main_filter_block .filter_label {
  display: none;
}
.main_filter_block .filter_cover {
  margin: 15px;
}
.main_filter_block .filter_mid_cover {
  width: 240px;
}
.main_filter_block .filter_field {
  border-color: #1855A3;
}
.main_filter_block .select2-selection {
  border-color: #1855A3;
}
.main_filter_block .select2-container--default {
  width: 100% !important;
}
.main_filter_block .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #1855A3 transparent transparent;
}
.main_filter_block .filter_pseudo_cover {
  width: 375px;
}
.main_filter_block .filter_pseudo_dropdown {
  display: none;
  width: 375px;
  top: 40px;
  left: 0;
}
.main_filter_block .filter_pseudo_dropdown .filter_cover {
  margin: 15px 0;
}
.main_filter_block .filter_pseudo_dropdown .filter_label {
  display: block;
}
.main_filter_block .transport_additional {
  display: none;
}
.main_filter_block .delete_transport {
  margin-left: 10px;
}
.why_us {
  margin-bottom: 80px;
}
.why_us_inner {
  display: flex;
  margin-top: 55px;
}
.why_us_item {
  width: 25%;
  padding: 0 10px;
}
.why_us_image {
  max-width: 100%;
  margin: 0 auto 30px auto;
  text-align: center;
}
.why_us_image img {
  max-width: 100%;
}
.why_us_text {
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}
.map_container {
  position: relative;
}
.map_inner {
  margin-top: 35px;
  min-height: 550px;
}
.map_side {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 260px;
  height: 430px;
  z-index: 1;
  box-shadow: -1px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0 0 20px;
  overflow: hidden;
  background-color: #ffffff;
}
.map_side_icon {
  background-color: #00A0E8;
  padding: 10px;
  text-align: center;
}
.map_side_icon .material-icons {
  color: #ffffff;
}
.map_side_quays,
.map_side_ferries {
  padding: 25px 0;
  margin: 0 30px;
  text-align: center;
}
.map_side_quays {
  border-bottom: 1px solid #DADADA;
}
.map_side_type,
.map_side_button {
  display: block;
}
.map_side_type {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 10px;
}
.map_side_button {
  font-size: 18px;
  line-height: 25px;
  /**/
  color: #0C356A;
  background-color: transparent;
  cursor: pointer;
  text-align: center;
  margin: 0 auto 8px auto;
}
.map_side_button:hover {
  color: #00A0E8;
}
.map_side_button:last-child {
  margin-bottom: 0;
}
@media (max-width: 1000px) {
  .desktop_slider {
    display: none;
  }
  .mobile_slider {
    display: block;
    padding: 50px 0;
  }
  .mobile_slider h1 {
    font-size: 26px;
    line-height: 36px;
    color: #ffffff;
  }
  .mobile_slider .subtitle {
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
  }
  .mobile_slider .solo_carousel,
  .mobile_slider .mobile_solo_slide {
    box-sizing: border-box;
  }
  .mobile_solo_slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("/img/title_carousel/slide_2.jpg");
  }
  .main_filter_block {
    border-radius: 0;
    min-height: calc(100vh - 64px);
  }
  .main_filter_block .filter_label {
    display: block;
    color: #ffffff;
  }
  .main_filter_block .filter_label_empty {
    height: 14px;
  }
  .main_filter_block .filters {
    margin: 0 20px;
    justify-content: space-between;
    padding: 10px;
    background-color: #1855a3;
    border-radius: 20px;
  }
  .main_filter_block .adding_transport .select2-container {
    width: 81% !important;
  }
  .main_filter_block .filter_pseudo_dropdown {
    width: 100%;
    top: 52px;
  }
  .main_filter_block .filter_pseudo_dropdown .filter_label {
    color: #1855A3;
  }
  .main_filter_block .filter_cover {
    margin: 0 0 10px 0;
  }
  .main_filter_block .filter_submit_cover {
    margin-top: 10px;
  }
  .main_filter_block .filter_mid_cover {
    width: 48%;
  }
  .main_filter_block .filter_pseudo_cover {
    width: 100%;
  }
  .main_filter_block .filter_submit_cover {
    width: 100%;
  }
  .section_title {
    font-size: 20px;
    line-height: 27px;
  }
  .why_us {
    margin-bottom: 20px;
  }
  .why_us_inner {
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .why_us_item {
    width: 49%;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  .why_us_text {
    font-size: 14px;
    line-height: 19px;
  }
  .map_inner {
    min-height: 450px;
  }
  .map_side {
    position: relative;
    right: auto;
    top: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
    transform: none;
    box-sizing: border-box;
  }
  .map_side_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    padding: 0;
    box-sizing: border-box;
    display: flex;
  }
  .map_side_icon .material-icons {
    vertical-align: middle;
    color: #C12C2C;
    margin: auto;
  }
  .map_side_quays,
  .map_side_ferries {
    width: 50%;
    margin: 20px 0;
    padding: 0 30px;
    box-sizing: border-box;
  }
  .map_side_quays {
    border-bottom: none;
    border-right: 1px solid #DADADA;
  }
  .map_side_type,
  .map_side_button {
    font-size: 18px;
    line-height: 25px;
    color: #0C356A;
  }
}
.color_red {
  color: #C12C2C !important;
}
.schedule {
  padding-bottom: 60px;
}
.schedule .schedule_controls {
  margin-bottom: 30px;
}
.schedule .schedule_controls_inner {
  display: flex;
  justify-content: space-between;
}
.schedule .upload_schedule_cover {
  align-self: flex-end;
}
.schedule .schedule_reminder span {
  vertical-align: middle;
}
.schedule .reminder_check {
  color: #C12C2C;
  font-size: 18px;
  margin-right: 6px;
  font-weight: bold;
}
.schedule .calendar_controls {
  width: 250px;
  margin: 0 auto;
  padding: 0 70px;
  text-align: center;
  z-index: 1;
}
.schedule .calendar_month {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}
.schedule .calendar_body {
  padding: 20px 0;
}
.schedule .calendar_body .owl-nav {
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;
  display: flex;
  justify-content: space-between;
}
.schedule .calendar_body .owl-nav button.owl-prev,
.schedule .calendar_body .owl-nav button.owl-next {
  width: 40px;
  height: 40px;
  color: #C12C2C;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
}
.schedule .calendar_body .owl-nav button.owl-prev:hover,
.schedule .calendar_body .owl-nav button.owl-next:hover {
  border-color: #C12C2C;
}
.schedule .calendar_body .owl-nav button.owl-prev .material-icons,
.schedule .calendar_body .owl-nav button.owl-next .material-icons {
  vertical-align: middle;
  display: block;
  font-size: 34px;
}
.schedule .calendar_header_cell {
  width: 150px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  position: relative;
}
.schedule .calendar_header_cell::after {
  position: absolute;
  display: block;
  content: '';
  height: 12px;
  width: 2px;
  background-color: #DADADA;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.schedule .calendar_header_cell:last-child::after {
  display: none;
}
.schedule .calendar_body_row:first-child .calendar_body_cell:first-child .calendar_day {
  border-radius: 20px 0 0 0;
}
.schedule .calendar_body_row:first-child .calendar_body_cell:last-child .calendar_day {
  border-radius: 0 20px 0 0;
}
.schedule .calendar_body_row:last-child .calendar_body_cell:first-child .calendar_day {
  border-radius: 0 0 0 20px;
}
.schedule .calendar_body_row:last-child .calendar_body_cell:last-child .calendar_day {
  border-radius: 0 0 20px 0;
}
.schedule .calendar_body_row .calendar_body_cell:nth-child(2n) .calendar_day_number {
  background-color: #1855A3;
}
.schedule .calendar_day {
  margin: 5px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  padding: 20px 30px 20px 20px;
  overflow: hidden;
  position: relative;
  height: 100px;
  color: #000000;
}
.schedule .calendar_day_number {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-color: #00A0E8;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
}
.schedule .calendar_day_data .reminder_check {
  margin-right: 0;
  margin-left: 5px;
  vertical-align: middle;
}
@media (max-width: 1000px) {
  .schedule .wrapper {
    padding: 0 10px;
  }
  .schedule .schedule_controls .filter_cover,
  .schedule .schedule_controls .filter_xl_half_cover {
    width: 100%;
  }
  .schedule .calendar_controls {
    width: 100%;
    box-sizing: border-box;
  }
  .schedule .calendar_body .owl-nav {
    width: 100%;
  }
  .schedule .calendar_day {
    border-radius: 20px 0 20px 20px;
    box-sizing: border-box;
  }
}
.table_cover {
  width: 100%;
}
.sort_column {
  cursor: pointer;
}
.sorting_header {
  display: flex;
  align-items: center;
}
.sorting .sort_element {
  width: 8px;
  height: 16px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}
.sorting .sort_element::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}
.sorting .sort_element[data-sort-direction="desc"]::after {
  content: '\2193';
}
.sorting .sort_element[data-sort-direction="asc"]::after {
  content: '\2191';
}
.inner_table {
  width: 100%;
  border-collapse: collapse;
}
.inner_table th,
.inner_table td {
  padding: 15px;
  text-align: left;
}
.inner_table .nested_table_cell {
  padding: 30px 15px;
}
.inner_table .offer_column {
  text-align: center;
}
.inner_table .price_column,
.inner_table .button_column {
  text-align: right;
}
.inner_table .price_column .sorting_header {
  justify-content: flex-end;
}
.inner_table .table_head_row {
  background: #00A0E8;
}
.inner_table .table_head_row th {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.inner_table .reserved {
  color: #828282;
}
.inner_table .material-icons {
  vertical-align: middle;
}
.inner_table .offer_icon {
  color: #C12C2C;
}
.inner_table .cost_small {
  color: #828282;
  text-decoration: line-through;
  font-size: 12px;
  line-height: 14px;
}
.inner_table .empty_table_warning {
  text-align: center;
  padding: 60px 15px;
}
.inner_table .empty_table_warning span {
  font-size: 24px;
  line-height: 20px;
  font-weight: bold;
}
.inner_table .table_filter_cover {
  margin: 0;
}
.inner_table .table_item_subtitle {
  display: none;
}
.inner_table .transport_data_title {
  display: none;
}
.button_column_controls {
  position: relative;
  width: 36px;
  height: 36px;
}
.button_column_cover {
  display: inline-flex;
  align-items: center;
}
.order_additional {
  display: none;
  position: absolute;
  background-color: #F6FAFF;
  padding: 20px;
  border-radius: 20px 0 20px 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  right: 90%;
  top: 90%;
  z-index: 1;
  width: 240px;
  box-sizing: border-box;
  text-align: left;
}
.order_additional_button {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #1855A3;
  padding: 0 0 15px 0;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.order_additional_button:hover {
  color: #00A0E8;
}
.order_additional_button:last-child {
  padding-bottom: 0;
}
.table_row_opener {
  cursor: pointer;
  border-bottom: 1px solid #DADADA;
  height: 66px;
}
.table_row_opener:hover {
  background-color: #eae5e5;
}
.table_row_openable {
  box-shadow: inset 0 2px 12px rgba(0, 0, 0, 0.25);
  display: none;
}
.nested_table_group.opened .table_row_opener .table_button {
  color: #0C356A;
  border-color: #0C356A;
}
.nested_table_group.opened .table_row_opener td {
  color: #0C356A;
}
.nested_table_group.opened .table_row_openable {
  display: table-row;
}
.nested_table_cover:nth-child(n+2) .nested_table_title {
  margin-top: 15px;
}
.nested_table_title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  display: block;
  text-align: left;
  margin-bottom: 14px;
  color: #1855A3;
}
.nested_table_title .nested_title_button {
  margin-left: 20px;
  border-color: #1855A3;
  color: #1855A3;
}
.nested_table_title_text {
  vertical-align: middle;
  font-size: 18px;
  line-height: 25px;
}
.nested_table {
  background-color: #ffffff;
}
.nested_table .nested_table_head_row {
  background-color: #00A0E8;
  border: 1px solid #00A0E8;
}
.nested_table th {
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  padding: 12px;
}
.nested_table .nested_table_body_row {
  border: 1px solid #00A0E8;
}
.nested_table .nested_table_body_row td {
  font-size: 14px;
  line-height: 16px;
  padding: 12px;
}
.table_button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  color: #00A0E8;
  background-color: #ffffff;
  border: 1px solid #00A0E8;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.table_button:not(:first-child) {
  margin-left: 10px;
}
.table_button .material-icons {
  font-size: 21px;
}
.table_button:active {
  box-shadow: none;
}
.table_button_link {
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}
.table_button_link .material-icons {
  margin-top: 6px;
}
@media (max-width: 1000px) {
  .table_row_opener {
    height: auto;
  }
  .table_row_opener:hover {
    background-color: transparent;
  }
}
.trip {
  padding: 20px;
}
.trip .trip_header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.trip .trip_header_text {
  font-size: 36px;
  line-height: 49px;
  font-weight: bold;
  margin: 0 0 0 140px;
  color: #1855A3;
  font-family: 'Nunito', sans-serif;
}
.trip .trip_section {
  background-color: #F6FAFF;
  border-radius: 20px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.trip .title_section {
  padding: 0 30px 30px 30px;
}
.trip .title_section .trip_section_title {
  padding-left: 0;
}
.trip .trip_title {
  font-size: 18px;
  line-height: 20px;
  color: #142D52;
}
.trip .trip_title_text {
  vertical-align: middle;
  font-weight: bold;
  color: #1855A3;
  font-size: 24px;
  line-height: 33px;
}
.trip .trip_back {
  background-color: #00A0E8;
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  padding: 0 20px 0 16px;
  height: 40px;
}
.trip .trip_back span {
  vertical-align: middle;
}
.trip .trip_back .material-icons {
  vertical-align: middle;
  color: #ffffff;
}
.trip .title_data {
  display: flex;
  flex-wrap: wrap;
}
.trip .title_data_item {
  width: 45%;
  margin-right: 20px;
}
.trip .title_data_item .material-icons {
  vertical-align: middle;
}
.trip .trip_section_title {
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  color: #1855A3;
  padding: 30px;
  margin: 0;
}
.trip .passengers_item {
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.trip .passengers_block {
  padding-bottom: 30px;
}
.trip .passengers_map {
  display: flex;
  margin-left: 50px;
}
.trip .passengers_map_legend {
  align-self: center;
}
.trip .legend_horizontal {
  display: none;
}
.trip .passengers_data_row {
  height: 40px;
  margin-bottom: 15px;
}
.trip .passengers_data_row:last-child {
  margin-bottom: 0;
}
.trip .passengers_data_row .passengers_data_item {
  height: 100%;
  display: flex;
}
.trip .passengers_data_row .passengers_data_title {
  width: 155px;
  background-color: #00A0E8;
  border: 1px solid #00A0E8;
  border-radius: 20px 0 0 20px;
  text-align: right;
  padding: 10px 16px;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
}
.trip .passengers_data_row .passenger_document_data_title {
  padding: 8px 16px;
  line-height: 12px;
}
.trip .passengers_data_row .passenger_data_input {
  width: 210px;
  padding: 10px 16px;
  color: #1855A3;
  border: 1px solid #00A0E8;
  border-radius: 0 20px 20px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
}
.trip .trip_table .trip_head_row {
  background: #00A0E8;
}
.trip .trip_table .trip_head_row th {
  padding: 12px 20px;
  color: #ffffff;
}
.trip .trip_table tr {
  background-color: #F8F8F8;
}
.trip .trip_table td {
  padding: 10px 20px;
}
.trip .transport_plate_field {
  width: 240px;
  color: #1855A3;
}
.trip .trip_table_cost {
  text-align: right;
}
.trip td.trip_table_cost {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}
.trip .passenger_data_field {
  width: 160px;
}
.trip .seat_selector_opener {
  background-color: transparent;
  border: none;
  color: #1855A3;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  white-space: nowrap;
  padding: 0;
  min-width: 118px;
  text-align: left;
}
.trip .seat_selector_opener .seat_selector_text {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
.trip .seat_selector_opener .material-icons {
  vertical-align: middle;
}
.trip .seat_selector_cell {
  padding: 0;
}
.trip .seat_selector_map {
  padding: 10px 20px;
  border-radius: 20px;
}
.trip .seat_selector_map svg {
  width: 518px;
  height: 411px;
}
.trip .seat_selector_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.trip .seat_selector_list li {
  list-style: none;
}
.trip .seat_selector_list .radio_cover {
  margin-right: 10px;
  width: 50px;
}
.trip .seat_selector_list .radio_cover label span {
  top: 0;
}
.trip .total_section {
  overflow: hidden;
  padding-bottom: 0;
}
.trip .total_section_inner {
  display: flex;
}
.trip .total_section_right {
  padding: 30px;
}
.trip .finalize_row {
  flex-direction: column;
}
.trip .payment_type_block {
  padding: 60px 30px;
  background-color: #00A0E8;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}
.trip .payment_type_text {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  display: block;
  margin-bottom: 25px;
}
.trip .payment_type_row {
  margin-bottom: 20px;
}
.trip .payment_type_row:last-child {
  margin: 0;
}
.trip .payment_type_row label {
  display: flex;
}
.trip .payment_type_row .payment_type_icon {
  height: 43px;
  width: 50px;
  border: 1px solid #1855A3;
  border-radius: 20px 0 0 20px;
  background-color: #ffffff;
  box-sizing: border-box;
}
.trip .payment_type_row .payment_type_icon:after {
  transition: none;
  margin: 0;
}
.trip .payment_type_row .payment_type_title {
  width: 185px;
  padding: 2px 16px;
  color: #1855A3;
  border: 1px solid #1855A3;
  border-radius: 0 20px 20px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  background-color: #ffffff;
  height: 43px;
  display: flex;
  align-items: center;
  text-align: left;
  top: 0;
}
.trip .payment_type_row input[type=radio]:checked + .icon {
  background-color: #1855A3;
}
.trip .payment_type_row input[type=radio]:checked + .icon::after {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
  background-color: #1855A3;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-position: 50%;
  transition: none;
}
.trip .total_row {
  display: flex;
}
.trip .total_checkbox {
  margin-bottom: 20px;
}
.trip .total_field_cover {
  width: 280px;
  margin-right: 20px;
  padding-bottom: 15px;
  position: relative;
}
.trip .total_field_cover .filter_field {
  padding-left: 15px;
}
.trip .special_field_reminder {
  color: #1855A3;
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.trip .total_price {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #1855A3;
}
.trip .total_send {
  width: 280px;
  margin: 20px 20px 0 0;
}
.trip .no_data {
  padding: 50px 20px;
}
.trip .no_data .submit {
  width: 240px;
}
.trip .no_data_text {
  margin-right: 20px;
}
@media (max-width: 1000px) {
  .trip .trip_header_text {
    font-size: 20px;
    line-height: 27px;
    padding: 20px 0;
    margin: 0 0 0 20px;
  }
  .trip .trip_back {
    padding: 0;
    width: 40px;
  }
  .trip .trip_back_text {
    display: none;
  }
  .trip .trip_section {
    padding-bottom: 20px;
  }
  .trip .title_section {
    padding: 0 20px 20px 20px;
  }
  .trip .trip_section_title {
    font-size: 20px;
    line-height: 24px;
    padding: 20px;
  }
  .trip .title_data {
    display: block;
  }
  .trip .title_data_item {
    margin: 0;
    width: 100%;
  }
  .trip .title_data_item strong {
    margin-right: 10px;
  }
  .trip .transport_secondary_data {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #00A0E8;
  }
  .trip .transport_data_title {
    display: inline;
    width: 40%;
    min-height: 40px;
    background-color: #00A0E8;
    border: 1px solid #00A0E8;
    border-radius: 20px 0 0 20px;
    text-align: right;
    padding: 10px;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
    box-sizing: border-box;
  }
  .trip .transport_data_field {
    width: 60%;
    min-height: 40px;
    padding: 10px;
    color: #1855A3;
    border: 1px solid #00A0E8;
    border-radius: 0 20px 20px 0;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    font-weight: normal;
    box-sizing: border-box;
  }
  .trip .transport_data_field .cost_small {
    display: none;
  }
  .trip .transport_data_field::placeholder {
    color: #828282;
  }
  .trip .passengers_item {
    flex-direction: column;
    padding: 0 20px;
  }
  .trip .passengers_data_row {
    height: auto;
    min-height: 40px;
  }
  .trip .passengers_data_row .passengers_data_title {
    width: 40%;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .trip .passengers_data_row .passenger_data_input {
    width: 60%;
    font-size: 12px;
    line-height: 16px;
  }
  .trip .passengers_map {
    flex-direction: column;
  }
  .trip .passengers_map .seat_selector_map {
    padding: 10px 0;
  }
  .trip .passengers_map .seat_selector_map svg {
    width: 100% !important;
  }
  .trip .legend_horizontal {
    display: block;
  }
  .trip .legend_vertical {
    display: none;
  }
  .trip .total_section_inner {
    flex-direction: column;
  }
  .trip .payment_type_block {
    padding: 40px 20px;
  }
  .trip .total_section_right {
    padding: 20px;
  }
  .trip .total_field_cover .filter_label {
    white-space: nowrap;
  }
  .trip .total_email_cover {
    margin-right: 0;
  }
  .trip .label_extended {
    display: none;
  }
  .trip .total_checkbox {
    display: flex;
    align-items: center;
  }
  .trip .total_agreement {
    line-height: 16px;
    margin-left: 10px;
  }
  .trip .total_send {
    margin-right: 0;
    width: 100%;
  }
}
.boarding_summary {
  padding: 35px 30px 15px 30px;
  display: flex;
  border-bottom: 1px solid #00A0E8;
}
.boarding_summary .boarding_summary_info,
.boarding_summary .boarding_summary_controls {
  width: 49%;
}
.boarding_summary .boarding_summary_row {
  margin-bottom: 10px;
}
.boarding_summary .trip_title {
  font-size: 18px;
  line-height: 20px;
  color: #142d52;
  margin: 0;
}
.boarding_summary .boarding_summary_item {
  margin-right: 20px;
  display: inline;
}
.boarding_summary .trip_title_text {
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
}
.boarding_summary .icons_item {
  display: inline-flex;
  align-items: center;
}
.boarding_summary .icons_item .material-icons {
  margin: 0 10px;
}
.boarding_summary .icons_item_destinations {
  font-size: 18px;
  line-height: 21px;
}
.boarding_summary .boarding_summary_controls {
  text-align: right;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}
.boarding_summary .boarding_controls_button {
  width: 200px;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 22px;
}
.boarding_summary .boarding_controls_button:not(:last-child) {
  margin-right: 10px;
}
.boarding_summary .onboard_controls_button {
  width: 300px;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 22px;
}
.boarding_filters_inner {
  display: flex;
}
.boarding_filters_inner .select2-container {
  width: 100% !important;
}
.tab_selector {
  display: flex;
  justify-content: space-between;
  height: 40px;
  box-sizing: border-box;
  margin: auto 20px 15px auto;
  border: 1px solid #00A0E8;
  border-radius: 20px;
  padding: 4px;
}
.tab_selector_radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.tab_selector_radio:checked + .tab_selector_item {
  background-color: #00A0E8;
  border-color: #00A0E8;
  color: #fff;
}
.tab_selector_item {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 4px 10px;
  cursor: pointer;
  font-size: 13px;
  color: #1855A3;
}
.boarding_tab {
  display: none;
}
.tab_shown {
  display: block !important;
}
.boarding_page .arrival_button {
  width: 100px;
}
.boarding_page .arrival_button.arrival_button_red {
  background-color: #FFCFCF;
  color: #C12C2C;
  border-color: #C12C2C;
}
.arrrived-data {
  margin-left: 10px;
}
.arrival_status {
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 36px;
}
.arrival_status.arrival_status_danger {
  color: #C12C2C;
}
.nested_table_group .icon_arrow_up {
  display: none;
}
.nested_table_group.opened .icon_arrow_up {
  display: initial;
}
.nested_table_group.opened .icon_arrow_down {
  display: none;
}
.onboard_page .onboard_table_button {
  width: 100px;
}
.onboard_page .onboard_table_button_large {
  width: 204px;
}
.page-content .k-widget {
  border-width: 0;
}
.page-content .options_button {
  background-color: #00A0E8 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  font-size: 14px;
  line-height: 22px !important;
  text-align: center;
  color: #fff !important;
  border: none;
  padding: 8px !important;
  cursor: pointer;
  height: 40px;
  width: auto;
  transition: box-shadow 0.3s;
}
.page-content .options_button:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}
.page-content .options_button:active {
  box-shadow: none;
}
.page-content .options_button.info_button {
  width: 24px;
  height: 24px;
  line-height: 24px !important;
  padding: 0 !important;
  border: 1px solid #00A0E8 !important;
  background-color: #ffffff !important;
  color: #00A0E8 !important;
  font-weight: bold;
  text-align: center;
}
.page-content .add_button .k-i-plus::before {
  content: "\e11e" !important;
  background: none;
}
.page-content .k-grid tr,
.page-content .k-grid .k-alt {
  color: #1855A3;
}
.page-content .k-grid tr td {
  border-top: none !important;
  border-bottom-color: #DADADA;
}
.page-content .k-grid-header {
  border-bottom: none;
}
.page-content .k-grid-header .k-header {
  background: #00A0E8 !important;
  border: none !important;
  color: #ffffff;
}
.page-content .k-grid-header .k-header a {
  color: #ffffff;
}
.page-content .tariffs_table .k-grid-header {
  padding-right: 0 !important;
}
.page-content .tariffs_table .k-grid-content {
  height: auto !important;
  overflow-y: auto;
}
.page-content .k-grid-edit,
.page-content .k-grid-delete,
.page-content .k-grid-edit-custom,
.page-content .k-grid-show-custom,
.page-content .k-grid-info-custom {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff !important;
  border: none;
  padding: 8px !important;
  cursor: pointer;
  height: 36px;
  width: 36px;
}
.page-content .k-grid-edit,
.page-content .k-grid-edit-custom,
.page-content .k-grid-show-custom,
.page-content .k-grid-info-custom {
  background: #ffffff !important;
  border: 1px solid #00A0E8;
}
.page-content .k-grid-edit .k-icon::before,
.page-content .k-grid-edit-custom .k-icon::before,
.page-content .k-grid-show-custom .k-icon::before,
.page-content .k-grid-info-custom .k-icon::before {
  background: none;
  font-size: 20px;
  line-height: 22px;
  color: #00A0E8;
}
.page-content .k-grid-edit .k-icon::before {
  content: '\e10b' !important;
}
.page-content .k-event {
  background-color: #00A0E8;
}
.page-content .k-scheduler-toolbar .k-nav-today,
.page-content .k-scheduler-toolbar .k-nav-prev,
.page-content .k-scheduler-toolbar .k-nav-next,
.page-content .k-scheduler-toolbar .k-nav-current,
.page-content .k-scheduler-toolbar .k-state-selected {
  background-color: #00A0E8 !important;
  color: #ffffff !important;
  height: 34px !important;
  box-sizing: border-box;
}
.page-content .k-scheduler-toolbar .k-nav-today:hover,
.page-content .k-scheduler-toolbar .k-nav-prev:hover,
.page-content .k-scheduler-toolbar .k-nav-next:hover,
.page-content .k-scheduler-toolbar .k-nav-current:hover,
.page-content .k-scheduler-toolbar .k-state-selected:hover,
.page-content .k-scheduler-toolbar .k-nav-today:active,
.page-content .k-scheduler-toolbar .k-nav-prev:active,
.page-content .k-scheduler-toolbar .k-nav-next:active,
.page-content .k-scheduler-toolbar .k-nav-current:active,
.page-content .k-scheduler-toolbar .k-state-selected:active {
  z-index: initial;
}
.page-content .k-scheduler-toolbar .k-nav-today {
  border-radius: 20px 0 0 20px !important;
}
.page-content .k-scheduler-toolbar .k-nav-prev {
  border-radius: 0 !important;
  border-left: 1px solid #ffffff !important;
}
.page-content .k-scheduler-toolbar .k-nav-next {
  border-radius: 0 20px 20px 0 !important;
  border-left: 1px solid #ffffff !important;
}
.page-content .k-scheduler-toolbar .k-nav-current {
  padding: 7px 10px;
  border-radius: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.page-content .k-scheduler-toolbar .k-state-selected {
  padding: 5px 10px !important;
  border-radius: 20px !important;
}
.k-grid-update,
.k-grid-cancel {
  background: #00A0E8 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff !important;
  border: none;
  padding: 8px !important;
  cursor: pointer;
  height: 36px;
  width: auto;
}
.offices_edit_form {
  display: flex;
  flex-direction: column;
}
.edit_form_inputs {
  min-height: 350px;
}
.k-pager-numbers-wrap .k-dropdown {
  width: auto;
  margin-left: 20px;
  cursor: pointer;
}
.log_info_controls {
  padding: 30px 0;
}
.log_info_button {
  background-color: #00A0E8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border: 1px solid #00A0E8;
  transition: background 0.3s, box-shadow 0.3s;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  border-radius: 20px;
  padding: 11px 30px;
  cursor: pointer;
  text-decoration: none;
}
.log_info_button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.k-grid-edit-custom .k-icon,
.k-grid-show-custom .k-icon,
.k-grid-info-custom .k-icon {
  width: 30px!important;
  margin: 0!important;
  top: 3px;
  height: 30px!important;
}
.k-grid-edit-custom .k-icon::before,
.k-grid-show-custom .k-icon::before,
.k-grid-info-custom .k-icon::before {
  background: 0 0;
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 20px;
  line-height: 22px;
  color: #00a0e8;
}
.k-grid-edit-custom .k-icon::before {
  content: '\e10b';
}
.k-grid-show-custom .k-icon::before {
  content: '';
  background: url('/img/show.svg') !important;
}
.k-grid-info-custom .k-icon::before {
  content: '';
  background: url('/img/info.svg') !important;
}
.options_filters {
  padding: 15px 30px;
  display: flex;
}
.options_filters .tab_selector {
  margin: 0 auto 0 0;
}
.ticket {
  width: 800px;
  padding: 80px 50px;
}
.ticket .ticket_general {
  padding: 20px;
}
.ticket .ticket_head {
  background-color: #1855A3;
  color: #ffffff !important;
  font-size: 24px;
  line-height: 28px;
}
.ticket .ticket_head:first-letter {
  color: #ffffff !important;
}
.ticket .ticket_time {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.ticket .ticket_title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  text-transform: uppercase;
}
.ticket .ticket_inner_item {
  display: flex;
  padding: 8px 20px;
}
.ticket .ticket_inner_field {
  font-size: 16px;
  line-height: 19px;
}
.ticket .ticket_inner_position {
  margin-right: 20px;
}
.ticket .ticket_inner_transport:nth-child(n + 2),
.ticket .ticket_inner_passenger:nth-child(n + 2) {
  margin-top: 16px;
}
.ticket .ticket_footer {
  padding: 0 20px;
}
.ticket .ticket_footer p {
  font-size: 16px;
  line-height: 19px;
}
.ticket .qr_code {
  height: 200px;
  width: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.receipt {
  padding: 30px;
  width: 800px;
}
.receipt_table {
  width: 100%;
  border: 1px solid #000000;
  border-collapse: collapse;
  vertical-align: top;
}
.receipt_table td,
.receipt_table th {
  padding: 6px;
  border: 1px solid #000000;
  border-collapse: collapse;
  vertical-align: top;
}
.receipt_date {
  font-size: 20px;
  font-weight: bold;
  padding: 20px 0 10px 0;
  margin: 0;
}
.receipt_mid {
  border: none;
  border-collapse: separate;
  border-spacing: 0 8px;
}
.receipt_mid td:first-of-type {
  border-right: 0;
}
.receipt_mid td:last-of-type {
  font-weight: bold;
}
.receipt_mid {
  margin-bottom: 40px;
}
.receipt_bot {
  border: none;
}
.receipt_bot_details {
  border: 1px solid #000000;
}
.receipt_bot_summary {
  border: none;
}
.receipt_bot_summary th,
.receipt_bot_summary td {
  text-align: right;
  border: none;
}
.number_td {
  white-space: nowrap;
  text-align: right;
}
.receipt_reminders {
  border-bottom: 2px solid #000000;
  margin-bottom: 40px;
}
.receipt_reminders p,
.receipt_reminders strong {
  margin-bottom: 4px;
}
.receipt_signature {
  position: relative;
}
.receipt_signature .receipt_stamp {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-36%);
  width: 350px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("/img/signature.png");
}
.receipt_title {
  float: left;
  font-weight: bold;
}
.receipt_surname {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #000000;
}
.receipt_clear {
  clear: both;
}
.client .tickets_page {
  max-width: 1080px;
  margin: 0 auto;
}
.client .tickets_page .tickets_page_title {
  display: block;
}
.client .tickets_page .filters {
  flex-wrap: nowrap;
  border: 1px solid #00A0E8;
  border-radius: 20px;
  padding: 0;
  margin: 20px 0 50px 0;
}
.client .tickets_page .filters .filter_field {
  border: none;
}
.client .tickets_page .filters .filter_cover {
  width: auto;
  position: relative;
  margin-bottom: 0;
}
.client .tickets_page .filters .filter_cover::after {
  position: absolute;
  display: block;
  content: '';
  width: 2px;
  height: 30px;
  background-color: #DADADA;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
.client .tickets_page .filters .filter_cover:last-child::after,
.client .tickets_page .filters .filter_cover:nth-last-child(2)::after {
  display: none;
}
.client .tickets_page .filters .number_filter_cover {
  margin-bottom: 15px;
}
.client .tickets_page .filters .number_filter_cover::after {
  display: none;
}
.client .tickets_page .filters .filter_label {
  position: absolute;
  top: -20px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  width: 100%;
}
.client .tickets_page .filters .client_only_label {
  display: inline;
}
.client .tickets_page .filters .second_level_filter {
  margin: 0 0 15px 0;
}
.client .tickets_page .filters .second_level_filter::after {
  display: none;
}
.client .tickets_page .filters .second_level_filter .filter_label {
  display: block;
  padding-left: 20px;
  position: static;
  font-weight: normal;
  text-align: left;
  width: auto;
}
.client .tickets_page .filters .second_level_filter .filter_field {
  border: 1px solid #00A0E8;
}
.client .tickets_page .filters .filter_pseudo_field {
  padding-right: 30px;
}
.client .tickets_page .filters .filter_pseudo_field .absolute_icon {
  top: 12px;
}
.client .tickets_page .filters .dates_divider {
  font-size: 0;
  width: 2px;
  height: 30px;
  background-color: #DADADA;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
}
.client .tickets_page .filters .submit_cover {
  flex: 1 1 0;
  margin: 0;
}
.client .tickets_page .filters .submit_cover .submit {
  border-radius: 0 20px 20px 0;
}
.client .tickets_page .filters .filter_pseudo_dropdown {
  top: 40px;
  left: -6px;
  border-top-left-radius: 0;
}
.client .tickets_page .table_with_pseudo {
  position: relative;
}
.client .tickets_page .table_with_pseudo:before {
  position: absolute;
  content: '';
  display: block;
  height: 62px;
  width: 100vw;
  background-color: #00A0E8;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.client .tickets_page .table_with_pseudo .submit {
  min-width: 120px;
}
.client .tickets_page .table_with_pseudo tr:not(.table_head_row):not(.empty_table_row):hover {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.client .tickets_page .table_with_pseudo .table_head_row th {
  position: relative;
}
.client .tickets_page .table_with_pseudo .table_head_row th:after {
  position: absolute;
  display: block;
  content: '';
  height: 30px;
  width: 2px;
  background-color: #DADADA;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.client .tickets_page .table_with_pseudo .table_head_row th:last-child::after,
.client .tickets_page .table_with_pseudo .table_head_row th:nth-last-child(2)::after {
  display: none;
}
.client .tickets_page .table_with_pseudo td:not(.empty_table_warning) {
  padding: 20px 15px;
}
.client .tickets_page .trip .passengers_map {
  margin-left: 0;
}
.client .tickets_page .trip .passengers_map .seat_selector_map svg {
  height: auto;
  width: 452px;
}
@media (max-width: 1000px) {
  .client .tickets_page .filters {
    margin: 0 0 20px 0;
    flex-wrap: wrap;
    border: none;
    border-radius: 0;
    background-color: #F6FAFF;
    padding: 20px;
    justify-content: space-between;
  }
  .client .tickets_page .filters .filter_cover {
    width: 49%;
    margin: 0 0 15px 0;
  }
  .client .tickets_page .filters .filter_cover::after {
    display: none;
  }
  .client .tickets_page .filters .filter_half {
    width: 49%;
  }
  .client .tickets_page .filters .filter_half:first-of-type {
    margin-right: 2px;
  }
  .client .tickets_page .filters .filter_wide_cover,
  .client .tickets_page .filters .second_level_filter,
  .client .tickets_page .filters .number_filter_cover {
    width: 100%;
  }
  .client .tickets_page .filters .filter_pseudo_dropdown {
    top: 54px;
    left: 0;
    border-top-left-radius: 20px;
    width: auto;
    min-width: 280px;
  }
  .client .tickets_page .filters .filter_pseudo_passengers .filter_pseudo_dropdown {
    left: auto;
    right: 0;
  }
  .client .tickets_page .filters .filter_label {
    position: static;
    font-weight: normal;
    text-align: left;
    top: auto;
    padding-left: 15px;
  }
  .client .tickets_page .filters .filter_field {
    border: 1px solid #00A0E8;
  }
  .client .tickets_page .filters .dates_divider {
    display: none;
  }
  .client .tickets_page .filters .submit_cover .submit {
    border-radius: 20px;
  }
  .client .tickets_page .empty_table_warning {
    padding: 20px 15px;
  }
  .client .tickets_page .empty_table_warning span {
    font-size: 20px;
    line-height: 22px;
    padding: 0 30px;
    display: block;
  }
  .client .tickets_page .table_cover .transport_data_item {
    display: flex;
  }
  .client .tickets_page .table_with_pseudo .table_item_row td {
    padding-bottom: 0;
  }
  .client .tickets_page .table_with_pseudo .table_item_row .table_item_offer {
    padding: 15px 0;
  }
  .client .tickets_page .table_with_pseudo .table_item_row .table_item_submit {
    padding-bottom: 20px;
  }
  .client .tickets_page .table_item_row {
    margin: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .client .tickets_page .table_item_row td {
    width: 49%;
    padding: 15px;
    box-sizing: border-box;
  }
  .client .tickets_page .table_item_row .table_item_subtitle {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
  }
  .client .tickets_page .table_item_row .table_item_price {
    order: 1;
    text-align: left;
    width: 39%;
  }
  .client .tickets_page .table_item_row .table_item_price .cost_large {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
  }
  .client .tickets_page .table_item_row .table_item_price .cost_small {
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
  }
  .client .tickets_page .table_item_row .table_item_offer {
    order: 2;
    width: 8%;
  }
  .client .tickets_page .table_item_row .table_item_space {
    order: 3;
    display: flex;
    flex-wrap: wrap;
  }
  .client .tickets_page .table_item_row .table_item_space .table_item_subtitle {
    width: 100%;
  }
  .client .tickets_page .table_item_row .table_item_space .table_item_space_count {
    margin-right: 10px;
    box-sizing: border-box;
  }
  .client .tickets_page .table_item_row .table_item_date {
    order: 4;
  }
  .client .tickets_page .table_item_row .table_item_destination {
    order: 5;
  }
  .client .tickets_page .table_item_row .table_item_ferry {
    order: 6;
  }
  .client .tickets_page .table_item_row .table_item_submit {
    order: 7;
    width: 100%;
  }
  .client .tickets_page .table_item_row .table_item_submit .submit {
    width: 120px;
    margin: 0 auto;
    display: block;
  }
  .client .tickets_page .table_with_pseudo::before {
    display: none;
  }
}
.offices_tab_selector {
  border: none;
  border-bottom: 2px solid #DADADA;
  border-radius: 0;
  justify-content: flex-start;
  height: auto;
  padding: 0;
  margin: 0;
}
.offices_tab_selector .tab_selector_item {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #0C356A;
  border-radius: 0;
  border: none;
  padding: 5px 30px;
}
.offices_tab_selector .tab_selector_radio:checked + .tab_selector_item {
  color: #00A0E8;
  background-color: transparent;
  position: relative;
}
.offices_tab_selector .tab_selector_radio:checked + .tab_selector_item::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  display: block;
  content: '';
  height: 4px;
  width: 100%;
  background-color: #00A0E8;
}
.sales_offices_inner {
  padding: 20px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.offices_tab {
  display: none;
}
.offices_item {
  margin: 0 10px 10px 0;
  padding: 20px 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 260px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.offices_item.active {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
}
.office_info {
  margin-bottom: 4px;
}
.office_name {
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
  color: #0C356A;
}
.office_info_type,
.office_info_text {
  display: block;
  margin-bottom: 8px;
  color: #0C356A;
}
.office_on_map {
  background-color: transparent;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 20px;
  border: 1px solid #00A0E8;
  color: #00A0E8;
  text-align: center;
  cursor: pointer;
  margin: auto auto 0 auto;
}
.office_on_map:hover {
  background-color: #00A0E8;
  color: #ffffff;
}
@media (max-width: 1000px) {
  .sales_offices_tabs {
    display: none;
  }
  .sales_offices_inner {
    flex-direction: column;
  }
  .offices_item {
    width: auto;
    margin: 10px;
  }
  .office_on_map {
    margin: auto auto 0 0;
  }
}
.profile .table_item_subtitle {
  display: none;
}
.profile .profile_item {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 40px;
  position: relative;
}
.profile .profile_item .filter_auto_cover {
  width: auto;
  flex: 1 1 0;
}
.profile .profile_item .filter_mid_cover {
  width: 220px;
}
.profile .is_static .static.flex {
  display: flex;
}
.profile .is_static .static.block {
  display: block;
}
.profile .is_static .static.inline {
  display: inline;
}
.profile .is_static .editing {
  display: none;
}
.profile .is_editing .static {
  display: none;
}
.profile .is_editing .editing.flex {
  display: flex;
}
.profile .is_editing .editing.block {
  display: block;
}
.profile .is_editing .editing.inline {
  display: inline;
}
.profile .is_editing .editing .profile_data_row {
  justify-content: flex-start;
}
.profile .profile_item_title {
  font-family: 'Nunito', Arial, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #0C356A;
  margin: 0;
  padding-bottom: 20px;
}
.profile .profile_item_data {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.profile .profile_data_half {
  width: 40%;
}
.profile .profile_data_full {
  width: 100%;
}
.profile .profile_data_row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}
.profile .profile_submit {
  width: 220px;
}
.profile .inner_table tr {
  border-bottom: 1px solid #DADADA;
}
.profile .row_controls {
  display: flex;
}
.profile .delete_row {
  vertical-align: middle;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}
.profile .delete_row .material-icons {
  vertical-align: middle;
  color: #C12C2C;
  font-size: 30px;
}
.profile .profile_add_transport {
  background-color: transparent;
  border: 1px solid #0C356A;
  color: #0C356A;
}
.profile .profile_add_transport:hover {
  color: #00A0E8;
  border-color: #00A0E8;
}
.profile .profile_no_model {
  background-color: transparent;
  color: #0C356A;
  margin-left: 28px;
  cursor: pointer;
}
.profile .profile_no_model:hover {
  color: #00A0E8;
}
.profile .profile_table_data {
  margin: 0 -30px;
}
.profile .profile_table_data .inner_table th:first-child,
.profile .profile_table_data .inner_table td:first-child {
  padding-left: 30px;
}
.profile .profile_table_data .inner_table th:last-child,
.profile .profile_table_data .inner_table td:last-child {
  padding-right: 30px;
}
.order_history {
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.order_history .table_row_openable {
  box-shadow: inset 0 11px 8px -10px rgba(0, 0, 0, 0.25), inset 0 -11px 8px -10px rgba(0, 0, 0, 0.25);
}
.order_history .scalable_submit .scalable_submit_icon {
  display: none;
}
.order_history .table_item_subtitle {
  display: none;
}
.order_history .switchable_table_button {
  display: none;
}
.order_history .awaiting_payment {
  background-color: #00A0E8;
  color: #0C356A;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 13px;
  text-align: center;
  width: fit-content;
}
@media (max-width: 1000px) {
  .client .order_history .switchable_filters {
    flex-wrap: nowrap;
  }
  .client .order_history .switchable_filters .filter_xl_cover {
    width: calc(100% - 48px);
    margin-right: 6px;
  }
  .client .order_history .switchable_filters .filter_xl_cover .select2-selection {
    border-radius: 20px 0 0 20px;
  }
  .client .order_history .switchable_filters .filter_mid_cover {
    width: 42px;
    margin-right: 0;
  }
  .client .order_history .switchable_filters .scalable_submit {
    border-radius: 0 20px 20px 0;
    width: 100%;
    align-self: flex-end;
  }
  .client .order_history .switchable_filters .scalable_submit .scalable_submit_icon {
    display: inline;
  }
  .client .order_history .switchable_filters .scalable_submit .scalable_submit_text {
    display: none;
  }
  .client .order_history .nested_table_group {
    border-radius: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    margin: 10px;
  }
  .client .order_history .table_row_opener {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: none;
  }
  .client .order_history .table_row_opener td {
    width: 49%;
    box-sizing: border-box;
  }
  .client .order_history .table_row_opener .button_column {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .client .order_history .table_item_subtitle {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
  }
  .client .order_history .price_column {
    text-align: left;
  }
  .client .order_history .switchable_table_button {
    display: initial;
    padding: 8px 20px;
    width: fit-content;
  }
  .client .order_history .switchable_table_button {
    border: 1px solid #00A0E8;
  }
  .client .order_history .switchable_table_button.btn_opened {
    color: #00A0E8;
    border-color: #00A0E8;
    background-color: #ffffff;
  }
  .client .order_history .nested_table_body_row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: none;
  }
  .client .order_history .nested_table_body_row td {
    width: 49%;
    box-sizing: border-box;
  }
  .client .order_history .nested_table_body_row .button_column {
    width: 100%;
  }
  .client .order_history .nested_table_cell {
    padding: 30px 0 20px 0;
  }
  .client .order_history .nested_table_title {
    padding: 0 15px;
  }
  .client .profile {
    padding: 0 10px;
  }
  .client .profile .profile_item {
    box-sizing: border-box;
    padding: 15px 0;
  }
  .client .profile .profile_item_title {
    font-size: 18px;
    line-height: 25px;
    padding: 0 15px 10px 15px;
  }
  .client .profile .table_item_subtitle {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
  }
  .client .profile .profile_item_data tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .client .profile .profile_item_data tr th,
  .client .profile .profile_item_data tr td {
    width: 49%;
    box-sizing: border-box;
  }
  .client .profile .profile_table_data {
    margin: 0;
  }
  .client .profile .profile_table_data .inner_table th:first-child,
  .client .profile .profile_table_data .inner_table td:first-child,
  .client .profile .profile_table_data .inner_table th:last-child,
  .client .profile .profile_table_data .inner_table td:last-child {
    padding: 15px;
  }
  .client .profile .profile_item_controls {
    padding: 0 15px;
  }
  .client .profile .is_editing .editing .profile_data_row {
    flex-wrap: wrap;
    padding: 0 15px;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .client .profile .is_editing .editing_transport .profile_data_row,
  .client .profile .is_editing .editing_passengers .profile_data_row,
  .client .profile .is_editing .editing_drivers .profile_data_row {
    border-bottom: 1px solid #DADADA;
    padding: 15px;
  }
  .client .profile .is_editing .editing_transport .profile_data_row .filter_mid_cover,
  .client .profile .is_editing .editing_passengers .profile_data_row .filter_mid_cover,
  .client .profile .is_editing .editing_drivers .profile_data_row .filter_mid_cover {
    width: 48%;
    margin-right: 0;
  }
  .client .profile .is_editing .editing_transport .profile_data_row .filter_doc_cover,
  .client .profile .is_editing .editing_passengers .profile_data_row .filter_doc_cover,
  .client .profile .is_editing .editing_drivers .profile_data_row .filter_doc_cover {
    margin-right: 0;
    width: 100%;
  }
  .client .profile .is_editing .editing_transport .profile_data_row .row_controls,
  .client .profile .is_editing .editing_passengers .profile_data_row .row_controls,
  .client .profile .is_editing .editing_drivers .profile_data_row .row_controls {
    width: 100%;
  }
  .client .profile .is_editing .editing_transport .profile_data_row .row_controls .delete_row,
  .client .profile .is_editing .editing_passengers .profile_data_row .row_controls .delete_row,
  .client .profile .is_editing .editing_drivers .profile_data_row .row_controls .delete_row {
    margin-left: auto;
  }
  .client .profile .is_editing .editing_juridical .profile_data_row .filter_auto_cover {
    width: 100%;
    margin-right: 0;
  }
  .client .profile .filter_auto_cover {
    width: 44%;
    flex: auto;
  }
  .client .profile .filter_auto_cover:nth-of-type(2n) {
    margin-right: 0;
  }
}
.statement {
  height: 737.5px;
}
.statement:nth-of-type(odd) {
  position: relative;
}
.statement:nth-of-type(odd)::after {
  position: absolute;
  display: block;
  content: '';
  height: 1px;
  left: -50px;
  right: -50px;
  bottom: 0;
  border-bottom: 1px dashed #828282;
}
.statement .statement_table_cover {
  padding: 30px 50px;
  box-sizing: border-box;
}
.statement .statement_table {
  width: 100%;
  max-height: 100%;
  border-collapse: collapse;
}
.statement .statement_table th,
.statement .statement_table td {
  border: 1px solid #828282;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 16px;
}
.statement .statement_table .text_center {
  text-align: center;
}
.statement .statement_table .text_right {
  text-align: right;
}
.statement .statement_table .width_60 {
  width: 60%;
}
.statement .statement_table .width_70 {
  width: 70%;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  size: A4;
  margin: 0;
  .statement_body {
    width: 210mm;
  }
}
.order_info {
  display: flex;
  height: 100%;
}
.order_info_inner {
  margin: auto;
  padding: 40px 50px;
  width: 420px;
  box-sizing: border-box;
  text-align: center;
  background: #f6faff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.order_info_stats {
  font-size: 26px;
  line-height: 38px;
}
.order_info_controls .submit {
  display: block;
  margin: 10px auto;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 22px;
}
.persons .persons_tabs {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1;
}
.persons .persons_tabs .tab_selector {
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  justify-content: flex-start;
}
.persons .persons_tabs .tab_selector_item {
  font-size: 16px;
  line-height: 19px;
  border-radius: 0;
  border: none;
  border-bottom: 3px solid transparent;
  padding: 12px;
  margin: 0 10px;
}
.persons .persons_tabs .tab_selector_radio:checked + .tab_selector_item {
  border-bottom: 3px solid #1855A3;
  background-color: transparent;
  color: #1855A3;
}
.persons .persons_inner_tab {
  display: none;
}
.persons .persons_inner_tab.tab_shown {
  display: block;
}
.persons .persons_inner_tab .persons_filter_cover {
  padding: 20px 0 0 0;
}
.persons .persons_inner_tab .persons_filter_cover .k-clear-value {
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
}
.persons .persons_inner_tab .persons_filter_cover .k-widget {
  height: auto;
}
.persons .persons_inner_tab .filter_field.k-input {
  height: 40px;
  padding: 9px 10px 8px 10px;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #00A0E8;
}
.persons .persons_table_row {
  height: 66px;
  border-bottom: 1px solid #DADADA;
}
.persons_filter_row {
  display: flex;
  justify-content: space-between;
}
.persons_submit_col {
  padding: 22px 14px 0 0;
}
.article {
  padding: 0 10px;
  color: #0C356A;
}
.article h1,
.article h2,
.article h3,
.article h4,
.article h5,
.article h6,
.article .h1,
.article .h2,
.article .h3,
.article .h4,
.article .h5,
.article .h6 {
  font-family: 'Nunito', Arial, sans-serif;
}
.article h1 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.article h2 {
  font-size: 32px;
}
.article h3 {
  font-size: 28px;
}
.article h4 {
  font-size: 24px;
}
.article p {
  padding-bottom: 15px;
}
.article ul {
  padding-bottom: 15px;
}
.article ul li {
  list-style-type: disc;
  list-style-position: inside;
  padding: 5px 0;
}
.article ol {
  padding-bottom: 15px;
}
.article ol li {
  list-style-position: inside;
  padding: 5px 0;
}
.article input,
.article textarea {
  padding: 6px 10px;
  font-size: 13px;
  line-height: 1.462;
  border-radius: 6px;
  border: 2px solid #00A0E8;
  resize: none;
}
.article input:focus,
.article textarea:focus {
  border-color: #1855A3;
  transition: all 0.2s;
}
.article a {
  display: inline-block;
  color: #0C356A;
}
.article .table_cover {
  margin: 20px 0;
  overflow-x: auto;
}
.article .table_cover table {
  border-collapse: collapse;
}
.article .table_cover table th,
.article .table_cover table td {
  border: 1px solid #00A0E8;
  padding: 5px;
}
.article img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}
.edit_article .edit_article_filters {
  flex-direction: column;
  padding-bottom: 35px;
}
.edit_article .filters_row {
  display: flex;
}
.edit_article .textarea_cover textarea {
  padding-top: 12px;
  min-height: 40px;
}
.edit_article .article_divider {
  padding: 0.75rem 30px;
  background-color: #00A0E8;
  color: #ffffff;
}
.edit_article .article_editor_cover {
  padding: 15px 30px;
}
.edit_article .article_editor .k-editor {
  border-collapse: separate;
  border-spacing: 0 8px;
}
.edit_article .article_editor .k-editor-toolbar {
  background: none;
  padding: 10px 10px 4px 10px;
  border: 1px solid #00A0E8;
  border-radius: 20px;
}
.edit_article .article_editor .k-tool-group {
  margin-bottom: 6px;
}
.edit_article .article_editor .k-editable-area {
  padding: 10px;
  border: 1px solid #00A0E8;
  border-radius: 20px;
}
.edit_article .edit_article_controls {
  padding: 30px;
}
@media (max-width: 1000px) {
  .article ul,
  .article ol {
    padding-left: 10px;
    line-height: 20px;
  }
}
.ferry_modal {
  padding: 30px;
  border-radius: 20px;
  max-width: 1080px;
  width: auto;
  overflow: hidden;
}
.ferry_modal .form_error {
  font-size: 14px;
}
.ferry_modal .modal-content {
  padding-bottom: 6px;
}
.ferry_modal .box-modal_close .material-icons {
  color: #C12C2C;
}
.ferry_modal .modal_title_text {
  color: #1855A3;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  margin: 0;
}
.ferry_modal .passengers_item {
  display: flex;
  align-items: center;
}
.ferry_modal .passengers_block {
  padding-bottom: 30px;
}
.ferry_modal .passengers_map {
  display: flex;
}
.ferry_modal .passengers_map .passengers_map_image svg {
  width: 518px;
  height: auto;
}
.ferry_modal .passengers_map_legend {
  align-self: center;
}
.ferry_modal .passengers_data_row {
  height: 40px;
  margin-bottom: 15px;
}
.ferry_modal .passengers_data_row:last-child {
  margin-bottom: 0;
}
.ferry_modal .passengers_data_row .passengers_data_item {
  height: 100%;
  display: flex;
}
.ferry_modal .passengers_data_row .passengers_data_title {
  width: 155px;
  background-color: #00A0E8;
  border: 1px solid #00A0E8;
  border-radius: 20px 0 0 20px;
  text-align: right;
  padding: 10px 16px;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
}
.ferry_modal .passengers_data_row .passenger_data_input {
  width: 210px;
  padding: 10px 16px;
  color: #1855A3;
  border: 1px solid #00A0E8;
  border-radius: 0 20px 20px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
}
.ferry_modal .passengers_data_seat_item {
  position: relative;
}
.ferry_modal .passengers_data_seat_item .clear_seat_button {
  position: absolute;
  right: 6px;
  padding: 6px 8px;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00A0E8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border: 1px solid #00A0E8;
  transition: background 0.3s, box-shadow 0.3s;
  color: #ffffff;
  line-height: 16px;
  border-radius: 20px;
  cursor: pointer;
}
.ferry_modal .passengers_data_seat_item .clear_seat_button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.ferry_modal .passengers_controls {
  margin-top: 30px;
}
.ferry_modal .ferry_modal_button {
  background-color: #00A0E8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border: 1px solid #00A0E8;
  transition: background 0.3s, box-shadow 0.3s;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  border-radius: 20px;
  padding: 11px 30px;
  cursor: pointer;
}
.ferry_modal .ferry_modal_button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}
.ferry_modal .modal_footer {
  border-top: 1px solid #00A0E8;
  margin: 0 -30px -30px -30px;
  display: flex;
}
.ferry_modal .modal_footer_left {
  width: 300px;
}
.ferry_modal .payment_type_block {
  padding: 60px 30px;
  background-color: #00A0E8;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
}
.ferry_modal .payment_type_text {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
  display: block;
  margin-bottom: 25px;
}
.ferry_modal .payment_type_row {
  margin-bottom: 20px;
}
.ferry_modal .payment_type_row:last-child {
  margin: 0;
}
.ferry_modal .payment_type_row label {
  display: flex;
}
.ferry_modal .payment_type_row .payment_type_icon {
  height: 43px;
  width: 50px;
  border: 1px solid #1855A3;
  border-radius: 20px 0 0 20px;
  background-color: #ffffff;
  box-sizing: border-box;
}
.ferry_modal .payment_type_row .payment_type_icon:after {
  transition: none;
  margin: 0;
}
.ferry_modal .payment_type_row .payment_type_title {
  width: 185px;
  padding: 2px 16px;
  color: #1855A3;
  border: 1px solid #1855A3;
  border-radius: 0 20px 20px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  background-color: #ffffff;
  height: 43px;
  display: flex;
  align-items: center;
  text-align: left;
  top: 0;
}
.ferry_modal .payment_type_row input[type=radio]:checked + .icon {
  background-color: #1855A3;
}
.ferry_modal .payment_type_row input[type=radio]:checked + .icon::after {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
  background-color: #1855A3;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-position: 50%;
  transition: none;
}
.ferry_modal .modal_footer_right {
  padding: 30px;
  text-align: left;
}
.ferry_modal .modal_consent_checkbox {
  display: block;
  margin: 5px 0;
}
.ferry_modal .total_field_cover {
  width: 280px;
  margin-right: 20px;
  padding-bottom: 15px;
  position: relative;
}
.ferry_modal .modal_consent_block .invalid_field {
  color: #ff0000;
}
.ferry_modal .filter_label {
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding-left: 15px;
  cursor: pointer;
  color: #1855A3;
}
.ferry_modal .filter_field {
  border: 1px solid #00A0E8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 10px 8px 14px;
  color: #1855A3;
  cursor: pointer;
  width: 100%;
  height: 40px;
}
.ferry_modal .total_row {
  margin: 20px 0;
}
.ferry_modal .total_price {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #1855A3;
}
.ferry_modal .select2-selection {
  width: 210px;
  padding: 10px 16px;
  color: #1855A3;
  border: 1px solid #00A0E8;
  border-radius: 0 20px 20px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  height: 100%;
  outline: none;
}
.ferry_modal .select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #00A0E8;
}
.ferry_modal .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #1855A3;
  line-height: 18px;
  padding: 0 !important;
}
.ferry_modal .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
}
.ferry_modal .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #00A0E8 transparent transparent;
}
.ferry_modal .select2-container--default .select2-dropdown {
  border: none;
}
.ferry_modal .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 !important;
}
.ferry_modal .select2-container--default .select2-selection--multiple .select2-search {
  display: none;
}
.ferry_modal .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #E0EDFF;
  border: 1px solid #1855A3;
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 12px;
  line-height: 18px;
  color: #1855A3;
  margin-top: 0;
  height: 20px;
}
.ferry_modal .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #1855A3;
}
.ferry_modal .transport_modal_form {
  display: flex;
  margin-bottom: 30px;
}
.ferry_modal .transport_modal_form .filter_field,
.ferry_modal .transport_modal_form .select2-selection {
  border-radius: 20px;
  height: 40px;
  width: 240px;
  border-color: #00A0E8;
}
.ferry_modal .transport_modal_form .filter_cover {
  width: 240px;
  margin: 0 20px 20px 0;
}
.ferry_modal .transport_modal_form .filter_cover:last-child {
  margin-right: 0;
}
.ferry_modal.ferry_notification_modal .notification_modal_form {
  display: flex;
  flex-wrap: wrap;
}
.ferry_modal.ferry_notification_modal .notification_modal_form .filter_cover {
  width: 49%;
}
.ferry_modal.ferry_notification_modal .notification_modal_form .textarea_cover {
  width: 100%;
  margin: 5px 0 20px 0;
}
.ferry_modal.ferry_notification_modal .notification_modal_form .textarea_cover textarea {
  width: 100%;
  resize: none;
  height: auto;
}
.ferry_modal.ferry_notification_modal .notification_checkbox_cover {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
}
.ferry_modal.ferry_notification_modal .notification_checkbox_cover label {
  border: 1px solid #E0EDFF;
  padding: 9px 8px 8px 20px;
  width: 100%;
  align-self: flex-end;
  border-radius: 20px;
}
.ferry_modal.ferry_notification_modal .notification_checkbox_cover.notification_checkbox_wide {
  width: 100%;
  margin: 0 0 20px 0;
}
.ferry_modal.ferry_notification_modal .notification_modal_controls {
  padding: 26px 30px 20px 30px;
  text-align: left;
}
.ferry_modal.ferry_notification_modal .modal_footer {
  flex-direction: column;
  text-align: left;
  padding-bottom: 20px;
}
.ferry_modal.ferry_notification_modal .notification_table_title {
  padding: 0 30px;
}
.ferry_modal.ferry_notification_modal .notification_modal_table {
  width: 100%;
  border-collapse: collapse;
}
.ferry_modal.ferry_notification_modal .notification_modal_table th,
.ferry_modal.ferry_notification_modal .notification_modal_table td {
  text-align: left;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 16px;
}
.ferry_modal.ferry_notification_modal .notification_modal_table th:first-child,
.ferry_modal.ferry_notification_modal .notification_modal_table td:first-child {
  padding-left: 30px;
}
.ferry_modal.ferry_notification_modal .notification_modal_table th:last-child,
.ferry_modal.ferry_notification_modal .notification_modal_table td:last-child {
  padding-right: 30px;
}
.ferry_modal.ferry_notification_modal .notification_modal_table .button_column {
  text-align: center;
}
.ferry_modal.ferry_notification_modal .notification_modal_table .table_head_row {
  background-color: #00A0E8;
  color: #ffffff;
}
.ferry_modal.ferry_notification_modal .notification_modal_table td {
  color: #1855A3;
}
.ferry_modal.ferry_notification_modal .order_additional {
  top: auto;
  bottom: 90%;
  border-radius: 20px 20px 0 20px;
}
.ferry_modal.ferry_notification_modal .notification_full_email {
  display: none;
  width: 300px;
}
.ferry_modal.ferry_notification_modal .notification_show_email {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ferry_modal.ferry_notification_modal .notification_show_email:hover + .notification_full_email {
  display: block;
}
.ferry_modal input.invalid_field {
  border-color: #ff0000 !important;
}
.ferry_modal .filter_file_cover {
  width: 100%;
  margin-right: 0;
}
.ferry_modal .filter_file_inner {
  display: flex;
  justify-content: space-between;
}
.ferry_modal .register_file_input {
  opacity: 0;
  position: absolute;
  top: -10000px;
  left: 0;
  z-index: -1;
  clip: rect(0 0 0 0);
}
.ferry_modal .register_file_name {
  padding: 10px 16px;
  border: 1px solid #00A0E8;
  color: #1855A3;
  border-radius: 20px;
  flex: 1 1 0;
  font-size: 14px;
}
.ferry_modal .file_button_pseudo {
  padding: 10px;
  border: 1px solid #0C356A;
  border-radius: 20px;
  cursor: pointer;
  width: 200px;
  margin-left: 20px;
  text-align: center;
  color: #0C356A;
}
.ferry_modal .file_button_pseudo:hover {
  background-color: #0C356A;
  color: #ffffff;
}
.ferry_modal .ferry_modal_controls {
  padding: 26px 30px 20px 30px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
}
.ferry_modal.small_ferry_modal .select2-selection {
  width: auto;
  border-radius: 20px;
}
.ferry_modal.reports_modal .reports_modal_form {
  padding-bottom: 20px;
}
.ferry_modal.reports_modal .reports_form_row {
  display: flex;
  width: 100%;
}
.ferry_modal.reports_modal .filter_cover {
  width: 340px;
  margin: 0 20px 20px 0;
}
.ferry_modal.reports_modal .filter_cover:nth-child(2n) {
  margin-right: 0;
}
.ferry_modal.end_order_modal .ips_cover {
  width: 340px;
  margin: 0 20px 20px 0;
}
.ferry_modal.end_order_modal .order_bill_number {
  margin-right: 20px;
}
.ferry_modal .ferry_modal_button_outline {
  background-color: transparent;
  color: #0C356A;
  border-color: #0C356A;
}
.ferry_modal .forgot_password {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  background-color: transparent;
  margin-top: 20px;
  color: #1855A3;
  cursor: pointer;
}
.ferry_modal .forgot_password:hover {
  color: #00A0E8;
}
.ferry_modal.authorization_modal .ferry_modal_button_outline {
  margin-left: 20px;
}
.ferry_modal.authorization_modal .filter_cover {
  margin-right: 0;
}
.ferry_modal.authorization_modal .double_row .filter_cover:first-child {
  margin-right: 20px;
}
.ferry_modal.authorization_modal .filter_select {
  width: 100%;
}
.ferry_modal.authorization_modal .select_cover .select2-container {
  width: 100% !important;
}
.ferry_modal.authorization_modal .filter_file_cover {
  width: 100%;
}
.ferry_modal.authorization_modal .modal_consent_checkbox {
  margin-bottom: 20px;
}
.ferry_modal.authorization_modal .modal_consent_checkbox .consent_text {
  font-weight: bold;
  color: #1855A3;
}
.ferry_modal.ferry_refund_modal {
  max-width: 740px;
}
.ferry_modal.ferry_refund_modal .modal_content_wide {
  margin: 0 -30px;
}
.ferry_modal.ferry_refund_modal .modal_content_table {
  padding-bottom: 0;
}
.ferry_modal.ferry_refund_modal .refund_file_fields {
  padding-bottom: 16px;
}
.ferry_modal.ferry_refund_modal tbody tr {
  border-bottom: 1px solid #DADADA;
}
.ferry_modal.ferry_refund_modal .filter_file_cover {
  margin-bottom: 10px;
}
.ferry_modal.ferry_refund_modal .refund_disclaimer {
  font-size: 14px;
  line-height: 18px;
  color: #0C356A;
}
.ferry_modal.issue_ticket_modal .issue_ticket_subtitle {
  font-size: 20px;
  line-height: 24px;
  color: #1855A3;
  margin: 10px 0;
}
.ferry_modal.issue_ticket_modal .issue_ticket_block {
  margin-bottom: 20px;
}
.ferry_modal.issue_ticket_modal .filter_cover {
  margin-bottom: 10px;
  width: 340px;
}
.ferry_modal .table_button span {
  vertical-align: middle;
}
.ferry_modal .red_button {
  color: #C12C2C;
  border-color: #C12C2C;
  background-color: #FFCFCF;
}
.ferry_modal .legend_horizontal {
  display: none;
}
.ferry_modal.change_trip_modal {
  overflow: visible;
}
.ferry_modal.change_trip_modal .select2-selection {
  width: 100%;
  border-radius: 20px;
}
.ferry_modal.change_trip_modal .change_trip_content .change_trip_line {
  color: #1855A3;
}
.ferry_modal.ferry_no_car_modal .no_car_textarea {
  height: 80px;
  resize: none;
}
.ferry_modal.ferry_no_car_modal .filter_cover {
  margin-bottom: 15px;
}
.ferry_modal.ferry_no_car_modal .validation_text {
  display: none;
  color: #C12C2C;
  padding-left: 15px;
  font-size: 12px;
}
.ferry_modal.ferry_no_car_modal .filter_field.input-validation-error {
  border-color: #C12C2C;
}
.ferry_modal.ferry_no_car_modal .input-validation-error + .validation_text {
  display: block;
}
.ferry_modal.comment_modal .comment_modal_order {
  font-size: 16px;
  font-weight: bold;
  color: #1855A3;
}
.ferry_modal.comment_modal .textarea_cover {
  width: 300px;
}
.ferry_modal.comment_modal .textarea_cover .filter_field {
  height: auto;
  resize: none;
  cursor: auto;
}
.ferry_modal.change_price_modal .modal-content {
  padding-bottom: 20px;
}
.ferry_modal.change_price_modal .row:not(:first-child) {
  margin-top: 10px;
}
.ferry_modal.change_price_modal .notification_modal_controls {
  padding: 26px 30px 20px;
  text-align: left;
}
.ferry_modal.issue_ticket_modal .additional_transport_filed {
  width: 87%;
}
.ferry_modal.issue_ticket_modal .delete_transport {
  background: linear-gradient(180deg, #FFCFCF 0%, #FFB4B4 100%);
  border: 1px solid #C12C2C;
  color: #C12C2C;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  vertical-align: bottom;
  padding: 0;
}
.ferry_modal.issue_ticket_modal .delete_transport .material-icons {
  vertical-align: middle;
}
.ferry_modal.issue_ticket_modal .number_filter_input {
  width: 24px;
  text-align: center;
}
@media (max-width: 1000px) {
  .ferry_modal {
    padding: 20px;
  }
  .ferry_modal .transport_modal_form {
    flex-direction: column;
  }
  .ferry_modal .transport_modal_form .filter_cover {
    width: 100%;
    margin-right: 0;
  }
  .ferry_modal .transport_modal_form .filter_cover .filter_field {
    width: 100%;
  }
  .ferry_modal .modal_footer {
    flex-direction: column;
  }
  .ferry_modal .modal_footer_left {
    width: 100%;
  }
  .ferry_modal .total_field_cover {
    width: 100%;
  }
  .ferry_modal .passengers_item {
    flex-direction: column;
  }
  .ferry_modal .passengers_data_row {
    height: auto;
    min-height: 40px;
  }
  .ferry_modal .passengers_data_row .passengers_data_title {
    width: 40%;
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .ferry_modal .passengers_data_row .passenger_data_input {
    width: 60%;
    font-size: 12px;
    line-height: 16px;
  }
  .ferry_modal .passengers_map {
    flex-direction: column;
  }
  .ferry_modal .passengers_map .passengers_map_image {
    padding: 10px 0;
  }
  .ferry_modal .passengers_map .passengers_map_image svg {
    width: 100% !important;
  }
  .ferry_modal .seat_selector_map {
    padding: 10px 0;
  }
  .ferry_modal .legend_vertical {
    display: none;
  }
  .ferry_modal .legend_horizontal {
    display: block;
  }
}
@media (max-width: 320px) {
  .arcticmodal-container_i2 {
    padding: 24px 0;
  }
  .ferry_modal.ferry_transport_modal,
  .ferry_modal.ferry_passenger_modal {
    max-width: 96vw;
    box-sizing: border-box;
  }
}
.error_page {
  display: flex;
  height: 100%;
}
.error_page h1 {
  margin: auto;
}
@media (max-width: 1500px) {
  .show_hint span {
    font-size: 0;
  }
  .monitoring_page .grid_cover_content {
    justify-content: space-around;
  }
  .monitoring_page .grid_cover_content .mdl-textfield {
    width: 215px;
  }
  .monitoring_page .grid_cover_content .multiselect__placeholder {
    padding-right: 15px;
  }
  .monitoring_page .grid_cover_content .multiselect__option {
    white-space: normal;
    padding-right: 35px;
  }
  .expandable_column {
    display: none;
  }
}
@media (max-height: 601px) {
  #generic_create_modal {
    width: auto;
  }
  .route_point_modal {
    display: flex;
    flex-direction: row;
  }
  .route_point_modal .map_container {
    width: 650px;
    height: 350px;
    padding-right: 50px;
  }
  .route_point_modal .inputs_cover {
    height: 200px;
  }
  /*  .route_point_modal {
    .modalCustomTimeZoneOffset {
      .select2-results {
        max-height: 100px;
        overflow-y: auto;

        .select2-results__options {
          overflow: visible;
        }
      }
    }
  }*/
}
