.order_info {
  display: flex;
  height: 100%;
}

.order_info_inner {
  margin: auto;
  padding: 40px 50px;
  width: 420px;
  box-sizing: border-box;
  text-align: center;
  background: #f6faff;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.order_info_stats {
  font-size: 26px;
  line-height: 38px;
}

.order_info_controls {
  .submit {
    display: block;
    margin: 10px auto;
    text-decoration: none;
    box-sizing: border-box;
    line-height: 22px;
  }
}