﻿input, textarea, select, button{
  outline: none !important;
  &:focus, &:active{
    outline: none !important;
  }
}

button{
  border: none;
}

.add_route{
  position: relative;
  .training{
    position: absolute;
    right: 720px;
    width: 40px;
    top: 12px;
    height: 40px;
    background: url("../../img/training.svg");
    cursor: pointer;
    &#training_route_templates{
      right: 420px;
      top: 77px;
    }
  }
  #myMap{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ccc;
  }
  .double_input{
    display: flex;
    justify-content: space-between;
    >div{
      flex-grow: 1;
      max-width: ~"calc(50% - 7px)";
      min-width: ~"calc(50% - 7px)";
    }
  }
  .input_cover, .select_cover, .switch_cover{
    position: relative;
    label{
      margin: 10px 5px;
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 16px;
      color: #9DA3B0;
      display: block;
      i{
        font-style: normal;
        color: #F56C6C;
      }
    }
    input, textarea{
      background: #FFFFFF;
      width: 100%;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #606266;
      padding: 8px 15px;
      outline: none !important;
      &:focus{
        border-color: #409EFF;
      }
    }
    textarea{
      height: 82px;
      resize: none;
    }
    .address_input{
      background: #FFFFFF;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #606266;
      display: flex;
      input{
        padding: 8px 15px;
        border: none;
        border-top: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;
        flex-grow: 1;
        border-radius: 0;
      }
      .point{
        width: 32px;
        background: #C6E2FF;
        border: 1px solid #409EFF;
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 16px;
        color: #409EFF;
      }
      .copi_icon{
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DCDFE6;
        border-left: none;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        i{
          color: #409EFF;
          font-size: 20px;
        }
      }
      &.disabled{
        pointer-events: none;
        cursor: default;
        background: #DCDFE6;
        input{
          background: #DCDFE6;
          border-color: #9DA3B0;
        }
        .copi_icon{
          background: #DCDFE6;
          border-color: #9DA3B0;
          i{
            opacity: 0;
          }
        }
        .point{
          background: #DCDFE6;
          color: #9DA3B0;
          border-color: #9DA3B0;
        }
      }
    }
    &.disabled{
      .select2{
        .select2-selection--single{
          background: #DCDFE6;
          border-color: #9DA3B0;
        }
      }
    }
    &.error{
      .select2-container--default .select2-selection--single, > input{
        border-color: red;
      }
      .address_input{
        input, .copi_icon{
          border-color: red;
        }
      }
      .field-validation-error{
        display: none !important;
      }
    }

    .field-validation-error{
      position: absolute;
      left: 0;
      bottom: -18px;
      color: red;
    }
  }
  .checkbox_cover{
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    margin-top: 20px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    label{
      margin: 10px 5px;
      font-size: 14px;
      line-height: 16px;
      color: #9DA3B0;
      display: inline-block;
      i{
        font-style: normal;
        color: #F56C6C;
      }
    }
    input{
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .column_address{
    display: flex;
    .address_list{
      flex-grow: 1;
    }
  }
  .switch_cover{
    margin-left: 22px;
    align-self: flex-end;
    position: relative;
    top: -31px;
    .switch {
      position: relative;
      display: inline-block;
      width: 64px;
      height: 32px;
      margin: 0;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 7px;
      bottom: 7px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    input:checked + .slider {
      background-color: #C6E2FF;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px #C6E2FF;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(31px);
      -ms-transform: translateX(31px);
      transform: translateX(31px);
      background-color: #409EFF;
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  .address_list{
    .input_cover{
      label{
        margin-left: 39px;
      }
    }
    &.disabled_address{
      .column_address {
        .info_block{
          width: 100%;
        }
        >div:nth-child(2){
          display: none;
        }
        &:nth-child(1n + 2){
          .address_input{
            pointer-events: none;
            cursor: default;
            background: #DCDFE6;
            input{
              background: #DCDFE6;
              border-color: #9DA3B0;
            }
            .copi_icon{
              background: #DCDFE6;
              border-color: #9DA3B0;
              i{
                opacity: 0;
              }
            }
            .point{
              background: #DCDFE6;
              color: #9DA3B0;
              border-color: #9DA3B0;
            }
          }
        }
      }
    }
  }
  .supporting_point_block{
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
    position: relative;
    .remove_step{
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      padding: 3px 16px;
      color: #F56C6C;
      background: rgba(245, 108, 108, 0.3);
      border: 1px solid #F56C6C;
      margin-right: auto;
    }
    .supporting_point{
      background: #C6E2FF;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid #409EFF;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      padding: 3px 16px;
      color: #409EFF;
      i{
        color: #409EFF;
        margin-right: 14px;
      }
    }
    .add_step-error{
      color: red;
      position: absolute;
      right: 0;
      bottom: 40px;
    }
  }
  .bottom_cover{
    margin-top: 30px;
    .btn{
      width: 100%;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      padding: 8px 16px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .btn_orange{
      background: #EBB563;
      color: #FFFFFF;
    }
    &.double_btn{
      display: flex;
      align-items: flex-start;
      .btn{
        width: auto;
        flex-grow: 1;
        margin-right: 15px;
      }
      .k-button{
        width: 80px;
        min-width: 80px;
        height: 32px;
      }
    }
  }
  .select2{
    width: 100% !important;
  }
  .select2-container--default{
    .select2-selection--single{
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      border-radius: 4px;
      height: 34px;
      .select2-selection__rendered{
        line-height: 34px;
        padding-left: 17px;
        color: #606266;
      }
      .select2-selection__arrow{
        height: 32px;
        width: 28px;
        b{
          margin: 0;
          display: inline-block;
          top: 5px;
          right: -5px;
          left: initial;
          border: none;
          position: relative;
          width: 12px;
          height: 8px;
          background: url(../../img/down.png);
        }
      }
    }
  }
}

.add_route.cursor_marker{
  cursor: url("../../img/cursor_map.cur"), url("../../img/cursor_map.svg"), crosshair !important;
}

#button_create_my_location{
  background: #C6E2FF;
  border: 1px solid #409EFF;
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 5px;
  padding-top: 6px;
  cursor: pointer;
  .material-icons{
    color: #409EFF;
    font-size: 16px;
  }
}

.select2-container--default {
  .select2-dropdown {
    border-left: 1px solid #DCDFE6;
    border-right: 1px solid #DCDFE6;
    .select2-search--dropdown{
      background: transparent;
      padding: 0 15px;
    }
    .select2-results{
      top: 30px;
      border-radius: 0 0 4px 4px;
    }
  }
}

.add_route_abs{
  position: absolute;
  right: 10px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 20px;
  width: 400px;
  box-sizing: border-box;
}

.abs_map_button{
  position: absolute;
  right: 420px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 20px;
  .btn_search{
    padding: 3px 17px;
    background: #C6E2FF;
    border: 1px solid #409EFF;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #409EFF;
    cursor: pointer;
    i{
      color: #409EFF;
      margin-right: 15px;
    }
  }
}

.template_list_conteiner{
  position: absolute;
  right: 420px;
  top: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px 20px;
  padding-top: 15px;
  width: 500px;
  .title{
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    color: #272A29;
    margin-bottom: 15px;
  }
  .template_list{
    padding-bottom: 10px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    .item{
      margin-bottom: 10px;
      cursor: pointer;
      padding: 8px 16px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      input[type="radio"]{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
      }
      .grow{
        flex-grow: 1;
        padding: 0 15px;
      }
      .radio_fake{
        width: 14px;
        min-width: 14px;
        height: 14px;
        border: 1px solid #EDEFF2;
        border-radius: 50%;
        margin:0;
        position: relative;
      }
      .icon{
        width: 20px;
        min-width: 20px;
        height: 20px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        position: relative;
        z-index: 3;
      }
      &.status_tried{
        .icon{
          background: url("/img/status_tried.svg");
        }
      }
      &.status_middle{
        .icon{
          background: url("/img/status_middle.svg");
        }
      }
      &.status_untested{
        .icon{
          background: url("/img/status_untested.svg");
        }
      }
      &.active{
        border-color: #409EFF;
        .radio_fake{
          border-color: #409EFF;
          &:before{
            content: '';
            pointer-events: none;
            position: absolute;
            top: 1px;
            left: 1px;
            width: 11px;
            height: 11px;
            background: #409EFF;
            border-radius: 50%;
          }
        }
      }
      .address{
        padding-bottom: 5px;
        font-size: 14px;
        line-height: 16px;
        color: #606266;
      }
      .deck{
        font-size: 14px;
        line-height: 16px;
        color: #9DA3B0;
      }
    }
  }
  .bottom-part{
    display: flex;
    justify-content: flex-end;
    .template_not_use{
      background: rgba(245, 108, 108, 0.3);
      border: 1px solid #F56C6C;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #F56C6C;
      align-items: center;
      display: flex;
      height: 32px;
      cursor: pointer;
      i{
        color: #F56C6C;
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
}

.select_route_points_modal{
  cursor: pointer;
}

.select_cover .field-validation-error{
  display: none !important;
}

.page-content.loading{
  .loading_page{
    display: block !important;
  }
}

#route_preservation_modal{
  width: 360px;
  .modal_title{
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    color: #272A29;
    font-weight: normal;
  }
  .input_cover, .select_cover, .switch_cover{
    position: relative;
    label{
      margin: 10px 5px;
      margin-bottom: 2px;
      font-size: 14px;
      line-height: 16px;
      color: #9DA3B0;
      display: block;
      i{
        font-style: normal;
        color: #F56C6C;
      }
    }
    input, textarea{
      background: #FFFFFF;
      width: 100%;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #606266;
      padding: 8px 15px;
      outline: none !important;
      resize: none;
      &:focus{
        border-color: #409EFF;
      }
    }
    &.error{
      .select2-container--default .select2-selection--single, > input{
        border-color: red;
      }
      .address_input{
        input, .copi_icon{
          border-color: red;
        }
      }
      .field-validation-error{
        display: none !important;
      }
    }
    .field-validation-error{
      position: absolute;
      left: 0;
      bottom: -18px;
      color: red;
    }
  }
 
  .modal_footer{
    display: flex;
    justify-content: space-between;
    button{
      height: 32px;
      padding: 0 15px !important;
      margin: 0 !important;
    }
  }
}

.box-modal.select_template#selectSegmentTemplateModal{
  width: 475px;
}

.line_address{
  .column_address{
    >.info_block{
      flex-grow: 1;
    }
  }
}
