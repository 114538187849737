.dispatcher_modal {
  .modal-title {
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 25px;
  }

  .button_cover {
    text-align: right;
  }

  .button_delete {
    color: #FF6A7C;
  }
}

.edit_small_modal {
  width: 360px;

  .dispatcher_input_cover {
    width: 100%;
  }

  .dispatcher_select_cover {
    width: 100%;

    span.select2 {
      width: 100% !important;
    }
  }
}

.edit_map_modal {
  width: 70vw;

  .dispatcher_input_cover {
    margin-right: 30px;
    width: 300px;
    vertical-align: middle;

    .mdl-textfield__input {
      padding-bottom: 11px;
    }

    .mdl-textfield__label {
      top: 31px;
    }

    &.is-focused,
    &.is-dirty,
    &.has-placeholder {
      .mdl-textfield__label {
        top: 4px;
      }
    }
  }

  .dispatcher_select_cover {
    width: 300px;
    margin-right: 30px;

    span.select2 {
      width: 300px !important;
    }
  }

  .button_cover {
    text-align: right;
  }
}

.dispatcher_modal_map {
  height: 300px;
}