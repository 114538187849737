.reports {
  padding: 0 24px;

  > b {
    margin: 20px 0;
    display: inline-block;
    margin-right: 24px;
  }

  .dispatcher_form_cover {
    width: 300px;
  }

  .dispatcher_select_cover {
    width: 100%;
  }

  .dispatcher_select_cover {
    &:not(:first-child) {
      width: 47%;
    }

    &:not(:nth-child(2n + 1)) {
      margin-right: 10px;
    }
  }

  .button_cover {
    margin-top: 20px;
  }
}