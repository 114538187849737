@media (max-width: 1500px) {
  .show_hint {
    span {
      font-size: 0;
    }
  }

  .monitoring_page {
    .grid_cover_content {
      .mdl-textfield {
        width: 215px;
      }
      justify-content: space-around;
      .multiselect__placeholder {
        padding-right: 15px;
      }
      .multiselect__option {
        white-space: normal;
        padding-right: 35px;
      }
    }
  }
  .expandable_column {
    display: none;
  }
}

@media (max-height: 601px) {
  #generic_create_modal {
    width: auto;
  }

  .route_point_modal {
    display: flex;
    flex-direction: row;

    .map_container {
      width: 650px;
      height: 350px;
      padding-right: 50px;
    }

    .inputs_cover {
      height: 200px;
    }
  }
/*  .route_point_modal {
    .modalCustomTimeZoneOffset {
      .select2-results {
        max-height: 100px;
        overflow-y: auto;

        .select2-results__options {
          overflow: visible;
        }
      }
    }
  }*/
}