.client {
  .tickets_page {
    max-width: 1080px;
    margin: 0 auto;

    .tickets_page_title {
      display: block;
    }

    .filters {
      flex-wrap: nowrap;
      border: 1px solid @color_light_blue;
      border-radius: 20px;
      padding: 0;
      margin: 20px 0 50px 0;

      .filter_field {
        border: none;
      }

      .filter_cover {
        width: auto;
        position: relative;
        margin-bottom: 0;

        &::after {
          position: absolute;
          display: block;
          content: '';
          width: 2px;
          height: 30px;
          background-color: @color_grey;
          right: -15px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:last-child,
        &:nth-last-child(2){
          &::after {
            display: none;
          }
        }
      }

      .number_filter_cover {
        margin-bottom: 15px;

        &::after {
          display: none;
        }
      }

      .filter_label {
        position: absolute;
        top: -20px;
        font-weight: bold;
        padding: 0;
        text-align: center;
        width: 100%;
      }

      .client_only_label {
        display: inline;
      }

      .second_level_filter {
        margin: 0 0 15px 0;

        &::after {
          display: none;
        }

        .filter_label {
          display: block;
          padding-left: 20px;
          position: static;
          font-weight: normal;
          text-align: left;
          width: auto;
        }

        .filter_field {
          border: 1px solid @color_light_blue;
        }
      }

      .filter_pseudo_field {
        padding-right: 30px;

        .absolute_icon {
          top: 12px;
        }
      }

      .dates_divider {
        font-size: 0;
        width: 2px;
        height: 30px;
        background-color: @color_grey;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
      }

      .submit_cover {
        flex: 1 1 0;
        margin: 0;

        .submit {
          border-radius: 0 20px 20px 0;
        }
      }

      .filter_pseudo_dropdown {
        top: 40px;
        left: -6px;
        border-top-left-radius: 0;
      }
    }

    .table_with_pseudo {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 62px;
        width: 100vw;
        background-color: @color_light_blue;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }

      .submit {
        min-width: 120px;
      }

      tr:not(.table_head_row):not(.empty_table_row) {
        &:hover {
          box-shadow: 0 2px 16px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
        }
      }

      .table_head_row {
        th {
          position: relative;

          &:after {
            position: absolute;
            display: block;
            content: '';
            height: 30px;
            width: 2px;
            background-color: @color_grey;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }

          &:last-child,
          &:nth-last-child(2) {
            &::after {
              display: none;
            }
          }
        }
      }

      td:not(.empty_table_warning) {
        padding: 20px 15px;
      }
    }

    .trip {
      .passengers_map {
        margin-left: 0;

        .seat_selector_map {
          svg {
            height: auto;
            width: 452px;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .client {
    .tickets_page {
      .filters {
        margin: 0 0 20px 0;
        flex-wrap: wrap;
        border: none;
        border-radius: 0;
        background-color: @color_light_grey;
        padding: 20px;
        justify-content: space-between;

        .filter_cover {
          width: 49%;
          margin: 0 0 15px 0;

          &::after {
            display: none;
          }
        }

        .filter_half {
          width: 49%;

          &:first-of-type {
            margin-right: 2px;
          }
        }

        .filter_wide_cover,
        .second_level_filter,
        .number_filter_cover {
          width: 100%;
        }


        .filter_pseudo_dropdown {
          top: 54px;
          left: 0;
          border-top-left-radius: 20px;
          width: auto;
          min-width: 280px;
        }

        .filter_pseudo_passengers {
          .filter_pseudo_dropdown {
            left: auto;
            right: 0;
          }
        }

        .filter_label {
          position: static;
          font-weight: normal;
          text-align: left;
          top: auto;
          padding-left: 15px;
        }

        .filter_field {
          border: 1px solid @color_light_blue;
        }

        .dates_divider {
          display: none;
        }

        .submit_cover {
          .submit {
            border-radius: 20px;
          }
        }
      }

      .empty_table_warning {
        padding: 20px 15px;

        span {
          font-size: 20px;
          line-height: 22px;
          padding: 0 30px;
          display: block;
        }
      }

      .table_cover {
        .transport_data_item {
          display: flex;
        }
      }

      .table_with_pseudo {
        .table_item_row {
          td {
            padding-bottom: 0;
          }

          .table_item_offer {
            padding: 15px 0;
          }

          .table_item_submit {
            padding-bottom: 20px;
          }
        }
      }

      .table_item_row {
        margin: 10px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        td {
          width: 49%;
          padding: 15px;
          box-sizing: border-box;
        }

        .table_item_subtitle {
          display: block;
          font-size: 12px;
          line-height: 16px;
          color: @color_dark_grey;
        }

        .table_item_price {
          order: 1;
          text-align: left;
          width: 39%;

          .cost_large {
            font-size: 18px;
            line-height: 25px;
            font-weight: bold;
          }

          .cost_small {
            font-size: 14px;
            line-height: 19px;
            font-weight: bold;
          }
        }

        .table_item_offer {
          order: 2;
          width: 8%;
        }

        .table_item_space {
          order: 3;
          display: flex;
          flex-wrap: wrap;

          .table_item_subtitle {
            width: 100%;
          }

          .table_item_space_count {
            margin-right: 10px;
            box-sizing: border-box;
          }
        }

        .table_item_date {
          order: 4;
        }

        .table_item_destination {
          order: 5;
        }

        .table_item_ferry {
          order: 6;
        }

        .table_item_submit {
          order: 7;
          width: 100%;

          .submit {
            width: 120px;
            margin: 0 auto;
            display: block;
          }
        }
      }

      .table_with_pseudo {
        &::before {
          display: none;
        }
      }
    }
  }
}