.schedule {
  padding-bottom: 60px;

  .schedule_controls {
    margin-bottom: 30px;
  }

  .schedule_controls_inner {
    display: flex;
    justify-content: space-between;
  }

  .upload_schedule_cover {
    align-self: flex-end;
  }

  .schedule_reminder {
    span {
      vertical-align: middle;
    }
  }

  .reminder_check {
    color: @color_red;
    font-size: 18px;
    margin-right: 6px;
    font-weight: bold;
  }

  .calendar_controls {
    width: 250px;
    margin: 0 auto;
    padding: 0 70px;
    text-align: center;
    z-index: 1;
  }

  .calendar_month {
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }

  .calendar_body {
    padding: 20px 0;

    .owl-nav {
      position: absolute;
      top: -38px;
      left: 50%;
      transform: translateX(-50%);
      width: 360px;
      display: flex;
      justify-content: space-between;

      button {
        &.owl-prev,
        &.owl-next {
          width: 40px;
          height: 40px;
          color: @color_red;
          background-color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 20px;

          &:hover {
            border-color: @color_red;
          }

          .material-icons {
            vertical-align: middle;
            display: block;
            font-size: 34px;
          }
        }
      }
    }
  }

  .calendar_header_cell {
    width: 150px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    position: relative;

    &::after {
      position: absolute;
      display: block;
      content: '';
      height: 12px;
      width: 2px;
      background-color: @color_grey;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  .calendar_body_row {
    &:first-child {
      .calendar_body_cell {
        &:first-child {
          .calendar_day {
            border-radius: 20px 0 0 0;
          }
        }

        &:last-child {
          .calendar_day {
            border-radius: 0 20px 0 0;
          }
        }
      }
    }

    &:last-child {
      .calendar_body_cell {
        &:first-child {
          .calendar_day {
            border-radius: 0 0 0 20px;
          }
        }

        &:last-child {
          .calendar_day {
            border-radius: 0 0 20px 0;
          }
        }
      }
    }
    .calendar_body_cell {
      &:nth-child(2n) {
        .calendar_day_number {
          background-color: @color_dark_blue;
        }
      }
    }
  }

  .calendar_day {
    margin: 5px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    padding: 20px 30px 20px 20px;
    overflow: hidden;
    position: relative;
    height: 100px;
    color: #000000;
  }

  .calendar_day_number {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: @color_light_blue;
    color: #ffffff;
    text-align: center;
    line-height: 30px;
  }

  .calendar_day_data {
    .reminder_check {
      margin-right: 0;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
}

@media (max-width: 1000px) {
  .schedule {
    .wrapper {
      padding: 0 10px;
    }

    .schedule_controls {
      .filter_cover,
      .filter_xl_half_cover {
        width: 100%;
      }
    }

    .calendar_controls {
      width: 100%;
      box-sizing: border-box;
    }

    .calendar_body {
      .owl-nav {
        width: 100%;
      }
    }

    .calendar_day {
      border-radius: 20px 0 20px 20px;
      box-sizing: border-box;
    }
  }
}