@import "../partial/select.less";
@import "../partial/cardscover.less";
@import "../partial/template.less";
@import "../partials/app_gas_station_map";
@import "../components/arctic_modal.less";
@import "../components/autorize_cover.less";
@import "../components/jquery.mCustomScrollbar.less";
@import "../common/variables.less";

body, input, textarea, select, button {
  font-family: 'Nunito', sans-serif;
}

body {
  display: flex;
  flex-direction: column;

  .page-content,
  .k-widget {
    font-family: 'Nunito', sans-serif;
  }
}

.mdl-layout {
  flex: 1 0 auto;
}

a {
  color: #5469d4;
}
#map {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.dataPageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .4);
  z-index: 1000;
  .modal-dialog {
    display: table;
    width: 100%;
    height: 100%;
  }
  .modal-content-cover {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .modal-header {
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }
  .modal-content {
    display: inline-block;
    width: 400px;
    background: #fff;
    padding: 15px;
    text-align: left;
    input, textarea {
      width: 100%;
      box-sizing: border-box;
    }
  }
  .modal-footer {
    text-align: center;
    margin-top: 10px;
  }
}

.modal{
  display: none;
}

.box-modal{
  .mdl-textfield{
    position: relative;
    .field-validation-error{
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 10px;
      color: #d50000;
    }
    .textfield__valid{
      color: #48b04b;
      position: absolute;
      font-size: 12px;
      margin-top: 3px;
      display: block;
      &.msgBlockerror {
        color: #d50000;
        font-size: 12px;
        margin-top: 0;
        display: block;
      }
    }
    &.bid_cover{
      width: 100px;
      margin-right: 7px;
    }
    .point_icon{
      color: #3F51B5;
      min-height: 24px;
      min-width: 24px;
      max-width: 24px;
      max-height: 24px;
      background: #C5CEFF;
      border-radius: 25px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      margin-right: 10px;
    }
    input.placeholdered{
      opacity: 0;
      &:focus{
        opacity: 1;
      }
    }
    input.placeholdered:focus + .mdl-textfield-placeholder{
      opacity: 0;
    }
    .mdl-textfield-placeholder{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: right;
      box-shadow: inset 0 -1px 0 #ccc;
      font-family: "Nunito","Arial",sans-serif;
      pointer-events: none;
      font-size: 14px;
      padding: 4px 0;
      line-height: 17px;
      box-sizing: border-box;
      opacity: 1;
    }
    input[type=date]{
      border: none;
      width: 100%;
      min-width: 120px;
      padding-bottom: 3px;
      padding-top: 2px;
      margin: 3px 0;
      margin-top: 10px;
      font-size: 16px;
    }
  }
}

.logo_company{
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  >div:nth-of-type(1){
    color: #fff;
  }
  >div:nth-of-type(2){
    color: #fdc927;
    font-size: 30px;
    max-height: 24px;
    margin-top: -43px;
    padding: 0 1px;
  }
  >div:nth-of-type(3){
    color: #7986cb;
  }
}

.more_info_footer_button{
  display: none;
}

.page_controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 100%;

  .logo_company {
    width: 195px;
    text-decoration: none;
    border-right: 1px solid #ffffff;
    height: 100%;

    div {
      &:nth-child(2) {
       margin-top: -5px;
      }
    }
  }
  .page_controls_back {
    background-color: #ffffff;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
    margin: 0 20px 0 0;

    i {
      display: block;
      color: #3F51B5;
      font-weight: bold;
    }
  }
  .confirm_close {
    background-color: rgb(255, 215, 64);
    color: rgb(63, 81, 181);
    border-radius: 3px;
    padding: 3px 7px 2px 7px;
    margin-left: 20px;
    cursor: pointer;
  }
  .close_confirmed {
    background-color: transparent;
    border: 1px solid #ffffff;
    border-radius: 3px;
    padding: 3px 7px 2px 7px;
    margin-left: 20px;
  }
}
.basket_header{
  position: absolute;
  top: 0;
  height: 48px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
  left: 9px;
  span{
    display: none;
  }
}

.burger_header{
  margin: 8px 0px;
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: 'Nunito', Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 98px;
  color: rgb(255, 255, 255);
  z-index: 4;
  outline: none !important;
  position: absolute;
  color: rgb(255, 255, 255);
  background-color: inherit;
  transform: rotate(90deg);
  transition: all 300ms;
  z-index: 2;
  i{
    color: #ffffff;
  }
}

.mdl-layout:not(.is-small-screen) .nav_menu{
  //height: ~"calc(100vh - 62px)";
  //top: 62px;
  //background: #272A29;
  //left: 56px;
  .mdl-list__item{
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 8px 6px;
    position: relative;
    flex-wrap: wrap;
    span span{
      display: none;
    }
    i{
      color: #FFFFFF;
    }
    &.active{
      background-color: @color_dark_blue;

      >a, >span{
        color: #ffffff;
      }
      &:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background: #ffffff;
      }
      i{
        color: #ffffff;
      }
    }
  }
}
.mdl-list__item-primary-content{
  position: relative;
  .material-icons{
    right: 0 !important;
    margin-right: 13px !important;
  }
  .arrow.material-icons{
    display: none;
  }
}

.mdl-layout__header {
  background-color: @color_dark_blue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.mdl-layout__header-row {
  padding: 0 40px 0 165px;
  transition: padding 0.3s;
}

.open_menu, .remember_open_menu{
  .basket_header{
    width: auto;
    left: 13px;
    text-decoration: none;
    >img{
      margin-bottom: -5px;
    }
    span{
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
      margin-left: 18px;
    }
  }
  .burger_header{
    left: 245px;
    transform: rotate(0deg);
  }
  .mdl-layout__header-row{
    padding: 0 40px 0 321px;
    transition: padding 0.3s;
  }
  .mdl-layout:not(.is-small-screen) .nav_menu{
    //width: 300px;
   // left: 0;
    //z-index: 5;
    .mdl-list__item{
      min-height: auto;
      span span{
        display: block;
      }
      .mdl-list__item-icon {
        margin-right: 20px !important;
      }
      .arrow{
        position: absolute;
        right: 0;
        top: 12px;
      }
    }
  }
  .mdl-list__item-primary-content{
    .material-icons{
      position: relative;
      right: initial;
    }
    .arrow.material-icons{
      display: block;
    }
  }
  .mdl-layout__header{
    z-index: 5;
  }
  .mdl-layout__content{
    margin-left: 300px !important;
  }
}

.mdl-layout-title{
  background-color: @color_dark_blue;
  padding-left: 0;
}

.mdl-textfield__input{
  &:focus{
    outline: none;
  }
}

.mdl-data-table{
  width: 100%;
  td{
    padding: 2px 15px;
  }
  th{
    padding: 2px 15px;
    font-size: 14px;
    color: #272A29;
  }
  thead{
    background: rgba(255, 208, 75, 0.3);
    z-index: 2;
    position: relative;
    tr{
      &:hover{
        background: transparent !important;
      }
    }
  }
  &.disabled_table{
    .mdl-textfield-placeholder{
      opacity: .5 !important;
    }
  }
}

.table_inner_cover {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.mdl-list__item .mdl-list__item-primary-content{
  white-space: nowrap;
}

.nav_menu{
  border-right: none;
  transition: width 400ms;
  z-index: 2;
  .mdl-layout-title{
    padding-left: 16px;
  }
  .mdl-list__item{
    position: relative;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    min-height: 56px;
    padding-right: 0 !important;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.account_cover{
  position: relative;
  background: @color_dark_blue;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.account_cover_button {
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  width: 36px;
  height: 36px;
  color: @color_light_blue;
  border: 1px solid @color_light_blue;

  .material-icons {
    font-size: 22px;
    vertical-align: middle;
  }

  &.has_notifications {
    position: relative;

    &::after {
      position: absolute;
      right: -3px;
      top: -3px;
      display: block;
      width: 14px;
      height: 14px;
      font-size: 10px;
      background-color: @color_red;
      content: attr(data-counter);
      color: #ffffff;
      border-radius: 50%;
    }
  }

  &:hover {
    color: #000000;

    &::after {
      color: #ffffff;
    }
  }
}

.account_cover_content {
  display: none;
  position: absolute;
  background-color: #f6faff;
  padding: 20px;
  border-radius: 20px 0 20px 20px;
  box-shadow: 0 2px 16px rgba(0,0,0,.25);
  right: 90%;
  top: 90%;
  z-index: 1;
  width: auto;
  box-sizing: border-box;
  text-align: left;

  &.account_cover_content_wide {
    width: 240px;
  }
}

.account_cover_content_button {
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  color: #1855a3;
  padding: 0 0 15px;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:hover {
    color: @color_light_blue;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.header_user_info{
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
}

.menu_bottom_controls {
  position: relative;
}

.footer_info{
  padding-left: 16px;
  padding-bottom: 20px;
  transition: opacity 400ms;
  .typography_caption{
    font-size: 12px;
    display: block;
  }
  .tel{
    margin-bottom: 15px;
  }
  .copywrite{
    margin-top: 15px;
  }
}

.select2-dropdown{
  background: transparent !important;
  .select2-search__field{
    padding: 4px 15px;
  }
}

.mdl-textfield-select{
  select{
    width: 100%;
  }
  .select2-container{
    z-index: 1;
    .select2-selection--single{
      height: 41px;
      padding-top: 10px;
      &:focus{
        outline: none !important;
      }
    }
  }
  .mdl-textfield__label{
    &:after{
      display: none !important;
    }
  }
  .select2-container--search_theme{
    .select2-selection--single{
      cursor: text;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .select2-container--default, .select2-container--single_no_input, .select2-container--single_no_input_top{
    &.select2-container--focus{
      .select2-selection--multiple{
        border: 1px solid transparent;
        border-bottom: 1px solid rgba(0,0,0,.12);
      }
    }
    .select2-selection--multiple, .select2-selection--single{
      border: 1px solid transparent;
      border-bottom: 1px solid rgba(0,0,0,.12);
      background-color: transparent;
      border-radius: 0;
      .select2-selection__arrow {
        position: absolute;
        top: 15px;
        right: 0px;
        height: 20px;
        width: 20px;
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        b{
          position: absolute;
          left: 6px;
          top: 8px;
          border-top: 5px solid #828282;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
        }
      }
      .select2-selection__rendered{
        padding: 0;
      }
      .select2-selection__choice{
        cursor: default;
        border-radius: 16px;
        border: 0;
        background-color: #dedede;
        padding: 0 12px;
        margin-bottom: 0;
        margin-top: 2px;
      }
      .mdl-chip--deletable {
        padding-right: 4px;
      }
      .select2-search--inline{
        margin-top: 8px;
      }
    }
  }
  .mdl-textfield__label{
    top: 31px;
    left: 15px;
  }
}

.mdl-button--raised.mdl-button--colored{
  font-size: 14px;
  line-height: 16px;
  color: #409EFF;
  background: rgba(64, 158, 255, 0.3);
  border: 1px solid #409EFF;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: none;
  padding: 8px 16px;
  text-transform: none;
  font-weight: normal;
  display: flex;
  align-items: center;
  &:hover, &:active, &:focus{
    background: rgba(64, 158, 255, 0.3) !important;
    box-shadow: none !important;
  }
  >img, i.material-icons{
    margin-right: 13px;
  }
}

.select2.select2-container--open{
  
}
.mdl-textfield.is-focused{
  .mdl-textfield__label{
    display: none !important;
  }
}

.select2-selection__rendered{
  position: relative;
  padding: 0 12px !important;
  padding-right: 29px !important;
  .select2-selection__clear{
    position: absolute !important;
    right: 0 !important;
    font-size: 20px !important;
  }
}


.grid_cover_content{
  box-shadow: none;
  .big_select{
    .select2-container{
      width: 330px !important;
    }
    .mdl-textfield__label{
      width: 103%;
    }
  }
}

.material-icons{
  &.icon-in-cell{
    margin-top: 5px;
  }
  &.red{
    color: #ec0d3f;
  }
  &.blue{
    color: #1c70f5;
  }
}

.checkbox_cover{
  display: inline-block;
  label{
    cursor: pointer;
    .icon{
      width: 16px;
      height: 16px;
      border: 2px solid #3f51b5;
      border-radius: 2px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      top: -1px;
      &.indefinite{
        background: #ffd740;
        border: 2px solid #ffd740;
        &:before{
          content: '';
          height: 2px;
          width: 10px;
          position: absolute;
          left: 3px;
          top: 7px;
          background: #ffffff;
        }
      }
      &:after{
        content: '';
        height: 16px;
        width: 16px;
        background: transparent;
        display: block;
      }
    }
    input[type=checkbox]{
      display: none;
    }
    input[type=checkbox]:checked + .icon{
      &.indefinite{
        background: #ffd740;
        border: 2px solid #ffd740;
        &:after{
          display: none;
        }
      }
      &:after{
        background :#3f51b5 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 70%;
      }
    }
  }

  a {
    color: @color_dark_blue;
  }
}

.radio_cover{
  label{
    cursor: pointer;
    span{
      position: relative;
      top: 2px;
    }
    .icon{
      width: 16px;
      height: 16px;
      border: 2px solid #1855A3;
      border-radius: 10px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      &:after{
        content: '';
        height: 12px;
        width: 12px;
        margin-left: 2px;
        margin-top: 2px;
        background: transparent;
        display: block;
        border-radius: 10px;
        transform: scale(0);
        transition: 400ms;
      }
    }
    input[type=radio]{
      display: none;
    }
    input[type=radio]:checked + .icon{
      &:after{
        background: #1855A3;
        transform: scale(1);
      }
    }
  }
}

.disabled{
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  pointer-events: none !important;
  opacity: 0.7 !important;
  filter: grayscale(100%) !important;
}
#app, #discountTemplates_app {
    position: relative;
}

#saveTableChanges{
  position: absolute;    
  top: -36px;
  left: 50%;
  margin-left: -68px;
  z-index: 3;
  width: auto;
  min-width: 136px;
  border-radius: 20px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  .material-icons{
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: translate(0px,-2px);
    transform: translate(0px,-2px);
    vertical-align: middle;
    display: inline-block;
  }
  span{
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translate(0px,-1px);
    transform: translate(0px,-1px);
  }
  &.disabled{
    .material-icons{
      transform: none;
      -webkit-animation:spin 4s linear infinite;
      -moz-animation:spin 4s linear infinite;
      animation:spin 4s linear infinite;
    }
  }
}

[v-cloak] { display: none; }

.page-content{
  .table_scroll_cover{
    overflow: auto;
    position: relative;
    //height: ~"calc(100vh - 97px - 64px - 10px)";
  }
  .table_footer{
    text-align: right;
  }
  .table-cover{
    width: 100%;

    .mCSB_dragger,
    .mCSB_dragger_bar{
      background-color: #3f51b5;
    }
    .mCSB_dragger {
      border-radius: 20px;
    }
    tr.disabled-row{
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      pointer-events: none;
      opacity: 0.7;
      background: #cecece;
      filter: grayscale(100%);
    }
  }
  .table_levels{
    &.fixed_head{
      margin-bottom: -48px;
      position: relative;
      z-index: 2;
      box-shadow: none;
      border-top: none;
      margin-top: 15px;
    }
    table{
      table-layout: fixed;
    }
    table-layout: fixed;
    white-space: normal !important;
    border: none;
    .scroll_guy{
      max-height: 350px;
      overflow: auto;
      table{
        background: #f9f9f9;
      }
      tr{
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        align-items: center;
        td{
          flex-grow: 1;
          display: flex;
          align-items: center;
          border-top: none;
          background: #efefef;
          &.dieselPrice{
            justify-content: flex-end;
            min-width: 11%;
            max-width: 11%;
            padding-right: 2.2vw;
          }
          &.premiumDieselPrice{
            min-width: 11%;
            max-width: 11%;
            justify-content: flex-end;    
            padding-right: 24px;
          }
          &.discount_temlate_drop{
            padding-right: 3.0vw;
            min-width: 22vw;
            max-width: 25%;
            justify-content: flex-end;
            &.icon_td{
              max-width: 0;
              width: 0;
              padding: 0;
              min-width: 0;
            }
          }
        }
      }
    }
    .table_levels{
      border: none;
    }
    .section_title{
      margin-bottom: 7px;
      margin-left: 9px;
      font-size: 17px;
      margin-top: 18px;
      font-weight: 500;
      text-align: left;
      color: #606060;
    }
    th:first-of-type {
      padding-left: 10px;
    }
    tr.unchecked{
      opacity: 0.7 !important;
      filter: grayscale(100%) !important;
    }
    tr.ghost{
      opacity: 0.5 !important;
      filter: grayscale(100%) !important;
      background: #a0a0a0;
      pointer-events: none;
      display: none;
    }
    thead tr > th, tr[data-level] > td{
      &:first-of-type{
        width: 360px;
      }
    }
    tr{
      height: auto;
      &:hover{
        background: #fff;
      }
    }
    td[colspan="4"], td[colspan="3"]{
      padding: 0;
      border: none;
      height: auto;
      >table{
        border-spacing: 0;
        border-collapse: collapse;
        border: none;
        width: 100%;
      }
    }
    td[colspan="3"]{
      >table{
        td:first-of-type{
          padding-left: 37px;
          span:not(.edit_template){
            cursor: default;
            &:before{
              display: none;
            }
          }
          i.line_to_top{
            position: absolute;
            display: block;
            left: 23px;
            bottom: 13px;
            height: 47px;
            width: 10px;
            border-left: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            pointer-events: none;
          }
        }
      }
      .scroll_guy{
        border-top: 1px solid #ccc;
        >div{
          margin-top: -1px;
        }
        table, tr td{
          background: #fff;
        }
      }
    }
    td.icon_td, th.icon_td{
      width: 60px;
      max-width: 60px;
      .material-icons{
        color: #3F51B5;
        margin-top: 7px;
        cursor: pointer;
      }
    }
    tr[data-level]{
      td:first-of-type{
        font-weight: 500;
        font-size: 16px;
        padding-left: 10px;
        span:not(edit_template), span:not(load){
          
          cursor: pointer;
          position: relative;
          border-bottom: 1px dashed #b5b5b5;
          user-select: none;
          display: inline-block;
          &:focus{
            outline: transparent;
            box-shadow: 0 0 0 2px #9db8e4;
          }
          &:before{
            content: '';
            display: inline-block;
            height: 0;
            width: 0;
            border-top: 5px solid #000;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            margin-right: 5px;
            position: relative;
            top: -2px;
          }
          i.line_to_top{
            position: absolute;  
            display: block;
            left: -8px;
            bottom: -1px;
            height: 47px;
            width: 10px;
            border-left: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            pointer-events: none;
          }
          i.line_to_bottom{
            position: absolute;
            left: 13px;
            bottom: -15px;
            height: 15px;
            width: 1px;
            background: #b5b5b5;         
          }
        }
        .load{
          opacity: 0;
          display: block;
          position: absolute;
          right: -45px;
          top: -1px;
          border-bottom: none;
          transform: translateX(0px);
          transition: right 8s, opacity 500ms;
          pointer-events: none;
          &:after{
            content: '';
            position: absolute;
            right: 28px;
            top: 8px;
            height: 1px;
            width: 12px;
            background: #ccc;
          }
          &:before{
            content: '';
            position: absolute;
            right: 30px;
            top: 12px;
            height: 1px;
            width: 10px;
            background: #ccc;
            border: none;
          }
          &.active{
            -webkit-animation: vibrate-1 1s linear infinite both;
            animation: vibrate-1 1s linear infinite both;
            opacity: 1;
            right: -76px;
            &:after{
              -webkit-animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
              animation: shake-horizontal 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
            }
            &:before{
              -webkit-animation: shake-horizontal 1s 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
              animation: shake-horizontal 1s 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
            }
          }
          img{    
            height: 25px;
          }
        }
        .counter{
          font-size: 12px;
          border-bottom: none;
          pointer-events: none;
          &:before{
            display: none;
          }
        }
      }
      .checkbox_cover label .icon{
        margin-right: 0;
      }
      td{
        height: 48px;
        border-top: none;
        border-bottom: none;
        box-shadow: inset 0 1px rgba(0,0,0,.12);
        .price{
          font-size: 12px;
          color: #ccc;
          position: relative;
          top: -2px;
        }
        .mdl-textfield{
          padding: 0;
        }
      }
    }
    tr[data-level="0"]{
      td:first-of-type{
        font-weight: 500;
        .checkbox_cover{
          margin-right: 6px;
        }
        span{
          i:not(.material-icons){
            content: '';
            position: absolute;
            left: -4px;
            bottom: -1px;
            height: 47px;
            width: 7px;
            border-left: none;
            border-bottom: 1px dashed #757575;
          }
          &.edit_template{
            i{
              position: relative;
              left: 0;
              bottom: -5px;
              color: #3F51B5;
              cursor: pointer;
            }
          }
        }
      }
    }
    tr[data-level="1"]{
      td:first-of-type{
        font-weight: normal;
        .checkbox_cover{
          margin-right: 17px;
        }
      }
    }
    tr[data-level="2"]{
      td:first-of-type{    
        font-size: 15px;
        font-weight: lighter;
        .checkbox_cover{
          margin-right: 30px;
        }
      }
    }
    tr[data-level="3"]{
      &.bigMargin{
        td:first-of-type{
          .checkbox_cover{
            margin-right: 55px;
          }
        }
      }
      td:first-of-type{
        font-size: 12px;
        color: #606266;
        font-weight: 700;
        max-width: none;
        span{
          border-bottom: 1px solid #b5b5b5;
          padding-left: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          &:before{
            display: none;
          }
        }
        span:not(.edit_template):before{
          display: none;
        }
        .checkbox_cover{
          margin-right: 42px;
        }
      }
    }
  }
  .mdl-data-table{
    width: 100%;
    .checkbox_cover{
      position: relative;
      top: -2px;
    }
    .icon_cell{
      width: 60px;
    }
    th{
      line-height: 14px;
      padding-bottom: 17px;
      border: 1px solid #DCDFE6;
    }
    th.sortable {
      cursor: pointer;
      span{
        border-bottom: 1px dashed rgba(0,0,0,.54);
      }
    }
    th.cell_checkbox_cover, td.cell_checkbox_cover{
      padding-right: 0;
    }
    td{
      /*height: 61px;*/
      &:not(.mdl-data-table__cell--non-numeric){
        .mdl-textfield{
          border: 1px solid #DCDFE6;
          box-sizing: border-box;
          border-radius: 4px;
          width: 88px;
          padding: 2px 0 !important;
          height: 32px;
          .mdl-textfield-placeholder{
            box-shadow: none;
            border: none;
            padding: 8px;
          }
          .mdl-textfield__input{
            text-align: right;
            font-size: 14px;
            width: 100%;
            box-sizing: border-box;
            padding: 6px 8px;
            &:focus, &:active{
              border: none;
            }
          }
          .mdl-textfield__label{
            text-align: right;
          }
        }
      }
    }
  }
  .mdl-button{
    &.red{
      color: #ec0d3f;
    }
    &.blue{
      color: #1c70f5;
    }
    &.make_bid{
      background: rgba(158,158,158,.2);
      box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24);
      margin: 2px 0;
      vertical-align: middle;
      &:active{
        box-shadow: none;
      }
    }
  }
  .bet_counter{
    &.red{
      color: #dc424f;
      .counter_cover .counter{
        background: #dc424f;
        color: #fff;
      }
    }
    &.green{
      color: #48b04b;
      .counter_cover .counter{
        background: #48b04b;
        color: #fff;
      }
    }
    .counter_cover{
      display: flex;
      margin-bottom: 4px;
      .counter{
        background: #ccc;
        padding: 3px;
        border-radius: 15px;
        height: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
      i{
        margin-left: 8px;
      }
    }
    .message{
      font-weight: bold;
    }
  }
  .mdl-textfield{
    position: relative;
    .field-validation-error{
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 10px;
      color: #d50000;
    }
    .textfield__valid{
      color: #48b04b;
      position: absolute;
      font-size: 12px;
      margin-top: 3px;
      display: block;
      &.msgBlockerror {
        color: #d50000;
        font-size: 12px;
        margin-top: 0;
        display: block;
      }
    }
    &.bid_cover{
      width: 100px;
      margin-right: 7px;
    }
    .point_icon{
      color: #3F51B5;
      min-height: 24px;
      min-width: 24px;
      max-width: 24px;
      max-height: 24px;
      background: #C5CEFF;
      border-radius: 25px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      margin-right: 10px;
    }
    input.placeholdered{
      opacity: 0;
      &:focus{
        opacity: 1;
      }
    }
    input.placeholdered:focus + .mdl-textfield-placeholder{
      opacity: 0;
    }
    .mdl-textfield-placeholder{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align: right;
      box-shadow: inset 0 -1px 0 #ccc;
      font-family: "Nunito","Arial",sans-serif;
      pointer-events: none;
      font-size: 14px;
      padding: 4px 0;
      line-height: 17px;
      box-sizing: border-box;
      opacity: 1;
    }
    input[type=date]{
      border: none;
      width: 100%;
      min-width: 120px;
      padding-bottom: 3px;
      padding-top: 2px;
      margin: 3px 0;
      margin-top: 10px;
      font-size: 16px;
    }
  }
  .gavel_cell_cover{
    position: relative;
    padding-right: 80px;
    /*.gavel_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }*/
    .mdl-button{
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -2px;
      transform: translateY(-50%);
    }
    .go_ride{
      font-weight: bold;
      color: #48b04b;
      cursor: pointer;
      margin-top: 8px;
      display: inline-block;
    }
    .start{
      font-weight: bold;
      color: rgba(0,0,0,.87);
      margin-top: 8px;
      display: inline-block;
    }
    .mdl-button.green{
      width: 100%;
      margin-top: 10px;
    }
    .current{
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
  }
  .grid_cover_content{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    align-self: stretch;
    padding: 0 13px;
    padding-bottom: 10px;
    z-index: 6;
    position: relative;
    .mdl-layout-spacer{
      max-width: 100%;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
    .col-10{
      max-width: 9%;
      margin: 0 0.5%;
    }
    .col-15{
      max-width: 14%;
      margin: 0 0.5%;
    }
    .col-20{
      max-width: 19%;
      margin: 0 0.5%;
    }
    .col-25{
      max-width: 24%;
      margin: 0 0.5%;
    }
    .col-30{
      max-width: 29%;
      margin: 0 0.5%;
    }
  }
  .map_toggler {
    .map_toggler_button {
      padding: 0;
      width: 64px;
      font-size: 0;
    }
    i {
      display: inline-block;
      height: 100%;
      width: 50%;
      vertical-align: middle;
      line-height: 36px;
      background-color: #ffffff;
      color: rgb(63, 81, 181);
    }
    .toggled {
      background-color: rgb(63, 81, 181);
      color: #ffffff;
    }
  }
  .marker_map {
    height: ~"calc(100vh - 155px)" !important;
  }
  .visually-hidden {
    opacity: 0;
    position: absolute;
    top: -10000px;
    left: 0;
    z-index: -1;
    clip: rect(0 0 0 0);
  }
  .group_title{
    font-size: 16px;
    font-weight: bold;
    padding: 0 24px;
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .carrier_cover{
    padding: 0 24px;
    >div{
      overflow: visible;
    }
    .mdl-textfield{
      width: 34%;
      & +.mdl-textfield{
        //margin-left: 15px;
      }
      &.longWidth{
        width: 800px;
        max-width: 100%;
        margin-top: 20px;
      }
    }
    .checkbox_cover{
      margin: 20px 0;
    }
    .mdl-button{
      //margin-left: 10px;
    }
    .mdl-button--nomargin {
      margin-left: 0;
    }
    b{
      margin: 20px 0;
      display: inline-block;
      margin-right: 24px;
    }
    .mdl-chip{
      margin-right: 15px;
      position: relative;
      top: -3px;
    }
  }
  .mt24{
    margin-top: 24px;
  }
  .mt30{
    margin-top: 30px;
  }
  .ml15{
    margin-left: 15px;
  }
  .vabt{
    vertical-align: bottom;
  }
  .mdl-chip{
    &[type=button]{
      cursor: pointer;    
      outline: 0;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
      &:active{
        box-shadow: none;
      }
    }
    &.green{
      background: #48b04b;
      color: #fff;
    }
  }
  .mdl-button{
    &.green{
      background: #48b04b;
      color: #fff;
    }
  }
}

.checkbox_cover{
  label {
    .icon{
      border: 2px solid #1855A3;
    }
    input[type=checkbox]:checked + .icon:after{
      background: #1855A3 url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
    }
  }
}

.fuel_amount_radio_form{
  position: relative;
  flex-grow: 1;
  .label{
    position: absolute;
    left: 0;
    color: rgb(63, 81, 181);
    font-size: 12px;
    top: -10px;
  }
}
.fuel_amount_label{
  display: inline-block;
  cursor: pointer;
  position: relative;
  height: 48px;
  width: 48px;
  top: 15px;
  & + .fuel_amount_label{
    margin-left: 3px;
  }
  input[type="radio"]{
    display: none;
  }
  input[type="radio"]:checked + .icon{
    border: 2px solid #3f51b5;
  }
  .icon{
    height: 44px;
    width: 44px;
    position: relative;
    border-radius: 8px;
    border: 2px solid #ccc;
    text-align: center;
    transition: all 300ms;
    left: 0;
    top: 0;
    &:hover{
      transform: translateY(-2px);
    }
    img{
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 30px;
      position: absolute;
      display: inline-block;
    }
  }
}

.comparison_discount{
  font-size: 12px;
  color: #9e9e9e;
  line-height: 0;
  position: relative;
  top: -1px;
}

.select2-container--default{
  .select2-dropdown {
    top: -30px;
    border: none;
    .select2-search--dropdown {
      padding: 0;
      .select2-search__field {
        background: transparent;
        border: none;
        &:focus{
          outline: none;
        }
      }
    }
    .select2-results__option{
      padding: 0 16px;
      margin: 3px 0;
      min-height: 27px;
      height: auto;
      line-height: 24px;
      display: flex;
      align-items: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      -webkit-box-pack: center;
      &.select2-results__option--highlighted{
        background: #f2f2f2;
        color: #a5a5a5;
      }
    }
    .select2-results{
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      top: 37px;
      margin: 0;
      padding: 0;
      overflow: auto;
    }
  }
}

.select2-container--search_theme {
  .select2-dropdown {
    top: -33px;
    border: none;
    .select2-search--dropdown {
      padding: 0;
      .select2-search__field {
        background: transparent;
        border: none;
        &:focus{
          outline: none;
        }
      }
    }
    .select2-results__option{
      padding: 7px 16px;
      min-height: 35px;
      display: flex;
      align-items: center;
      line-height: 18px;
      &.select2-results__option--highlighted{
        background: #f2f2f2;
        color: #a5a5a5;
      }
    }
    .select2-results{
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      top: 37px;
      margin: 0;
      padding: 0;
      overflow: auto;
    }
  }
}

.select2-container--single_no_input{
  .select2-dropdown {
    top: -30px;
    border: none;
    .select2-search--dropdown {
      padding: 0;
      .select2-search__field {
        background: transparent;
        border: none;
        &:focus{
          outline: none;
        }
      }
    }
    .select2-results__option{
      padding: 0 16px;
      height: 48px;
      display: flex;
      align-items: center;
      /*&:first-of-type{
        height: 0;
      }*/
      &.select2-results__option--highlighted{
        background: #f2f2f2;
        color: #a5a5a5;
      }
    }
    .select2-results{
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      top: 37px;
      margin: 0;
      padding: 0;
      overflow: auto;
      ul{
        max-height: 50vh;
      }
    }
  }
}

.select2-container--single_no_input_top{
  .select2-dropdown {
    top: -30px;
    border: none;
    .select2-search--dropdown {
      padding: 0;
      .select2-search__field {
        background: transparent;
        border: none;
        &:focus{
          outline: none;
        }
      }
    }
    .select2-results__option{
      padding: 0 16px;
      height: 48px;
      display: flex;
      align-items: center;
      /*&:first-of-type{
        height: 0;
      }*/
      &.select2-results__option--highlighted{
        background: #f2f2f2;
        color: #a5a5a5;
      }
    }
    .select2-results{
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      top: auto;
      bottom: 11px;
      margin: 0;
      padding: 0;
      overflow: auto;
      ul{
        max-height: 50vh;
      }
    }
  }
}

.select2-container--single_no_input_mini{
  outline: 0;
  span{
    outline: 0;
  }
  .select2-dropdown {
    top: -30px;
    border: none;
    .select2-search--dropdown {
      padding: 0;
      .select2-search__field {
        background: transparent;
        border: none;
        &:focus{
          outline: none;
        }
      }
    }
    .select2-results__option{
      padding: 0 16px;
      height: 42px;
      font-size: 12px;
      display: flex;
      align-items: center;
      /*&:first-of-type{
        height: 0;
      }*/
      &.select2-results__option--highlighted{
        background: #f2f2f2;
        color: #a5a5a5;
      }
    }
    .select2-results{
      -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
      background-color: #fff;
      color: #000;
      border: none;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      -webkit-box-sizing: border-box;
      top: 37px;
      margin: 0;
      padding: 0;
      overflow: auto;
      ul{
        max-height: 300px;
      }
    }
  }
  &.select2-container{
    .select2-selection--single{
      height: 28px;
      .select2-selection__rendered {
        padding: 4px 0;
        padding-bottom: 1px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
}
.page-content .table_levels tr[data-level] td .mdl-textfield{
  position: relative;
  display: block;
  float: right;
}
.row_pagination{
  display: flex;
  justify-content: flex-end;
  color: #848484;
  padding: 0 20px;
  padding-top: 7px;
  font-size: 14px;
  .counter{
    margin-right: 24px;
    position: relative;
    top: 3px;
    .mdl-textfield{
      width: 30px;
      text-align: center;
      padding: 0;
      .mdl-textfield__input{
        text-align: center;
      }
    }
  }
  .pagination_arrows{
    height: 26px;
    a{
      color: #ccc;
    }
    .left, .right{
      cursor: pointer;
      font-size: 30px;
      margin-left: 8px;
    }
  }
}

.answer_snackbars{
  position: absolute;
  text-align: center;
  left: 50%;
  min-width: 210px;
  margin-left: -105px;
  top: 18px;
  height: 48px;
  line-height: 52px;
  padding: 0 16px;
  box-shadow: 0 4px 14px rgba(76, 76, 76, 0.3);
  border-radius: 4px;
  z-index: 9;
  color: #ffffffde;
  font-size: 14px;
  cursor: default;
  box-sizing: border-box;
  &.success_answer{
    background: #45B539;
  }
  &.error_answer{
    background: #ef4040;
  }
}

.slideFade-enter-active {
  transition: all 300ms ease;
}
.slideFade-leave-active {
  transition: all 800ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideFade-enter, .slideFade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(15px);
  opacity: 0 !important;
}

.slideFadeTopDelay-enter-active {
  transition: all 300ms ease;
}
.slideFadeTopDelay-leave-active {
  transition: all 800ms 2000ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideFadeTopDelay-enter, .slideFadeTopDelay-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-5px);
  opacity: 0 !important;
}

.slideFadeTop-enter-active {
  transition: all 300ms ease;
}
.slideFadeTop-leave-active {
  transition: all 800ms cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slideFadeTop-enter, .slideFadeTop-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-5px);
  opacity: 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-1 11:8:32
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  20% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  40% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  60% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  80% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  90% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  20% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px);
  }
  30% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  40% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  60% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  70% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  80% {
    -webkit-transform: translate(-1px, 1px);
    transform: translate(-1px, 1px);
  }
  90% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-1 11:17:48
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  80% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  90% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  80% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  90% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% { 
    -moz-transform: rotate(-360deg); 
  } 
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(-360deg); 
  } 
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform:rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(-360deg); 
    transform:rotate(-360deg); 
  } 
}
.nav_menu{
  position: fixed;
  left: 0;
  width: 47px;
  background: @color_light_blue;
  top: 0;
  transition: width 300ms;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 64px;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.25);
  .menu_lvl2.active{
    height: 0;
  }
}
.nav_menu_inner {
  overflow-y: auto;
  height: 100%;
}
.basket_header{
  margin: 8px 2px;
}
.remember_open_menu, .open_menu{
  .nav_menu{
    width: 300px;
    .menu_lvl2.active{
      height: auto;
    }
  }
  .basket_header{
    margin: 5px 0px;
  }
}

.nav_menu .mdl-navigation {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding-top: 16px
}

.nav_menu .mdl-navigation .mdl-navigation__link {
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575
}

.mdl-layout {
  &:not(.is-small-screen) {
    &.is-upgraded > .mdl-layout__header {
     

      > .mdl-layout__content {
        margin-left: 0;
      }

    
    }
  }
}

.mdl-layout__content{
  margin-left: 47px !important;
  transition: margin 0.3s;
}

.mdl-layout:not(.is-small-screen){
> .route_page.mdl-layout__content {
  margin-left: 0;
  .page-content .page_big_map{
    height: 100vh;
  }
}
}

.mCSB_scrollTools_horizontal{
&.fixed {
  position: fixed;
  bottom: 0;
  margin: 0 17px 0 0;
  padding: 0 50px;
  max-width: 100%;
  z-index: 77;
  box-sizing: border-box;
}
}
.version {
max-width: 1800px;
margin: 0 auto;
padding: 60px;
}
.unconfirmed {
background-color: #FFCECE;
}

@import "../components/print.less";
@import "../components/tracking.less";
@import "../components/new_path_dispatcher.less";
@import "../components/settings_dispatcher.less";
@import "../components/reports.less";
@import "../components/modals_dispatcher.less";
@import "../components/vue-multiselect.less";
@import "../partials/documents";
@import "../partials/monitoring_page";
@import "../partials/monitoring_request";
@import "../partials/new_route_page";
@import "../partials/side_menu";
@import "../partials/general_settings";
@import "../partials/tasks";
@import "../partials/markers";
@import "../partials/driver_card";
@import "../partials/instructor";
@import "../partials/add_route";
@import "../partials/calculation_result";
@import "../partials/training";
@import "../partials/loader";
@import "../partials/telerik";

@import "../partials/inner_page";
@import "../partials/number_filter";
@import "../partials/client";
@import "../partials/admin";
@import "../partials/home";
@import "../partials/client_schedule";
@import "../partials/inner_table";
@import "../partials/trip";
@import "../partials/boarding";
@import "../partials/options";
@import "../partials/ticket";
@import "../partials/receipt";
@import "../partials/client_ticket_page";
@import "../partials/salesoffices";
@import "../partials/profile";
@import "../partials/statement";
@import "../partials/order_info";
@import "../partials/persons";
@import "../partials/article";
@import "../partials/modals";
@import "../partials/error_page";
@import "../partials/media";
