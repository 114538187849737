#app_gas_station_map{
  height: 100vh !important;
  .info_col_cover{
    height: 100vh;
  }
  .info_col{
    padding-bottom: 0;
    .info_block{
      padding: 20px 16px;
      padding-left: 32px;
      .title{
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        display: block;
      }
      .radio_cover, .checkbox_cover{
        margin-bottom: 10px;
        display: block;
      }
    }
  }
  .mobile_filter_icon, .mobile_filter_icon_close, .mobile_ok_filter{
    display: none;
  }
  .color_indificator{
    margin-left: 5px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
}

#gasStationModal{
  .gasStationInfo{
    >div{
      margin-bottom: 10px;
      b{
        margin-right: 5px;
      }
    }
  }
  .modal_footer{
    input{
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, .12);
      display: inline-block;
      font-size: 16px;
      font-family: "Helvetica", "Arial", sans-serif;
      margin: 0;
      padding: 4px 0;
      background: 0 0;
      text-align: left;
      color: inherit;
      &:focus{
        outline: none;
      }
    }
    .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect{
      display: none;
    }
    .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p{
      display: none;
    }
  }
}

@media screen and (max-width: 1000px){
  #gasStationModal, #noGasStationsModal, #locationDisabledModal{
    width: 85%;
    padding: 25px;
    padding-top: 35px;
    margin: 0 auto;
    .modal_footer{
      >div{
        display: flex;
      }
      #coordinateInput{
        width: 65%;
      }
      .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect{
        display: block;
      }
      .mdl-button.mdl-js-button.mdl-button--colored.mdl-button--raised.mdl-js-ripple-effect + p{
        display: block;
      }
    }
  }
  #app_gas_station_map{
    .mobile_filter{
      transition: right 400ms;
    }
    .info_col_cover{
      position: absolute;
      width: 100%;
      right: 0;
      .info_col{
        right: 0;
        width: 100%;
        padding-bottom: 50px;
      }
    }
    .mobile_filter_icon, .mobile_filter_icon_close{
      display: block;
      cursor: pointer;
    }
    .mobile_filter_icon_close{
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      background: transparent;
    }
    .mobile_filter_icon{
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 9px;
      background: #3f51b5;
      border-radius: 5px;
      box-shadow: 0 0 15px -1px #cccccc;
      line-height: 0;
      i{
        color: #fff;
      }
    }
    .content_cols{
      justify-content: flex-start !important;
    }
    .mobile_ok_filter{
      display: inline-block;
      margin-left: 20px;
    }
  }
}
