.article {
  padding: 0 10px;
  color: @color_deep_blue;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-family: 'Nunito', Arial, sans-serif;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
  }

  h2 {
    font-size: 32px
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  p{
    padding-bottom: 15px;
  }

  ul{
    padding-bottom: 15px;

    li{
      list-style-type: disc;
      list-style-position: inside;
      padding: 5px 0;
    }
  }

  ol{
    padding-bottom: 15px;

    li{
      list-style-position: inside;
      padding: 5px 0;
    }
  }

  input, textarea{
    padding: 6px 10px;
    font-size: 13px;
    line-height: 1.462;
    border-radius: 6px;
    border: 2px solid @color_light_blue;
    resize: none;

    &:focus{
      border-color: @color_dark_blue;
      transition: all 0.2s;
    }
  }

  a{
    display: inline-block;
    color: @color_deep_blue;
  }

  .table_cover {
    margin: 20px 0;
    overflow-x: auto;

    table {
      border-collapse: collapse;
      th,
      td {
        border: 1px solid @color_light_blue;
        padding: 5px;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
  }
}

.edit_article {
  .edit_article_filters {
    flex-direction: column;
    padding-bottom: 35px;
  }

  .filters_row {
    display: flex;
  }

  .textarea_cover {
    textarea {
      padding-top: 12px;
      min-height: 40px;
    }
  }

  .article_divider {
    padding: .75rem 30px;
    background-color: @color_light_blue;
    color: #ffffff;
  }

  .article_editor_cover {
    padding: 15px 30px;
  }

  .article_editor {
    .k-editor {
      border-collapse: separate;
      border-spacing: 0 8px;
    }

    .k-editor-toolbar {
      background: none;
      padding: 10px 10px 4px 10px;
      border: 1px solid @color_light_blue;
      border-radius: 20px;
    }

    .k-tool-group {
      margin-bottom: 6px;
    }

    .k-editable-area {
      padding: 10px;
      border: 1px solid @color_light_blue;
      border-radius: 20px;
    }
  }

  .edit_article_controls {
    padding: 30px;
  }
}

@media (max-width: 1000px) {
  .article {
    ul,
    ol {
      padding-left: 10px;
      line-height: 20px;
    }
  }
}