@table_highlight_red: #FFCECE;
@table_highlight_yellow: #FFF6D4;

.highlight_red {
  background-color: @table_highlight_red;
}

.highlight_yellow {
  background-color: @table_highlight_yellow;
}

.x_scroll {
  overflow-x: auto;

  th {
    white-space: normal;
  }
}