.page-content {
  .page_big_map.new_path_dispatcher {
    .info_col_cover {
      overflow: initial;

      .info_col.big {
        margin-left: auto;

        .table-cover {
          height: ~"calc(100vh - 261px)";
          width: auto;
          margin: 0 -25px;
        }

        .table_levels_small {
          .dispatcher_narrow_col {
            width: 50px;
          }
          .dispatcher_wide_col {
            width: 230px;
          }
        }

        .table_levels_small_summary {
          padding: 25px 25px 10px 25px
        }
      }
    }
    .dispatcher_total {
      b {
        text-transform: uppercase;
        margin-right: 10px;
      }
    }
  }
}