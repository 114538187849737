.documentation_page{
  .doc_list{
    white-space: nowrap;
    >div{
      display: inline-block;
    }
    button{
      height: 30px;
      width: 30px;
      margin-right: 10px;
      padding: 0;
      border: none;
      box-shadow: none;
      background: transparent;
      cursor: pointer;
      text-align: center;
      transition: box-shadow 400ms;
      i.material-icons{
        line-height: 30px;
        transition: all 400ms;
      }
      &.doc_status{
        border-radius: 50%;
        background: #C4C4C4;
        i.material-icons{
          color: #fff;
        }
        &.accepted{
          background: #17D435;
        }
        &.not_accepted{
          background: #EB3535;
        }
        &:hover{
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }
      }
      &.add_doc{
        &:hover{
          i.material-icons{
            text-shadow: 1px 1px  rgba(0, 0, 0, .2);
          }
        }
      }
    }
  }
  .doc_button_send, .doc_completion{
    width: 230px;
    border-radius: 2px;
    text-transform: uppercase;
    border: 1px solid #3F51B5;
    line-height: 27px;
    box-shadow: none;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
  }
  .doc_button_send{
    color: #fff;
    cursor: pointer;
    background: #3F51B5;
  }
  .doc_completion{
    background: #fff;
    color: #3F51B5;
  }
  .mdl-button.red.doc_list_delete {
    color: #ffffff;

    &:hover {
      background-color: rgb(63, 81, 181);
    }
  }
  .doc_list_accept{
    border-radius: 2px;
    text-transform: uppercase;
    color: #fff;
    line-height: 27px;
    box-shadow: none;
    font-weight: 500;
    font-size: 14px;
    background: #17D435;
    cursor: pointer;
    border: none;
    padding: 1px 15px;
  }
}

.mdl-button--colored{
  &.red{
    background: #EB3535;
    &:hover{
      background: #EB3535;
    }
  }
  &.green{
    background: #17D435;
    &:hover{
      background: #17D435;
    }
  }
}

#add_document_modal, #document_rework_modal{
  width: 360px;
  .mdl-textfield{
    width: 100%;
  }
}

#document_more_modal{
  .doc_images{
    a, img{
      display: block;
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
.doc_download {
  color: #ffffff;
  text-decoration: none;
}